import React, { useState, useEffect, useContext } from "react"
import { FirebaseContext } from "../Firebase"
import Modal from "react-bootstrap/Modal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faImages, faSpinner } from "@fortawesome/free-solid-svg-icons"
import imageCompression from "browser-image-compression"
import { FormattedMessage, useIntl } from "react-intl"

let fileReader
if (typeof window !== "undefined") {
  fileReader = new FileReader()
}

const ProfileImageModal = ({ currentImage, hasText }) => {
  const { firebase } = useContext(FirebaseContext)
  const [error, setError] = useState(null)
  const [fileUrl, setFileUrl] = useState(currentImage)
  const [fileBlob, setFileBlob] = useState(null)
  const [open, setOpen] = useState(false)
  const [isCompressing, setIsCompressing] = useState(false)
  const { formatMessage } = useIntl()
  const { locale } = useIntl()

  useEffect(() => {
    // set the preview image to current image, once available
    if (currentImage) {
      setFileUrl(currentImage)
    }
  }, [currentImage])

  /**
   * Validate image parameters and generate thumbnail.
   *
   * @param {SyntheticEvent} e
   */
  const handleImageChange = async e => {
    e.persist()
    const file = e.target.files[0]

    setError(null)

    if (!file) {
      return
    }

    const allowedTypes = ["image/jpg", "image/jpeg", "image/png"]

    if (!allowedTypes.includes(file.type)) {
      setError("File must be one of jpg, jpeg, png")
      return
    }

    const profileImageLabel = document.getElementById('profileImageLabel');
    if (file) {
      profileImageLabel.textContent = `${file.name}`;
    } else {
      profileImageLabel.textContent = locale === 'en-US' ? 'No file chosen' : 'Ningún archivo seleccionado';
    }

    try {
      const compressedImage = await imageCompression(file, {
        maxSizeMB: 2,
        onProgress: percent => {
          percent < 100 ? setIsCompressing(true) : setIsCompressing(false)
        },
      })
      setIsCompressing(false)

      // Triggered by readAsDataURL
      fileReader.onloadend = () => {
        setFileUrl(fileReader.result)

        // create image element in order to capture pixel dimensions
        const img = new Image()
        img.src = fileReader.result
      }

      fileReader.readAsDataURL(compressedImage)
      setFileBlob(compressedImage)
    } catch {
      setError("Something went wrong uploading your image")
    }
  }

  const handleSubmit = e => {
    e.preventDefault()

    if (!fileBlob || error) {
      setError("You must upload a file before saving")
      return
    }

    // store the image
    firebase
      .storeProfileImage(fileBlob)
      .then(fileUrl => {
        if (fileUrl) {
          firebase
            .updateProfile({ data: { profileImageUrl: fileUrl } })
            .catch(e => {
              setError(
                "There was a problem updating your profile. Please try again later."
              )
            })
        }
      })
      .catch(e => {
        setError(
          "There was a problem updating your profile. Please try again later."
        )
      })

    setOpen(false)
  }

  return (
    <>
      <a
        href="#"
        style={{ fontWeight: "bold" }}
        onClick={(e) => {
          e.preventDefault()
          setOpen(true)
        }}
      >
        {hasText ? (
          <>
            <button className="button component-button component-button-ghost component-button-ghost-inactive component-button-small">
            <FormattedMessage id="portfolio-profile-image-change-profile-picture" defaultMessage="Change Profile Picture" />{" "}
            </button>
          </>
        ) : (
          <FontAwesomeIcon icon={faImages} />
        )}
      </a>
      <Modal
        show={open}
        size="lg"
        onShow={() => {
          setError(null)
        }}
        onHide={() => setOpen(false)}
        centered
        animation={false}
      >
        <Modal.Header style={{ justifyContent: "center"}}>
          <h3 style={{ marginBottom: "0" }}>
            <FormattedMessage id="portfolio-profile-image-change-profile-picture" defaultMessage="Change Profile Picture" />{" "}
          </h3>
        </Modal.Header>
        <form onSubmit={handleSubmit} style={{ marginBottom: "0px" }}>

        <Modal.Body style={{ paddingBottom: '30px'}}>
          <div>
            <h5>
              <FormattedMessage id="portfolio-profile-image-preview-profile-picture" defaultMessage="Profile Picture Preview" />
            </h5>
              {fileUrl && !isCompressing ? (
                <div
                  style={{
                    backgroundImage: `url(${fileUrl})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    borderRadius: ".5rem",
                    maxWidth: "320px",
                    height: "320px",
                    border: "1px dashed #bbb",
                    margin: "3rem auto",
                    overflow: "hidden",
                  }}
                />
              ) : (
                <div
                  style={{
                    borderRadius: ".5rem",
                    width: "320px",
                    height: "320px",
                    border: "1px dashed #bbb",
                    backgroundColor: "white",
                    margin: "3rem auto",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FontAwesomeIcon icon={faSpinner} spin size="3x" color="#4DA497" />
                </div>
              )}
              <div
                className="form-element"
                style={{
                  border: "1px solid #d3d3d3",
                  padding: "10px",
                  display: "flex",
                  alignItems: "baseline",
                  gap: "10px",
                  borderColor: error ? "red" : "",
                }}
              >
                <label
                  style={{
                    padding: "2px",
                    width: "max-content",
                    border: "1px solid #565656",
                    cursor: "pointer",
                    margin: "0px",
                    borderRadius: "3px",
                  }}
                  className=" custom-file-input-label"
                  htmlFor="profileImage"
                >
                  {locale === "en-US" ? "Choose file" : "Elija el archivo"}
                </label>
                <input id="profileImage" name="profileImage" type="file" onChange={handleImageChange} />
                <span id="profileImageLabel">{locale === "en-US" ? "No file chosen" : "Ningún archivo seleccionado"}</span>
                {error && <small style={{ color: "red" }}>{error}</small>}
              </div>

          </div>
        </Modal.Body>
        <Modal.Footer>
        <div className="form-element" style={{ textAlign: "right", marginBottom: "0px" }}>
                <button
                  className="component-button component-button-cancel"
                  style={{ marginRight: "1rem" }}
                  onClick={(e) => {
                    e.preventDefault()
                    setOpen(false)
                  }}
                >
                  <FormattedMessage id="dashboard-cancel" defaultMessage="Cancel" />
                </button>
                <input
                  type="submit"
                  className="component-button component-button-submit"
                  value={formatMessage({ id: "dashboard-this-week-save-button", defaultMessage: "Save" })}
                />
              </div>
        </Modal.Footer>
        </form>

      </Modal>
    </>
  )
}

export default ProfileImageModal
