import React from "react"
import ExperienceDatesInput from "./experience-dates-input"
import ExperienceTextInput from "./experience-text-input"
import { FormattedMessage, useIntl } from "react-intl"

const ExperienceInputLife = ({ type }) => {
  const { formatMessage } = useIntl()
  return (
    <>
      <ExperienceTextInput
        placeholder={formatMessage({ id: 'resume-experience-life-form-milestone-placeholder', defaultMessage: "Ex. Moved to a new city" })}
        label={<FormattedMessage
          id="resume-experience-form-milestone"
          defaultMessage="Milestone"
        />}
        dataKey="milestone"
        required={true}
      />
      <ExperienceDatesInput
        label={<FormattedMessage
          id="resume-experience-form-time-period"
          defaultMessage="Time Period"
        />}
        dataKey="timePeriod"
        required={true}
        isSingle={true}
      />
      <ExperienceTextInput
        placeholder={formatMessage({ id: 'resume-experience-life-form-description-placeholder', defaultMessage: "Ex. I have always dreamed of returning to New York City so I packed my bags and set out on a new adventure." })}
        label={<FormattedMessage
          id="resume-experience-form-description"
          defaultMessage="Description"
        />}
        dataKey="description"
        required={true}
        inputType="textarea"
      />
    </>
  )
}

export default ExperienceInputLife
