import React, { useState, useContext, useEffect } from "react"
import "./notifications.css"
import Notifications from "./notifications"
import OutsideClickHandler from "react-outside-click-handler"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBell } from "@fortawesome/pro-light-svg-icons"
import { FirebaseContext } from "../../Firebase"

const NotificationsDropdown = () => {
  const [showDropdown, setShowDropdown] = useState(false)
  const {
    localFirestore: { notificationsRecord, markUserNotificationsAsRead },
    notifier,
  } = useContext(FirebaseContext)
  const unreadNotificationCount = notificationsRecord.filter((notification) => !notification.isRead).length

  const handleToggleDropdown = (e) => {
    e.preventDefault()
    setShowDropdown(!showDropdown)

    if (showDropdown){
      markUserNotificationsAsRead()
    } 
  }

  const handleOutsideClick = (e) => {
    e.preventDefault()
    // handles outside click for notifications dropdown
    if (
      e?.target?.parentNode?.classList?.contains("notifications-icon-svg") ||
      e?.target?.parentNode?.classList?.contains("notifications-icon") ||
      e?.target?.parentNode?.classList?.contains("notifications-dropdown")
    ) {
      return
    }
    markUserNotificationsAsRead()
    setShowDropdown(false)
  }

  useEffect(() => {
    notifier.setIsDisabled(showDropdown)
  }, [showDropdown])

  return (
    <div className="notifications-dropdown">
      <div className={`notifications-icon ${showDropdown && "showing"}`} onClick={handleToggleDropdown}>
        <FontAwesomeIcon className="notifications-icon-svg" color={"white"} style={{ cursor: "pointer" }} icon={faBell} size="lg" />
        {unreadNotificationCount > 0 && !notifier.isDisabled && (
          <div className="notification-indicator">
            <span>{unreadNotificationCount}</span>
          </div>
        )}
      </div>
      {showDropdown && (
        <OutsideClickHandler onOutsideClick={handleOutsideClick}>
          <div className="dropdown-content">
            <Notifications setShowDropdown={setShowDropdown} />
          </div>
        </OutsideClickHandler>
      )}
    </div>
  )
}

export default NotificationsDropdown
