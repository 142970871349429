
/**
 * This is a list of milestone types that have a single date, and they have
 * a free text field.
 *
 * @see MilestoneTextDate
 * @see allowedTypes
 *
 * @type {string[]}
 */
export const allowedTextDateTypes = [
  'prof_license',
  'certificate',
  'exam',
  'hs_diploma',
  'portfolio',
  'classes',
];

/**
 * This is a list of milestone types that have a date period, and they have
 * a free text field.
 *
 * @see MilestoneTextDates
 * @see allowedTypes
 *
 * @type {string[]}
 */
export const allowedTextDatePeriodTypes = [
  'apprenticeship',
  'training',
];

/**
 * Allowed list types. This list is used for creating and rendering milestone
 * that have a goal, list, and time period. Usually the list consists of school
 * with a major as the goal.
 *
 * @see MilestoneListDates
 * @see allowedTypes
 *
 * @type {string[]}
 *   `milestone_id`'s that are the school type.
 */
export const allowedListTypes = [
  'internship',
  'post_doc',
  'fellowship',
  'residency',
  'work_experience',
];

/**
 * List of milestones that are Algolia enabled and are a list type.
 *
 * These types are list types that use a date period. If adding a type
 * you must also add the index data to `AlgoliaEnabledMilestones'
 *
 * @see src/components/careers/algolia/AlgoliaEnabledMilestones.js
 *
 * @type {string[]}
 */
export const allowedAlgoliaListTypes = [
  'masters_deg',
  'bachelor_degree',
  'associate_degree',
  'doctorate',
]

/**
 * Allowed milestone types that are synced from Cosmic.
 *
 * The ordering of this array determines the order in which milestones appear
 * in the career builder. This array must be updated if a new milestone type is added
 * and handling must be added to the `MilestoneFactory` and to the career builder.
 * You must also make certain that there is handling added for pathways in
 * `CareerPlanPathwayFactory`. If you are adding new milestones you must make
 * sure their milestone id will be present in this array.
 *
 * @see src/components/career-plans/CareerPlanPathwayFactory.js
 * @see src/components/careers/MilestoneFactory.js
 * @see src/components/careers/milestone-input.js
 *
 * @type {string[]}
 *   The milestone_id that is used to create the correct milestone structure.
 */
const allowedTypes = [
  ...allowedAlgoliaListTypes,
  ...allowedListTypes,
  ...allowedTextDateTypes,
  ...allowedTextDatePeriodTypes
];

/**
 * List of allowed months used in month inputs and validation.
 *
 * @type {string[]}
 */
export const allowedMonths = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
];

/**
 * List of allowed month names.
 *
 * @type {string[]}
 */
export const allowedMonthsNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];
export const allowedMonthsNamesEs = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Puede",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre"
];

/**
 * If the date being set is a valid date.
 *
 * @param {string} date
 *   The string date.
 *
 * @return {boolean}
 *   If the date is valid or not.
 */
export const isValidDateString = (date) => {
  if (!date) {
    return false;
  }
  const dateArray = date.split("-");
  if (dateArray.length !== 2) {
    return false;
  }
  const yearString = dateArray[0];
  if (!isValidYear(yearString)) {
    return false;
  }

  const monthString = dateArray[1];
  return isValidMonthString(monthString);
}

/**
 * Parses month day data and returns object.
 *
 * This does not validate if the date actually exists for the month because no
 * year data is available. And this is used for data processing, not for user
 * input.
 *
 * @param {string} date
 *   The date in `M-D` format.
 *
 * @return {{month: number, day: number}|null}
 *   The date parsed as integers or null if invalid.
 */
export const getValidMonthDay = (date) => {
  if (!date) {
    return null;
  }
  const dateArray = date.split("-");
  if (dateArray.length !== 2) {
    return null;
  }
  const month = parseInt(dateArray[0]);
  if (month > 12 || month < 1) {
    return null;
  }
  const day = parseInt(dateArray[1]);
  if (day > 31 || day < 0) {
    return null;
  }
  return { month, day };
}

/**
 * Checks if the month string is valid.
 *
 * @param {string} month
 *   The month to check
 *
 * @return {boolean}
 *   If the month is valid.
 */
export const isValidMonthString = (month) => {
  return allowedMonths.includes(month);
}

/**
 * Checks if the year string is valid.
 *
 * @param {string} yearString
 *   The year string.
 *
 * @return {boolean}
 *   If the date is valid.
 */
export const isValidYear = (yearString) => {
  if (yearString.length !== 4) {
    return false;
  }
  const yearInt = parseInt(yearString);

  return !(yearInt < 1900 || yearInt > 9999);
}

export default allowedTypes;
