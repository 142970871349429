import {
  Paragraph,
  TextRun,
  Tab,
  TabStopType,
} from "docx"
import { renderEducationSection } from "./renderEducationSection"
import { renderLeadershipSection } from "./renderLeadershipSection"
import { renderWorkSection } from "./renderWorkSection"
import { renderVolunteerSection } from "./renderVolunteerSection"
import { renderLifeSection } from "./renderLifeSection"
import { renderSkillsInterestsSection } from "./renderSkillsInterestsSection"
import { renderAwardsSection } from "./renderAwardSection"

const accentTemplate = (
  name,
  city,
  state,
  email,
  work,
  education,
  volunteer,
  awards,
  life,
  leadership,
  skills,
  interests,
  aboutMe,
  strengths
) => {
  return {
    styles: {
      default: {
        document: {
          run: {
            font: "Calibri",
          },
        },
      },
    },
    sections: [
      {
        properties: {
          page: {
            margin: {
              top: 720,
              right: 720,
              bottom: 720,
              left: 720,
            },
          },
        },
        children: [
          ...createResumeHeader(name, city, state, email),
          // Add an empty paragraph as a spacer
          new Paragraph({
            text: "",
            spacing: { after: 200 },
          }),
          ...(education && education.length > 0 ? renderEducationSection(education) : []),
          ...(work && work.length > 0 ? renderWorkSection(work) : []),
          ...(leadership && leadership.length > 0 ? renderLeadershipSection(leadership) : []),
          ...(volunteer && volunteer.length > 0 ? renderVolunteerSection(volunteer) : []),
          ...(awards && awards.length > 0 ? renderAwardsSection(awards) : []),
          ...(life && life.length > 0 ? renderLifeSection(life) : []),
          ...renderSkillsInterestsSection(skills, interests),
        ],
      },
    ],
  }
}

/**
 * Generates the header section for the accent template
 */
const createResumeHeader = (name, city, state, email) => {
  const headerTabStops = [
    {
      position: 5225,
      type: TabStopType.LEFT,
    },
    {
      position: 7837.5,
      type: TabStopType.LEFT,
    },
  ]

  return [
    new Paragraph({
      tabStops: headerTabStops,
      children: [
        new TextRun({
          text: name,
          bold: true,
          size: 32,
        }),
        new TextRun({ 
          children: [
            new Tab(),
            "Phone",
            new Tab(),
            "Email",
          ],
          bold: true,
        }),
      ],
    }),
    new Paragraph({
      tabStops: headerTabStops,
      children: [
        new TextRun({ 
          children: [
            new Tab(),
            "PHONE",
            new Tab(),
            email,
          ],
        }),
      ],
    }),
    new Paragraph({
      tabStops: headerTabStops,
      children: [
        new TextRun({ 
          children: [
            new Tab(),
            "Location",
            new Tab(),
            "LinkedIn",
          ],
          bold: true,
        }),
      ],
    }),
    new Paragraph({
      tabStops: headerTabStops,
      children: [
        new TextRun({ 
          children: [
            new Tab(),
            `${city}, ${state}`,
            new Tab(),
            "LINKEDIN",
          ],
        }),
      ],
    }),
  ]
}

export default accentTemplate
