import { useStaticQuery, graphql } from "gatsby"

export const useActivities = locale => {
  const { allCosmicjsActivities } = useStaticQuery(graphql`
    query {
      allCosmicjsActivities {
        edges {
          node {
            slug
            id
            title
            locale
            metadata {
              activity_category {
                value
              }
              activity_view_url
              activity_download_file {
                url
              }
              activity_image {
                url
              }
              activity_image_alt
            }
          }
        }
      }
    }
  `)
  return allCosmicjsActivities.edges
    .filter(career => career.node.locale === locale)
    .map((career, index) => ({
      key: index,
      title: career.node.title,
      slug: career.node.slug,
      id: career.node.id,
      category: career.node.metadata.activity_category.value,
      viewUrl: career.node.metadata.activity_view_url,
      downloadFile: career.node.metadata.activity_download_file?.url,
      image: career.node.metadata.activity_image?.url,
      imageAlt: career.node.metadata.activity_image_alt,
    }))
}
