import React, { useState, useContext, createContext, useEffect } from "react"
import Tabs from "react-responsive-tabs"
import DashboardTabContent from "./dashboard-tab-content"
import Dashboard from "./dashboard-heading"
import "react-responsive-tabs/styles.css"
import "./dashboard.css"
import { useQueryParam, StringParam } from "use-query-params"
import { PropsContext } from "./PropsContext"
import { FirebaseContext } from "../Firebase"
import { navigate } from "gatsby"

function getTabs() {
  return DashboardTabContent.map((tab, index) => ({
    title: tab.name,
    getContent: () => tab.content,
    /* Optional parameters */
    key: index,
    tabClassName: "tab tab-page",
    panelClassName: "panel",
  }))
}

const DashboardExplore = (props) => {
  const { user, profile, firebase, notifier } = useContext(FirebaseContext)

  const [isWelcomeSent, setIsWelcomeSent] = useState(false)

  useEffect(() => {
    if (!user || user?.membership?.active !== true) {
      navigate("/", { replace: true })
    }
  }, [user])

  useEffect(() => {
    const sendAsyncWelcome = async () => {
      if (!!profile && !isWelcomeSent) {
        await sendWelcomeNotification(profile?.loginCount)
      }
    }

    sendAsyncWelcome()
  }, [])

  const sendWelcomeNotification = async (loginCount) => {
    if (isWelcomeSent) return
    setIsWelcomeSent(true)
    const visitCount = await firebase.getVisitCount()

    if (visitCount <= 1 && loginCount <= 1) {
      await notifier.handleWelcomeNotification()
    }

    if (visitCount > 1) {
      await notifier.handleReturningUserNotification()
    }
  }

  // tab param in written string format
  const [tabParam, setTabParam] = useQueryParam("tab", StringParam)

  // selected tab index
  const [selectedTab, setSelectedTab] = useState(0)

  // array of avaliable tabs in written string format
  const tabs = DashboardTabContent.map((tab) => tab.param)

  const setLocation = (index) => {
    // prevents default focus behavior from RRT from lingering after click or back / forward navigation
    document.activeElement.blur()

    setTabParam(tabs[index], "push")
    setSelectedTab(index)
  }

  useEffect(() => {
    // if there is a tab parameter, set the selected tab to that, default to 0 if missing, need to trim trailing slashes in URL
    if (tabParam) {
      tabs.includes(tabParam.replace(/\/$/, "")) && setSelectedTab(tabs.indexOf(tabParam.replace(/\/$/, "")))
    } else {
      setSelectedTab(0)
    }
  }, [tabParam])

  return (
    <PropsContext.Provider value={{ ...props }}>
      <section className="dashboard-intro-tabs">
        <div id="tab-nav-position">
          <Dashboard />
        </div>
        <div id="start" />
        <Tabs
          items={getTabs()}
          onChange={(index) => setLocation(index)}
          transform={false}
          selectedTabKey={selectedTab}
          showMoreLabel={<>&#8942;</>}
          showMore={true}
        />
      </section>
    </PropsContext.Provider>
  )
}

export default DashboardExplore
