import { useState, useEffect } from "react"

// This is a custom hook that handles notifications, local state is used to keep track of dynamic values used in composing notifications
const useSiteMetrics = (firebase) => {
  const [isInitialized, setIsInitialized] = useState(false)

  useEffect(() => {
    /**
     * Initialize so that we can check if the user is logged in before any actions are taken
     */
    const init = async () => {
      const userId = firebase?.auth?._delegate?.currentUser?.uid || null

      if (isInitialized) return
      if (!userId) return
      logUserSiteVisit()
      setIsInitialized(true)
    }

    init()
  }, [firebase?.auth?._delegate?.currentUser?.uid, isInitialized])

  const logUserSiteVisit = async () => {
    const detectBrowserName = () => {
      const userAgent = navigator.userAgent

      if (userAgent.includes("Chrome") && !userAgent.includes("Edg")) {
        return "Chrome"
      } else if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
        return "Safari"
      } else if (userAgent.includes("Firefox")) {
        return "Firefox"
      } else if (userAgent.includes("MSIE") || userAgent.includes("Trident")) {
        return "Internet Explorer"
      } else if (userAgent.includes("Edg")) {
        return "Edge"
      } else {
        return "Unknown"
      }
    }

    const detectDeviceType = () => {
      const userAgent = navigator.userAgent

      if (/Mobi|Android/i.test(userAgent)) {
        return "Mobile"
      } else if (/Tablet|iPad/i.test(userAgent)) {
        return "Tablet"
      } else {
        return "Desktop"
      }
    }

    const detectOperatingSystem = () => {
      const userAgent = navigator.userAgent.toLowerCase() // Normalize to lower case

      if (userAgent.includes("win")) {
        return "Windows"
      } else if (userAgent.includes("mac")) {
        return "macOS"
      } else if (userAgent.includes("linux")) {
        return "Linux"
      } else if (userAgent.includes("android")) {
        return "Android"
      } else if (userAgent.includes("iphone") || userAgent.includes("ipad")) {
        return "iOS"
      } else {
        return "Unknown" // If no match is found
      }
    }

    const detectBrowserVersion = () => {
      const userAgent = navigator.userAgent
      let match

      // Checking for common browser version patterns
      if ((match = userAgent.match(/Chrome\/([0-9.]+)/))) {
        return match[1] // Extract the version from Chrome
      } else if ((match = userAgent.match(/Firefox\/([0-9.]+)/))) {
        return match[1] // Extract the version from Firefox
      } else if ((match = userAgent.match(/Version\/([0-9.]+)/))) {
        return match[1] // Extract the version from Safari
      } else if ((match = userAgent.match(/Edge\/([0-9.]+)/))) {
        return match[1] // Extract the version from Edge
      } else if ((match = userAgent.match(/Trident.*rv:([0-9.]+)/))) {
        return match[1] // Extract the version from Internet Explorer 11
      }

      return "Unknown" // Return "Unknown" if no match is found
    }

    const browserName = detectBrowserName()
    const browserVersion = detectBrowserVersion()
    const deviceType = detectDeviceType()
    const operatingSystem = detectOperatingSystem()

    const userSiteVisit = {
      os: operatingSystem,
      browser: browserName,
      browserVersion: browserVersion,
      device: deviceType,
    }

    await firebase.logUserSiteVisit(userSiteVisit)
  }

  return {}
}

export default useSiteMetrics
