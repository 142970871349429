import React from "react"
import ExperienceDatesInput from "./experience-dates-input"
import ExperienceTextInput from "./experience-text-input"
import ExperienceBulletsInput from "./experience-bullets-input"
import { FormattedMessage, useIntl } from "react-intl"

/**
 * Component renders experience form inputs.
 *
 */
const ExperienceInputWork = ({ type }) => {
  const { formatMessage } = useIntl()
  return (
    <>
      <ExperienceTextInput
        placeholder={formatMessage({ id: 'resume-experience-form-title-placeholder', defaultMessage: "Ex. Engineer in Thermophysics Group" })}
        label={<FormattedMessage
          id="resume-experience-form-title"
          defaultMessage="Title"
        />}
        dataKey="title"
        required={true}
        type={type}
      />
      <ExperienceTextInput
        placeholder={formatMessage({ id: 'resume-experience-form-employer-placeholder', defaultMessage: "Ex. The Boeing Co." })}
        label={<FormattedMessage
          id="resume-experience-form-employer"
          defaultMessage="Employer"
        />}
        dataKey="employer"
        required={true}
        type={type}
      />
      <ExperienceDatesInput
        label={<FormattedMessage
          id="resume-experience-form-time-period"
          defaultMessage="Time Period"
        />}
        dataKey="timePeriod"
        required={true}
        isSingle={false}
      />
      <ExperienceTextInput
        placeholder={formatMessage({ id: 'resume-experience-form-location-placeholder', defaultMessage: "Ex. Los Angeles" })}
        label={<FormattedMessage
          id="resume-experience-form-location"
          defaultMessage="Location"
        />}
        dataKey="location"
        required={true}
        type={type}
      />
      <ExperienceTextInput
        placeholder={formatMessage({ id: 'resume-experience-form-description-placeholder', defaultMessage: "Ex. Designed satellites to make sure they could endure the extreme temperatures in space." })}

        label={<FormattedMessage
          id="resume-experience-form-description"
          defaultMessage="Description"
        />}
        dataKey="description"
        inputType="textarea"
        type={type}
      />
      <ExperienceBulletsInput
        label={<FormattedMessage
          id="resume-experience-form-highlights"
          defaultMessage="Highlights"
        />}
        dataKey="highlights"
        type={type}
        placeholders={[
          formatMessage({ id: 'resume-experience-form-highlights-placeholder-1', defaultMessage: "Ex. Analyzed spacecraft components for conditions in space." }),
          formatMessage({ id: 'resume-experience-form-highlights-placeholder-2', defaultMessage: "Ex. Worked on research and development in thermal transport technology." })
        ]}
      />
    </>
  )
}

export default ExperienceInputWork
