import { isValidDateString, isValidMonthString, isValidYear } from "./AllowedTypes"

/**
 * Provides a base class for handling a single date.
 */
export default class DateMonthYear {

  /**
   * Constructor.
   *
   * @param {string} date
   *   The date.
   */
  constructor(date) {
    if (isValidDateString(date)) {
      this._year = date.split("-")[0];
      this._month = date.split("-")[1];
    }
  }

  /**
   * Clears the date values.
   */
  clearDate() {
    this._year = null;
    this._month = null;
  }

  /**
   * Clears the month value.
   */
  clearMonth() {
    this._month = null;
  }

  /**
   * Clears the year value.
   */
  clearYear() {
    this._year = null;
  }

  /**
   * Sets the date.
   *
   * @param {string} date
   *   The date to set in `YYYY-MM` format.
   */
  set date(date) {
    if (!isValidDateString(date)) {
      throw new Error("Attempting to set an invalid date");
    }
    this.year = date.split("-")[0];
    this.month = date.split("-")[1];
  }

  /**
   * Gets the date `YYYY-MM`, or null if not fully set.
   *
   * @return {null|string}
   *   Returns the date.
   */
  get date() {
    if (!this.year || !this.month) {
      return null;
    }
    return `${this._year}-${this._month}`;
  }

  /**
   * Gets the month.
   *
   * @return {string}
   */
  get month() {
    return this._month;
  }

  /**
   * Sets the month.
   *
   * @param {string} month
   *   The month to set.
   */
  set month(month) {
    if (!isValidMonthString(month)) {
      throw new Error("Attempting to set an invalid month.");
    }
    this._month = month;
  }

  /**
   * Gets the year.
   *
   * @return {string}
   *   The year.
   */
  get year() {
    return this._year;
  }

  /**
   * Sets the year.
   *
   * @param {string} year
   */
  set year(year) {
    if (!isValidYear(year)) {
      throw new Error("Attempting to set an invalid year.");
    }
    this._year = year;
  }
}
