import React, { useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCog } from "@fortawesome/pro-light-svg-icons"

const MaintenancePage = () => {
  // forces reload when component is unmounted and maintenance is done
  useEffect(() => {
    return () => {
      if (typeof window !== "undefined") {
        window.location.reload()
      }
    }
  }, [])

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        fontSize: "4rem",
        textAlign: "center",
        maxWidth: "700px",
        flexDirection: "column",
        gap: "2rem",
        margin: "0 auto",
      }}
    >
      <div>
        <FontAwesomeIcon color={"#8FBCC7"} icon={faCog} spin size="lg" />
      </div>

      <p>
        🔧
        <b style={{ fontSize: "30px" }}>
          {" "}
          Wingspans is momentarily under construction.
        </b>{" "}
        🔧
        <br />
        <br />
        We're taking a short break to upgrade our platform, ensuring you have
        the best possible experience as you navigate your career journey.
        {/* <br />
        <br />
        <i>Expected Time to Resume Your Journey:</i>
        <b> 24 hours </b> */}
        <br />
        <br />
        🌟 Helping you pave the way for a bright future is our utmost priority.
        🌟
        <br />
        <br />
        If you have any questions or need assistance during this upgrade, please
        don't hesitate to contact us at{" "}
        <a href="support@wingspans.com"> support@wingspans.com</a>
        <br />
        <br />
        Thank you for your understanding and patience. We're here to support you
        every step of the way!
      </p>
    </div>
  )
}

export default MaintenancePage
