import React, { useState, Fragment, useContext, useEffect } from "react"
import ResumeTimelineExperience from "./ResumeTimelineExperience.js"
import ResumeExperienceEditorModal from "./ResumeExperienceEditorModal.js"
import { PortfolioContext } from "../dashboard/PortfolioContext.js"
import { FormattedMessage } from "react-intl"

const timelineInfo = {
  work: {
    color: "#61CADC",
    description:
      <FormattedMessage
        id="resume-timeline-work-desc"
        defaultMessage="List all of your past work experiences to show off your mad job skills. Include job titles, company names, location, and dates of employment."
      />
  },
  education: {
    color: "#E0742F",
    description:
      <FormattedMessage
        id="resume-timeline-education-desc"
        defaultMessage="Don't hold back, highlight your degrees, certificates, and any relevant coursework."
      />
  },
  award: {
    color: "#67C744",
    description:
      <FormattedMessage
        id="resume-timeline-award"
        defaultMessage="Set yourself apart by listing your accomplishments. This includes academic awards, professional recognitions, or anything else that makes you shine."
      />
  },
  volunteer: {
    color: "#911FDC",
    description:
      <FormattedMessage
        id="resume-timeline-volunteer-desc"
        defaultMessage="Your volunteer experience speaks volumes about the kind of person you are! Include the organization's name, your role, and the dates of your involvement."
      />
  },
  leadership: {
    color: "#3D9AD2",
    description:
      <FormattedMessage
        id="resume-timeline-leadership-desc"
        defaultMessage="Let the world know you are a natural-born leader by listing your previous leadership roles or accomplishments."
      />
  },
  life: {
    color: "#CC3A5D",
    description:
      <FormattedMessage
        id="resume-timeline-life-desc"
        defaultMessage="Don't underestimate the value of your life experiences. List any non-traditional experiences that demonstrate your skills and qualifications. It could be anything from running a business to moving to a new city."
      />
  },
}

const ResumeTimeline = ({
  type,
  label,
  filteredExperiences,
  isPublicFacing,
  icon
}) => {
  const { isResumeForcedOpen } = useContext(PortfolioContext) ?? true
  const [isExpanded, setIsExpanded] = useState(false)
  const [isEditorModalOpen, setIsEditorModalOpen] = useState(false)
  const limit = 3

  const isExperienceArrayFilled = filteredExperiences =>
    Array.isArray(filteredExperiences) && filteredExperiences.length

  useEffect(() => {
    setIsExpanded(isResumeForcedOpen)
  }, [isResumeForcedOpen])

  useEffect(() => {
    if (isPublicFacing) {
      setIsExpanded(true)
    }
  }, [isPublicFacing])

  const closeTimelineModal = () => {
    setIsEditorModalOpen(false)
  }

  if (!isExperienceArrayFilled(filteredExperiences)) {
    return null
  }

  return (
    <>
      {isEditorModalOpen && (
        <ResumeExperienceEditorModal
          isNewExperience={true}
          show={isEditorModalOpen}
          type={type}
          closeTimelineModal={closeTimelineModal}
        />
      )}

      <div className="resume-timeline-container">
        <aside
          style={{ alignItems: isPublicFacing ? "flex-end" : "flex-start" }}
        >
          <div className="resume-timeline-container-header">
            <h3>
              {icon} {label}
            </h3>
            {!isPublicFacing && (
              <>
                <button
                  className="button component-button component-button-ghost add-experience component-button-small"
                  onClick={e => {
                    e.target.blur()
                    setIsEditorModalOpen(true)
                  }}
                >
                  + <FormattedMessage id="resume-timeline-add" defaultMessage="Add" />
                </button>
              </>
            )}
          </div>

          <div className="resume-timeline">
            <div>
              {isExperienceArrayFilled(filteredExperiences) &&
                filteredExperiences.map((experienceData, index) => {
                  if (isExpanded || index < limit) {
                    return (
                      <Fragment key={`${type}-item-${index}`}>
                        <ResumeTimelineExperience
                          type={type}
                          color={timelineInfo[type].color}
                          icon={timelineInfo[type].icon}
                          experienceData={experienceData}
                          precedingExperience={
                            filteredExperiences[index - 1] ?? null
                          }
                          isPublicFacing={isPublicFacing}
                        />
                      </Fragment>
                    )
                  }
                })}
            </div>

            {filteredExperiences.length > limit && !isPublicFacing && (
              <button
                className="resume-expand"
                onClick={() => setIsExpanded(!isExpanded)}
              >
                <span>{isExpanded ? <FormattedMessage id="resume-timeline-see-fewer" defaultMessage="See fewer" /> : <FormattedMessage id="resume-timeline-see-see-all" defaultMessage="See all" />}</span>
              </button>
            )}
          </div>
        </aside>
      </div>
    </>
  )
}

export default ResumeTimeline
