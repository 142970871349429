import React from "react"
import CardCareers from "../cards/cards-careers"
import EyeBrow from "../eyebrow/eyebrow"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight, faChevronLeft } from "@fortawesome/free-solid-svg-icons"
import algoliasearch from 'algoliasearch';
import { InstantSearch, Configure, Pagination, Hits, ScrollTo } from 'react-instantsearch-dom';
import AlgoliaClientKeys from "./AlgoliaClientKeys"
import "./algolia.css"
import "./algolia-explore.css"
import { FormattedMessage, useIntl } from "react-intl"

const searchClient = algoliasearch(AlgoliaClientKeys.appId, AlgoliaClientKeys.apiKey);

const careerResult = ({ hit }) => {
  const {
    objectID,
    title,
    career_banner_image,
    career_salary_entry_level,
    career_salary_experienced,
    career_demand,
    career_job_categories,
    slug,
    type
  } = hit;

  return (
    <CardCareers
      objectID={objectID}
      img={career_banner_image}
      title={title}
      slug={slug}
      demand={career_demand}
      category={career_job_categories}
      contentType={type}
      salaryEntry={career_salary_entry_level}
      salaryExperienced={career_salary_experienced}
    />
  );
}

const AlgoliaCareersSaved = props => {
  const { classContext, filterList } = props;

  const { locale } = useIntl()

  if (filterList === null) {
    return (
      <div className={`stream stream-${classContext} stream-${classContext}-saved`}>
        <p><FormattedMessage
          id="algolia-saved-js-message"
          defaultMessage="You have no saved items. Bookmark a page to save content that resonates with you."
        /></p>
      </div>
    )

  } else {

    return (

      <InstantSearch
        indexName="careers_explore"
        searchClient={searchClient}
        filters={filterList}
      >
        <ScrollTo>
          <div className={`stream stream-${classContext} stream-${classContext}-saved`}>
            <EyeBrow text={<FormattedMessage
              id="algolia-toggle-dropdown-careers"
              defaultMessage="Careers"
            />} style={{ marginBottom: '2rem' }} />

            <Configure
              hitsPerPage={8}
              distinct
              filters={`${filterList} AND locale:${locale}`}
            />

            <Hits hitComponent={careerResult} />
            <Pagination
              showFirst={false}
              showLast={false}
              translations={{
                previous: <FontAwesomeIcon icon={faChevronLeft} size="sm" />,
                next: <FontAwesomeIcon icon={faChevronRight} size="sm" />
              }}
            />
          </div>
        </ScrollTo>
      </InstantSearch>
    )
  }
}

export default AlgoliaCareersSaved
