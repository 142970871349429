import { globalHistory } from "@reach/router"
import { useState, useEffect } from "react"

function useClientSideLocale() {
  const [locale, setLocale] = useState("en-US")
  const pathname = globalHistory.location.pathname

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (pathname.split("/").includes("es-ES")) {
        setLocale("es-ES")
      } else {
        setLocale("en-US")
      }
    } else {
      setLocale("en-US")
    } 
  }, [pathname])

  return locale
}

export default useClientSideLocale
