import React from "react"

const CancelMembership = () => {
  return (
    <>
      <h2>Cancel subscription</h2>
    </>
  )
}

export default CancelMembership
