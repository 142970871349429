import Milestone from "./Milestone"

/**
 * A milestone with a text field item.
 */
export default class MilestoneTextType extends Milestone {

  /**
   * Constructor.
   *
   * @param milestoneData
   *   Existing milestone data.
   */
  constructor(milestoneData = null) {
    super(milestoneData);
    this._goal = milestoneData?.goal;
    this._text = milestoneData?.text;
  }

  /**
   * Gets the goal.
   *
   * @return {string|null}
   */
  get goal() {
    return this._goal;
  }

  /**
   * Sets the goal.
   *
   * @param {string} goal
   *   The goal name.
   */
  set goal(goal) {
    this._goal = goal;
  }

  /**
   * Gets the text.
   *
   * @return {*}
   */
  get text() {
    return this._text;
  }

  /**
   * Sets the text.
   *
   * @param text
   */
  set text(text) {
    this._text = text;
  }

  /**
   * @inheritDoc
   */
  getData() {
    const data = super.getData();
    data.goal = this.goal;
    data.text = this.text;
    return data;
  }

}
