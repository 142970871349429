import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Link from "../../link"
import { useIntl } from "react-intl"

const KnowledgeBaseLinks = () => {
  const { locale } = useIntl();
  return (
    <section
      className="knowledge-base-links"
      style={{ margin: "0 0 1rem .5rem" }}
    >
      <StaticQuery
        query={graphql`
        {
          allCosmicjsBasicPages(
            sort: { order: DESC, fields: created_at }
            filter: { slug: { nin: ["privacy-policy", "terms-of-use"] } }
          ) {
            edges {
              node {
                slug
                title
                created_at
                locale
              }
            }
          }
        }
      `}
        render={data =>
          data.allCosmicjsBasicPages.edges.filter((lang) => lang.node.locale === locale).map(({ node }, idx) => {
            return (
              <div key={idx}>
                <Link to={`/about/${node.slug}`}>{node.title}</Link>
              </div>
            )
          })
        }
      ></StaticQuery>
    </section>
  )
}

export default KnowledgeBaseLinks
