import React, { useState, useEffect, useRef } from "react"
import orderBy from 'lodash.orderby';
import _ from "lodash"
import CardSchools from "../cards/cards-schools"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown, faChevronUp, faChevronRight, faChevronLeft, faTimes } from "@fortawesome/free-solid-svg-icons"
import { faSearch } from "@fortawesome/pro-regular-svg-icons"
import EyeBrow from "../eyebrow/eyebrow"
import OutsideClickHandler from 'react-outside-click-handler';
import AlgoliaClientKeys from "./AlgoliaClientKeys"
import algoliasearch from 'algoliasearch';
import { InstantSearch, SearchBox, Configure, Pagination, Hits, RefinementList, ClearRefinements, ScrollTo } from 'react-instantsearch-dom';
import "./algolia.css"
import "./algolia-explore.css"
import { useQueryParam, JsonParam, BooleanParam } from "use-query-params"
import { FormattedMessage, useIntl } from "react-intl"
import {
  globalHistory,
} from "@reach/router"
import { localizeSizeCheckboxTranslations, localizeSettingCheckboxTranslations,
  localizeTypeCheckboxTranslations, localizeCostCheckboxTranslations} from "./localized-algolia-checkboxes"



// @TODO Style tweaking and cleanup
// @TODO make the component NOT display if there are no results
// @TODO update filter states

const searchClient = algoliasearch(AlgoliaClientKeys.appId, AlgoliaClientKeys.apiKey);

const schoolResult = ({ hit }) => {
  const {
    objectID,
    title,
    school_banner_image,
    slug,
    type,
    school_city,
    school_state_or_region,
    school_acceptance_rate,
    school_enrolled_count
  } = hit;

  return (
    <CardSchools
      objectID={objectID}
      img={school_banner_image}
      title={title}
      slug={slug}
      contentType={type}
      city={school_city}
      state={school_state_or_region}
      acceptance={school_acceptance_rate}
      enrolled={school_enrolled_count}
    />
  );
}

/*
 * title = The title of the page. ie - Financial Manager, Architect, etc.
 * filterReference = The Algolia filter reference. ie - story_school_reference
 * classContext = A class to override default styles. ie - career, company, major, etc.
 */

const AlgoliaSchoolsExplore = props => {
  const { classContext, filterList } = props

  const [loadedFromHistory, setLoadedFromHistory] = useState(false)

  // ref to handle URL update timeout
  // ref to handle reseting scroll behavior to auto on timeout
  // ref to handle toggling off loaded from history
  const debouncedSetState = useRef(null)
  const resetScrollRef = useRef(null)
  const loadingRef = useRef(null)
  const { locale, formatMessage } = useIntl()
  const localeFilter = `locale:${locale}`
  const filterListWithLocale = filterList ? `(${filterList}) AND ${localeFilter}` : localeFilter

  const setHTMLScrollStyle = (behavior) => {
    document.querySelector('html').style.scrollBehavior = behavior
  }

  //  component mount / unmount hook to handle:
  // 1. clearing timeouts for
  // 2. setting loaded from history to prevent from triggering re-route, starts timeout to set back for normal behavior
  // 3. removing smooth scrolling
  // 4. unmount, clear timeouts and set scroll style to auto
  useEffect(() => {
    const timeoutLoadedFromHistory = () => {
      loadingRef.current = setTimeout(() => {
        setLoadedFromHistory(false)
      }, 100)
    }
    globalHistory.listen(({ action }) => {
      if (action === "POP") {
        setHTMLScrollStyle("auto")
        setLoadedFromHistory(true)
        timeoutLoadedFromHistory()
        clearTimeout(debouncedSetState.current)
      } else if (action === "PUSH") {
        setLoadedFromHistory(false)
        clearTimeout(debouncedSetState.current)
      }
    })
    timeoutLoadedFromHistory()

    return () => {
      setHTMLScrollStyle("auto")
      clearTimeout(debouncedSetState.current)
      clearTimeout(resetScrollRef.current)
      clearTimeout(loadingRef.current)
    }
  }, [])

  // URL search parameters
  // applied filters object, page, filters, and search input data of stories
  // boolean for if saved is showing
  const [queryFilters, setQueryFilters] = useQueryParam("queryFilters", JsonParam)
  const [showSavedQuery, setShowSavedQuery] = useQueryParam("showSaved", BooleanParam)

  // component state for showing saved vs newest results. Initial value set by query if exists
  // component state for toggling filter visibility
  // component state for instantly updating search results, can inherit value from URL
  const [showSaved, setShowSaved] = useState(showSavedQuery || false)
  const [showFilters, setShowFilters] = useState(false)
  const [searchState, setSearchState] = useState(queryFilters || {})

  const filters = () => {
    if (!showFilters) {
      setShowFilters(true)
    } else {
      handleOutsideFilterClick()
      setShowFilters(false)
    }
  }

  /**
   * Handles toggling of the newest | saved feature. Updates URL
   *
   * @param {SyntheticEvent} e
   */
  const handleShowSavedClick = e => {
    e.preventDefault()
    if (e.target.attributes.class.value === "active") {
      return
    }
    setShowSaved(!showSaved)
    setQueryFilters(undefined)
    setShowSavedQuery(!showSaved)
  }

  // hook for updating component state when query filters change
  useEffect(() => {
    setShowSaved(showSavedQuery)
  }, [showSavedQuery])

  // hook for updating component state when query filters change
  useEffect(() => {
    setSearchState(queryFilters)
  }, [queryFilters])

  // Handles updating the component search state and URL after any change to algolias search filters
  const onSearchStateChange = searchInput => {
    // set scroll to smooth for algolia components
    setHTMLScrollStyle('smooth')

    // reset scroll behavior on timeout, unmount reset is inconsistent
    resetScrollRef.current = setTimeout(() => {
      setHTMLScrollStyle('auto')
    }, 1)

    // update component state after any change. Allows for instant filtering
    setSearchState(searchInput)

    // conditional variables, may be absent from query or component search state
    const queryRefinementList = queryFilters ? queryFilters.refinementList : {}
    const searchInputRefinementList = searchInput.refinementList ? searchInput.refinementList : {}

    // booleans to determine the type of query change. Determined by comparing URL with current search
    const isTypedQuery = (queryFilters?.query !== searchInput?.query && searchInput?.query.length > 0)
    const isFilterSelectionQuery = (!_.isEqual(queryRefinementList, searchInputRefinementList) && !_.isEmpty(searchInputRefinementList))
    const isSortByQuery = showSaved !== showSavedQuery
    const isEmptyURL = (queryFilters || showSavedQuery) ? false : true
    const isEmptySearch = _.isEqual(searchInput, { configure: { hitsPerPage: 8, distinct: true, filters: '' } })

    // clear timeout on URL updates for typed query
    clearTimeout(debouncedSetState.current)

    if (isTypedQuery) {
      // updates URL after timeout
      debouncedSetState.current = setTimeout(() => {
        setQueryFilters(searchInput)
      }, 1000)
    } else if (isFilterSelectionQuery) {
      // do nothing if filter change, URL is only updated after filter dropdown is exited
    } else if (isSortByQuery) {
      // do nothing if sort by query change, URL is updated on click
    } else if (isEmptySearch && isEmptyURL) {
      // if attempting to re route with no search info. Needed because of sort by toggling triggering this function on category change
    } else {
      // immediately update URL by default, runs when new page selected
      if (!loadedFromHistory) {
        setQueryFilters(searchInput)
      }
    }
    setLoadedFromHistory(false)
  }

  // handles toggle off of showFilters and updates URL
  const handleOutsideFilterClick = (e) => {
    // prevents click from firing when clicking scroll bar
    if (e?.target?.tagName === "HTML") return

    // check to see if filters changed, prevents URL setting when they are the same as current params
    const queryRefinementList = queryFilters ? queryFilters.refinementList : {}
    const searchStateRefinementList = searchState?.refinementList ? searchState.refinementList : {}
    const filtersHaveChanged = (!_.isEqual(queryRefinementList, searchStateRefinementList) && !_.isEmpty(searchStateRefinementList))

    showFilters && filtersHaveChanged && setQueryFilters(searchState)

    setShowFilters(false)
  }

  const transformSchoolSizeFilterItems = (items) => {
    const localizedItems = items.map((item) => {
      const localizeSizetItem = { ...item };
      if (
        localizeSizeCheckboxTranslations[item.label] &&
        localizeSizeCheckboxTranslations[item.label][locale]
      ) {
        localizeSizetItem.label =
          localizeSizeCheckboxTranslations[item.label][locale];
      }
      return localizeSizetItem;
    });
    const sortedItems = orderBy(localizedItems, ["label", "count"], ["asc", "desc"]);
    return sortedItems;
  };

  const transformSchoolSettingFilterItems = (items) => {
    const localizedItems = items.map((item) => {
      const localizeSettingtItem = { ...item };
      if (
        localizeSettingCheckboxTranslations[item.label] &&
        localizeSettingCheckboxTranslations[item.label][locale]
      ) {
        localizeSettingtItem.label =
          localizeSettingCheckboxTranslations[item.label][locale];
      }
      return localizeSettingtItem;
    });
    const sortedItems = orderBy(localizedItems, ["label", "count"], ["asc", "desc"]);
    return sortedItems;
  };

  const transformSchoolTypeFilterItems = (items) => {
    const localizedItems = items.map((item) => {
      const localizeTypetItem = { ...item };
      if (
        localizeTypeCheckboxTranslations[item.label] &&
        localizeTypeCheckboxTranslations[item.label][locale]
      ) {
        localizeTypetItem.label =
          localizeTypeCheckboxTranslations[item.label][locale];
      }
      return localizeTypetItem;
    });
    const sortedItems = orderBy(localizedItems, ["label", "count"], ["asc", "desc"]);
    return sortedItems;
  };

  const transformSchoolCostFilterItems = (items) => {
    const localizedItems = items.map((item) => {
      const localizeCosttItem = { ...item };
      if (
        localizeCostCheckboxTranslations[item.label] &&
        localizeCostCheckboxTranslations[item.label][locale]
      ) {
        localizeCosttItem.label =
          localizeCostCheckboxTranslations[item.label][locale];
      }
      return localizeCosttItem;
    });
    const sortedItems = orderBy(localizedItems, ["label", "count"], ["asc", "desc"]);
    return sortedItems;
  };

  return (

    <InstantSearch
      indexName="schools_explore"
      searchState={searchState || {}}
      onSearchStateChange={onSearchStateChange}
      searchClient={searchClient}
    >

      <SearchBox
        searchAsYouType={true}
        translations={{
          placeholder: formatMessage({ id: "algolia-stories-view-search-placeholder", defaultMessage: "Search by name, job title, location, company" })
        }}
        submit={<FontAwesomeIcon icon={faSearch} />}
        reset={<FontAwesomeIcon icon={faTimes} />}
      />

      <ScrollTo>
        <div className={`stream stream-${classContext} stream-${classContext}-explore`}>
          <OutsideClickHandler onOutsideClick={handleOutsideFilterClick}>
            <div className="stream-explore-filters">
              <button className="stream-explore-filters-button" onClick={filters}>
                <FormattedMessage id="more-filters" defaultMessage="More Filters" />
                <FontAwesomeIcon className={`${!showFilters ? 'show' : 'hide'}`} icon={faChevronDown} style={{ color: '#363636', marginLeft: '.5rem' }} />
                <FontAwesomeIcon className={`${showFilters ? 'show' : 'hide'}`} icon={faChevronUp} style={{ color: '#363636', marginLeft: '.5rem' }} />
              </button>

              <div className={`explore-context-filter ${showFilters ? 'show' : 'hide'}`}>
                <h3><FormattedMessage id="algolia-school-explore-filter-school-by" defaultMessage="Filter Schools by" />:</h3>
                <div className="filter filter-five">
                  <EyeBrow text={formatMessage({ id: "school-explore-size", defaultMessage: "Size" })} style={{ marginBottom: '1.5rem' }} />
                  <RefinementList
                    attribute="school_size_filter"
                    transformItems={transformSchoolSizeFilterItems}
                  />
                </div>
                <div className="filter filter-five">
                  <EyeBrow text={formatMessage({ id: "school-explore-setting", defaultMessage: "Setting" })} style={{ marginBottom: '1.5rem' }} />
                  <RefinementList
                    attribute="school_setting_filter"
                    transformItems={transformSchoolSettingFilterItems}
                  />
                </div>
                <div className="filter filter-five">
                  <EyeBrow text={formatMessage({ id: "school-explore-type", defaultMessage: "Type" })} style={{ marginBottom: '1.5rem' }} />
                  <RefinementList
                    attribute="school_type_filter"
                    transformItems={transformSchoolTypeFilterItems}
                  />
                </div>
                <div className="filter filter-five">
                  <EyeBrow text={formatMessage({ id: "school-explore-acceptance-rate", defaultMessage: "Acceptance Rate" })} style={{ marginBottom: '1.5rem' }} />
                  <RefinementList
                    attribute="school_selectivity_filter"
                    transformItems={items =>
                      orderBy(items, ['label', 'count'], ['asc', 'desc'])
                    }
                  />
                </div>
                <div className="filter filter-five filter-last">
                  <EyeBrow text={formatMessage({ id: "school-explore-cost", defaultMessage: "Cost" })} style={{ marginBottom: '1.5rem' }} />
                  <RefinementList
                    attribute="school_cost_filter"
                    transformItems={transformSchoolCostFilterItems}
                  />
                </div>
                <ClearRefinements
                  translations={{
                    reset: formatMessage({ id: "careers-explore-clear-filters", defaultMessage: "Clear Filters" }),
                  }}
                />
              </div>

              <ul className="explore-filters-list">
                <li className="explore-filters-list-item explore-filters-list-item-sort">
                  <FormattedMessage id="algolia-stories-view-sort-by" defaultMessage="Sort by:" />
                </li>
                <li className="explore-filters-list-item explore-filters-list-item-newest">
                  <a href="/" className={(showSaved) ? '' : 'active'} onClick={handleShowSavedClick}>
                    <FormattedMessage id="algolia-stories-view-newest" defaultMessage="Newest" />
                  </a>
                </li>
                <li className="explore-filters-list-item explore-filters-list-item-saved">
                  <a href="/" className={(showSaved) ? 'active' : ''} onClick={handleShowSavedClick}>
                    <FormattedMessage id="algolia-stories-view-saved" defaultMessage="Saved" />
                  </a>
                </li>
              </ul>
            </div>
          </OutsideClickHandler>

          <Configure
            hitsPerPage={8}
            distinct
            filters={(showSaved) ? filterListWithLocale : localeFilter}
          />

          <Hits hitComponent={schoolResult} />

          <Pagination
            showFirst={false}
            showLast={false}
            translations={{
              previous: <FontAwesomeIcon icon={faChevronLeft} size="sm" />,
              next: <FontAwesomeIcon icon={faChevronRight} size="sm" />
            }}
          />
        </div>
      </ScrollTo>
    </InstantSearch>
  )
}

export default AlgoliaSchoolsExplore
