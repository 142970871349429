export default [
  {
    stepKey: "createFuture",
    title: "Create Your Future",
    content:
      "The future is yours! Whether you are embarking on a career for the first time or interested in a career transition, Wingspans is here to help. We interview real people about their jobs to unravel the mysteries of the world of work and help you find a path that’s right for you.",
    // videoTutorialLink: "https://vimeo.com/253989945",
    // videoTutorialText: "<i>Video:</i> Get Started",
    pdfTutorialLink:
      "https://docs.google.com/presentation/d/1pUscuE-QpbJNu-ENl0Deg_Yt67VjJXJyWPkhiho5MiY/edit?usp=sharing",
    pdfTutorialText: "<i>PDF:</i> Get Started",
  },
  {
    stepKey: "selfAwareness",
    title: "Increase Self-Awareness",
    content:
      "Take the first step to finding a career that aligns with who you are. The 60-question assessment takes 15-20 minutes, outputs information on your personality and career matches rated by excellent, good, and fair, and introduces you to 60 inspiring people in the process.",
    buttonText: "Take Personality Assessment",
    buttonLink: "/assessment/personality",
    // videoTutorialLink: "https://vimeo.com/252443587",
    // videoTutorialText: "<i>Video:</i> Guide to fill assessment",
    pdfTutorialLink:
      "https://docs.google.com/presentation/d/1SnkHNTZBjMXyIo-8OqZzzfgP7nHuPtBFl673nAL75Ks/edit?usp=sharing",
    pdfTutorialText: "<i>PDF:</i> Guide to fill assessment",
  },
  {
    stepKey: "explorePossibilities",
    title: "Explore",
    content:
      "Expand your idea of what is possible. Explore 700+ videos and written stories about work by career, school, major, employers, and videos. Select <i>More Filters</i> to sort stories and careers by identity, personality, interests, and credentials.",
    buttonText: "Explore by Category",
    buttonLink: "/dashboard?tab=explore#start",
    // videoTutorialLink: "http://www.google.com/videos",
    // videoTutorialText: "<i>Video:</i> Guide on how to explore",
    // pdfTutorialLink: "http://www.google.com/videos",
    // pdfTutorialText: "<i>PDF:</i> Guide on how to explore",
  },
  {
    stepKey: "engage",
    title: "Engage",
    content: "Take an active role in your future. Bookmark three new people every week that inspire you. Set a weekly SMART goal. Write down something you’re looking forward to. And build work readiness competencies.",
    buttonText: "Work Readiness",
    buttonLink: "/dashboard?tab=work-readiness#start",
    // videoTutorialLink: "http://www.google.com/videos",
    // videoTutorialText: "<i>Video:</i> Guide on how to engage",
    // pdfTutorialLink: "http://www.google.com/videos",
    // pdfTutorialText: "<i>PDF:</i> Guide on how to engage",
  },
  {
    stepKey: "buildPortfolio",
    title: "Build a Portfolio",
    content:
      "Personalize a portfolio that tells the story of who you are. Bookmark pages that inspire you, assess your interests and skills and build a resume that exports to a beautiful, ATS-styled Word doc.",
    buttonText: "Portfolio",
    buttonLink: "/dashboard?tab=portfolio#start",
    // videoTutorialLink: "http://www.google.com/videos",
    // videoTutorialText: "<i>Video:</i> Guide on portfolio",
    // pdfTutorialLink: "http://www.google.com/videos",
    // pdfTutorialText: "<i>PDF:</i> Guide on portfolio",
  },
  {
    stepKey: "planPath",
    title: "Plan Your Path",
    content:
      "Go beyond imagining and plan your path! Create a personalized timeline using our Guided or Unguided workflow. Dare to dream big and set 1-Year, 5-Year, and 10-Year goals.",
    buttonText: "Career Plans",
    buttonLink: "/dashboard?tab=career-plan#start",
    // videoTutorialLink: "http://www.google.com/videos",
    // videoTutorialText: "<i>Video:</i> Guide on career plans",
    // pdfTutorialLink: "http://www.google.com/videos",
    // pdfTutorialText: "<i>PDF:</i> Guide on career plans",
  },
]
