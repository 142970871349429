/**
 * Algolia client keys.
 *
 * @type {{apiKey: string, appId: string}}
 */
const AlgoliaClientKeys = {
  appId: process.env.GATSBY_ALGOLIA_APP_ID || 'N7QFRS1570',
  apiKey: process.env.GATSBY_ALGOLIA_API_KEY || '6aad582b7480ed27c1a262269e346020',
}

export default AlgoliaClientKeys;
