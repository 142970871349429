import {
  Paragraph,
  TextRun,
  AlignmentType,
  Tab,
  TabStopType,
} from "docx"
import { createDurationString } from "../../utils/utils"

// Function to create a header for resume sections
export const createHeader = text => {
  return new Paragraph({
    children: [
      new TextRun({
        text: text,
        size: 24,
        font: "Cambria",
        characterSpacing: 60,
        bold: false,
      }),
    ],
    alignment: AlignmentType.CENTER,
    border: {
      bottom: {
        color: "#000000",
        space: 1, // Adjusts the spacing between the text and the border
        style: "single", // This is the style of the border
        size: 6, // Thickness of the border
      },
    },
    spacing: { before: 50, after: 150 },
  })
}

// Function to create a sub-header for resume sections
export const createSubHeader = (role, organization) => {
  return new Paragraph({
    children: [
      new TextRun({
        text: role,
        bold: true,
        italics: true,
        size: 22,
      }),
      new TextRun({
        text: " | ",
        bold: true,
        size: 22,
      }),
      new TextRun({
        text: organization,
        bold: true,
        size: 22,
      }),
    ],
    spacing: { before: 100, after: 50 },
  })
}

// Function to create bullet points for resume sections
export const createBulletPoint = text => {
  if (!text.trim()) return null

  return new Paragraph({
    text: text,
    bullet: {
      level: 0,
    },
    size: 20,
  })
}

// Function to create bullet points for resume sections
export const createSkillsInterestsBullet = (title, text) => {

  return new Paragraph({
    children: [
      new TextRun({
        text: title,
        bullet: {
          level: 0,
        },
        size: 20,
        bold: true,
      }),

      new TextRun({
        text: text,
        size: 20,
      })
    ]

  })
}

export const createSpacingLine = new Paragraph({
  children: [
    new TextRun({
      text: "",
      size: 10,
    }),
  ],
})

export const createAlignedHeader = (title, organization, startDate, endDate) => {
  const duration = createDurationString(startDate, endDate)
  return new Paragraph({
    tabStops: [
      {
        position: 10450,
        type: TabStopType.RIGHT,
      },
    ],
    children: [
      new TextRun({
            text: title,
            bold: true,
            italics: true,
            size: 22,
      }),
      new TextRun({
        text: " | ",
        bold: true,
        size: 22,
      }),
      new TextRun({
        text: organization,
        bold: true,
        size: 22,
      }),
      new TextRun({
        children: [
          new Tab(), 
          duration,
        ],
        size: 22,
      }),
    ]
  })
}

export const createLifeSubHeader = (milestone, time) => {
  return new Paragraph({
    tabStops: [
      {
        position: 10450,
        type: TabStopType.RIGHT,
      },
    ],
    children: [
      new TextRun({
        text: milestone,
        bold: true,
        italics: true,
        size: 22,
      }),
      new TextRun({
        children: [
          new Tab(),
          time,
        ],
        size: 22,
      }),
    ]
  })
}
