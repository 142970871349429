import { toast } from "sonner"
import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationCircle, faCheckCircle } from "@fortawesome/pro-regular-svg-icons"
import { faReadme as fabReadme } from "@fortawesome/free-brands-svg-icons"
import { useIntl } from "react-intl"
import "./toasts.css"

/**
 * used for error messages and generic toast notifications that arent part of the users notification drawer
 * @param { JSX } content - the content of the toast, can be JSX for custom content
 * @param { string } message - the message to display in the toast
 * @param { string } heading - the heading to display in the toast
 *  */
export const sendStandardToast = ({ message, heading, type = 'warning' }) => {
  toast(<StandardToast heading={heading} message={message} type={type} />, {
    position: "top-right",
    closeButton: true,
    duration: 120000,
    style: {
      marginTop: "4rem",
      padding: "0",
    },
  })
}

const StandardToast = ({ message, heading, type = "warning" }) => {
  const icons = {
    warning: <FontAwesomeIcon icon={faExclamationCircle} color="#E89544" size="3x" />,
    success: <FontAwesomeIcon icon={faCheckCircle} color="#3DCC91" size="3x" />,
  }

  return (
    <div className="notifications-toast-item">
      <div className={`notifications-toast-item-icon ${type}`}>
        <div>{icons[type]}</div>
      </div>
      <div className="notifications-toast-item-message">
        <label>{heading}</label>
        <span>
          {message}
        </span>
      </div>
    </div>
  )
}
