export default [
    {
        stepKey: "createFuture",
        title: "Crea tu futuro",
        content:
            "El futuro es tuyo! Ya sea que se esté embarcando en una carrera por primera vez o esté interesado en una transición profesional, Wingspans está aquí para ayudarlo. Entrevistamos a personas reales sobre sus trabajos para desentrañar los misterios del mundo laboral y ayudarte a encontrar el camino adecuado para ti.",
        // videoTutorialLink: "https://vimeo.com/253989945",
        // videoTutorialText: "<i>Video:</i> Get Started",
        pdfTutorialLink:
            "https://docs.google.com/presentation/d/1pUscuE-QpbJNu-ENl0Deg_Yt67VjJXJyWPkhiho5MiY/edit?usp=sharing",
        pdfTutorialText: "<i>PDF:</i> Empezar",
    },
    {
        stepKey: "selfAwareness",
        title: "Aumentar la autoconciencia",
        content:
            "Da el primer paso para encontrar una carrera que se alinee con quién eres. La evaluación de 60 preguntas dura entre 15 y 20 minutos, genera información sobre su personalidad y carreras profesionales calificadas como excelente, buena y justa, y le presenta a 60 personas inspiradoras en el proceso.",
        buttonText: "Realizar una evaluación de personalidad",
        buttonLink: "/es-ES/assessment/personality",
        // videoTutorialLink: "https://vimeo.com/252443587",
        // videoTutorialText: "<i>Video:</i> Guide to fill assessment",
        pdfTutorialLink:
            "https://docs.google.com/presentation/d/1SnkHNTZBjMXyIo-8OqZzzfgP7nHuPtBFl673nAL75Ks/edit?usp=sharing",
        pdfTutorialText: "<i>PDF:</i> Guía para llenar la evaluación",
    },
    {
        stepKey: "explorePossibilities",
        title: "Explorar",
        content:
            "Amplíe su idea de lo que es posible. Explore más de 700 videos e historias escritas sobre el trabajo por carrera, escuela, especialidad, empleadores y videos. Seleccione <i>Más filtros</i> para ordenar historias y carreras por identidad, personalidad, intereses y credenciales.",
        buttonText: "Explorar por categoría",
        buttonLink: "/es-ES/dashboard?tab=explore#start",
        // videoTutorialLink: "http://www.google.com/videos",
        // videoTutorialText: "<i>Video:</i> Guide on how to explore",
        // pdfTutorialLink: "http://www.google.com/videos",
        // pdfTutorialText: "<i>PDF:</i> Guide on how to explore",
    },
    {
        stepKey: "engage",
        title: "Participar",
        content:
            "Tome un papel activo en su futuro. Añade a favoritos tres personas nuevas cada semana que te inspiren. Establezca una meta INTELIGENTE semanal. Escribe algo que estés esperando. Y desarrollar competencias de preparación para el trabajo.",
        buttonText: "Recursos profesionales",
        buttonLink: "/es-ES/dashboard?tab=work-readiness#start",
        // videoTutorialLink: "http://www.google.com/videos",
        // videoTutorialText: "<i>Video:</i> Guide on how to engage",
        // pdfTutorialLink: "http://www.google.com/videos",
        // pdfTutorialText: "<i>PDF:</i> Guide on how to engage",
    },
    {
        stepKey: "buildPortfolio",
        title: "Construya una cartera",
        content:
            "Personaliza un portafolio que cuente la historia de quién eres. Marque páginas que lo inspiren, evalúe sus intereses y habilidades y cree un currículum que se exporte a un hermoso documento de Word con estilo ATS.",
        buttonText: "Portafolio",
        buttonLink: "/es-ES/dashboard?tab=portfolio#start",
        // videoTutorialLink: "http://www.google.com/videos",
        // videoTutorialText: "<i>Video:</i> Guide on portfolio",
        // pdfTutorialLink: "http://www.google.com/videos",
        // pdfTutorialText: "<i>PDF:</i> Guide on portfolio",
    },
    {
        stepKey: "planPath",
        title: "Planifica tu camino",
        content:
            "Ve más allá de lo imaginable y planifica tu camino! Cree una línea de tiempo personalizada utilizando nuestro flujo de trabajo guiado o no guiado. Atrévete a soñar en grande y establece metas a 1, 5 y 10 años.",
        buttonText: "Plan de estudios",
        buttonLink: "/es-ES/dashboard?tab=career-plan#start",
        // videoTutorialLink: "http://www.google.com/videos",
        // videoTutorialText: "<i>Video:</i> Guide on career plans",
        // pdfTutorialLink: "http://www.google.com/videos",
        // pdfTutorialText: "<i>PDF:</i> Guide on career plans",
    },
]
