import MilestoneFactory from './MilestoneFactory';

/**
 * Default career plan class that handles career plan data.
 */
export default class CareerPlan {

  /**
   * Constructor.
   *
   * @param id
   *   The document ID, set to null for a new plan.
   * @param careerData
   *   The career plan data, set to null for a new career plan.
   */
  constructor(id = null, careerData = null) {
    this._label = careerData?.label;
    this._id = id;
    this.buildMilestones(careerData?.milestones);
    this._userId = careerData?.userId;
    this._created = careerData?.created;
    this._updated = careerData?.updated;
  }

  /**
   * Internal method to build milestones from an array of data.
   *
   * @param milestones
   *   Array of milestone data.
   */
  buildMilestones(milestones) {
    this._milestones = [];
    if (!Array.isArray(milestones) || milestones.length === 0) {
      return;
    }
    milestones.forEach((dataValue) => {
      const milestone = MilestoneFactory(null, dataValue);
      if (!milestone) {
        return;
      }
      this._milestones.push(milestone);
    });
  }

  /**
   * If the career plan has any milestones.
   *
   * @return {boolean}
   *   True if there are milestones otherwise false.
   */
  hasMilestones() {
    return this._milestones.length > 0;
  }

  /**
   * Removes a milestone by its index in the milestone array.
   *
   * @param index
   *   The index to remove the milestone.
   */
  removeMilestoneByIndex(index) {
    this._milestones.splice(index, 1);
  }

  /**
   * Gets a milestone by the index.
   *
   * @param index
   *
   * @return {Milestone}
   *   Returns a milestone data object.
   */
  getMilestoneByIndex(index) {
    return this._milestones[index];
  }

  /**
   * Adds a new milestone by `milestone_id` type.
   *
   * @param {string} type
   *   The `milestone_id` of new milestone to create.
   *
   * @return {null|Milestone}
   *   The milestone, or null if incorrect type is passed.
   */
  addNewMilestone(type) {
    const milestone = MilestoneFactory(type);
    if (!milestone) {
      return null;
    }
    this._milestones.push(milestone);
    return milestone;
  }

  /**
   * Set the career plan label.
   *
   * @param label
   *   The plan label.
   */
  set label(label) {
    this._label = label;
  }

  /**
   * Gets the career plan label.
   *
   * @return {null|string}
   *   The career plan label.
   */
  get label() {
    return this._label;
  }

  /**
   * Sets the career plan document ID, must match firestore.
   *
   * @param {string} id
   *   The document ID.
   */
  set id(id) {
    this._id = id;
  }

  /**
   * Gets the document ID, will be null for new plans.
   *
   * @return {null|string}
   *   The document ID, null for new plans.
   */
  get id() {
    return this._id;
  }

  /**
   * If the career plan is a new one.
   *
   * @return {boolean}
   *   `true` for a new plan, `false` for an existing plan.
   */
  isNew() {
    return this._id === null ? true : this._id.length === 0;
  }

  /**
   * Gets the milestone list.
   *
   * @return {Milestone[]}
   *   Array of milestones, or an empty array if none are set.
   */
  get milestones() {
    return this._milestones;
  }

  /**
   * The data object used to pass to firestore on save.
   *
   * @return {{label: (null|string), id: (null|string), milestones: []}}
   *   The data object.
   */
  getData() {
    const data = {
      label: this.label,
      id: this.id,
      milestones: [],
    };
    this.milestones.forEach((milestone) => {
      data.milestones.push(milestone.getData());
    });
    return data;
  }

  /**
   * The date the career plan was created in firestore.
   *
   * @return {null|string}
   *   UTC String of date or null.
   */
  get created() {
    return this._created;
  }

  /**
   * Sets the creation date.
   *
   * @param {string} created
   *   UTC String of date.
   */
  set created(created) {
    this._created = created;
  }

  /**
   * Gets the date the plan was last updated.
   *
   * @return {null|string}
   *   UTC String of date or null.
   */
  get updated() {
    return this._updated;
  }

  /**
   * Sets the last updated date.
   *
   * @param {string} updated
   *   UTC String of date or null.
   */
  set updated(updated) {
    this._updated = updated;
  }

  /**
   * Gets the user ID of current plan.
   *
   * @return {null|string}
   *   The user uid of the plan, null if new.
   */
  get userId() {
    return this._userId;
  }

  /**
   * Sets the userId of the plan.
   *
   * @param {string} id
   *   The user uid that owns the current plan.
   */
  set userId(id) {
    this._userId = id;
  }
}
