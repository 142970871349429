import MonthDayDeadline from "./MonthDayDeadline"

/**
 * The application deadline class.
 */
export default class ApplicationDeadLine {

  /**
   * Constructor.
   *
   * @param {Object} firestoreData
   *   The firestore data.
   */
  constructor(firestoreData) {
    this._type = firestoreData?.type;
    this._closingDate = firestoreData?.closingDate ? new MonthDayDeadline(firestoreData?.closingDate) : null;
    this._notificationDate =  firestoreData?.notificationDate ? new MonthDayDeadline(firestoreData?.notificationDate) : null;
    this._financialAidDate = firestoreData?.financialAidDate ? new MonthDayDeadline(firestoreData?.financialAidDate) : null;
    this._isRollingAdmission = firestoreData?.isRollingAdmission;
    if (this._closingDate && !this.closingDate.isValid) {
      this._closingDate = null;
    }
    if (this._notificationDate && !this.notificationDate.isValid) {
      this._notificationDate = null;
    }
  }

  /**
   * The deadline type.
   *
   * @return {string}
   */
  get type() {
    return this._type;
  }

  /**
   * The financial aid deadline date.
   *
   * @return {MonthDayDeadline|null}
   */
  get financialAidDate() {
    return this._financialAidDate;
  }

  /**
   * The closing date.
   *
   * @return {MonthDayDeadline|null}
   */
  get closingDate() {
    return this._closingDate;
  }

  /**
   * The notification date.
   *
   * @return {MonthDayDeadline|null}
   */
  get notificationDate() {
    return this._notificationDate;
  }

  /**
   * If the deadline is a rolling admission.
   *
   * @return {boolean}
   */
  get isRollingAdmission() {
    return this._isRollingAdmission;
  }
}
