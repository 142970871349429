import React from "react"
import CardCompanies from "../../cards/cards-companies"
import EyeBrow from "../../eyebrow/eyebrow"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons"
import algoliasearch from "algoliasearch"
import {
  InstantSearch,
  Configure,
  Pagination,
  Hits,
} from "react-instantsearch-dom"
import AlgoliaClientKeys from "../AlgoliaClientKeys"
import "../algolia.css"
import "../algolia-explore.css"
import { FormattedMessage, useIntl } from "react-intl"

const searchClient = algoliasearch(
  AlgoliaClientKeys.appId,
  AlgoliaClientKeys.apiKey
)

const companyResult = ({ hit }) => {
  const {
    objectID,
    title,
    company_logo,
    company_location,
    company_industry_filter,
    company_size_filter,
    slug,
    type,
  } = hit

  return (
    <CardCompanies
      objectID={objectID}
      img={company_logo}
      title={title}
      slug={slug}
      industries={company_industry_filter}
      size={company_size_filter}
      location={company_location}
      contentType={type}
      hideBookmarks={true}
    />
  )
}
const AlgoliaPublicCompaniesSaved = props => {
  const { classContext, filterList } = props

  const { locale } = useIntl()
  const localeFilter = `locale:${locale}`
  const filterListWithLocale = filterList
    ? `(${filterList}) AND ${localeFilter}`
    : localeFilter


  if (filterListWithLocale === null) {
    return (
      <div
        className={`stream stream-${classContext} stream-${classContext}-saved`}
      >
        <p><FormattedMessage id="no-content-saved-message" defaultMessage="No content has been saved in this category" /></p>
      </div>
    )
  } else {
    return (
      <InstantSearch indexName="companies_explore" searchClient={searchClient}>
        <div
          className={`stream stream-${classContext} stream-${classContext}-saved`}
        >
          <EyeBrow text="Companies" style={{ marginBottom: "2rem" }} />

          <Configure hitsPerPage={8} distinct filters={filterListWithLocale} />

          <Hits hitComponent={companyResult} />
          <Pagination
            showFirst={false}
            showLast={false}
            translations={{
              previous: <FontAwesomeIcon icon={faChevronLeft} size="sm" />,
              next: <FontAwesomeIcon icon={faChevronRight} size="sm" />,
            }}
          />
        </div>
      </InstantSearch>
    )
  }
}

export default AlgoliaPublicCompaniesSaved
