import { createHeader, createAlignedHeader, createBulletPoint, createSpacingLine } from './componentHelpers'

export const renderWorkSection = workExperiences => {
  return [
    createHeader("WORK EXPERIENCE"),
    ...workExperiences
      .filter(experience => experience)
      .flatMap(experience => {
        const title = experience.title || "JOB TITLE"
        const employer = experience.employer || "EMPLOYER"
        const bulletPoints = experience.bulletPoints || []
        const startDate= experience.startDate || "STARTDATE"
        const endDate = experience.endDate || "Present"

        return [
          createAlignedHeader(title, employer, startDate, endDate),
          ...bulletPoints.map(b => createBulletPoint(b)),
          createSpacingLine,
        ]
      }),
  ]
}
