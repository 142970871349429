import MilestoneListType from "./MilestoneListType"
import getAlgoliaIndexData from "./algolia/AlgoliaEnabledMilestones"

/**
 * This milestone type has Algolia search enabled for its inputs.
 */
export default class MilestoneAlgoliaListType extends MilestoneListType {

  /**
   * Constructor.
   *
   * @param milestoneData
   *   Existing milestone data.
   */
  constructor(milestoneData = null) {
    super(milestoneData);
    this._algoliaPrimaryField = milestoneData?.algoliaPrimaryField;
    if (this._algoliaPrimaryField === undefined) {
      this._algoliaPrimaryField = null;
    }
    this._indexData = getAlgoliaIndexData(milestoneData?.type);
  }

  /**
   * Gets the milestone index data.
   *
   * @return {null|AlgoliaEnabledMilestone}
   */
  get indexData() {
    if (!this._indexData) {
      this._indexData = getAlgoliaIndexData(this.type);
      return this._indexData;
    }
    return this._indexData;
  }

  /**
   * Gets the primary field data.
   *
   * This is usually major CIP code that links majors to schools.
   *
   * @return {null|*}
   */
  get algoliaPrimaryField() {
    return this._algoliaPrimaryField;
  }

  /**
   * Sets the primary field data.
   *
   * @param value
   */
  set algoliaPrimaryField(value) {
    this._algoliaPrimaryField = value;
  }

  /**
   * Disables secondary search as there is no primary gaol found in index.
   */
  disableAlgoliaSecondarySearch() {
    this._algoliaPrimaryField = false;
  }

  /**
   * @inheritDoc
   */
  getData() {
    const data = super.getData();
    data.algoliaPrimaryField = this.algoliaPrimaryField;
    return data;
  }

}
