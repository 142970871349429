import Milestone from "./Milestone"

/**
 * A milestone with a goal and a list item.
 */
export default class MilestoneListType extends Milestone {

  /**
   * Constructor.
   *
   * @param milestoneData
   *   Existing milestone data.
   */
  constructor(milestoneData = null) {
    super(milestoneData);
    this._goal = milestoneData?.goal;
    this._list = milestoneData?.list;
    if (!this._list) {
      this._list = [];
    }
  }

  /**
   * Gets the goal.
   *
   * @return {string|null}
   */
  get goal() {
    return this._goal;
  }

  /**
   * Sets the goal.
   *
   * @param {string} goal
   *   The goal name.
   */
  set goal(goal) {
    this._goal = goal;
  }

  /**
   * Gets the list.
   *
   * @return {string[]}
   */
  get list() {
    return this._list;
  }

  /**
   * If there are any items in the list.
   *
   * @return {boolean}
   *   `true` if the list has items.
   */
  hasListItems() {
    return this._list.length > 0;
  }

  /**
   * Sets the list.
   *
   * @param {string[]} list
   */
  set list(list) {
    if (!Array.isArray(list)) {
      throw new Error('List must be a valid array');
    }
    this._list = list;
  }

  /**
   * Updates a list item by its index.
   *
   * @param value
   *   The value to update.
   * @param index
   *   The index of the list item.
   */
  updateListItemByIndex(value, index) {
    this._list[index] = value;
  }

  /**
   * Gets a list item by an index, does not check for a valid index.
   *
   * @param {number} index
   *   The items index.
   *
   * @return {string}
   *   The list item value.
   */
  getListItemByIndex(index) {
    return this._list[index];
  }

  /**
   * Adds a new list item.
   *
   * @param {string} item
   *   The item to add.
   */
  addListItem(item) {
    this._list.push(item);
  }

  /**
   * Removes a list item by index.
   *
   * @param {number} index
   *   The index to remove the item.
   */
  removeListItemByIndex(index) {
    this._list.splice(index, 1)
  }

  /**
   * Removes a list item, does not check for multiple items with same value.
   *
   * @param {string} item
   *   The item value.
   */
  removeListItem(item) {
    const index = this._list.indexOf(item);
    if (index === -1) {
      return;
    }
    this._list.splice(index, 1)
  }

  /**
   * @inheritDoc
   */
  getData() {
    const data = super.getData();
    data.goal = this.goal;
    data.list = this.list;
    return data;
  }
}
