import { createHeader, createSpacingLine, createAlignedTexts, createBulletPoint } from "./componentHelpers"

export const renderLifeSection = (life) => {
  return [
    ...createHeader("LIFE EXPERIENCES"),
    ...life
      .filter((experience) => experience)
      .flatMap((experience) => {
        const milestone = experience.milestone || "MILESTONE"
        const time = experience.endDate || "TIME PERIOD"
        const bulletPoints = experience.bulletPoints || []

        return [
          createAlignedTexts(milestone, time),
          bulletPoints.map(b => createBulletPoint(b)),
          createSpacingLine,
        ].flat()
      }),
    createSpacingLine,
  ]
}
