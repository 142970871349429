import React, { useState, useEffect, useContext } from "react"
import { FirebaseContext } from "../Firebase"
import Modal from "react-bootstrap/Modal"
import { Alert, OverlayTrigger, Tooltip } from "react-bootstrap"
import { FormattedMessage } from "react-intl"

const PublicProfileModal = () => {
  const { firebase, profile } = useContext(FirebaseContext)
  const [checked, setChecked] = useState(false)
  const [open, setOpen] = useState(false)
  const [publicError, setPublicError] = useState(false)
  const [privateError, setPrivateError] = useState(false)

  useEffect(() => {
    // set the preview image to current image, once available
    if (profile && profile.profileIsPublic === true) {
      setChecked(true)
    }
  }, [profile])

  const handleCheckboxChange = async e => {
    if (e.target.checked) {
      setOpen(true)
    } else {
      // Delete the public profile without confirmation
      const success = await firebase.makeProfilePrivate()
      if (!success) {
        setPrivateError(true)
      } else {
        setChecked(false)
      }
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const success = await firebase.makeProfilePublic()
    if (!success) {
      setChecked(false)
      setPublicError(true)
    } else {
      setPublicError(false)
      setChecked(true)
      setOpen(false)
    }
  }

  // const handleCopy = () => {
  //   navigator.clipboard.writeText(
  //     `https://wingspans.com/profile/${profile.userId}`
  //   )
  //   addNotification("success", "x", <p><FormattedMessage
  //     id="portfolio-share-model-profile-link-copied"
  //     defaultMessage="Profile link copied to clipboard" /></p>)
  // }

  return (
    <>
      {/* <form className="form portfolio-hero-share printer-none">
        {checked && (
          <>
            <div className="form-element portfolio-hero-share-input">
              <div
                style={{
                  display: "flex",
                  position: "relative",
                  marginRight: ".5rem",
                }}
              >
                <input
                  type="text"
                  style={{ backgroundColor: "#fff", position: "relative" }}
                  defaultValue={`https://wingspans.com/profile/${profile.userId}`}
                  disabled={true}
                />

                <OverlayTrigger
                  placement={"top"}
                  overlay={
                    <Tooltip id={`tooltip-top`}>Copy Profile Link</Tooltip>
                  }
                >
                  <span
                    style={{
                      cursor: "pointer",
                      position: "absolute",
                      right: "6px",
                      top: "8px",
                    }}
                    onClick={handleCopy}
                  >
                    <FontAwesomeIcon
                      color={"#40777C"}
                      icon={faCopy}
                      size="lg"
                    />
                  </span>
                </OverlayTrigger>
              </div>
            </div>

            <div className="form-element form-element-submit portfolio-hero-share-submit">
              <a
                className="button component-button component-button-link"
                href={`/profile/${profile.userId}`}
                target="_blank"
                rel="noreferrer"
              >
                <span className="mobile">View</span>
                <span className="desktop">View Public Portfolio</span>
              </a>
            </div>
          </>
        )}
        <div
          className={`form-element portfolio-hero-share-checkbox ${
            checked
              ? "portfolio-hero-share-checkbox-on"
              : "portfolio-hero-share-checkbox-off"
          }`}
        >
          <label htmlFor="share">
            {!!privateError && (
              <Alert variant="danger">
                There was a problem making your profile private. Please try
                again later.
              </Alert>
            )}
            <input
              type="checkbox"
              name="share"
              id="share"
              checked={checked}
              onChange={handleCheckboxChange}
            />
            <span className="label-text paragraph">
              <FontAwesomeIcon icon={faCheckSquare} />
              <FontAwesomeIcon icon={faTimesSquare} />
              Profile Sharing is {checked ? "On" : "Off"}
            </span>
          </label>
        </div>
      </form> */}

      <Modal
        show={open}
        onHide={() => setOpen(false)}
        centered
        animation={false}
      >
        <Modal.Header>
          <h4 style={{ marginBottom: "0" }}> <FormattedMessage
            id="portfolio-make-profile-public-make-profile-public"
            defaultMessage="Make Profile Public?" /></h4>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p>
              <FormattedMessage
                id="portfolio-make-profile-public-para-1"
                defaultMessage="This will display the personal information on this page to the
                public. Are you sure?"/>

            </p>
            {!!publicError && (
              <Alert variant="danger">
                <FormattedMessage
                  id="portfolio-make-profile-public-para-2"
                  defaultMessage="There was a problem making your profile public. Please try again
                later."/>

              </Alert>
            )}
            <form onSubmit={handleSubmit}>
              <div className="form-element">
                <button
                  className="component-button component-button-cancel"
                  style={{ marginRight: "1rem" }}
                  onClick={e => {
                    e.preventDefault()
                    setOpen(false)
                  }}
                >
                  <FormattedMessage
                    id="portfolio-make-profile-cancel"
                    defaultMessage="Cancel" />

                </button>
                <input
                  type="submit"
                  className="component-button component-button-submit"
                  style={{ maxWidth: "fit-content" }}
                  value="Confirm"
                />
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default PublicProfileModal
