import React, { useContext, useEffect, useState } from "react"
import { FirebaseContext } from "./Firebase"
import { Alert } from "react-bootstrap"
import Link from "../link"
import { FormattedMessage } from "react-intl"

const BannerMessage = () => {
  const { loading, user } = useContext(FirebaseContext)
  const [showBanner, setShowBanner] = useState(false)

  useEffect(() => {
    if (!loading && user) {
      if (user.membership && user.membership.active === false) {
        setShowBanner(true)
      }
    }
  }, [loading, user])

  return (
    <>
      {showBanner && (
        <Alert variant={"warning"} style={{ marginBottom: 0 }}>
          <FormattedMessage id="banner-message" defaultMessage="You're membership has expired. Please visit your" />{" "}
          <Link to="/member/account"><FormattedMessage id="banner-message-account-page" defaultMessage="Account page" /></Link> <FormattedMessage id="banner-message-to-renew" defaultMessage="to renew." />
        </Alert>
      )}
    </>
  )
}

export default BannerMessage
