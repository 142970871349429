import DatePeriod from "./DatePeriod"
import MilestoneAlgoliaListType from "./MilestoneAlgoliaListType"

/**
 * Provides a milestone with an Algolia enabled list and date period.
 */
export default class MilestoneAlgoliaListDates extends MilestoneAlgoliaListType {

  /**
   * Constructor.
   *
   * @param milestoneData
   *   The milestone data.
   */
  constructor(milestoneData = null) {
    super(milestoneData);
    this.period = new DatePeriod(milestoneData?.startDate, milestoneData?.endDate);
  }

  /**
   * @inheritDoc
   */
  getData() {
    const data = super.getData();
    data.endDate = this.period.endDate;
    data.startDate = this.period.startDate;
    return data;
  }
}
