/*
  Utilities to replace carriage returns to <br>.
  Use this utils to format some data that was pushed to Cosmic via apis and if it contains line breaks.
*/

const convertNewLineToBR = str => str.replace(/(?:\r\n|\r|\n)/g, "<br>")

const formatText = str => `<p>${convertNewLineToBR(str)}</p>`

const storyReadingOptions = {
  standard: {
    text: "Standard",
    value: "standard",
    textEs: "Estándar",
  },
  simplified: {
    text: "Quick Read",
    value: "simplified",
    textEs: "Lectura rápida",
  },
}
const demandTranslations = {
  'Stable Demand': {
    'en-US': 'Stable Demand',
    'es-ES': 'Demanda estable'
  },
  'Increasing Demand': {
    'en-US': 'Increasing Demand',
    'es-ES': 'Aumento de la demanda'
  },
  'Declining Demand': {
    'en-US': 'Declining Demand',
    'es-ES': 'Demanda en declive'
  },
  'High Demand': {
    'en-US': 'High Demand',
    'es-ES': 'Alta demanda'
  },
  'Low Demand': {
    'en-US': 'Low Demand',
    'es-ES': 'Baja demanda'
  }
};
export { convertNewLineToBR, formatText, storyReadingOptions, demandTranslations }
