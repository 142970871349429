import { toast } from "sonner"
import DOMPurify from "dompurify"
import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight, faArrowRight } from "@fortawesome/pro-light-svg-icons"
import { navigate } from "gatsby"
import { faCompass as farCompass } from "@fortawesome/free-regular-svg-icons"
import { faMapMarkerAlt as farMapMarkerAlt } from "@fortawesome/pro-regular-svg-icons"
import { faLightbulbOn as farLightbulbOn, faChevronRight as faRegChevronRight } from "@fortawesome/pro-regular-svg-icons"
import { faChevronRight as faBoldChevronRight } from "@fortawesome/pro-solid-svg-icons"
import { faReadme as fabReadme } from "@fortawesome/free-brands-svg-icons"
import { useIntl } from "react-intl"
import "./toasts.css"

const sendUserNotificationToast = (notification) => {
  // class we can assign to toast to prevent duplicate toasts from being displayed off the same notification
  const classIdentifier = `toast-${notification.notificationId}`
  let isWindowWide = false

  if (typeof window !== "undefined") {
    const element = document.querySelector(`.${classIdentifier}`)
    isWindowWide = window.innerWidth > 1100
    if (element) return
  }

  toast.dismiss(classIdentifier)

  toast(<UserNotification notification={notification} handleDelete={() => toast.dismiss(classIdentifier)} />, {
    position: isWindowWide ? "top-right" : "bottom-right",
    closeButton: true,
    duration: 12000,
    id: notification.notificationId,
    className: classIdentifier,
    style: {
      marginTop: "4rem",
      padding: "0",
    },
  })
}

const UserNotification = ({ notification, handleDelete }) => {
  // destriucture notification object found in firebase userNotifications collection
  const { message, messageEs, type, publicationDate, notificationId, isRead, pageLink, heading, headingEs } = notification
  const { locale } = useIntl()

  const [isHovered, setIsHovered] = useState(false)

  const isNavigational = !!pageLink

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  function CleanHTML({ dirtyHTML }) {
    if (!dirtyHTML) return ""

    // Sanitize the text
    const sanitizedText = DOMPurify.sanitize(dirtyHTML)

    // OLD CODE for bolding numbers, keeping in case we need to revert

    // // Define a regular expression to match numbers
    // const regex = /(\d+)/g

    // // Split the text into parts based on the regex
    // const parts = sanitizedText.split(regex)

    // // Map through the parts, wrapping numbers in <strong> tags
    // const formattedText = parts.map((part, index) => {
    //   if (part.match(regex)) {
    //     return <span key={index}>{part}</span>
    //   } else {
    //     return part
    //   }
    // })

    return <div dangerouslySetInnerHTML={{ __html: sanitizedText }} />
  }

  const handleNavigation = (notificationId) => {
    if (isNavigational) {
      navigate(`${pageLink}`)
      toast.dismiss(notificationId)
    }
  }

  // map icons to notification types
  const icons = {
    newContent: <FontAwesomeIcon icon={fabReadme} color="#37c742" size="2x" />,
    motivational: <FontAwesomeIcon icon={farLightbulbOn} color="#e07306" size="2x" />,
    accomplishment: <FontAwesomeIcon icon={farCompass} color="#c737d3" size="2x" />,
    initial: <FontAwesomeIcon icon={farMapMarkerAlt} color="#00b8ef" size="2x" />,
  }

  return (
    <>
      <div
        className={`notifications-toast-item ${type} ${isNavigational ? "link" : ""}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        role={`${isNavigational ? "link" : ""}`}
        onClick={() => handleNavigation(notificationId)}
      >
        <div className={`notifications-toast-item-circle ${type}`}>
          <i>{icons[type]}</i>
        </div>
        <div className="notifications-toast-item-message">
          <label>{locale === "es-ES" ? headingEs : heading}</label>
          <span>
            <CleanHTML dirtyHTML={locale === "es-ES" ? messageEs : message} />
          </span>

          <span style={isRead ? { fontStyle: "italic", color: "#959595" } : { fontWeight: "bold" }}>{/* {displayTime} */}</span>
          {isNavigational && (
            <div style={{ float: "right" }}>
              <FontAwesomeIcon icon={faArrowRight} size="1x" />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default sendUserNotificationToast
