import React, { useState, useContext } from "react"; 
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { sendStandardToast } from "../../toasts/standard-toast"
import { FormattedMessage } from "react-intl"
import { faMagic } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; 
import { OverlayTrigger, Tooltip, Modal, ListGroup } from "react-bootstrap"; 
import { FirebaseContext } from "../../Firebase"
import './optimizer-button.css'


export default function OptimizerButton({ type, text, updateData }) {
  const [showModal, setShowModal] = useState(false)
  const [optimizedBullets, setOptimizedBullets] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const { firebase } = useContext(FirebaseContext)

  const fetchOptimizedText = async (text, e) => {

    e.stopPropagation()

    if (!text || text.trim() === "" || text.length < 5) {
      sendStandardToast({
        message: (
          <>
            <div>Please ensure text is at least 20 characters long</div>
          </>
        ),
        heading: <FormattedMessage id="resume-text-optimizer-modal-message-requirement" defaultMessage="AI Optimizer" />,
      })

      return
    }


    setIsLoading(true)

    try {
      const response = await firebase.optimizeText(text, type)
      const optimized = response.optimized
      setOptimizedBullets(optimized)
      setShowModal(true)
    } catch (error) {
      console.error("Error fetching optimized bullets:", error)
    }
    setIsLoading(false) // End loading
  }

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props} >
      <FormattedMessage id="resume-use-ai-to-optimize-bullet" defaultMessage="Use AI to optimize your bullet!" />
    </Tooltip>
  )

  const selectBullet = (bullet) => {
    setShowModal(false)
    updateData({ target: { value: bullet } })
  }
  return (
    <div className='resume-optimizer'>
      <OverlayTrigger placement="top" overlay={renderTooltip}>
        <button
          onClick={!isLoading ? (e) => fetchOptimizedText(text, e) : null}
          className='optimizer-button'
          style={{pointerEvents: isLoading ? "none" : "auto"}}
        >
          {isLoading ? (
            <FontAwesomeIcon icon={faSpinner} spin size="md" color="#75C6D8" />
          ) : (
            <FontAwesomeIcon icon={faMagic} size="md" color="#75C6D8" />
          )}
        </button>
      </OverlayTrigger>
      <Modal show={showModal} onHide={() => setShowModal(false)} backdrop={false} className="optimizer-modal">
        <div style={{ boxShadow: "10px 10px 20px rgba(0, 0, 0, 0.5)" }}>
          <Modal.Header closeButton>
            <Modal.Title style={{ margin: 0 }}>
              <div style={{ display: "flex", marginBotton: "1rem" }}>
                <FontAwesomeIcon icon={faMagic} color="#75C6D8" />
                <h4 style={{ margin: 0, marginLeft: "1rem" }}>
                  <b>
                    <FormattedMessage id="resume-optimizer-choose-option" defaultMessage="Choose an Option" />
                  </b>
                </h4>
              </div>
              <p style={{ margin: 0, fontSize: "1rem" }}>
                <FormattedMessage id="resume-use-ai-to-optimize-text" defaultMessage="Use AI to optimize your text." />              
              </p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ListGroup>
              {optimizedBullets.map((bullet, idx) => (
                <ListGroup.Item key={idx} action onClick={() => selectBullet(bullet)} style={{ border: "2px solid #72e1f9", marginTop: "1rem", fontSize: "1rem",}}>
                  {bullet}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Modal.Body>
        </div>
      </Modal>
    </div>
  )
}
