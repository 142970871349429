import { faDownload, faEye } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ProgressBar } from "react-bootstrap"
import { useQueryParam, StringParam } from "use-query-params"
import React, { useState, useEffect, useContext } from "react"

import { FirebaseContext } from "../Firebase"
import EyeBrow from "../eyebrow/eyebrow"

import "./work-readiness.css"
import { FormattedMessage, useIntl } from "react-intl"
import { useActivities } from "./useActivities"
import useClientSideLocale from "../hooks/useClientSideLocale"

/**
 * Work Readiness tab component, displays either K-12 or Adult work readiness activities.
 *
 * @returns JSX component
 */
const WorkReadinessTab = () => {
  const { formatMessage } = useIntl()
  const { subdomainAudienceType } = useContext(FirebaseContext);

  const categories = {
    "K-12": [
      {
        name: "6th Grade",
        displayName: formatMessage({
          id: "engage-6th-grade",
          defaultMessage: "6th Grade",
        }),
        key: "6th-grade",
      },
      {
        name: "7th Grade",
        displayName: formatMessage({
          id: "engage-7th-grade",
          defaultMessage: "7th Grade",
        }),
        key: "7th-grade",
      },
      {
        name: "8th Grade",
        displayName: formatMessage({
          id: "engage-8th-grade",
          defaultMessage: "8th Grade",
        }),
        key: "8th-grade",
      },
      {
        name: "9th Grade",
        displayName: formatMessage({
          id: "engage-9th-grade",
          defaultMessage: "9th Grade",
        }),
        key: "9th-grade",
      },
      {
        name: "10th Grade",
        displayName: formatMessage({
          id: "engage-10th-grade",
          defaultMessage: "10th Grade",
        }),
        key: "10th-grade",
      },
      {
        name: "11th Grade",
        displayName: formatMessage({
          id: "engage-11th-grade",
          defaultMessage: "11th Grade",
        }),
        key: "11th-grade",
      },
      {
        name: "12th Grade",
        displayName: formatMessage({
          id: "engage-12th-grade",
          defaultMessage: "12th Grade",
        }),
        key: "12th-grade",
      },
    ],
    ADULT: [
      {
        name: "Career and Self Development",
        displayName: formatMessage({
          id: "engage-career-self-development",
          defaultMessage: "Career & Self Development",
        }),
        key: "career_and_self_dev",
      },
      {
        name: "Communication",
        displayName: formatMessage({
          id: "engage-communication",
          defaultMessage: "Communication",
        }),
        key: "communication",
      },
      {
        name: "Critical Thinking",
        displayName: formatMessage({
          id: "engage-critical-thinking",
          defaultMessage: "Critical Thinking",
        }),
        key: "critical_thinking",
      },
      {
        name: "Equity and Inclusion",
        displayName: formatMessage({
          id: "engage-equity-inclusion",
          defaultMessage: "Equity & Inclusion",
        }),
        key: "equity_and_inclusion",
      },
      {
        name: "Leadership",
        displayName: formatMessage({
          id: "engage-leadership",
          defaultMessage: "Leadership",
        }),
        key: "leadership",
      },
      {
        name: "Professionalism",
        displayName: formatMessage({
          id: "engage-professionalism",
          defaultMessage: "Professionalism",
        }),
        key: "professionalism",
      },
      {
        name: "Teamwork",
        displayName: formatMessage({
          id: "engage-teamwork",
          defaultMessage: "Teamwork",
        }),
        key: "teamwork",
      },
      {
        name: "Technology",
        displayName: formatMessage({
          id: "engage-technology",
          defaultMessage: "Technology",
        }),
        key: "technology",
      },
    ],
  }

  return (
    <>
      {subdomainAudienceType === "K-12" ? (
        <WorkReadiness audience="K-12" categories={categories["K-12"]} />
      ) : (
        <WorkReadiness audience="ADULT" categories={categories["ADULT"]} />
      )}
    </>
  )
}

const WorkReadiness = ({ audience, categories }) => {
  const [percentageComplete, setPercentageComplete] = useState(0)
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0)
  // all activities for the currently displayed category
  const [displayedActivities, setDisplayedActivities] = useState([])
  // URL query parameter for category
  const [categoryQuery, setCategoryQuery] = useQueryParam("activityCategory", StringParam)
  const { localFirestore } = useContext(FirebaseContext)

  const locale = useClientSideLocale()

  // list of all possible activities for the current locale from Cosmic
  const allActivities = useActivities(locale)

  // function to handle category of content a user selects
  const handleCategorySelect = (index) => {
    setSelectedCategoryIndex(index)
    setCategoryQuery(categories[index].key, "replaceIn")
  }

  // listen for changes in URL parameters
  useEffect(() => {
    if (categoryQuery) {
      const categoryIndex = categories.findIndex((item) => item.key === categoryQuery)
      setSelectedCategoryIndex(categoryIndex)
    }
  }, [categoryQuery])

  // utility function for filtering activities by category
  const getActivitesByCategory = (category) => {
    return allActivities.filter((item) => item.category === category)
  }

  useEffect(() => {
    // get only the activities for the currently displayed category
    const activities = getActivitesByCategory(categories[selectedCategoryIndex]?.name) || []

    setDisplayedActivities(activities)
  }, [selectedCategoryIndex, locale, displayedActivities])

  useEffect(() => {
    // use this to keep track of activities that a user has completed
    // figure out the percentage of activities completed for the current category

    const completedDisplayedActivities = displayedActivities.filter((item) =>
      localFirestore.activityRecord?.some((activity) => activity.slug === item.slug)
    )

    const percentage = Math.floor((completedDisplayedActivities.length / displayedActivities.length) * 100)
    setPercentageComplete(percentage)
  }, [localFirestore.activityRecord, displayedActivities])

  return (
    <div className="engage">
      <div className="inner engage-introduction">
        <h2>
          <FormattedMessage id="dashboard-tab-work-readiness" defaultMessage="Work Readiness" />
        </h2>
        {audience === "K-12" ? (
          <>
            <p>
              <FormattedMessage
                id="engage-k12-para-1"
                defaultMessage="We're here to help you navigate those four years of your life
              called high school"
              />
            </p>
            <p>
              <FormattedMessage
                id="engage-k12-para-2"
                defaultMessage="We've talked to experts of all kinds - students, teachers,
              nutritonists, and other people on the inside - and shared their
              insight. Maybe their ideas will inspire you. We hope they do."
              />
            </p>
          </>
        ) : (
          <>
            <p>
              <FormattedMessage
                id="engage-para-1"
                defaultMessage="Prepare for success in the workplace! Learn how to advocate for
              yourself and others, chart an inspiring path for your future,
              develop plans for your future, master decision-making, immerse
              yourself in the principles of equity and inclusion practices, and
              hone your listening skills."
              />
            </p>

            <p>
              <FormattedMessage id="engage-para-2" defaultMessage="Activities are based on the NACE competency framework." />
            </p>
          </>
        )}
      </div>
      <div className="engage-body">
        <aside>
          <EyeBrow text={<FormattedMessage id="breadcrumbs-categories" defaultMessage="Categories" />} style={{ marginBottom: "1.5rem" }} />
          <ul>
            {categories.map((item, index) => (
              <li
                key={index}
                role="button"
                onClick={() => handleCategorySelect(index)}
                className={index === selectedCategoryIndex ? "category selected" : "category"}
              >
                {item.displayName}
              </li>
            ))}
          </ul>
        </aside>
        <main>
          <div className="engage-progress-header">
            <div className="engage-progress-header-start">
              <label>{`${percentageComplete}%`}</label>
            </div>
            <div className="engage-progress-header-end">
              <div className="content">
                <label>{`${categories[selectedCategoryIndex]?.displayName}`}</label>

                <ProgressBar variant="info" now={percentageComplete} />
              </div>
            </div>
          </div>
          <div className="engage-materials">
            {displayedActivities.map((item) => (
              <ActivityCard
                key={item.id}
                id={item.id}
                slug={item.slug}
                title={item.title}
                image={item.image}
                imageAlt={item.imageAlt}
                downloadFile={item.downloadFile}
                viewUrl={item.viewUrl}
              />
            ))}
            {/* {loadedActivities[selectedCategoryIndex]?.activities.length % 2 !==
              0 && <div style={{ minWidth: "47%" }} />} */}
          </div>
        </main>
      </div>
    </div>
  )
}

const ActivityCard = (props) => {
  const { slug, title, image, imageAlt, viewUrl, downloadFile, coordinates } = props

  const { localFirestore, notifier } = useContext(FirebaseContext)

  const [isCompleted, setIsCompleted] = useState(false)

  // function used for handling the checkbox select
  const handleCheckboxSelect = async () => {
    if (isCompleted) {
      // remove from local firestore and firebase
      await localFirestore.removeActivity({ slug: slug })
    } else {
      // add to local firestore and firebase
      await localFirestore.addActivity({
        slug: slug,
        title: title,
      })
      await notifier.handleActivityCompletion(title, slug)
    }
  }

  useEffect(() => {
    // check if the activity is completed whenever the activity record changes, could happen through subscription database changes
    if (localFirestore.activityRecord) {
      const isCompleted = localFirestore.activityRecord.some((activity) => activity.slug === slug)
      setIsCompleted(isCompleted)
    }
  }, [localFirestore.activityRecord])

  return (
    <div className="engage-material-item">
      <div className="checkbox">
        <label class="custom-checkbox">
          <input type="checkbox" checked={isCompleted} onClick={(e) => handleCheckboxSelect(e, slug, title, coordinates)} />
          <span class="checkmark"></span>
        </label>
      </div>

      <div className="image-container">
        <img src={image || "https://imgix.cosmicjs.com/14c6ef90-481c-11ee-89ab-17371fc03105-Personal-Brand.jpg"} alt={imageAlt} />
      </div>

      <div className="content">
        <h6>{title}</h6>
        <div className="links">
          {downloadFile && (
            <a href={downloadFile} download rel="noopener noreferrer" target="_blank">
              <FontAwesomeIcon icon={faDownload} size="sm" /> <FormattedMessage id="engage-download" defaultMessage="Download" />
            </a>
          )}
          {viewUrl && (
            <a href={viewUrl} rel="noopener noreferrer" target="_blank">
              <FontAwesomeIcon icon={faEye} size="sm" /> <FormattedMessage id="engage-view" defaultMessage="View" />
            </a>
          )}
        </div>
      </div>
    </div>
  )
}

export default WorkReadinessTab
