import React from "react"
import Bookmark from "../bookmark/bookmark"
import Link from "../../link"
import { FormattedMessage } from "react-intl"

const CardMajors = props => {
  const {
    img,
    title,
    slug,
    bachelor,
    associate,
    graduate,
    master,
    doctorate,
    categories,
    objectID,
    idx,
    contentType,
    hideBookmarks,
  } = props

  return (
    <div
      className="component-card component-card-majors"
      key={idx}
      data-object={objectID}
    >
      <div className="component-card-image component-card-image-majors">
        {img ? (
          <Link to={`/major/${slug}`}>
            <img
              src={`${img}?q=&auto=compress,&w=712&h=446&fit=crop&crop=faces`}
              alt=""
            />
          </Link>
        ) : (
          <Link to={`/major/${slug}`}>
            <img
              src="https://imgix.cosmicjs.com/90920fd0-6000-11eb-ad8f-d1a7eae548e8-major1.jpg?q=&auto=compress,&w=712&h=446&fit=crop&crop=faces"
              alt=""
            />
          </Link>
        )}
      </div>
      <div className="component-card-meta component-card-meta-majors">
        <div className="component-card-meta-header component-card-meta-header-majors">
          {!hideBookmarks && (
            <Bookmark
              contentTitle={title}
              classContext={"majors"}
              contentId={objectID}
              contentSlug={slug}
              contentType={contentType}
            />
          )}
          <Link
            to={`/major/${slug}`}
            className="component-card-title component-card-title-majors"
          >
            {title}
          </Link>
        </div>
        {(bachelor || associate || graduate || master || doctorate) && (
          <div className="component-card-degree component-card-degree-major">
            <ul>
              <li>
                <strong><FormattedMessage id="cards-major-degree" defaultMessage="Degree" />: </strong>
              </li>
              {associate === true && <li><FormattedMessage id="cards-major-associates" defaultMessage="Associate's" /></li>}
              {bachelor && <li><FormattedMessage id="cards-major-bachelor" defaultMessage="Bachelor's" /></li>}
              {graduate && <li><FormattedMessage id="cards-major-graduate" defaultMessage="Graduate" /></li>}
              {master && <li><FormattedMessage id="cards-major-masters" defaultMessage="Master's" /></li>}
              {doctorate && <li><FormattedMessage id="cards-major-doctorate" defaultMessage="Doctorate" /></li>}
            </ul>
          </div>
        )}
        {categories && (
          <div className="component-card-category component-card-category-major">
            <strong><FormattedMessage id="cards-major-category" defaultMessage="Category" />:</strong> {categories}
          </div>
        )}
      </div>
    </div>
  )
}

export default CardMajors
