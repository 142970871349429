import React from "react"

import "./eyebrow.css"

/* Add props to functional components */
/* @SEE https://medium.com/front10/how-to-style-react-components-with-functional-css-d6d23d2ea419 */

const EyeBrow = props => {
  const { style, text } = props;
  return (
    <div className="eyebrow component-eyebrow" style={style}>{text}</div>
  )
}

export default EyeBrow
