import React, { useContext, useEffect, useState } from "react"
import { FirebaseContext } from "../../components/Firebase"
import SEO from "../../components/seo"
import ReactPasswordStrength from "react-password-strength/dist/universal"
import "./account.css"
import { FormattedMessage, useIntl } from "react-intl"
import ProfileImageModal from "../../components/dashboard/portfolio-profile-image-modal"
import PortfolioProfile from "../../../static/assets/images/portfolio-profile-default.jpg"
import { format } from "util"
import { Alert } from "react-bootstrap"
import { sendStandardToast } from "../../components/toasts/standard-toast"
import LoadingSpinner from "../loading/LoadingSpinner"

const MyAccount = ({ isEducatorView }) => {
  const { firebase, user, loading, profile } = useContext(FirebaseContext)
  const [errorMessages, setErrorMessages] = useState([])
  const [messages, setMessages] = useState([])
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    city: "",
    state: "",
    schools: [],
    groups: [],
    departments: [],
    campuses: [],
    role: "",
    gender: "",
  })
  const [errors, setErrors] = useState({})
  const [pointEvents, setPointEvents] = useState(null)
  const misMatchErrorMessage = "Password and Password Confirm must match"
  const weakPasswordMessage = "Password is not strong enough"
  const { locale } = useIntl()

  const formatRoleText = (role) => {
    // super admin, admin, educator, student
    switch (role) {
      case "super-admin":
        return "Super Admin"
      case "admin":
        return "Admin"
      case "educator":
        return "Educator"
      case "student":
        return "Student"
      default:
        return "Student"
    }
  }

  useEffect(() => {
    if (profile && firebase) {
      const getFullProfile = async () => {
        const educatorProfile = await firebase.getEducatorAccountInfo()
        const { user, schools, ownedGroups, memberOfGroups, departments, campuses } = educatorProfile.data

        setFormValues({
          ...formValues,
          firstName: profile.firstName ?? "",
          lastName: profile.lastName ?? "",
          email: profile.email ?? "",
          state: profile.state ?? "",
          city: profile.city ?? "",
          schools: schools || [],
          groups: ownedGroups || [],
          departments: departments || [],
          campuses: campuses || [],
          role: formatRoleText(user.role),
        })
      }
      getFullProfile()
    }
  }, [profile]) /* eslint-disable-line */

  useEffect(() => {
    let isCancelled = false

    if (loading) {
      return
    }

    if (firebase) {
      try {
        const loadPointEvents = async () => {
          const res = await firebase.loadPointEvents()
          if (!isCancelled && res) {
            setPointEvents(res.sort((a, b) => b.eventDate - a.eventDate))
          }
        }
        loadPointEvents()
      } catch (e) {
        console.error("💣", e)
      }
    }

    return () => {
      isCancelled = true
    }
  }, [firebase, loading])

  function handleInputChange(e) {
    e.persist()
    setFormValues((currentValues) => ({
      ...currentValues,
      [e.target.name]: e.target.value,
    }))

    if (formValues.password && e.target.name === "confirmPassword") {
      const passwordError = e.target.value !== formValues.password ? misMatchErrorMessage : ""
      setErrors({
        ...errors,
        confirmPassword: passwordError,
      })
    }
  }

  function handlePasswordChange({ isValid, password }) {
    // if password is being changed, confirm should be wiped clear
    setFormValues({
      ...formValues,
      password: password,
      confirmPassword: "",
    })

    const prevErrors = errors

    if (!isValid && password.length > 0) {
      prevErrors.password = weakPasswordMessage
    } else {
      if (prevErrors.password) {
        delete prevErrors.password
      }
    }

    if (errors.confirmPassword) {
      delete errors.confirmPassword
    }

    setErrors(prevErrors)
  }

  function validates() {
    // password validation
    const validations = {}
    if (formValues.password) {
      if (formValues.password !== formValues.confirmPassword) {
        validations.confirmPassword = misMatchErrorMessage
      }
    }

    // carry over password validation
    if (errors.password === weakPasswordMessage) {
      validations.password = errors.password
    }

    // first and last name validation
    if (formValues.firstName.length < 2) {
      validations.firstName = "First name is required and must be at least 2 characters"
    }

    if (formValues.lastName.length < 2) {
      validations.lastName = "Last name is required and must be at least 2 characters"
    }

    setErrors(validations)
    return JSON.stringify(validations) === "{}"
  }

  async function handleSubmit(e) {
    e.preventDefault()

    setMessages([])

    if (!validates()) {
      setErrorMessages(["Please correct the problems above"])
    } else {
      setErrorMessages([])
      // set up firestore fields
      const profileData = {
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        email: formValues.email,
        city: formValues.city,
        state: formValues.state,
      }

      if (formValues.email !== user.email && user.providerData[0].providerId === "password") {
        try {
          await firebase.updateUserEmail(formValues.email)
          setMessages([...messages, "Email address updated"])
        } catch (e) {
          setErrorMessages([
            ...errorMessages,
            e.code === "auth/requires-recent-login"
              ? "Updating email address or password is a sensitive operation. Please log in again before trying this request."
              : e.message,
          ])
          return
        }
      }

      if (formValues.password) {
        try {
          await firebase.updateUserPassword(formValues.password)
          setMessages([...messages, "Password updated"])
        } catch (e) {
          setErrorMessages([...errorMessages, e.message])
          return
        }
      }

      // Profile update
      try {
        await firebase.updateProfile({ userId: user.uid, data: profileData })

        if (isEducatorView) {
          setMessages([...messages, "Account information has been updated"])
        } else {
          sendStandardToast({
            type: "success",
            heading: "Account information has been updated",
          })
        }
      } catch (e) {
        setErrorMessages([...errorMessages, e.message])
      }
    }
  }

  if (profile === null || !!loading) {
    return <LoadingSpinner />
  }

  return (
    <div className="inner account" style={{ padding: isEducatorView ? "0px 0px" : "3rem 6rem " }}>
      <h2>Account Information</h2>
      <div className="account-info">
        <section className="account-info-details">
          <form onSubmit={handleSubmit}>
            <div className="profile-info">
              <div className="form-element">
                <label htmlFor="firstName">
                  <FormattedMessage id="account-first-name" defaultMessage="First Name" /> <span className="input-required">*</span>
                </label>
                <input onChange={handleInputChange} name="firstName" type="text" required value={formValues.firstName} />
                <span style={{ color: "red" }}>{errors.firstName}</span>
              </div>
              <div className="form-element">
                <label htmlFor="lastName">
                  <FormattedMessage id="account-last-name" defaultMessage="Last Name" /> <span className="input-required">*</span>
                </label>
                <input onChange={handleInputChange} name="lastName" type="text" required value={formValues.lastName} />
                <span style={{ color: "red" }}>{errors.lastName}</span>
              </div>

              <div className="form-element">
                <label htmlFor="email">
                  <FormattedMessage id="account-email" defaultMessage="Email" />
                </label>
                {!!user.providerData && user.providerData[0].providerId !== "password" && (
                  <small>
                    <FormattedMessage
                      id="account-email-password-managed-by-provider"
                      defaultMessage="Your email and password is managed through your login provider"
                    />
                  </small>
                )}
                <input onChange={handleInputChange} name="email" type="text" value={formValues.email} disabled={true} />
              </div>

              <div className="form-element">
                <label htmlFor="school">
                  <label>
                  <FormattedMessage id="school" defaultMessage="School" />
                    {formValues?.schools?.length > 1 ? "s" : ""}</label>
                </label>
                <textarea
                  onChange={handleInputChange}
                  name="school"
                  type="text"
                  rows={1}
                  value={formValues.schools.map((school) => school.name).join(", ")}
                  disabled={true}
                />
              </div>

              {isEducatorView && (
                <>
                  <div className="form-element">
                    <label htmlFor="department">Departments</label>
                    <textarea
                      onChange={handleInputChange}
                      name="department"
                      type="text"
                      rows={1}
                      value={formValues.departments.map((department) => department.name).join(", ")}
                      disabled={true}
                    />
                  </div>

                  <div className="form-element">
                    <label htmlFor="campus">Campuses</label>
                    <textarea
                      onChange={handleInputChange}
                      name="campus"
                      type="text"
                      rows={1}
                      value={formValues.campuses.map((campus) => campus.name).join(", ")}
                      disabled={true}
                    />
                  </div>

                  <div className="form-element">
                    <label htmlFor="group">Groups</label>
                    <textarea
                      onChange={handleInputChange}
                      name="group"
                      type="text"
                      rows={1}
                      value={formValues.groups.map((group) => group.name).join(", ")}
                      disabled={true}
                    />
                  </div>

                  <div className="form-element">
                    <label htmlFor="role">Role</label>
                    <input onChange={handleInputChange} name="role" type="text" value={formValues.role} disabled={true} />
                  </div>
                </>
              )}

              {!!user.providerData && user.providerData[0].providerId === "password" && (
                <>
                  <div className="form-element">
                    <label htmlFor="password">
                      <FormattedMessage id="account-update-password" defaultMessage="Update Password" />: <span className="input-required">*</span>
                    </label>
                    <span
                      style={{
                        fontWeight: "normal",
                        color: "#565656",
                        display: "block",
                      }}
                    >
                      <FormattedMessage id="account-strength-meter" defaultMessage="Strength Meter" />:
                    </span>
                    <ReactPasswordStrength
                      className="react-form-element"
                      minLength={8}
                      minScore={2}
                      scoreWords={["Weak", "Ok", "Good", "Strong", "Stronger"]}
                      inputProps={{ name: "password", autoComplete: "off" }}
                      changeCallback={handlePasswordChange}
                    />
                    <span style={{ color: "red" }}>{errors.password}</span>
                  </div>
                  <div className="form-element">
                    <label htmlFor="confirmPassword">
                      <FormattedMessage id="account-confirm-password" defaultMessage="Confirm Password" />:
                    </label>
                    <input
                      type="password"
                      name="confirmPassword"
                      value={formValues.confirmPassword}
                      onChange={handleInputChange}
                      style={{
                        borderColor: errors.confirmPassword ? "red" : "",
                      }}
                    />
                    {!!errors.confirmPassword && <span style={{ color: "red" }}>{errors.confirmPassword}</span>}
                  </div>
                </>
              )}

              <div className="form-element">
                <label htmlFor="city">
                  <FormattedMessage id="account-city" defaultMessage="City" />
                </label>
                <input onChange={handleInputChange} name="city" type="text" maxLength="50" value={formValues.city} />
              </div>

              <div className="form-element">
                <label htmlFor="state">
                  <FormattedMessage id="account-state" defaultMessage="State" />
                </label>
                <input onChange={handleInputChange} name="state" type="text" maxLength="15" value={formValues.state} />
              </div>

              <div className="form-element">
                <button className="component-button component-button-submit" type="submit" style={{maxWidth: 'unset'}}>
                  <FormattedMessage id="account-save" defaultMessage="Save" />
                </button>
              </div>
            </div>

            {messages.length > 0 && (
              <section className="messages" style={{ width: "100%" }}>
                <Alert variant="success">
                  <ul style={{ listStyleType: "none", margin: "0" }}>
                    {messages.map((message, index) => (
                      <li style={{ marginBottom: "0" }} key={index}>
                        {message}
                      </li>
                    ))}
                  </ul>
                </Alert>
              </section>
            )}
            {errorMessages.length > 0 && (
              <section className="messages" style={{ width: "100%" }}>
                <Alert variant="danger">
                  <ul>
                    {errorMessages.map((message, index) => (
                      <li key={index}>{message}</li>
                    ))}
                  </ul>
                </Alert>
              </section>
            )}
          </form>
        </section>
        <section className="account-info-picture">
          <section className="profile-image">
            <div>
              <label htmlFor="firstName">
                <FormattedMessage id="account-profile-pic" defaultMessage="Profile Picture" />
              </label>

              {!!profile.profileImageUrl ? (
                <img
                  src={profile.profileImageUrl}
                  alt=""
                />
              ) : (
                <div className="portfolio-hero-profile-image-placheholder">
                  <img
                    src={PortfolioProfile}
                    alt=""
                  />{" "}
                </div>
              )}
            </div>
            <ProfileImageModal hasText={true} currentImage={!!profile.profileImageUrl ? profile.profileImageUrl : PortfolioProfile} />
          </section>
        </section>
      </div>
    </div>
  )
}

export default MyAccount
