import { createHeader, createSubHeader, createSpacingLine, createBulletPoint } from "./componentHelpers"

export const renderEducationSection = (educationExperiences) => {
  return [
    ...createHeader("EDUCATION"),
    ...educationExperiences
      .filter((experience) => experience)
      .flatMap((experience) => {
        let fieldString = experience.field || 'FIELD'
        const school = experience.school || 'SCHOOL'
        const location = experience.location || 'LOCATION'
        const bulletPoints = experience.bulletPoints || []
        const startDate = experience.startDate || ''
        let endDate = experience.endDate || ''

        // If the experience includes a degree, add it to the field string
        if (experience.degree) {
          fieldString = `${experience.degree}, ${fieldString}`
        }

        // If the experience includes a startDate but not an endDate, then it's 
        // currently ongoing
        if (startDate && !endDate) {
          endDate = 'Present'
        }

        return [
          createSubHeader(
            school,
            fieldString,
            location,
            startDate,
            endDate,
          ),
          bulletPoints.map(b => createBulletPoint(b)),
          createSpacingLine,
        ].flat()
      }),
  ]
}
