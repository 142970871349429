import React from "react"
import Bookmark from "../bookmark/bookmark"
import Link from "../../link"
import { FormattedMessage } from "react-intl"

const CardSchools = props => {
  const {
    img,
    title,
    slug,
    city,
    state,
    acceptance,
    enrolled,
    objectID,
    idx,
    contentType,
    hideBookmarks,
  } = props

  return (
    <div
      className="component-card component-card-schools"
      key={idx}
      data-object={objectID}
    >
      <div className="component-card-image component-card-image-schools">
        {img ? (
          <Link to={`/school/${slug}`}>
            <img
              src={`${img}?q=&auto=compress,&w=712&h=446&fit=crop&crop=faces`}
              alt=""
            />
          </Link>
        ) : (
          <Link to={`/school/${slug}`}>
            <img
              src="https://imgix.cosmicjs.com/8cbd9150-6568-11eb-8120-dfe8ec2b682f-campus-centered.jpg?q=&auto=compress,&w=712&h=446&fit=crop&crop=faces"
              alt=""
            />
          </Link>
        )}
      </div>
      <div className="component-card-meta component-card-meta-schools">
        <div className="component-card-meta-header component-card-meta-header-schools">
          {!hideBookmarks && (
            <Bookmark
              contentTitle={title}
              classContext={"schools"}
              contentId={objectID}
              contentSlug={slug}
              contentType={contentType}
            />
          )}

          <Link
            to={`/school/${slug}`}
            className="component-card-title component-card-title-schools"
          >
            {title}
          </Link>
        </div>
        <div className="component-card-location component-card-location-schools">
          {city && !state && <span>{city}</span>}
          {state && !city && <span>{state}</span>}
          {city && state && (
            <span>
              {city}, {state}
            </span>
          )}
        </div>
        {enrolled && (
          <span className="component-card-students component-card-students-schools">
            {enrolled.toLocaleString("en")}{" "}
            <FormattedMessage id="school-card-students" defaultMessage="Students" />
          </span>
        )}
        {enrolled && acceptance && <span className="pipe">|</span>}
        {acceptance && (
          <span className="component-card-acceptance component-card-acceptance-schools">
            {Math.floor(acceptance)}%{" "}<FormattedMessage id="school-card-acceptance" defaultMessage="Acceptance" />
          </span>
        )}
      </div>
    </div>
  )
}

export default CardSchools
