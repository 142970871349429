import React, { useState, useEffect, useContext } from "react"
import { FirebaseContext } from "../../Firebase"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faThumbsUp as falThumbsUp,
  faThumbsDown as falThumbsDown,
} from "@fortawesome/pro-light-svg-icons"
import {
  faThumbsUp as fasThumbsUp,
  faThumbsDown as fasThumbsDown,
} from "@fortawesome/pro-solid-svg-icons"

import "./ThumbsFeedback.css"

const ThumbsFeedback = ({ contentSlug, setDislikedCareerSlugs }) => {
  const { profile, firebase, updateAssessment } = useContext(FirebaseContext)

  const [likedCareer, setLikedCareer] = useState(false)
  const [dislikedCareer, setDislikedCareer] = useState(false)

  // isLoading state to prevent rapid toggling of thumbs lag
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    // checks that profile and contentSlug exists
    if (profile && contentSlug) {
      // Profile is handled with a snapshot subscriber, so we leverage
      // profile updates rather than using component state to track liked/disliked careers.
      // This way, if a bookmark is updated in another tab, the change will trigger
      // useEffect to update this one immediately.

      const isLiked =
        profile.assessmentRecord?.likedCareers?.includes(contentSlug) || false
      const isDisliked =
        profile.assessmentRecord?.dislikedCareers?.includes(contentSlug) || false

      if (isLiked !== likedCareer) {
        setLikedCareer(isLiked)
      }

      if (isDisliked !== dislikedCareer) {
        setDislikedCareer(isDisliked)
      }
    }
  }, [profile, contentSlug])

  /**
   * Insert liked/disliked careers into the existing sets
   *
   * @param {string} contentSlug
   * @param {string} feedbackType
   */
  const addCareer = async (contentSlug, feedbackType) => {
    // updating firebase assessment record with the new set
    try {
      // use arrayUnion to prevent race conditions
      await firebase.addAssessmentCareersFeedback({
        feedbackType: feedbackType,
        contentSlug: contentSlug,
      })
    } catch (error) {
      console.error("Firebase Update Error:", error)
      alert(
        `There was a problem adding this content to ${feedbackType}. Please try again later.`
      )
    }
  }

  /**
   * Insert liked/disliked careers into the existing sets
   *
   * @param {string} contentSlug
   * @param {string} feedbackType
   */
  const removeCareer = async (contentSlug, feedbackType) => {
    // updating firebase assessment record with the new set
    try {
      await firebase.removeAssessmentCareersFeedback({
        feedbackType: feedbackType,
        contentSlug: contentSlug,
      })
    } catch (error) {
      console.error("Firebase Update Error:", error)
      alert(
        `There was a problem removing this content from ${feedbackType}. Please try again later.`
      )
    }
  }

  /**
   * Handle keypress events for thumbs toggle
   *
   * @param (SyntheticEvent) e
   * @param (string) feedbackType
   */
  const handleKeyPress = (e, feedbackType) => {
    if (e.key === " " || e.key === "Enter" || e.key === "Spacebar") {
      e.preventDefault()
      toggleThumbs(feedbackType)
    }
  }

  /**
   * Handle click events for thumbs toggle
   *
   * @param (SyntheticEvent) e
   * @param (string) feedbackType
   */
  const handleClick = (e, feedbackType) => {
    e.preventDefault()
    toggleThumbs(feedbackType)
  }

  /**
   * Handle thumbs changes for both clicks and key presses
   *
   * @param {string} feedbackType - "likedCareer" or "dislikedCareer"
   * @param {boolean} currentState - current state of the career (liked/disliked)
   * @param {Function} setStateFunc - function to set the state (setLikedCareer or setDislikedCareer)
   */
  const toggleThumbs = async feedbackType => {
    // lock thumbs functionality until career updated
    setIsLoading(true)

    if (feedbackType === "dislikedCareer" && !dislikedCareer) {
      setDislikedCareerSlugs(prev => [...prev, contentSlug])
      await addCareer(contentSlug, "dislikedCareers")
      await removeCareer(contentSlug, "likedCareers")
    } else if (feedbackType === "likedCareer" && !likedCareer) {
      setLikedCareer(true)
      await addCareer(contentSlug, "likedCareers")
    } else if (feedbackType === "likedCareer" && likedCareer) {
      setLikedCareer(false)
      await removeCareer(contentSlug, "likedCareers")
    } else if (feedbackType === "dislikedCareer" && dislikedCareer) {
      // should never get here
      console.error("career showing up after disliked error")
    }

    await updateAssessment()
    setIsLoading(false)
  }

  return (
    <div className="thumbs-container">
      <FontAwesomeIcon
        icon={likedCareer ? fasThumbsUp : falThumbsUp}
        size="lg"
        flip="horizontal"
        onClick={!isLoading ? e => handleClick(e, "likedCareer") : null}
        onKeyPress={!isLoading ? e => handleKeyPress(e, "likedCareer") : null}
        role="button"
      />
      <FontAwesomeIcon
        icon={dislikedCareer ? fasThumbsDown : falThumbsDown}
        size="lg"
        onClick={!isLoading ? e => handleClick(e, "dislikedCareer") : null}
        onKeyPress={
          !isLoading ? e => handleKeyPress(e, "dislikedCareer") : null
        }
        role="button"
      />
    </div>
  )
}

export default ThumbsFeedback
