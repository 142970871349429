/**
 * The User model.
 */
export class User {
  /**
   * Get RIASEC value for user.
   *
   * @return {number}
   */
  get personality_type_realistic() {
    return this._personality_type_realistic;
  }

  /**
   * Set RIASEC value for user.
   *
   * @param {number} value
   */
  set personality_type_realistic(value) {
    this._personality_type_realistic = value;
  }

  /**
   * Get RIASEC value for user.
   *
   * @return {number}
   */
  get personality_type_investigative() {
    return this._personality_type_investigative;
  }

  /**
   * Set RIASEC value for user.
   *
   * @param {number} value
   */
  set personality_type_investigative(value) {
    this._personality_type_investigative = value;
  }

  /**
   * Get RIASEC value for user.
   *
   * @return {number}
   */
  get personality_type_artistic() {
    return this._personality_type_artistic;
  }

  /**
   * Set RIASEC value for user.
   *
   * @param {number} value
   */
  set personality_type_artistic(value) {
    this._personality_type_artistic = value;
  }

  /**
   * Get RIASEC value for user.
   *
   * @return {number}
   */
  get personality_type_social() {
    return this._personality_type_social;
  }

  /**
   * Set RIASEC value for user.
   *
   * @param {number} value
   */
  set personality_type_social(value) {
    this._personality_type_social = value;
  }

  /**
   * Get RIASEC value for user.
   *
   * @return {number}
   */
  get personality_type_conventional() {
    return this._personality_type_conventional;
  }

  /**
   * Set RIASEC value for user.
   *
   * @param {number} value
   */
  set personality_type_conventional(value) {
    this._personality_type_conventional = value;
  }

  /**
   * Get RIASEC value for user.
   *
   * @return {number}
   */
  get personality_type_enterprising() {
    return this._personality_type_enterprising;
  }

  /**
   * Set RIASEC value for user.
   *
   * @param {number} value
   */
  set personality_type_enterprising(value) {
    this._personality_type_enterprising = value;
  }

  /**
   * Get the RIASEC assessment profile scores for the user.
   * @return {[]|null}
   */
  get assessmentResultProfile() {
    return this._assessmentResultProfile;
  }

  /**
   * Set the RIASEC assessment profile scores for the user.
   * @param {[]|null} value
   */
  set assessmentResultProfile(value) {
    this._assessmentResultProfile = value;
  }

  /**
   * Gets the last login date.
   *
   * @return {Date|null}
   */
  get lastLoginDate() {
    return this._lastLoginDate
  }

  /**
   * Sets the last login date.
   *
   * @param {Date|null} value
   */
  set lastLoginDate(value) {
    this._lastLoginDate = value
  }

  /**
   * Gets the user's interests.
   *
   * @return {[]}
   */
  get interests() {
    return this._interests
  }

  /**
   * Gets the user's SchCodes.
   *
   * @return {[]}
   */
  get schCodes() {
    return this._schCodes
  }

  /**
   * Gets the user's SchCodes label.
   *
   * @return {[]}
   */
  get schCodesName() {
    return this._schCodesName
  }

  /**
   * Sets the user's interests.
   *
   * @param value
   */
  set interests(value) {
    this._interests = value
  }

  /**
   * Sets the user's SchCodes.
   *
   * @param value
   */
  set schCodes(value) {
    this._schCodes = value
  }
  
  /**
   * Sets the user's SchCodes label.
   *
   * @param value
   */
  set schCodesName(value) {
    this._schCodesName = value
  }

  /**
   * Gets the user's skills.
   *
   * @return {[]}
   */
  get skills() {
    return this._skills
  }

  /**
   * Sets the user's skills.
   *
   * @param value
   */
  set skills(value) {
    this._skills = value
  }

  /**
   * Get if the user has completed the assessment quiz.
   *
   * @return {boolean}
   */
  get assessmentTaken() {
    return this._assessmentTaken
  }

  /**
   * Sets if the user has completed the assessment quiz.
   *
   * @param {boolean} value
   */
  set assessmentTaken(value) {
    this._assessmentTaken = value
  }

  // todo add assessment scores
  /**
   * Gets the bookmark titles of stories.
   *
   * @return {string[]|null}
   */
  get bookmarkStoryTitles() {
    return this._bookmarkStoryTitles;
  }

  /**
   * Adds a bookmark story title to the titles.
   *
   * @param {string} value
   */
  set bookmarkStoryTitles(value) {
    this._bookmarkStoryTitles.push(value);
  }

  /**
   * Gets the bookmark career titles.
   *
   * @return {string[]}
   */
  get bookmarkCareerTitles() {
    return this._bookmarkCareerTitles;
  }

  /**
   * Adds a bookmark career title to titles.
   *
   * @param {string} value
   */
  set bookmarkCareerTitles(value) {
    this._bookmarkCareerTitles.push(value);
  }

  /**
   * Gets the bookmark school titles.
   *
   * @return {string[]}
   */
  get bookmarkSchoolTitles() {
    return this._bookmarkSchoolTitles;
  }

  /**
   * Adds a school bookmark titles.
   *
   * @param {string} value
   */
  set bookmarkSchoolTitles(value) {
    this._bookmarkSchoolTitles.push(value);
  }

  /**
   * Gets the major titles.
   *
   * @return {string[]}
   */
  get bookmarkMajorTitles() {
    return this._bookmarkMajorTitles;
  }

  /**
   * Adds a major to the major titles.
   *
   * @param {string} value
   */
  set bookmarkMajorTitles(value) {
    this._bookmarkMajorTitles.push(value);
  }

  /**
   * Gets bookmark blog titles.
   *
   * @return {string[]}
   */
  get bookmarkBlogTitles() {
    return this._bookmarkBlogTitles;
  }

  /**
   * Adds a bookmark blog title.
   *
   * @param {string} value
   */
  set bookmarkBlogTitles(value) {
    this._bookmarkBlogTitles.push(value);
  }

  /**
   * Gets the city.
   *
   * @return {null|string}
   */
  get city() {
    return this._city;
  }

  /**
   * Sets the city.
   *
   * @param {string|null} value
   */
  set city(value) {
    this._city = value;
  }

  /**
   * Gets the state.
   *
   * @return {null}
   */
  get state() {
    return this._state;
  }

  /**
   * Sets the state.
   *
   * @param value
   */
  set state(value) {
    this._state = value;
  }

  /**
   * Gets the created date.
   *
   * @return {Date|null}
   */
  get createdDate() {
    return this._createdDate;
  }

  /**
   * Sets the created date.
   *
   * @param {Date|null} value
   */
  set createdDate(value) {
    this._createdDate = value;
  }

  /**
   * Gets the expiration date.
   *
   * @return {Date|null}
   */
  get expirationDate() {
    return this._expirationDate;
  }

  /**
   * Sets the expiration date.
   *
   * @param {Date|null} value
   */
  set expirationDate(value) {
    this._expirationDate = value;
  }

  /**
   * Gets the if the user has filled out a goal.
   *
   * @return {boolean}
   */
  get doMyGoal() {
    return this._doMyGoal;
  }

  /**
   * Sets the if the user has a goal set.
   *
   * @param {boolean} value
   */
  set doMyGoal(value) {
    this._doMyGoal = value;
  }

  /**
   * Gets if the user has filled out the looking forward to field.
   *
   * @return {boolean}
   */
  get doLookingForward() {
    return this._doLookingForward;
  }

  /**
   * Sets if the user has filled out the looking forward to field.
   *
   * @param {boolean} value
   */
  set doLookingForward(value) {
    this._doLookingForward = value;
  }

  /**
   * Gets the user's point count.
   *
   * @return {number}
   */
  get pointsCount() {
    return this._pointsCount;
  }

  /**
   * Sets the user's point count.
   *
   * @param {number} value
   */
  set pointsCount(value) {
    this._pointsCount = value;
  }

  /**
   * Gets the user's first name.
   *
   * @return {*}
   */
  get firstName() {
    return this._firstName;
  }

  /**
   * Sets the user's firstname.
   *
   * @param {string} value
   */
  set firstName(value) {
    this._firstName = value;
  }

  /**
   * Gets the user's last name.
   *
   * @return {*}
   */
  get lastName() {
    return this._lastName;
  }

  /**
   * Sets the user's last name.
   *
   * @param {string} value
   */
  set lastName(value) {
    this._lastName = value;
  }

  /**
   * Gets the user's email.
   *
   * @return {*}
   */
  get email() {
    return this._email;
  }

  /**
   * Sets the user's email.
   *
   * @param {string} value
   */
  set email(value) {
    this._email = value;
  }

  /**
   * Gets the user's auth ID.
   *
   * @return {*}
   */
  get userId() {
    return this._userId;
  }

  /**
   * Sets the user's auth ID.
   *
   * @param value
   */
  set userId(value) {
    this._userId = value;
  }

  /**
   * Gets if the user has filled out any their portfolio.
   *
   * @return {boolean}
   */
  get doPortfolio() {
    return this._doPortfolio;
  }

  /**
   * Sets if the user has filled out their portfolio.
   *
   * @param {boolean} value
   */
  set doPortfolio(value) {
    this._doPortfolio = value;
  }

  /**
   * Gets the number of career plans the user has.
   *
   * @return {number}
   */
  get careerPlanCount() {
    return this._careerPlanCount;
  }

  /**
   * Sets the career plan count.
   *
   * @param {number} value
   */
  set careerPlanCount(value) {
    this._careerPlanCount = value;
  }

  /**
   * Gets the number of skills the user has filled out.
   *
   * @return {number}
   */
  get skillCount() {
    return this._skillCount;
  }

  /**
   * Sets the user's skill count.
   *
   * @param {number} value
   */
  set skillCount(value) {
    this._skillCount = value;
  }

  /**
   * Gets the number of interests the user has filled out.
   *
   * @return {number}
   */
  get interestCount() {
    return this._interestCount;
  }

  /**
   * Sets the interest count the user has filled out.
   *
   * @param {number} value
   */
  set interestCount(value) {
    this._interestCount = value;
  }

  /**
   * Gets if the user has filled out their weekly goal.
   *
   * @return {boolean}
   */
  get doWeeklyGoal() {
    return this._doWeeklyGoal;
  }

  /**
   * Sets if the weekly goal has a value.
   *
   * @param {boolean} value
   */
  set doWeeklyGoal(value) {
    this._doWeeklyGoal = value;
  }

  /**
   * Gets the number of times a user has logged in.
   *
   * @return {number}
   */
  get loginCount() {
    return this._loginCount;
  }

  /**
   * Sets the login count.
   *
   * @param {number} value
   */
  set loginCount(value) {
    this._loginCount = value;
  }

  /**
   * Gets the story bookmark count.
   *
   * @return {number}
   */
  get bookmarkStoryCount() {
    return this._bookmarkStoryCount;
  }

  /**
   * Sets the bookmark story count.
   *
   * @param {number} value
   */
  set bookmarkStoryCount(value) {
    this._bookmarkStoryCount = value;
  }

  /**
   * Gets the bookmark career count.
   *
   * @return {number}
   */
  get bookmarkCareerCount() {
    return this._bookmarkCareerCount;
  }

  /**
   * Sets the bookmark career count.
   *
   * @param {number} value
   */
  set bookmarkCareerCount(value) {
    this._bookmarkCareerCount = value;
  }

  /**
   * Gets the bookmark school count.
   *
   * @return {number}
   */
  get bookmarkSchoolCount() {
    return this._bookmarkSchoolCount;
  }

  /**
   * Sets the bookmark school count.
   *
   * @param {number} value
   */
  set bookmarkSchoolCount(value) {
    this._bookmarkSchoolCount = value;
  }

  /**
   * Gets the bookmark major count.
   *
   * @return {number}
   */
  get bookmarkMajorCount() {
    return this._bookmarkMajorCount;
  }

  /**
   * Sets the bookmark major count.
   *
   * @param {number} value
   */
  set bookmarkMajorCount(value) {
    this._bookmarkMajorCount = value;
  }

  /**
   * Gets the bookmark blog count.
   *
   * @return {number}
   */
  get bookmarkBlogCount() {
    return this._bookmarkBlogCount;
  }

  /**
   * Sets the bookmark blog count.
   *
   * @param {number} value
   */
  set bookmarkBlogCount(value) {
    this._bookmarkBlogCount = value;
  }

  /**
   * Gets the user type.
   *
   * @return {string}
   */
  get userType() {
    return this._userType;
  }
  
  /**
   * Gets the user role.
   *
   * @return {string}
   */
  get role() {
    return this._role;
  }

  /**
   * Sets the user type.
   *
   * @param value
   */
  set userType(value) {
    this._userType = value;
  }

  /**
   * Sets the user role.
   *
   * @param value
   */
  set role(value) {
    this._role = value;
  }

  /**
   * Gets the current promo code applied by the user.
   *
   * @return {null}
   */
  get promoCode() {
    return this._promoCode;
  }

  /**
   * Sets the promo code applied by the user.
   *
   * @param value
   */
  set promoCode(value) {
    this._promoCode = value;
  }

  /**
   * Gets the user's organization code.
   *
   * @return {string}
   */
  get organizationCode() {
    return this._organizationCode;
  }

  /**
   * Sets the user's organization code.
   *
   * @param value
   */
  set organizationCode(value) {
    this._organizationCode = value;
  }

  /**
   * Gets the organization name.
   *
   * @return {null}
   */
  get organizationName() {
    return this._organizationName;
  }

  /**
   * Sets the organization name.
   *
   * @param value
   */
  set organizationName(value) {
    this._organizationName = value;
  }

  /**
   * Gets the user's school code.
   *
   * @return {string}
   */
  get schcode() {
    return this._schcode;
  }

  /**
   * Sets the user's school code.
   *
   * @param {string} value
   */
  set schcode(value) {
    this._schcode = value;
  }

  /**
   * Gets the district code.
   *
   * @return {string|null}
   */
  get distcode() {
    return this._distcode;
  }

  /**
   * Sets the district code.
   *
   * @param value
   */
  set distcode(value) {
    this._distcode = value;
  }

  /**
   * Gets the grade.
   *
   * @return {string|null}
   */
  get grade() {
    return this._grade;
  }

  /**
   * Sets the grade.
   *
   * @param value
   */
  set grade(value) {
    this._grade = value;
  }

  /**
   * Gets the LASID.
   *
   * @return {string|null}
   */
  get lasid() {
    return this._lasid;
  }

  /**
   * Sets the LASID.
   *
   * @param value
   */
  set lasid(value) {
    this._lasid = value;
  }

  /**
   * Gets the SASID.
   *
   * @return {string|null}
   */
  get sasid() {
    return this._sasid;
  }

  /**
   * Sets the SASID.
   *
   * @param value
   */
  set sasid(value) {
    this._sasid = value;
  }

  /**
   * Gets the school name.
   *
   * @return {string|null}
   */
  get schoolName() {
    return this._schoolName;
  }

  /**
   * Sets the school name.
   *
   * @param value
   */
  set schoolName(value) {
    this._schoolName = value;
  }

  /**
   * Gets the expiration status of the user.
   *
   * @return {boolean}
   *   If the user is expired or not.
   */
  isExpired() {
    // Users who never expire have empty date set.
    if (!this.expirationDate) {
      return false
    }
    return Date.now() > this.expirationDate.getTime()
  }

  /**
   * @constructor
   *
   * @param {string} userId
   *   User's auth id.
   */
  constructor(
    userId
  ) {
    this._firstName = null;
    this._lastName = null;
    this._email = null;
    this._userId = userId;
    this._doPortfolio = false;
    this._careerPlanCount = 0;
    this._skillCount = 0;
    this._interestCount = 0;
    this._doWeeklyGoal = false;
    this._loginCount = 0;
    this._bookmarkStoryCount = 0;
    this._bookmarkCareerCount = 0;
    this._bookmarkSchoolCount = 0;
    this._bookmarkMajorCount = 0;
    this._bookmarkBlogCount = 0;
    this._createdDate = null;
    this._expirationDate = null;
    this._doMyGoal = false;
    this._doLookingForward = false;
    this._pointsCount = 0;
    this._city = null;
    this._state = null;
    this._userType = null;
    this._role = null;
    this._schCodes = [];
    this._schCodesName = [];
    this._promoCode = null;
    this._bookmarkStoryTitles = [];
    this._bookmarkCareerTitles = [];
    this._bookmarkSchoolTitles = [];
    this._bookmarkMajorTitles = [];
    this._bookmarkBlogTitles = [];
    this._interests = [];
    this._skills = [];
    this._schcode = null;
    this._distcode = null;
    this._grade = null;
    this._lasid = null;
    this._sasid = null;
    this._schoolName = null;
    this._organizationCode = null;
    this._organizationName = null;
    this._lastLoginDate = null;
    this._assessmentTaken = null;
    this._assessmentResultProfile = null;
    this._personality_type_realistic = null;
    this._personality_type_investigative = null;
    this._personality_type_artistic = null;
    this._personality_type_social = null;
    this._personality_type_enterprising = null;
    this._personality_type_conventional = null;
  }

}
