import React from "react"
import ExperienceInputWork from "./experience-input-work"
import ExperienceInputEducation from "./experience-input-education"
import ExperienceInputVolunteer from "./experience-input-volunteer"
import ExperienceInputLife from "./experience-input-life"
import ExperienceInputAward from "./experience-input-award"
import ExperienceInputLeadership from "./experience-input-leadership"

const ExperienceInput = ({ type }) => {
  switch (type) {
    case "work":
      return <ExperienceInputWork type={type} />
    case "education":
      return <ExperienceInputEducation type={type} />
    case "volunteer":
      return <ExperienceInputVolunteer type={type} />
    case "life":
      return <ExperienceInputLife type={type} />
    case "leadership":
      return <ExperienceInputLeadership type={type} />
    case "award":
      return <ExperienceInputAward type={type} />
    case "award":
      return <ExperienceInputAward type={type} />
  }
}

export default ExperienceInput
