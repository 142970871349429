/**
 * Provides a sport data model.
 */
export default class Sport {
  /**
   * Constructor.
   *
   * @param {Object} firebaseData
   */
  constructor(firebaseData) {
    this._name = firebaseData?.label;
    this._intramuralMen = firebaseData?.intramuralMen;
    this._intramuralWomen = firebaseData?.intramuralWomen;
    this._intercollegiateMen = firebaseData?.intercollegiateMen;
    this._intercollegiateWomen = firebaseData?.intercollegiateWomen;
    this._scholarshipMen = firebaseData?.scholarshipMen;
    this._scholarshipWomen = firebaseData?.scholarshipWomen;
  }

  /**
   * @inheritDoc
   */
  toString() {
    return `${this.name}`;
  }

  /**
   * Gets the name of the sport.
   *
   * @return {string}
   */
  get name() {
    return this._name;
  }

  /**
   * If the sport is available for intramural men.
   *
   * @return {boolean}
   */
  get intramuralMen() {
    return this._intramuralMen;
  }

  /**
   * If the sport is available for intramural women.
   *
   * @return {boolean}
   */
  get intramuralWomen() {
    return this._intramuralWomen;
  }

  /**
   * If the sport is available for intercollegiate men.
   *
   * @return {boolean}
   */
  get intercollegiateMen() {
    return this._intercollegiateMen;
  }

  /**
   * If the sport is available for intercollegiate women.
   *
   * @return {boolean}
   */
  get intercollegiateWomen() {
    return this._intercollegiateWomen;
  }

  /**
   * If the sport has scholarships for men.
   *
   * @return {boolean}
   */
  get scholarshipMen() {
    return this._scholarshipMen;
  }

  /**
   * If the sport has scholarships for women.
   *
   * @return {boolean}
   */
  get scholarshipWomen() {
    return this._scholarshipWomen;
  }
}
