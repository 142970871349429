import React, { useState, useEffect } from "react"
import Link from "../link"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebook as fabFacebook } from "@fortawesome/free-brands-svg-icons"
import { faTwitter as fabTwitter } from "@fortawesome/free-brands-svg-icons"
import { faLinkedinIn as fabLinkedinIn } from "@fortawesome/free-brands-svg-icons"
import FooterLogo from "../../static/assets/images/logo-with-text-vertical.png"
import { useLocation } from "@reach/router"
import "./footer.css"
import { FormattedMessage } from "react-intl"
import LanguageSwitcher from "./language-switcher/LanguageSwitcher"

const Footer = ({ siteTitle }) => {
  const location = useLocation()
  const [isVisible, setIsVisible] = useState(true)
  const localizationEnabled = process.env.GATSBY_ENABLE_LOCALIZATION === "true"


  useEffect(() => {
    // paths to prevent from being rendered on page
    const hiddenPagePaths = [
      "/login",
      "/login/",
      "/login/individuals",
      "/login/individuals/",
      "/signup/individuals",
      "/signup/individuals/",
      "/es-ES/login",
      "/es-ES/login/",
      "/es-ES/login/individuals",
      "/es-ES/login/individuals/",
      "/es-ES/signup/individuals",
      "/es-ES/signup/individuals/",
      "/passwordless/authentication",
      "/educator/",
      "/educator",
      "/educator/students",
      "/educator/students/",
      "/educator/students/group/",
      "/educator/students/group",
      "/educator/faculty",
      "/educator/faculty/", 
      "/educator/analytics",
      "/educator/analytics/"
    ]
    const currentPath = location?.pathname;
    const isVisible = !hiddenPagePaths.some(path => currentPath.startsWith(path));
    setIsVisible(isVisible)
  }, [location])

  return (
    <>
      {isVisible && (
        <div className="footer">
          <div className="inner inner-footer">
            <div className="footer-primary">
              <span className="logo">
                <Link to="/">
                  <img
                    className="logo-image"
                    src={FooterLogo}
                    alt={siteTitle}
                  />
                </Link>
              </span>

              <nav className="navigation">
                <ul className="menu-secondary menu-secondary-level-0">
                  <li>
                    <span role="button">
                      {" "}
                      <FormattedMessage
                        id="footer-about-wingspans"
                        defaultMessage="About Wingspans"
                      />
                    </span>
                    <ul className="menu-secondary menu-secondary-level-1">
                      <li>
                        <Link to="/about">
                          {" "}
                          <FormattedMessage
                            id="footer-about-us"
                            defaultMessage="About Us"
                          />
                        </Link>
                      </li>
                      <li>
                        <Link to="/news">
                          {" "}
                          <FormattedMessage
                            id="footer-news"
                            defaultMessage="News"
                          />
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <span role="button">
                      {" "}
                      <FormattedMessage
                        id="footer-partners"
                        defaultMessage="Partners"
                      />
                    </span>
                    <ul className="menu-secondary menu-secondary-level-1">
                      <li>
                        <Link to="/signup/employers">
                          {" "}
                          <FormattedMessage
                            id="footer-employers"
                            defaultMessage="Employers"
                          />
                        </Link>
                      </li>
                      <li>
                        <Link to="/signup/educators">
                          {" "}
                          <FormattedMessage
                            id="footer-educators"
                            defaultMessage="Educators"
                          />
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <span role="button">
                      {" "}
                      <FormattedMessage
                        id="footer-support"
                        defaultMessage="Support"
                      />
                    </span>
                    <ul className="menu-secondary menu-secondary-level-1">
                      <li>
                        <Link to="/contact">
                          {" "}
                          <FormattedMessage
                            id="footer-contact"
                            defaultMessage="Contact"
                          />
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>

              <ul className="social-links">
                <li>
                  <a
                    aria-label="LinkedIn"
                    href="https://www.linkedin.com/company/wing-spans/"
                  >
                    <FontAwesomeIcon icon={fabLinkedinIn} />
                  </a>
                </li>
                <li>
                  <a aria-label="Twitter" href="https://twitter.com/Wing_spans">
                    <FontAwesomeIcon icon={fabTwitter} />
                  </a>
                </li>
                <li>
                  <a
                    aria-label="Facebook"
                    href="https://www.facebook.com/YourWingspans"
                  >
                    <FontAwesomeIcon icon={fabFacebook} />
                  </a>
                </li>
              </ul>
            </div>

            <div className="footer-language-switcher" >
              <div>{localizationEnabled && <LanguageSwitcher footer={true}/>}</div>
              <div
                className="footer-secondary"

              >
                <div className="copyright">&copy; <FormattedMessage
                  id="footer-copyright"
                  defaultMessage="Copyright"
                />  2024 Wingspans</div>
                <ul className="page-links">
                  <li>
                    <Link to="/about/privacy-policy">
                      {" "}
                      <FormattedMessage
                        id="footer-privacy-policy"
                        defaultMessage="Privacy Policy"
                      />
                    </Link>
                  </li>
                  <li style={{ marginRight: "0px" }}>
                    <Link to="/about/terms-of-use">
                      {" "}
                      <FormattedMessage
                        id="footer-terms-of-use"
                        defaultMessage="Terms of Use"
                      />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Footer
