import Sport from './Sport';
import Cost from "./Cost"
import MajorList from "./MajorList";
import ApplicationDeadline from "./ApplicationDeadline"

/**
 * Provides a model of school data from firestore.
 *
 * PLEASE NOTE: Check the getters for each data type to see what to expect
 * for the type. Also remember many schools have partial data, and no data
 * so almost every single property can have a `null` value. This is important
 * to check for any front end display; `property !== null` before rendering and
 * displaying any piece of data.
 */
export default class School {

  /**
   * Constructor.
   *
   * @param {Object} firestoreData
   *   Firestore school data.
   */
  constructor(firestoreData) {
    this._petersonId = firestoreData?.id;
    this._name = firestoreData?.name;
    this._schoolUrl = firestoreData?.schoolUrl;
    this._state = firestoreData?.state;
    this._country = firestoreData?.country;
    this._city = firestoreData?.city;
    this._zipCode = firestoreData?.zipCode;
    this._line1 = firestoreData?.line1;
    this._facultySize = firestoreData?.facultySize;
    this._ratioStudentFaculty = firestoreData?.ratioStudentFaculty;
    this._studentsLivingInHousing = firestoreData?.studentsLivingInHousing;
    this._diversityNonWhite = firestoreData?.diversityNonWhite;

    // School type
    this._ugSchooltype = firestoreData?.ugSchooltype;
    this._techSchooltype = firestoreData?.techSchooltype;
    this._gradSchooltype = firestoreData?.gradSchooltype;
    this._bachSchooltype = firestoreData?.bachSchooltype;
    this._associateSchooltype = firestoreData?.associateSchooltype;

    // Filters
    this._sizeFilter = firestoreData?.sizeFilter;
    this._locationSettingFilter = firestoreData?.locationSettingFilter;
    this._schoolTypeFilter = firestoreData?.schoolTypeFilter;
    this._schoolSelectivityFilter = firestoreData?.schoolSelectivityFilter;
    this._costFilter = firestoreData?.costFilter;

    // Admissions
    this._appliedCount = firestoreData?.appliedCount;
    this._acceptedCount = firestoreData?.acceptedCount;
    this._enrolledCount = firestoreData?.enrolledCount;
    this._averageSatReading = firestoreData?.averageSatReading;
    this._averageSatReading25th = firestoreData?.averageSatReading25th;
    this._averageSatReading75th = firestoreData?.averageSatReading75th;
    this._averageSatMath = firestoreData?.averageSatMath;
    this._averageSatMath25th = firestoreData?.averageSatMath25th;
    this._averageSatMath75th = firestoreData?.averageSatMath75th;
    this._acceptanceRate = firestoreData?.acceptanceRate;
    this._actCompositeScore = firestoreData?.actCompositeScore;
    this._actCompositeScore25th = firestoreData?.actCompositeScore25th;
    this._actCompositeScore75th = firestoreData?.actCompositeScore75th;
    this._averageHighSchoolGPA = firestoreData?.averageHighSchoolGPA;
    this._applicationFee = firestoreData?.applicationFee ? new Cost(firestoreData?.applicationFee) : null;
    this._applicationRequirements = firestoreData?.applicationRequirements;

    // Deadlines
    this._applicationDeadlineFreshman = firestoreData?.applicationDeadlineFreshman ? new ApplicationDeadline(firestoreData.applicationDeadlineFreshman) : null;
    this._applicationDeadlineOther = firestoreData?.applicationDeadlineOther ? new ApplicationDeadline(firestoreData.applicationDeadlineOther) : null;
    this._applicationDeadlineTransfer = firestoreData?.applicationDeadlineTransfer ? new ApplicationDeadline(firestoreData.applicationDeadlineTransfer) : null;
    this._applicationDeadlineEarly = firestoreData?.applicationDeadlineEarly ? new ApplicationDeadline(firestoreData.applicationDeadlineEarly) : null;
    this._applicationDeadlineOutOfState = firestoreData?.applicationDeadlineOutOfState ? new ApplicationDeadline(firestoreData.applicationDeadlineOutOfState) : null;

    // Tuition and Aid
    this._costBooksAndSuppliesCost = firestoreData?.costBooksAndSuppliesCost ? new Cost(firestoreData?.costBooksAndSuppliesCost) : null;
    this._costRoomAndBoard = firestoreData?.costRoomAndBoard ? new Cost(firestoreData?.costRoomAndBoard) : null;
    this._costRoomOnly = firestoreData?.costRoomOnly ? new Cost(firestoreData?.costRoomOnly) : null;
    this._generalTuition = firestoreData?.generalTuition ? new Cost(firestoreData?.generalTuition) : null;
    this._inStateTuition = firestoreData?.inStateTuition ? new Cost(firestoreData?.inStateTuition) : null;
    this._outOfStateTuition = firestoreData?.outOfStateTuition ? new Cost(firestoreData?.outOfStateTuition) : null;
    this._averageFinancialAid = firestoreData?.averageFinancialAid ? new Cost(firestoreData?.averageFinancialAid) : null;
    this._averageLoanAndWorkStudy = firestoreData?.averageLoanAndWorkStudy ? new Cost(firestoreData?.averageLoanAndWorkStudy) : null;
    this._averageScholarship = firestoreData?.averageScholarship ? new Cost(firestoreData?.averageScholarship) : null;
    this._averageFinancialAidCoverage = firestoreData?.averageFinancialAidCoverage;
    this._averageStudentNeedsMet = firestoreData?.averageStudentNeedsMet;

    // Student Population
    this._totalUndergradStudents = firestoreData?.totalUndergradStudents;
    this._totalAllRace = firestoreData?.totalAllRace;
    this._percentInStateStudents = firestoreData?.percentInStateStudents;
    this._percentOutOfStateStudents = firestoreData?.percentOutOfStateStudents;
    this._percentInternationalStudents = firestoreData?.percentInternationalStudents;
    this._percentFemaleStudents = firestoreData?.percentFemaleStudents;
    this._percentMaleStudents = firestoreData?.percentMaleStudents;
    this._demographicNativeAmerican = firestoreData?.demographicNativeAmerican;
    this._demographicAsian = firestoreData?.demographicAsian;
    this._demographicBlack = firestoreData?.demographicBlack;
    this._demographicLatin = firestoreData?.demographicLatin;
    this._demographicPacificIslander = firestoreData?.demographicPacificIslander;
    this._demographicOther = firestoreData?.demographicOther;
    this._demographicMixed = firestoreData?.demographicMixed;
    this._demographicWhite = firestoreData?.demographicWhite;

    // Athletics
    if (Array.isArray(firestoreData.athletics) && firestoreData.athletics.length > 0) {
      this._athletics = [];
      firestoreData.athletics.forEach((sportData) => {
        const sport = new Sport(sportData);
        this._athletics.push(sport);
      })
    } else {
      this._athletics = null;
    }

    // Cosmic Data
    this._slug = firestoreData?.slug;
    this._cosmicId = firestoreData?.cosmicId;

    // Majors data
    this._majors = firestoreData?.majors ? new MajorList(firestoreData?.majors) : null;

    // Tech school data
    this._techSchoolFunctionType = firestoreData?.techSchoolFunctionType;
    this._techSchoolPopType = firestoreData?.techSchoolPopType;
  }

  /**
   * Getter for teh tech school student body type (co-ed vs male/female)
   *
   * Please see the possible values and their definitions in format (value - definition):
   * {
   *  CE - Coeducational
   *  OM - Only Men
   *  OW - Only Women
   *  PM - Primarily Men
   *  PW - Primarily Women
   *  UM - Undergraduate: men only, graduate: coed
   *  UW - Undergraduate: women only, graduate: coed
   * }
   * @return {null|string}
   */
  get techSchoolPopType() {
    return this._techSchoolPopType;
  }

  /**
   * Getter for the tech school type (2 year or 4 year)
   *
   * Please see the possible values and their definitions in format (value - definition):
   * {
   *  2GRAD - 2-year college with graduate programs
   *  2YEAR - 2-year college
   *  4YEAR - 4-year college
   *  5YEAR - five-year college
   *  COMP - comprehensive higher education institution
   *  GRAD - graduate institution [offers only graduate-level programs]
   *  SYS - system [administers state-controlled institutions, public community colleges, independent institution systems, or proprietary school systems; does not, as a rule, award degrees]
   *  UNIV - university [offers four years of undergraduate work, plus graduate degrees through the doctorate in more than two fields]
   *  UPWG - upper-level higher education institution with graduate programs [offers last two years of bachelor’s program, and offers graduate degrees]
   *  UPWOG - upper-level higher education institution without graduate programs [offers last two years of bachelor’s program]
   * }
   *
   * @return {null|string}
   */
  get techSchoolFunctionType() {
    return this._techSchoolFunctionType;
  }

  /**
   * Getter for out of state application deadline.
   *
   * @return {ApplicationDeadLine|null}
   */
  get applicationDeadlineOutOfState() {
    return this._applicationDeadlineOutOfState;
  }

  /**
   * Getter for early application deadlines.
   *
   * @return {ApplicationDeadLine|null}
   */
  get applicationDeadlineEarly() {
    return this._applicationDeadlineEarly;
  }

  /**
   * If this school offers associate degrees.
   *
   * @return {boolean}
   */
  get associateSchooltype() {
    return this._associateSchooltype;
  }

  /**
   * If the school offers bachelor degrees.
   *
   * @return {boolean}
   */
  get bachSchooltype() {
    return this._bachSchooltype;
  }

  /**
   * Array of sports offered, or null if none found.
   *
   * @return {Sport[]|null}
   */
  get athletics() {
    return this._athletics;
  }

  /**
   * Ratio of students to faculty.
   *
   * @return {number|null}
   */
  get ratioStudentFaculty() {
    return this._ratioStudentFaculty;
  }

  /**
   * Total students of all races, used in race calculations.
   *
   * @return {number|null}
   */
  get totalAllRace() {
    return this._totalAllRace;
  }

  /**
   * The cost of general tuition.
   *
   * Sometimes schools don't have in/out of state, so use this number in showing
   * cost data.
   *
   * @return {Cost|null}
   */
  get generalTuition() {
    return this._generalTuition;
  }

  /**
   * Getter for freshmen deadlines.
   *
   * @return {ApplicationDeadLine|null}
   */
  get applicationDeadlineFreshman() {
    return this._applicationDeadlineFreshman;
  }

  /**
   * Getter for early other application deadlines.
   *
   * @return {ApplicationDeadLine|null}
   */
  get applicationDeadlineOther() {
    return this._applicationDeadlineOther;
  }

  /**
   * Getter for transfer application deadlines.
   *
   * @return {ApplicationDeadLine|null}
   */
  get applicationDeadlineTransfer() {
    return this._applicationDeadlineTransfer;
  }

  /**
   * The school street address.
   *
   * Some of the data has the incorrect address here, in most cases
   * it lists a street, but in some it lists a PO Box and the street
   * is in line2 which we do not transfer.
   *
   * @return {string|null}
   */
  get line1() {
    return this._line1;
  }

  /**
   * Gets the school major list object, @see {MajorList}
   *
   * @return {MajorList|null}
   */
  get majors() {
    return this._majors;
  }

  /**
   * Gets the cosmic object slug.
   *
   * @return {string|null}
   */
  get slug() {
    return this._slug;
  }

  /**
   * Gets the cosmic ID.
   *
   * @return {string|null}
   */
  get cosmicId() {
    return this._cosmicId;
  }

  /**
   * Gets the school ID, same as Peterson's ID.
   *
   * The data saves this as an integer, but in Cosmic it is a string.
   *
   * @return {number}
   */
  get id() {
    return this._petersonId;
  }

  /**
   * The school ID.
   *
   * @return {number}
   */
  get petersonId() {
    return this._petersonId;
  }

  /**
   * The school name, is also title in Cosmic.
   *
   * @return {string}
   */
  get name() {
    return this._name;
  }

  /**
   * The school's website.
   *
   * @return {string|null}
   */
  get schoolUrl() {
    return this._schoolUrl;
  }

  /**
   * The school's state code in format `MA`.
   *
   * @return {string|null}
   */
  get state() {
    return this._state;
  }

  /**
   * The school's country code in format `USA`.
   *
   * @return {string|null}
   */
  get country() {
    return this._country;
  }

  /**
   * The school's city name.
   *
   * @return {string|null}
   */
  get city() {
    return this._city;
  }

  /**
   * The school's zip code.
   *
   * @return {string|null}
   */
  get zipCode() {
    return this._zipCode;
  }

  /**
   * The school's faculty size.
   *
   * This is usually UG only, does not include grad school faculty.
   *
   * @return {number|null}
   */
  get facultySize() {
    return this._facultySize;
  }

  /**
   * Percent of students living in campus housing.
   *
   * @return {string|null}
   */
  get studentsLivingInHousing() {
    return this._studentsLivingInHousing;
  }

  /**
   * Percent of non-white students.
   *
   * @return {string|null}
   */
  get diversityNonWhite() {
    return this._diversityNonWhite;
  }

  /**
   * School size filter, used in cosmic.
   *
   * @return {string|null}
   */
  get sizeFilter() {
    return this._sizeFilter;
  }

  /**
   * School location filter, used in cosmic.
   *
   * @return {string|null}
   */
  get locationSettingFilter() {
    return this._locationSettingFilter;
  }

  /**
   * School type filter, used in cosmic.
   *
   * @return {string|null}
   */
  get schoolTypeFilter() {
    return this._schoolTypeFilter;
  }

  /**
   * School selectivity filter, used in cosmic.
   *
   * @return {string|null}
   */
  get schoolSelectivityFilter() {
    return this._schoolSelectivityFilter;
  }

  /**
   * School cost filter, used in cosmic.
   *
   * @return {string|null}
   */
  get costFilter() {
    return this._costFilter;
  }

  /**
   * The number of students who applied.
   *
   * @return {number|null}
   */
  get appliedCount() {
    return this._appliedCount;
  }

  /**
   * The number of students who were accepted.
   *
   * @return {number|null}
   */
  get acceptedCount() {
    return this._acceptedCount;
  }

  /**
   * The number of students enrolled.
   *
   * For UG schools this is total UG students, for tech schools it is enrolled
   * count.
   *
   * @return {number|null}
   */
  get enrolledCount() {
    return this._enrolledCount;
  }

  /**
   * The average SAT reading score.
   *
   * @return {number|null}
   */
  get averageSatReading() {
    return this._averageSatReading;
  }

  /**
   * The 25th percentile average SAT reading score.
   *
   * @return {number|null}
   */
  get averageSatReading25th() {
    return this._averageSatReading25th;
  }

  /**
   * The 75th percentile average SAT reading score.
   *
   * @return {number|null}
   */
  get averageSatReading75th() {
    return this._averageSatReading75th;
  }

  /**
   * The average SAT math score.
   *
   * @return {number|null}
   */
  get averageSatMath() {
    return this._averageSatMath;
  }

  /**
   * The 25th percentile average SAT math score.
   *
   * @return {number|null}
   */
  get averageSatMath25th() {
    return this._averageSatMath25th;
  }

  /**
   * The 75th percentile average SAT math score.
   *
   * @return {number|null}
   */
  get averageSatMath75th() {
    return this._averageSatMath75th;
  }

  /**
   * The acceptance rate, a percentage.
   *
   * @return {string|null}
   */
  get acceptanceRate() {
    return this._acceptanceRate;
  }

  /**
   * The average ACT composite score.
   *
   * @return {number|null}
   */
  get actCompositeScore() {
    return this._actCompositeScore;
  }

  /**
   * The 25th percentile average ACT composite score.
   *
   * @return {number|null}
   */
  get actCompositeScore25th() {
    return this._actCompositeScore25th;
  }

  /**
   * The 75th percentile average ACT composite score.
   *
   * @return {number|null}
   */
  get actCompositeScore75th() {
    return this._actCompositeScore75th;
  }

  /**
   * The average high school GPA between `0.0` and `5.0`.
   *
   * @return {number|null}
   */
  get averageHighSchoolGPA() {
    return this._averageHighSchoolGPA;
  }

  /**
   * The application fee.
   *
   * @return {Cost|null}
   */
  get applicationFee() {
    return this._applicationFee;
  }

  /**
   * The application requirements, this is a list of strings.
   *
   * @return {string[]|null}
   */
  get applicationRequirements() {
    return this._applicationRequirements;
  }

  /**
   * Cost of books and supplies.
   *
   * @return {Cost|null}
   */
  get costBooksAndSuppliesCost() {
    return this._costBooksAndSuppliesCost;
  }

  /**
   * Cost of room and board.
   *
   * @return {Cost|null}
   */
  get costRoomAndBoard() {
    return this._costRoomAndBoard;
  }

  /**
   * Cost of room only.
   *
   * @return {Cost|null}
   */
  get costRoomOnly() {
    return this._costRoomOnly;
  }

  /**
   * In state tuition.
   *
   * @return {Cost|null}
   */
  get inStateTuition() {
    return this._inStateTuition;
  }

  /**
   * Out of state tuition.
   *
   * @return {Cost|null}
   */
  get outOfStateTuition() {
    return this._outOfStateTuition;
  }

  /**
   * The average financial aid.
   *
   * @return {Cost|null}
   */
  get averageFinancialAid() {
    return this._averageFinancialAid;
  }

  /**
   * The average loan and work study added together.
   *
   * @return {Cost|null}
   */
  get averageLoanAndWorkStudy() {
    return this._averageLoanAndWorkStudy;
  }

  /**
   * The average scholarship.
   *
   * @return {Cost|null}
   */
  get averageScholarship() {
    return this._averageScholarship;
  }

  /**
   * The average financial aid coverage.
   *
   * NOTE: This is a percentage of average financial aid to tuition. We first
   * try to find it with out of state tuition and then fallback to general
   * tuition. Make sure your text reflects this, it is not always out of
   * state tuition.
   *
   * @return {string|null}
   */
  get averageFinancialAidCoverage() {
    return this._averageFinancialAidCoverage;
  }

  /**
   * The average student needs met percentage.
   *
   * @return {string|null}
   */
  get averageStudentNeedsMet() {
    return this._averageStudentNeedsMet;
  }

  /**
   * The total undergrad students.
   *
   * @return {null|number}
   */
  get totalUndergradStudents() {
    return this._totalUndergradStudents;
  }

  /**
   * Percent of in state students.
   *
   * @return {string|null}
   */
  get percentInStateStudents() {
    return this._percentInStateStudents;
  }

  /**
   * Percent of out of state students.
   *
   * @return {string|null}
   */
  get percentOutOfStateStudents() {
    return this._percentOutOfStateStudents;
  }

  /**
   * Percent of international students.
   *
   * @return {string|null}
   */
  get percentInternationalStudents() {
    return this._percentInternationalStudents;
  }

  /**
   * Percent of female students.
   *
   * @return {string|null}
   */
  get percentFemaleStudents() {
    return this._percentFemaleStudents;
  }

  /**
   * Percent of male students.
   *
   * @return {string|null}
   */
  get percentMaleStudents() {
    return this._percentMaleStudents;
  }

  /**
   * Percent of native american students.
   *
   * @return {string|null}
   */
  get demographicNativeAmerican() {
    return this._demographicNativeAmerican;
  }

  /**
   * Percent of asian students.
   *
   * @return {string|null}
   */
  get demographicAsian() {
    return this._demographicAsian;
  }

  /**
   * Percent of black students.
   *
   * @return {string|null}
   */
  get demographicBlack() {
    return this._demographicBlack;
  }

  /**
   * Percent of hispanic students.
   *
   * @return {string|null}
   */
  get demographicLatin() {
    return this._demographicLatin;
  }

  /**
   * Percent of pacific islander.
   *
   * @return {string|null}
   */
  get demographicPacificIslander() {
    return this._demographicPacificIslander;
  }

  /**
   * Percent of other demographic.
   *
   * @return {string|null}
   */
  get demographicOther() {
    return this._demographicOther;
  }

  /**
   * Percent of mixed demographic.
   *
   * @return {string|null}
   */
  get demographicMixed() {
    return this._demographicMixed;
  }

  /**
   * Percent of white demographic.
   *
   * @return {string|null}
   */
  get demographicWhite() {
    return this._demographicWhite;
  }

  /**
   * If the school is a undergraduate school.
   *
   * @return {boolean}
   */
  get ugSchooltype() {
    return this._ugSchooltype;
  }

  /**
   * If the school is a technical school.
   *
   * @return {boolean}
   */
  get techSchooltype() {
    return this._techSchooltype;
  }

  /**
   * If the school is a graudate school type.
   *
   * @return {boolean}
   */
  get gradSchooltype() {
    return this._gradSchooltype;
  }

  /**
   * If the school is only a technical school.
   *
   * @return {boolean}
   */
  isTechSchoolOnly() {
    return this.techSchooltype && !this.ugSchooltype && !this.gradSchooltype;
  }
}
