import MilestoneListDates from "./MilestoneListDates"
import MilestoneTextDate from "./MilestoneTextDate"
import MilestoneTextDates from "./MilestoneTextDates"
import React from "react"
import MilestoneAlgoliaListDates from "./MilestoneAlgoliaListDates"

/**
 * A component to render milestones.
 *
 * @param {Milestone} milestone
 *   The current milestone to render.
 * @param milestoneLabel
 *   The milestone labeling for that milestone type.
 *
 * @return {JSX.Element|null}
 */
const MilestoneView = ({ milestone, milestoneLabel }) => {

  /**
   * Gets the date period render.
   *
   * @param {Milestone} milestone
   *   A milestone that uses a `DatePeriod` to store date information.
   *
   * @return {JSX.Element|null}
   */
  const getDatePeriod = (milestone) => {
    if (milestone?.period.endDate && milestone?.period.startDate) {
      return (
        <div className="career-plan-planned-item-milestone-date"><strong>{milestone.period.startDateYear}-{milestone.period.endDateYear}</strong></div>
      );
    }
    return null;
  }

  /**
   * Gets the date render.
   *
   * @param {Milestone} milestone
   *   A milestone that uses a `DateMonthYear` to store date information.
   *
   * @return {JSX.Element|null}
   */
  const getDate = (milestone) => {
    if (milestone?.date.date) {
      return (
        <div className="career-plan-planned-item-milestone-date"><strong>{milestone.date.year}</strong></div>
      );
    }
    return null;
  }

  /**
   * Gets the milestone title or label if you will.
   *
   * @param {Milestone} milestone
   *
   * @return {JSX.Element}
   */
  const getLabel = (milestone) => {
    return (
      <div className="career-plan-planned-item-milestone-title">
        {milestoneLabel.title}
      </div>
    );
  }

  /**
   * Gets the primary detail information of the milestone.
   *
   * @param {Milestone} milestone
   *
   * @return {JSX.Element|null}
   */
  const getPrimaryDetail = (milestone) => {
    if (!milestone.goal) {
      return null;
    }
    return (
      <div className="career-plan-planned-item-milestone-major"><strong>{milestoneLabel.goalLabel}:</strong> {milestone.goal}</div>
    );
  }

  /**
   * Gets the list information from a `MilestoneListType` milestone.
   *
   * @param {MilestoneListType} milestone
   *   The milestone.
   *
   * @return {JSX.Element|null}
   */
  const getListDetail = (milestone) => {
    const milestoneCount = milestone.list.length;
    if (!milestone.list || milestoneCount < 1) {
      return null;
    }
    let moreMessage = null;
    if (milestoneCount > 3) {
      const moreNumber = milestoneCount - 3;
      moreMessage = `... + ${moreNumber} more`;
    }
    const listRender = [];
    for (let i = 0; i < 3; i++) {
      if (i === 2) {
        listRender.push(<div key={i}>{milestone.list[i]}{moreMessage}</div>);
      }
      else {
        listRender.push(<div key={i}>{milestone.list[i]}</div>);
      }
    }

    return (
      <div className="career-plan-planned-item-milestone-schools">
        <div className="career-plan-planned-item-milestone-schools-label"><strong>{milestoneLabel.goalDetailLabelPlural}:</strong></div>
        <div className="career-plan-planned-item-milestone-school">
          {listRender}
        </div>
      </div>
    );
  }

  /**
   * Gets the text details of a milestone.
   *
   * @param {MilestoneTextType} milestone
   *   A milestone that has text for the secondary detail.
   *
   * @return {JSX.Element|null}
   */
  const getTextDetail = (milestone) => {
    if (!milestone.text) {
      return null;
    }
    return (
      <div className="career-plan-planned-item-milestone-details-label"><strong>{milestoneLabel.goalDetailLabel}:</strong> {milestone.text}</div>
    );
  }

  if (milestone instanceof MilestoneListDates || milestone instanceof MilestoneAlgoliaListDates) {
    return (
      <div className="career-plan-planned-item-milestone">
        {getLabel(milestone)}
        {getDatePeriod(milestone)}
        {getPrimaryDetail(milestone)}
        {getListDetail(milestone)}
      </div>
    );
  }
  else if (milestone instanceof MilestoneTextDate) {
    return (
      <div className="career-plan-planned-item-milestone">
        {getLabel(milestone)}
        {getDate(milestone)}
        {getPrimaryDetail(milestone)}
        {getTextDetail(milestone)}
      </div>
    );
  }
  else if (milestone instanceof MilestoneTextDates) {
    return (
      <div className="career-plan-planned-item-milestone">
        {getLabel(milestone)}
        {getDatePeriod(milestone)}
        {getPrimaryDetail(milestone)}
        {getTextDetail(milestone)}
      </div>
    );
  }
  else {
    return null;
  }
};

export default MilestoneView;
