import React, { useState, useContext } from "react"
import Modal from "react-bootstrap/Modal"
import {
  faBriefcase,
  faSchool,
  faHandsHelping,
  faUser,
  faUsers,
  faMedal,
} from "@fortawesome/free-solid-svg-icons"
import {
  faStars,
  faHeartCircle,
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ResumeExperienceEditorModal from "./ResumeExperienceEditorModal"

import { PortfolioContext } from "../dashboard/PortfolioContext"
import { FormattedMessage } from "react-intl"

/**
 * Multi modal component
 * Modal 1: For selection of resume experience based upon type
 * Modal 2: Contains input fields for submitting experience
 *  */
const ExperienceTypeSelector = ({ isSelectorModalOpen, isEditorModalOpen }) => {
  const [selectedType, setSelectedType] = useState(null)
  const { handleModalChange } = useContext(PortfolioContext)

  const selectTypeAndChangeModal = type => {
    setSelectedType(type)
    handleModalChange("EDITING")
  }

  return (
    <>
      {/* first modal, for selecting */}
      {isSelectorModalOpen && (
        <Modal
          className="selector-modal"
          show={isSelectorModalOpen}
          onHide={() => handleModalChange("CLEAR")}
          size="md"
          animation={false}
        >
          <Modal.Header closeButton>
            <div className="eyebrow component-eyebrow"> <FormattedMessage
              id="portfolio-experience-selector-add-experience"
              defaultMessage="Add experience"
            /></div>
          </Modal.Header>
          <Modal.Body>
            <div
              className="experience-type-row"
              onClick={() => selectTypeAndChangeModal("education")}
            >
              <span style={{ color: "#F58E35" }}>
                <FontAwesomeIcon icon={faSchool} />{" "}
              </span>{" "}
              <FormattedMessage
                id="portfolio-experience-selector-education"
                defaultMessage="Education"
              />
            </div>{" "}
            <div
              className="experience-type-row"
              onClick={() => selectTypeAndChangeModal("work")}
            >
              <span style={{ color: "#61CADC" }}>
                <FontAwesomeIcon icon={faBriefcase} />{" "}
              </span>{" "}
              <FormattedMessage
                id="portfolio-experience-selector-work"
                defaultMessage="Work Experience"
              />
            </div>
            <div
              className="experience-type-row"
              onClick={() => selectTypeAndChangeModal("volunteer")}
            >
              <span style={{ color: "#911FDC" }}>
                <FontAwesomeIcon icon={faHandsHelping} />{" "}
              </span>{" "}
              <FormattedMessage
                id="portfolio-experience-selector-volunteer"
                defaultMessage="Volunteer"
              />
            </div>{" "}
            <div
              className="experience-type-row"
              onClick={() => selectTypeAndChangeModal("leadership")}
            >
              <span style={{ color: "#3D9AD2" }}>
                <FontAwesomeIcon icon={faUsers} />{" "}
              </span>{" "}
              <FormattedMessage
                id="portfolio-experience-selector-leadership"
                defaultMessage="Leadership"
              />
            </div>
            <div
              className="experience-type-row"
              onClick={() => selectTypeAndChangeModal("award")}
            >
              <span style={{ color: "#67C744" }}>
                <FontAwesomeIcon icon={faMedal} />{" "}
              </span>{" "}
              <FormattedMessage
                id="portfolio-experience-selector-honors-and-awards"
                defaultMessage="Honors and Awards"
              />

            </div>{" "}
            <div
              className="experience-type-row"
              onClick={() => selectTypeAndChangeModal("life")}
            >
              <span style={{ color: "#CC3A5D" }}>
                <FontAwesomeIcon icon={faUser} />{" "}
              </span>{" "}
              <FormattedMessage
                id="portfolio-experience-selector-life"
                defaultMessage="Life"
              />
            </div>
            <div
              className="experience-type-row"
              onClick={() => selectTypeAndChangeModal("skills")}
            >
              <span style={{ color: "#CC3A5D" }}>
                <FontAwesomeIcon icon={faStars} color={"#C737D3"} />{" "}
              </span>{" "}
              <FormattedMessage
                id="profile-skills"
                defaultMessage="Skills"
              />
            </div>
            <div
              className="experience-type-row"
              onClick={() => selectTypeAndChangeModal("interests")}
            >
              <span style={{ color: "#CC3A5D" }}>
                <FontAwesomeIcon icon={faHeartCircle} color={"#5E4CF2"} />{" "}
              </span>{" "}
              <FormattedMessage
                id="profile-interests"
                defaultMessage="Interests"
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="submit"
              onClick={() => handleModalChange("TIPS")}
              className="component-button component-button-submit-ghost component-button-small"
            >
              <FormattedMessage
                id="portfolio-experience-selector-resume-tips"
                defaultMessage="Resume Tips"
              />

            </button>
          </Modal.Footer>
        </Modal>
      )}

      {/* second modal, for adding a new experience */}

      {isEditorModalOpen && (
        <ResumeExperienceEditorModal
          isNewExperience={true}
          show={isEditorModalOpen}
          type={selectedType}
        />
      )}
    </>
  )
}

export default ExperienceTypeSelector
