import React, { useState, useEffect, useContext, useRef } from "react"
import { FirebaseContext } from "../Firebase"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit } from "@fortawesome/free-solid-svg-icons"
import { Alert } from "react-bootstrap"
import EyeBrow from "../eyebrow/eyebrow"
import { Checkbox } from "semantic-ui-react"
import Form from "react-bootstrap/Form"

import { notificationOptions } from "../dashboard/notifications/notificationOptions"
import PortfolioPublicToggle from "./portfolio-public-toggle.js"
import { FormattedMessage, useIntl } from "react-intl"

const PersonalInfoForm = () => {
  const {
    profile,
    firebase,
    notifier,
    setPointTotals,
    setNotification,
    setWalkThroughStepValues,
  } = useContext(FirebaseContext)
  const [editing, setEditing] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [formValues, setFormValues] = useState({
    profileQuote: "",
    aboutMe: "",
    myStrengths: "",
    myWeaknesses: "",
    city: "",
    state: "",
  })
  const { formatMessage, locale } = useIntl()

  const firstInput = useRef(null)

  const quoteMaxChar = 1000
  const inputMaxChar = 2000

  /**
   * When available, load existing profile data into the form. If the saved
   * data updates (e.g., from another browser tab) the form will update
   * immediately.
   */
  useEffect(() => {
    if (profile) {
      setFormValues({
        ...formValues,
        profileQuote: profile.profileQuote ?? "",
        aboutMe: profile.aboutMe ?? "",
        myStrengths: profile.myStrengths ?? "",
        myWeaknesses: profile.myWeaknesses ?? "",
        city: profile.city ?? "",
        state: profile.state ?? "",
      })
    }
  }, [profile])


  /**
   * Set focus on first form field when entering edit mode.
   */
  useEffect(() => {
    if (editing) {
      firstInput.current.focus()
    }
  }, [editing])

  const handleChange = e => {
    e.persist()
    setFormValues(currentValues => ({
      ...currentValues,
      [e.target.name]: e.target.value,
    }))
  }

  const handleKeyPress = e => {
    // Prevent line breaks and accidental form submissions
    if (e.key === "Enter") {
      e.preventDefault()
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()

    try {
      await firebase.updateProfile({ data: formValues })
      await firebase.saveWalkthroughStep("buildPortfolio", true)
      setEditing(false)

      await notifier.handlePersonalInfoSetting()

      firebase.getWalkthroughSteps().then(res => setWalkThroughStepValues(res))

    } catch (e) {
      setErrorMessage(
        "There was a problem updating your profile. Please try again later."
      )
    }
  }

  return (
    <>
      {editing && (
        <>
          {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
          <form className="form" onSubmit={handleSubmit}>
            <div className="form-element">
              <label htmlFor="profileQuote"><FormattedMessage
                id="portfolio-personal-info-form-quote"
                defaultMessage="Quote"
              /></label>
              <textarea
                type="text"
                name="profileQuote"
                rows="5"
                cols="35"
                placeholder="Choose a quote that captures the essence of who you are! It can be your own or one that's well-known."
                maxLength={quoteMaxChar}
                ref={firstInput}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
                value={formValues.profileQuote}
              />
            </div>

            <div className="form-element">
              <label htmlFor="city"><FormattedMessage
                id="portfolio-personal-info-form-city"
                defaultMessage="City"
              /></label>
              <input
                type="text"
                name="city"
                maxLength="50"
                onChange={handleChange}
                onKeyPress={handleKeyPress}
                value={formValues.city}
              />
            </div>

            <div className="form-element">
              <label htmlFor="state"><FormattedMessage
                id="portfolio-personal-info-form-state"
                defaultMessage="State"
              /></label>
              <input
                type="text"
                name="state"
                maxLength="15"
                onChange={handleChange}
                onKeyPress={handleKeyPress}
                value={formValues.state}
              />
            </div>

            <div className="portfolio-personal-info">
              <div className="portfolio-personal-about">
                <EyeBrow text={<FormattedMessage
                  id="portfolio-personal-info-form-about-me"
                  defaultMessage="A little about me"
                />} />
                <textarea
                  type="text"
                  maxLength={inputMaxChar}
                  rows="5"
                  cols="55"
                  name="aboutMe"
                  onChange={handleChange}
                  onKeyPress={handleKeyPress}
                  value={formValues.aboutMe}
                />
              </div>

              <div className="portfolio-personal-strengths">
                <EyeBrow text={<FormattedMessage
                  id="portfolio-personal-info-form-my-strengths"
                  defaultMessage="My Strengths"
                />} />
                <textarea
                  type="text"
                  maxLength={inputMaxChar}
                  rows="5"
                  cols="55"
                  name="myStrengths"
                  onChange={handleChange}
                  onKeyPress={handleKeyPress}
                  value={formValues.myStrengths}
                />
              </div>

              <div className="portfolio-personal-weaknesses">
                <EyeBrow text={<FormattedMessage
                  id="portfolio-personal-info-form-my-challenges"
                  defaultMessage="My Challenges"
                />} />
                <textarea
                  type="text"
                  maxLength={inputMaxChar}
                  rows="5"
                  cols="55"
                  name="myWeaknesses"
                  onChange={handleChange}
                  onKeyPress={handleKeyPress}
                  value={formValues.myWeaknesses}
                />
              </div>
            </div>

            <div className="form-element" style={{ textAlign: "right" }}>
              <button
                className="component-button component-button-cancel"
                style={{ marginRight: "1rem" }}
                onClick={e => {
                  e.preventDefault()
                  setEditing(false)
                }}
              >
                <FormattedMessage
                  id="portfolio-personal-info-form-cancel"
                  defaultMessage="Cancel"
                />
              </button>
              <input
                type="submit"
                className="component-button component-button-submit"
                value={formatMessage({ id: "portfolio-personal-info-form-submit", defaultMessage: "Submit" })}
              />
            </div>
          </form>
        </>
      )}

      {!editing && (
        <>
          <div className={profile && profile.profileQuote ? "portfolio-personal-quote blockquote" : "blockquote"}>
            {profile && profile.profileQuote && <p>{profile.profileQuote}</p>}
            <div className="portfolio-personal-public-toggle">
            </div>
          </div>

          <div className="portfolio-personal-header">
            <h3><FormattedMessage
              id="portfolio-personal-info-form-introduction"
              defaultMessage="Introduction"
            /></h3>
            <div
            className="portfolio-personal-location"
            style={{ marginLeft: "auto" }}
          >
            {profile && !!profile.city && !profile.state && (
              <>
                <span>{profile.city}</span>
              </>
            )}
            {profile && !profile.city && !!profile.state && (
              <>
                <span>{profile.state}</span>
              </>
            )}
            {profile && !!profile.city && !!profile.state && (
              <>
                <span>
                  {profile.city}, {profile.state}
                </span>
              </>
            )}
            <button
                className="portfolio-personal-edit"
                onClick={e => {
                  e.preventDefault()
                  setEditing(true)
                }}
              >
                <FontAwesomeIcon icon={faEdit} size="lg" color="#7a7a7a" className="printer-none" />

              </button>
          </div>
          </div>

          <div className="portfolio-personal-info">
            <div className="portfolio-personal-about">
              <EyeBrow text={<FormattedMessage
                id="portfolio-personal-info-form-about-me"
                defaultMessage="A little about me"
              />} />
              {profile && profile.aboutMe ? (
                <p>{profile.aboutMe}</p>
              ) : (
                <blockquote
                  className="component-blockquote component-blockquote-stories"
                  style={{ marginBottom: "1rem" }}
                >
                  <p>
                    <FormattedMessage
                      id="portfolio-personal-info-form-default-about-me"
                      defaultMessage={`What makes you unique? Talk about your biggest wins, your
            most compelling experiences, and what inspires you to do
            what you do. Show, not tell!`}
                    />

                  </p>
                </blockquote>
              )}
            </div>

            <div className="portfolio-personal-strengths">
              <EyeBrow text={<FormattedMessage
                id="portfolio-personal-info-form-my-strengths"
                defaultMessage="My Strengths"
              />} />
              {profile && profile.myStrengths ? (
                <p>{profile.myStrengths}</p>
              ) : (
                <blockquote
                  className="component-blockquote component-blockquote-stories"
                  style={{ marginBottom: "1rem" }}
                >
                  <p>
                    <FormattedMessage
                      id="portfolio-personal-info-form-default-my-strengths"
                      defaultMessage={`What do you do well? Practice selling your strengths without
            sounding conceited. Try to tie it into why this makes you
            well suited for a certain career or job. And tell it in a
            story!`}
                    />

                  </p>
                </blockquote>
              )}
            </div>

            <div className="portfolio-personal-weaknesses">
              <EyeBrow text={<FormattedMessage
                id="portfolio-personal-info-form-my-challenges"
                defaultMessage="My Challenges"
              />} />
              {profile && profile.myWeaknesses ? (
                <p>{profile.myWeaknesses}</p>
              ) : (
                <blockquote
                  className="component-blockquote component-blockquote-stories"
                  style={{ marginBottom: "1rem" }}
                >
                  <p style={{ marginBottom: "1.45rem" }}>
                    <FormattedMessage
                      id="portfolio-personal-info-form-default-challenges-1"
                      defaultMessage={`We all have weaknesses! Talk about a weakness that doesn't
            impair your ability to do a job. What did you learn from it?
            How do you correct for it in your day-to-day?`}
                    />
                  </p>
                  <p>
                    <FormattedMessage
                      id="portfolio-personal-info-form-default-challenges-2"
                      defaultMessage={`Tip: Don't say your weakness is you're a "perfectionist" or
            you "work too hard." We've all heard those before!`}
                    />
                  </p>
                </blockquote>
              )}
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default PersonalInfoForm
