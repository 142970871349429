import { createHeader, createSubHeader, createBulletPoint, createSpacingLine } from "./componentHelpers"

export const renderLeadershipSection = (leadershipExperiences) => {
  return [
    ...createHeader("LEADERSHIP EXPERIENCE"),
    ...leadershipExperiences
      .filter((experience) => experience)
      .flatMap((experience) => {
        const title = experience.title || 'TITLE'
        const affiliation = experience.affiliation || 'AFFILIATION'
        const location = experience.location || 'LOCATION'
        const startDate = experience.startDate || 'STARTDATE'
        const endDate = experience.endDate || 'Present'
        const bulletPoints = experience.bulletPoints || []

        return [
          createSubHeader(
            title,
            affiliation,
            location,
            startDate,
            endDate,
          ),
          bulletPoints.map(b => createBulletPoint(b)),
          createSpacingLine,
        ].flat()
      }),
  ]
}
