import React, { Component } from 'react'
import { connectAutoComplete } from 'react-instantsearch-dom'
import AutoSuggest from 'react-autosuggest'
import { injectIntl, useIntl } from "react-intl";
import { Link, navigate } from 'gatsby'

class AutoComplete extends Component {
  state = {
    value: this.props.currentRefinement,
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    })
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.props.refine(value)
  }

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    })
  }

  getSuggestionValue(hit) {
    return hit.title
  }

  renderSuggestion(hit) {
    // we dont want the highlight functionality right now
    // return <Highlight attribute='name' hit={hit} tagName='mark' />;
    // see gatsby-node.js for path information
    var typeSlug = ""

    if (hit.type === "stories") {
      typeSlug = "/story/"
    }
    if (hit.type === "schools") {
      typeSlug = "/school/"
    }
    if (hit.type === "careers") {
      typeSlug = "/career/"
    }
    if (hit.type === "majors") {
      typeSlug = "/major/"
    }
    if (hit.type === "companies") {
      typeSlug = "/company/"
    }
    if (hit.type === "blogs") {
      typeSlug = "/news/"
    }
    if (hit.type === "basic-pages") {
      typeSlug = "/about/"
    }
    return (
      <Link to={`${typeSlug}${hit.slug}`}>
        {typeSlug === '/story/' && hit.story_job_title
          ? `${hit.title}, ${hit.story_job_title}`
          : hit.title}
      </Link>
    )
  }

  renderSectionTitle(section) {
    const { formatMessage, locale } = this.props.intl
    if (section.hits.length === 0) {
      return ""
    }
    const localizeTranslations = {
      'companies': {
        'en-US': 'companies',
        'es-ES': formatMessage({ id: "algolia-toggle-dropdown-companies", defaultMessage: "Companies" })
      },
      'Posts': {
        'en-US': 'Posts',
        'es-ES': formatMessage({ id: "auto-complete-posts", defaultMessage: "Posts" })
      },
      'stories': {
        'en-US': 'stories',
        'es-ES': formatMessage({ id: "auto-complete-stories", defaultMessage: "stories" })
      },
      'careers': {
        'en-US': 'careers',
        'es-ES': formatMessage({ id: "details-alt-college-careers", defaultMessage: "Careers" })
      },
      'schools': {
        'en-US': 'schools',
        'es-ES': formatMessage({ id: "algolia-toggle-dropdown-schools", defaultMessage: "Schools" })
      },
      'majors': {
        'en-US': 'majors',
        'es-ES': formatMessage({ id: "algolia-toggle-dropdown-majors", defaultMessage: "Majors" })
      }
    };
    return localizeTranslations[section.index][locale] || section.index;
  }

  getSectionSuggestions(section) {
    if (section.index === "basic-pages") {
      section.index = "Pages"
    }
    if (section.index === "blogs") {
      section.index = "Posts"
    }
    return section.hits
  }

  onSuggestionSelected = (event, { suggestion, method }) => {
    const { locale } = this.props.intl

    var typeSlug = ""

    if (suggestion.type === "stories") {
      locale === 'en-US' ? typeSlug = "/story/" : typeSlug = "/es-ES/story/"
    }
    if (suggestion.type === "schools") {
      locale === 'en-US' ? typeSlug = "/school/" : typeSlug = "/es-ES/school/"
    }
    if (suggestion.type === "careers") {
      locale === 'en-US' ? typeSlug = "/career/" : typeSlug = "/es-ES/career/"
    }
    if (suggestion.type === "majors") {
      locale === 'en-US' ? typeSlug = "/major/" : typeSlug = "/es-ES/major/"
    }
    if (suggestion.type === "companies") {
      locale === 'en-US' ? typeSlug = "/company/" : typeSlug = "/es-ES/company/"
    }
    if (suggestion.type === "blogs") {
      locale === 'en-US' ? typeSlug = "/news/" : typeSlug = "/es-ES/news/"
    }
    if (suggestion.type === "basic-pages") {
      locale === 'en-US' ? typeSlug = "/about/" : typeSlug = "/es-ES/about/"
    }

    if (method === "enter" || method === "click") {
      navigate(`${typeSlug}${suggestion.slug}`)
    }

    this.setState({
      value: "",
    })
  }

  render() {
    const { hits } = this.props
    const { value } = this.state
    const { formatMessage, locale } = this.props.intl
    const inputProps = {
      placeholder: formatMessage({ id: "global-search-placeholder", defaultMessage: "Search by name, career, major, school… " }),
      onChange: this.onChange,
      value,
    }

    return (
      <AutoSuggest
        suggestions={hits}
        multiSection={true}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        onSuggestionSelected={this.onSuggestionSelected}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        inputProps={inputProps}
        renderSectionTitle={(section) => this.renderSectionTitle(section, locale)}
        getSectionSuggestions={this.getSectionSuggestions}
      />
    )
  }
}

export default injectIntl(connectAutoComplete(AutoComplete))
