import { createHeader, createBulletPoint, createSpacingLine, createSubHeader } from "./componentHelpers"

export const renderLeadershipSection = (leadershipExperiences) => {
  return [
    ...createHeader("LEADERSHIP EXPERIENCE"),
    ...leadershipExperiences
      .filter((experience) => experience)
      .flatMap((experience) => {
        const affiliation = experience.affiliation || "AFFILIATION"
        const title = experience.title || "TITLE"
        const startDate = experience.startDate || "STARTDATE"
        const endDate = experience.endDate || "Present"
        const location = experience.location || "LOCATION"
        const bulletPoints = experience.bulletPoints || []

        return [
          ...createSubHeader(
            title,
            affiliation,
            location,
            startDate,
            endDate
          ),
          ...bulletPoints.map(b => createBulletPoint(b)),
          createSpacingLine,
        ]
      }),
  ]
}
