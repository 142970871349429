import React, { useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import CardStories from "../cards/cards-stories"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons"
import AlgoliaClientKeys from "./AlgoliaClientKeys"
import algoliasearch from "algoliasearch"
import {
  InstantSearch,
  Configure,
  Pagination,
  Hits,
} from "react-instantsearch-dom"
import "./algolia.css"
import "./algolia-explore.css"
import { FormattedMessage } from "react-intl"
import { useIntl } from "react-intl"

const searchClient = algoliasearch(
  AlgoliaClientKeys.appId,
  AlgoliaClientKeys.apiKey
)

const storyResult = ({ hit }) => {
  const {
    objectID,
    title,
    story_personal_photo,
    story_video_url,
    story_job_title,
    story_company,
    story_quote,
    rating_average,
    slug,
    type,
  } = hit

  return (
    <CardStories
      objectID={objectID}
      img={story_personal_photo}
      title={title}
      slug={slug}
      rating_average={rating_average}
      story_video_url={story_video_url}
      story_job_title={story_job_title}
      story_company={story_company}
      story_quote={story_quote}
      contentType={type}
    />
  )
}

const AlgoliaStoriesWeek = () => {
  const { locale } = useIntl()

  // clear cache on mount and unmount
  useEffect(() => {
    searchClient.clearCache()
    return () => {
      searchClient.clearCache()
    }
  }, [])

  // Query the cosmicjs data to find the stories of the week
  const { allCosmicjsStories } = useStaticQuery(graphql`
    query {
      allCosmicjsStories {
        edges {
          node {
            slug
            locale
            metadata {
              story_display_in_stories_week
            }
          }
        }
      }
    }
  `)
  const storyOfWeekEdges = allCosmicjsStories.edges.filter(
    edge => (edge.node.locale === locale) && (edge.node.metadata.story_display_in_stories_week)
  )

  /* Construct an Algolia filter that will find the stories of the week. Use 
   * this filter instead of something like "story_display_in_stories_week:true" 
   * so that we don't have to manually resync Algolia every time the stories of 
   * the week change */
  const storyOfWeekIds = storyOfWeekEdges.map(
    edge => `objectID:${edge.node.slug}-${locale}`
  )
  const storyOfWeekFilter = storyOfWeekIds.join(" OR ")

  return (
    <InstantSearch
      indexName="stories_explore"
      refresh={true}
      searchClient={searchClient}
    >
      <div className={`stream stream-stories stream-stories-week`}>
        <div
          className="eyebrow component-eyebrow"
          style={{ marginBottom: "1.5rem" }}
        >
          <FormattedMessage
            id="algolia-stories-week-story-of-the-week"
            defaultMessage="Stories of the Week"
          />
        </div>

        <Configure hitsPerPage={3} filters={storyOfWeekFilter} />

        <Hits hitComponent={storyResult} />

        <Pagination
          showFirst={false}
          showLast={false}
          translations={{
            previous: <FontAwesomeIcon icon={faChevronLeft} size="sm" />,
            next: <FontAwesomeIcon icon={faChevronRight} size="sm" />,
          }}
        />
      </div>
    </InstantSearch>
  )
}

export default AlgoliaStoriesWeek
