import {
  Paragraph,
  TextRun,
  HeadingLevel,
  ShadingType,
  Tab,
  TabStopType,
} from "docx"
import { createDurationString } from "../../utils/utils"

export const createHeader = text => {
  const shadingPadding = new Paragraph({
    shading: {
      type: ShadingType.CLEAR,
      fill: "F2F2F2",
    },
    children: [new TextRun({ text: "\u00A0", size: 10 })],
  })
  return [
    shadingPadding,
    new Paragraph({
      heading: HeadingLevel.HEADING_1,
      shading: {
        type: ShadingType.CLEAR,
        fill: "F2F2F2",
      },
      children: [
        new TextRun({
          text: "  " + text,
          size: 28,
          color: "000000",
        }),
      ],
    }),
    shadingPadding,
  ]
}

export const createSubHeader = (
  role,
  organization,
  location,
  startDate,
  endDate,
) => {
  const duration = createDurationString(startDate, endDate)

  return [
    createAlignedTexts(role, duration),
    new Paragraph({
      children: [
        new TextRun({
          text: organization,
          size: 22,
        }),
        new TextRun({
          text: " | ",
          size: 22,
        }),
        new TextRun({
          text: location,
          size: 22,
        }),
      ],
    }),
  ]
}

export const createAwardSubHeader = (
  role,
  endDate
) => {
  return [
    createAlignedTexts(role, endDate),
  ]
}

export const createBulletPoint = text => {
  if (!text.trim()) return null

  return new Paragraph({
    text: text,
    bullet: {
      level: 0,
    },
    size: 20,
  })
}

export const createSpacingLine = new Paragraph({
  children: [
    new TextRun({
      text: "",
      size: 10,
    }),
  ],
})

export const createAlignedTexts = (leftText, rightText) => {
  return new Paragraph({
    tabStops: [
      {
        position: 10450,
        type: TabStopType.RIGHT,
      },
    ],
    children: [
      new TextRun({
        text: leftText,
        bold: true,
        size: 22,
      }),
      new TextRun({
        children: [
          new Tab(),
          rightText,
        ],
        size: 22,
      }),
    ],
  })
}

export const createSkillsInterestsBullet = (title, text) => {
  if (!text.trim()) return null

  return new Paragraph({
    children: [
      new TextRun({
        text: title,
        bullet: {
          level: 0,
        },
        size: 20,
        bold: true,
      }),

      new TextRun({
        text: text,
        size: 20,
      })
    ]

  })
}
