import React from "react"
import ExperienceDatesInput from "./experience-dates-input"
import ExperienceTextInput from "./experience-text-input"
import ExperienceBulletsInput from "./experience-bullets-input"
import { FormattedMessage, useIntl } from "react-intl"

const ExperienceInputVolunteer = ({ type }) => {
  const { formatMessage } = useIntl()
  return (
    <>
      <ExperienceTextInput
        placeholder={formatMessage({ id: 'resume-experience-volunteer-form-title-placeholder', defaultMessage: "Ex. Big Sister" })}
        label={<FormattedMessage
          id="resume-experience-form-title"
          defaultMessage="Title"
        />}
        dataKey="title"
        required={true}
      />
      <ExperienceTextInput
        placeholder={formatMessage({ id: 'resume-experience-volunteer-form-employer-placeholder', defaultMessage: "Ex. Big Brothers and Big Sisters" })}
        label={<FormattedMessage
          id="resume-experience-form-employer"
          defaultMessage="Employer"
        />}
        dataKey="employer"
        required={true}
      />
      <ExperienceDatesInput
        label={<FormattedMessage
          id="resume-experience-form-time-period"
          defaultMessage="Time Period"
        />}
        dataKey="timePeriod"
        required={true}
        isSingle={false}
      />
      <ExperienceTextInput
        placeholder={formatMessage({ id: 'resume-experience-form-location-placeholder', defaultMessage: "Ex. Los Angeles" })}
        label={<FormattedMessage
          id="resume-experience-form-location"
          defaultMessage="Location"
        />}
        dataKey="location"
        required={true}
      />
      <ExperienceTextInput
        placeholder={formatMessage({ id: 'resume-experience-volunteer-form-description-placeholder', defaultMessage: "Ex. Mentored Sadie from 9th-11th grade." })}
        label={<FormattedMessage
          id="resume-experience-form-description"
          defaultMessage="Description"
        />}
        dataKey="description"
        inputType="textarea"
      />
      <ExperienceBulletsInput
        label={<FormattedMessage
          id="resume-experience-form-highlights"
          defaultMessage="Highlights"
        />}
        dataKey="highlights"
        placeholders={[
          formatMessage({ id: 'resume-experience-volunteer-form-highlights-placeholder-1', defaultMessage: "Ex. Once a month, took Sadie out to lunch." }),
          formatMessage({ id: 'resume-experience-volunteer-form-highlights-placeholder-2', defaultMessage: "Ex. Helped Sadie get her first job." })
        ]}
      />
    </>
  )
}

export default ExperienceInputVolunteer
