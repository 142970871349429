import React from "react"
import CardStories from "../cards/cards-stories"
import EyeBrow from "../eyebrow/eyebrow"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight, faChevronLeft } from "@fortawesome/free-solid-svg-icons"
import AlgoliaClientKeys from "./AlgoliaClientKeys"
import algoliasearch from 'algoliasearch';
import { InstantSearch, Configure, Pagination, Hits } from 'react-instantsearch-dom';
import "./algolia.css"
import "./algolia-explore.css"
import { FormattedMessage, useIntl } from "react-intl"

const searchClient = algoliasearch(AlgoliaClientKeys.appId, AlgoliaClientKeys.apiKey);

const storyResult = ({ hit }) => {
  const {
    objectID,
    title,
    story_personal_photo,
    story_job_title,
    story_video_url,
    story_company,
    story_quote,
    slug,
    type
  } = hit;

  return (
    <CardStories
      objectID={objectID}
      img={story_personal_photo}
      title={title}
      slug={slug}
      story_video_url={story_video_url}
      story_job_title={story_job_title}
      story_company={story_company}
      story_quote={story_quote}
      contentType={type}
    />
  );
}

const AlgoliaStoriesSaved = props => {
  const { classContext, filterList } = props;
  const { locale, formatMessage } = useIntl()

  if (filterList === null) {
    return (
      <div className={`stream stream-${classContext} stream-${classContext}-saved`}>
        <p><FormattedMessage
          id="algolia-saved-js-message"
          defaultMessage="You have no saved items. Bookmark a page to save content that resonates with you."
        /></p>
      </div>
    )

  } else {

    return (

      <InstantSearch
        indexName="stories_explore"
        searchClient={searchClient}
      >

        <div className={`stream stream-${classContext} stream-${classContext}-saved`}>
          <EyeBrow text={formatMessage({ id: "algolia-toggle-saved-dropdown-people", defaultMessage: "People" })} style={{ marginBottom: '2rem' }} />

          <Configure
            hitsPerPage={8}
            distinct
            filters={`${filterList} AND locale:${locale}`}
          />

          {/* <Hits hitComponent={storyResult} /> */}
          <Pagination
            showFirst={false}
            showLast={false}
            translations={{
              previous: <FontAwesomeIcon icon={faChevronLeft} size="sm" />,
              next: <FontAwesomeIcon icon={faChevronRight} size="sm" />
            }}
          />
        </div>

      </InstantSearch>
    )
  }

}

export default AlgoliaStoriesSaved
