const languages = process.env.GATSBY_ENABLE_LOCALIZATION === "true" ? ["en-US", "es-ES"] : ["en-US"]
// const languages = ["en-US", "es-ES"]

const defaultLanguage = "en-US"
const languageNames = {
  "en-US": "English",
  "es-ES": "Spanish",
}
const language = {
  english: "en-US",
  spanish: "es-ES",
}

exports.defaultLanguage = defaultLanguage
exports.languages = languages
exports.languageNames = languageNames
exports.language = language
