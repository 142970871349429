import {
    createHeader,
    createLifeSubHeader,
    createBulletPoint,
    createSpacingLine,
  } from "./componentHelpers"
  
  export const renderLifeSection = (life) => {
    return [
      createHeader("LIFE EXPERIENCES"),
      ...life
        .filter(experience => experience)
        .flatMap(experience => {
          const milestone = experience.milestone || "MILESTONE"
          const time = experience.endDate || "TIME PERIOD"
          let bulletPoints = experience.bulletPoints || []

          return [
            createLifeSubHeader(milestone, time),
            ...bulletPoints.map(b => createBulletPoint(b)),
            createSpacingLine,
          ]
        }),
    ]
  }
