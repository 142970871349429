/**
 * Notification that a faculty member receives when they visit the educator dashboard.
 * Cycles through a set of motivational messages to encourage faculty to engage with the platform and does not repeat messages that have already been delivered.
 *
 * @param {string} studentName - The name of the student who completed the assessment
 * @returns {Notification} - The notification object
 */
export const makeEducatorMotivationalNotification = (profile, deliveredNotificationSet) => {
  const { userId } = profile

  let motivationalOptions = [
    {
      templateId: `EM1`,
      heading: "Welcome to Wingspans!",
      headingEs: "",
      message: "We're on a mission to help your students find a career that's meaningful to them.",
      messageEs: "",
      expireAfterDays: 300,
      clearIds: [],
    },
    {
      templateId: `EM2`,
      heading: "Student Engagement:",
      headingEs: "",
      message: "Have your students do the Daily Quest to increase their exposure to the world of work.",
      messageEs: "",
      expireAfterDays: 300,
      clearIds: [],
    },
    {
      templateId: `EM3`,
      heading: "Student Engagement:",
      headingEs: "",
      message: "Ask your students to bookmark 3 careers they want to learn more about.",
      messageEs: "",
      expireAfterDays: 300,
      clearIds: [],
    },
    {
      templateId: `EM4`,
      heading: "Student Engagement:",
      headingEs: "",
      message: "Ask your students to bookmark 3 careers that interest them.",
      messageEs: "",
      expireAfterDays: 300,
      clearIds: [],
    },
    {
      templateId: `EM5`,
      heading: "Student Engagement:",
      headingEs: "",
      message: "Ask your students where they see themselves in 10 years?",
      messageEs: "",
      expireAfterDays: 300,
      clearIds: [],
    },
    {
      templateId: `EM6`,
      heading: "Student Engagement:",
      headingEs: "",
      message: "Ask your students to add 3 SMART goals in THIS WEEK.",
      messageEs: "",
      expireAfterDays: 300,
      clearIds: [],
    },
    {
      templateId: `EM7`,
      heading: "Student Engagement:",
      headingEs: "",
      message: "Ask your students to bookmark 3 people who inspire them.",
      messageEs: "",
      expireAfterDays: 300,
      clearIds: [],
    },
  ]

  const queuedNotification = motivationalOptions.find((option) => !deliveredNotificationSet.has(option.templateId))

  if (!queuedNotification) {
    // No more motivational notifications to deliver
    return null
  }

  const notification = {
    templateId: queuedNotification.templateId,
    notificationId: null,
    isRead: false,
    isHidden: false,
    publicationDate: null,
    expirationDate: null,
    expireAfterDays: 300,
    type: "motivational",
    point: 0,
    heading: queuedNotification.heading,
    headingEs: queuedNotification.headingEs,
    message: queuedNotification.message,
    messageEs: queuedNotification.messageEs,
    pageLink: null,
    delaySeconds: 0,
    delayMinutes: 0,
    delayHours: 0,
    delayDays: 0,
    isPermanent: true,
    clearIds: queuedNotification.clearIds,
    senderId: userId,
    sendToSelf: true,
    sendToEducators: false,
    sendToAdmins: false,
  }
  return notification
}

/**
 * Notification that a faculty member receives when they create a new student group
 *
 * @param {string} groupName - The name of the new student group
 * @param {number} numStudents - The number of students in the new group
 * @returns {Notification} - The notification object
 */
export const makeEducatorNewStudentGroupNotification = (profile, groupName, numStudents) => {
  const { userId } = profile

  const notification = {
    templateId: "EG1",
    notificationId: null,
    isRead: false,
    isHidden: false,
    publicationDate: null,
    expirationDate: null,
    expireAfterDays: 300,
    type: "motivational",
    point: 0,
    heading: "Groups:",
    headingEs: "",
    message: `You created ${groupName} group, with ${numStudents} students.`,
    messageEs: "",
    pageLink: null,
    delaySeconds: 0,
    delayMinutes: 0,
    delayHours: 0,
    delayDays: 0,
    isPermanent: false,
    clearIds: [],
    senderId: userId,
    sendToSelf: true,
    sendToEducators: false,
    sendToAdmins: false,
  }
  return notification
}

/**
 * Notification that a faculty member receives when they create a new faculty group
 *
 * @param {string} groupName - The name of the new faculty group
 * @param {number} numFaculty - The number of faculty in the new group
 * @returns {Notification} - The notification object
 */
export const makeEducatorNewFacultyGroupNotification = (profile, groupName, numFaculty) => {
  const { userId } = profile

  const notification = {
    templateId: "EG2",
    notificationId: null,
    isRead: false,
    isHidden: false,
    publicationDate: null,
    expirationDate: null,
    expireAfterDays: 300,
    type: "motivational",
    point: 0,
    heading: "Groups:",
    headingEs: "",
    message: `You created ${groupName} group, with ${numFaculty} faculty members.`,
    messageEs: "",
    pageLink: null,
    delaySeconds: 0,
    delayMinutes: 0,
    delayHours: 0,
    delayDays: 0,
    isPermanent: false,
    clearIds: [],
    senderId: userId,
    sendToSelf: true,
    sendToEducators: false,
    sendToAdmins: false,
  }
  return notification
}

/**
 * Notification that a faculty member receives when a student does one of the following:
 * - Completes the personality assessment
 * - Completes a career plan
 * - Completes the resume
 * - Completes the Daily Quest
 * - Sets a goal
 * - Bookmarks a career
 * - Bookmarks a degree
 */
export const makeEducatorStudentEventNotification = (profile, eventType, param1) => {
  const { firstName, lastName, userId } = profile
  const studentName = `${firstName || ""} ${lastName || ""}`

  let notification = {
    templateId: null,
    notificationId: null,
    isRead: false,
    isHidden: false,
    publicationDate: null,
    expirationDate: null,
    expireAfterDays: 300,
    type: "activity",
    point: 0,
    heading: "Activity:",
    headingEs: "",
    message: "",
    messageEs: "",
    pageLink: null,
    delaySeconds: 0,
    delayMinutes: 0,
    delayHours: 0,
    delayDays: 0,
    isPermanent: false,
    clearIds: [],
    senderId: userId,
    sendToSelf: false,
    sendToEducators: true,
    sendToAdmins: false,
  }

  switch (eventType) {
    case "PERSONALITY_ASSESSMENT":
      notification.templateId = `EA1-${userId}`
      notification.message = `${studentName || `A student`} completed the personality assessment.`
      notification.isPermanent = true
      break
    case "CAREER_PLAN":
      notification.templateId = "EA2"
      notification.message = `${studentName || `A student`} completed a career plan.`
      break
    case "RESUME":
      notification.templateId = `EA3-${userId}`
      notification.message = `${studentName || `A student`} added experience to their resume.`
      notification.isPermanent = true
      break
    case "QUEST":
      notification.templateId = "EA4"
      notification.message = `${studentName || `A student`} completed the daily quest.`
      break
    case "GOAL":
      notification.templateId = `EA5-${userId}`
      notification.message = `${studentName || `A student`} set a goal.`
      notification.isPermanent = true
      break
    case "BOOKMARK_CAREER":
      notification.templateId = `EA6-${userId}-${param1}`
      notification.message = `${studentName || `A student`} bookmarked ${param1 || `a`} career.`
      notification.isPermanent = true
      break
    case "BOOKMARK_DEGREE":
      notification.templateId = `EA7-${userId}-${param1}`
      notification.message = `${studentName || `A student`} bookmarked ${param1 || `a`} degree.`
      notification.isPermanent = true
      break
    default:
      console.error("Invalid student event activity")
      return null
  }

  return notification
}

// admin notifications, delivered to all admins of a school

/**
 * Notification that a faculty member receives when they create a new student group
 *
 * @param {string} groupName - The name of the new student group
 * @param {number} numStudents - The number of students in the new group
 * @returns {Notification} - The notification object
 */
export const makeAdminGroupNotification = (profile, groupName) => {
  const { userId, firstName, lastName } = profile
  const facultyName = `${firstName || ""} ${lastName || ""}`

  const notification = {
    templateId: "EG3",
    notificationId: null,
    isRead: false,
    isHidden: false,
    publicationDate: null,
    expirationDate: null,
    expireAfterDays: 300,
    type: "activity",
    point: 0,
    heading: "Groups:",
    headingEs: "",
    message: `${facultyName.trim() || 'A faculty member'} created a group: ${groupName}`, 
    messageEs: "",
    pageLink: null,
    delaySeconds: 0,
    delayMinutes: 0,
    delayHours: 0,
    delayDays: 0,
    isPermanent: false,
    clearIds: [],
    senderId: userId,
    sendToSelf: false,
    sendToEducators: false,
    sendToAdmins: true,
  }
  return notification
}