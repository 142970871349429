import allowedTypes, { allowedListTypes, allowedTextDatePeriodTypes, allowedTextDateTypes, allowedAlgoliaListTypes } from "./AllowedTypes"
import MilestoneListDates from "./MilestoneListDates"
import MilestoneTextDate from "./MilestoneTextDate"
import MilestoneTextDates from "./MilestoneTextDates"
import MilestoneAlgoliaListDates from "./MilestoneAlgoliaListDates"

/**
 * A factory to create existing or new milestones for a career plan.
 *
 * @param typeNew
 *   Set this value for a new milestone, it maps to milestone type.
 * @param milestoneData
 *   Existing milestone data.
 *
 * @return {MilestoneListDates|MilestoneTextDate|null}
 */
export default function MilestoneFactory(typeNew = null, milestoneData = null) {
  const type = typeNew ? typeNew : milestoneData?.type;
  if (!allowedTypes.includes(type)) {
    return null;
  }
  if (allowedListTypes.includes(type)) {
    const milestone = new MilestoneListDates(milestoneData);
    if (!milestone.type) {
      milestone.type = type;
    }
    return milestone;
  }
  if (allowedAlgoliaListTypes.includes(type)) {
    const milestone = new MilestoneAlgoliaListDates(milestoneData);
    if (!milestone.type) {
      milestone.type = type;
    }
    return milestone;
  }
  if (allowedTextDateTypes.includes(type)) {
    const milestone = new MilestoneTextDate(milestoneData);
    if (!milestone.type) {
      milestone.type = type;
    }
    return milestone;
  }
  if (allowedTextDatePeriodTypes.includes(type)) {
    const milestone = new MilestoneTextDates(milestoneData);
    if (!milestone.type) {
      milestone.type = type;
    }
    return milestone;
  }
  return null;
}
