/**
 * Provides a class the a user entities address.
 */
class UserEntityAddress {

  /**
   * Getter for the city.
   *
   * @return {null|string}
   */
  get city() {
    return this._city
  }

  /**
   * Setter for the city.
   *
   * @param {null|string} value
   */
  set city(value) {
    this._city = value
  }

  /**
   * Getter for street.
   *
   * @return {null|string}
   */
  get street() {
    return this._street
  }

  /**
   * Setter for the street.
   *
   * @param {null|string} value
   */
  set street(value) {
    this._street = value
  }

  /**
   * Getter for the postal code.
   *
   * @return {null|string}
   */
  get postalCode() {
    return this._postalCode
  }

  /**
   * Setter for postal code.
   *
   * @param {null|string} value
   */
  set postalCode(value) {
    this._postalCode = value
  }

  /**
   * Getter for state.
   *
   * @return {null|string}
   */
  get state() {
    return this._state
  }

  /**
   * Setter for the state.
   *
   * @param {null|string} value
   */
  set state(value) {
    this._state = value
  }

  /**
   * Setter for the country.
   *
   * @return {null|string}
   */
  get country() {
    return this._country
  }

  /**
   * Setter for the country.
   *
   * @param {null|string} value
   */
  set country(value) {
    this._country = value
  }

  /**
   * Gets the render display of the address.
   *
   * @return {string|null}
   */
  getDisplay() {
    const values = [
      this.street,
      this.city,
      this.postalCode,
      this.state,
      this.country
    ].filter((value) => Boolean(value))

    return values.length > 0 ? values.join(", ") : null
  }

  /**
   * Address constructor.
   *
   * @param {null|string} street
   *   The street.
   * @param {null|string} postalCode
   *   The postal code.
   * @param {null|string} state
   *   The state.
   * @param {null|string} country
   *   The country.
   * @param {null|string} city
   *   The city.
   */
  constructor(
    street = null,
    postalCode = null,
    state = null,
    country = null,
    city = null
  ) {
    this._street = street
    this._postalCode = postalCode
    this._state = state
    this._country = country
    this._city = city
  }

  /**
   * Gets the data to be stored in firestore.
   *
   * @return {{country: (null|string), city: (null|string), street: (null|string), postalCode: (null|string), state: (null|string)}|null}
   */
  getData() {
    if (
      !this.street
      && !this.postalCode
      && !this.state
      && !this.country
      && !this.city
    ) {
      return null
    }

    return {
      street: this.street,
      postalCode: this.postalCode,
      state: this.state,
      country: this.country,
      city: this.city,
    }
  }

}

/**
 * Provides a user contact class.
 */
class UserEntityContact {

  /**
   * Getter for the name of the contact.
   *
   * @return {null|string}
   */
  get name() {
    return this._name
  }

  /**
   * Setter for the name.
   *
   * @param {null|string} value
   */
  set name(value) {
    this._name = value
  }

  /**
   * Getter for the phone address.
   *
   * @return {null|string}
   */
  get phone() {
    return this._phone
  }

  /**
   * Setter for the phone.
   *
   * @param {null|string} value
   */
  set phone(value) {
    this._phone = value
  }

  /**
   * Getter for the email address.
   *
   * @return {null|string}
   */
  get email() {
    return this._email
  }

  /**
   * Setter for the email.
   *
   * @param {null|string} value
   */
  set email(value) {
    this._email = value
  }

  /**
   * Gets the contact information render.
   *
   * @return {string|null}
   */
  getDisplay() {
    const displayData = []
    if (this.name) {
      displayData.push(this.name)
    }
    if (this.email) {
      displayData.push(this.email)
    }
    if (this.phone) {
      displayData.push(this.phone)
    }
    return displayData.length > 0 ? displayData.join(", ") : null
  }

  /**
   * Contact constructor.
   *
   * @param {null|string} name
   *   Contact name.
   * @param {null|string} phone
   *   Contact Phone.
   * @param {null|string} email
   *   Contact email.
   */
  constructor(name = null, phone = null, email = null) {
    this._name = name
    this._phone = phone
    this._email = email
  }

  /**
   * Gets the contact data for firestore.
   *
   * @return {{phone: (null|string), name: (null|string), email: (null|string)}|null}
   */
  getData() {
    if (!this.name && !this.phone && !this.email) {
      return null
    }
    return {
      name: this.name,
      phone: this.phone,
      email: this.email
    }
  }

}

/**
 * Provides a class for user entities.
 */
export default class UserEntity {

  /**
   * Getter for the active promo code for that entity.
   *
   * @return {string|null}
   */
  get activePromo() {
    return this._activePromo
  }

  /**
   * Setter for active promo code.
   *
   * @param {string|null} value
   */
  set activePromo(value) {
    this._activePromo = value
  }

  /**
   * Getter for the document ID.
   *
   * @return {string|null}
   */
  get docId() {
    return this._docId
  }

  /**
   * Setter for the document ID.
   *
   * @param {string|null} value
   */
  set docId(value) {
    this._docId = value
  }

  /**
   * Getter for the entity label.
   *
   * @return {string|null}
   */
  get label() {
    return this._label
  }

  /**
   * Setter for the entity label.
   *
   * @param {string|null} value
   */
  set label(value) {
    this._label = value
  }

  /**
   * Getter for the entity type.
   *
   * @return {string|null}
   */
  get type() {
    return this._type
  }

  /**
   * Setter for the entity type.
   *
   * @param {string|null} value
   */
  set type(value) {
    this._type = value
  }

  /**
   * Getter for the entity ID (code).
   *
   * @return {string|null}
   */
  get id() {
    return this._id
  }

  /**
   * Setter for the ID (code).
   *
   * @param {string|null} value
   */
  set id(value) {
    this._id = value
  }

  /**
   * Getter for the address.
   *
   * @return {UserEntityAddress|null}
   */
  get address() {
    return this._address
  }

  /**
   * Setter for the address info.
   *
   * @param {UserEntityAddress|null} value
   */
  set address(value) {
    this._address = value
  }

  /**
   * Getter for the contact info.
   *
   * @return {UserEntityContact|null}
   */
  get contact() {
    return this._contact
  }

  /**
   * Setter for the contact info.
   *
   * @param {UserEntityContact|null} value
   */
  set contact(value) {
    this._contact = value
  }

  /**
   * Constructor for the user entity.
   *
   * @param {string|null} label
   *   The entity label/name.
   * @param {string|null} type
   *   The entity type (school/organization)
   * @param {string|null} id
   *   The entity ID, the code.
   * @param {string|null} docId
   *   The firestore document ID.
   * @param {UserEntityAddress|null} address
   *   The address info.
   * @param {UserEntityContact|null} contact
   *   The contact info.
   * @param {string|null} activePromo
   *   The active promo code for the user entity.
   */
  constructor(
    label,
    type,
    id,
    docId,
    address,
    contact,
    activePromo
  ) {
    this._label = label
    this._type = type
    this._id = id
    this._address = address
    this._contact = contact
    this._docId = docId
    this._activePromo = activePromo
  }

  /**
   * Gets the firestore data of the entity.
   *
   * @return {{address: ({country: (null|string), city: (null|string), street: (null|string), postalCode: (null|string), state: (null|string)}|null), contact: ({phone: (null|string), name: (null|string), email: (null|string)}|null), activePromo: (string|null), label: (string|null), id: (string|null), type: (string|null)}}
   */
  getData() {
    return {
      label: this.label,
      type: this.type,
      id: this.id,
      address: this.address ? this.address.getData() : null,
      contact: this.contact ? this.contact.getData() : null,
      activePromo: this.activePromo
    }
  }

}

/**
 * Builds an entity from a firestore document.
 *
 * @param document
 *
 * @return {UserEntity}
 */
export const userEntityFactoryFromDoc = (document) => {
  const documentData = document.data()
  const address = new UserEntityAddress(
    documentData.address?.street ?? null,
    documentData.address?.postalCode ?? null,
    documentData.address?.state ?? null,
    documentData.address?.country ?? null,
    documentData.address?.city ?? null
  )
  const contact = new UserEntityContact(
    documentData.contact?.name ?? null,
    documentData.contact?.phone ?? null,
    documentData.contact?.email ?? null
  )
  return new UserEntity(
    documentData.label,
    documentData.type,
    documentData.id,
    document.id,
    address,
    contact,
    documentData.activePromo
  )
}

/**
 * Builds a new user entity from the type sent.
 *
 * @param {string|null} type
 *   The type of entity (school/organization).
 *
 * @return {UserEntity}
 */
export const userEntityFactoryFromType = (type) => {
  return new UserEntity(
    null,
    type,
    null,
    null,
    new UserEntityAddress(),
    new UserEntityContact(),
    null
  )
}
