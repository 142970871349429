import React, { useEffect, useState, useContext } from "react"
import { graphql, useStaticQuery } from "gatsby"
import PortfolioPublicToggle from "../../components/dashboard/portfolio-public-toggle"

import {
  getTopTenMatches,
  orderPersonalityStrength,
} from "../../components/assessment/utils/utils.js"

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Legend,
  Tooltip,
} from "chart.js"
import { Bar } from "react-chartjs-2"

import useWindowDimensions from "../../components/hooks/useWindowDimensions.js"
import "./assessment-portfolio.css"
import { FormattedMessage, useIntl } from "react-intl"
import { useCareers } from "./useCareers.js"
import { FirebaseContext } from "../../components/Firebase"

/**
 * Component to load all career plans for a user.
 */
const AssessmentPortfolio = props => {
  const { profile, hasPublicToggle } = props

  const [hasAssessmentData, setHasAssessmentData] = useState(false)

  const [chartOptions, setChartOptions] = useState(null)
  const [chartData, setChartData] = useState(null)

  const [topCareerMatches, setTopCareerMatches] = useState([])

  const { width } = useWindowDimensions()

  const { formatMessage, locale } = useIntl()

  const allCosmicjsCareers = useCareers(locale)

  const loadChart = (orderedProfile, aspectRatio) => {
    ChartJS.register(CategoryScale, LinearScale, BarElement, Legend, Tooltip)

    setChartOptions({
      responsive: true,
      maintainAspectRatio: true,
      aspectRatio: aspectRatio,
      scales: {
        x: {
          ticks: {
            font: {
              size: 18,
              family: "proxima-nova, sans-serif",
              // weight: 600
            },
            color: "#363636",
          },
          grid: {
            display: false,
          },
        },
        y: {
          ticks: {
            stepSize: 1,
            font: {
              size: 18,
              family: "proxima-nova, sans-serif",
              // weight: 600
            },
            color: "#363636",
          },
          grid: {
            display: false,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            // footer: () => 'Likes to study and solve math or science problems',
          },
        },
      },
    })

    const labels = [
      formatMessage({
        id: "career-chart-realistic",
        defaultMessage: "Realistic",
      }),
      formatMessage({
        id: "career-chart-investigative",
        defaultMessage: "Investigative",
      }),
      formatMessage({
        id: "career-chart-artistic",
        defaultMessage: "Artistic",
      }),
      formatMessage({ id: "career-chart-social", defaultMessage: "Social" }),
      formatMessage({
        id: "career-chart-enterprising",
        defaultMessage: "Enterprising",
      }),
      formatMessage({
        id: "career-chart-conventional",
        defaultMessage: "Conventional",
      }),
    ]

    const data = {
      labels,
      datasets: [
        {
          data: orderedProfile.values,
          backgroundColor: bar => {
            let scores = data.datasets[0].data
            let topScores = orderedProfile.values
              .sort((a, b) => b - a)
              .slice(0, 3)
            return topScores.includes(bar.parsed.y) ? "#00B8EFFF" : "#65D2C1"
          },
        },
      ],
    }
    setChartData(data)
  }

  useEffect(() => {
    // load chart when profile loads or when width changes to reload chart with correct aspect ratio
    // must accomodate different structure if pulling from public vs directly from profile
    if (profile?.resultProfile || profile?.assessmentRecord?.resultProfile) {
      setHasAssessmentData(true)
      const resultProfile =
        profile?.resultProfile || profile?.assessmentRecord?.resultProfile
      const careerMatches =
        profile?.careerMatches || profile?.assessmentRecord?.careerMatches

      const topTenCareers = getTopTenMatches(careerMatches, allCosmicjsCareers)

      const orderedProfile = orderPersonalityStrength(resultProfile)

      loadChart(orderedProfile, width <= 500 ? 1 : 2)

      setTopCareerMatches(topTenCareers)
    }
  }, [profile, width])

  return (
    <>
      {hasAssessmentData && (
        <section className="portfolio-personal-assessment">
          <div className="portfolio-personal-assessment-heading">
            <h2>
              <FormattedMessage
                id="assessment-porfolio-assessment"
                defaultMessage="Assessment"
              />
              <a id="CareerPlans" name="CareerPlans"></a>
            </h2>

            {/* {hasPublicToggle && <PortfolioPublicToggle section="assessment" />} */}
          </div>

          <div className="portfolio-personal-assessment-row">
            <div className="portfolio-personal-assessment-row-chart printer-no-border">
              {chartOptions && chartData && (
                <Bar options={chartOptions} data={chartData} />
              )}
            </div>
            <div className="portfolio-personal-assessment-row-matches printer-no-border">
              <h4 className="eyebrow component-eyebrow">
                <FormattedMessage
                  id="assessment-porfolio-career-matches"
                  defaultMessage="Career Matches"
                />
              </h4>
              <ol>
                {topCareerMatches.map((orderedCareer, index) => {
                  // find and merge career with career ID and correlation
                  const foundCareer = allCosmicjsCareers.find(
                    allCareer => allCareer.slug === orderedCareer.slug
                  )
                  if (foundCareer) {
                    return <li key={index}>{foundCareer.title}</li>
                  } else {
                    return null
                  }
                })}
              </ol>
            </div>
          </div>
        </section>
      )}
    </>
  )
}

export default AssessmentPortfolio
