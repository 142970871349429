import React, { useEffect, useState, useContext } from "react"
import "./flyout.css"
import { FirebaseContext } from "../Firebase"

const BackDrop = props => {
  return <div className="backdrop" onClick={props.closeDrawer}></div>
}

const SlideDrawer = ({ show, onClose, isFullScreen, ...restProps }) => {
  const { children } = restProps
  let drawerClasses = show ? "slide-drawer open" : "slide-drawer"

  return (
    <div className={`${drawerClasses} ${isFullScreen ? "fullscreen" : ""}`}>
      {children}
    </div>
  )
}

const Flyout = ({ show, setShow, isFullScreen, ...restProps }) => {
  const {
    notifier,
  } = useContext(FirebaseContext)
  const { children } = restProps
  const [drawerOpen, setDrawerOpen] = useState(show)

  useEffect(() => {
    setDrawerOpen(show)
  }, [show])

  function handleBackdropClick() {
    setDrawerOpen(false)
    setShow(false)
  }

  function handleClose() {
    setDrawerOpen(false)
    setShow(false)
  }

  useEffect(() => {
    notifier.setIsDisabled(drawerOpen)
  }, [drawerOpen])

  return (
    <>
      <SlideDrawer
        show={drawerOpen}
        onClose={handleClose}
        isFullScreen={isFullScreen}
      >
        {children}
      </SlideDrawer>

      {drawerOpen && <BackDrop closeDrawer={handleBackdropClick} />}
    </>
  )
}

export default Flyout
