import React, { useState } from "react"
import Bookmark from "../bookmark/bookmark"
import Link from "../../link"
import { calcMatchStrength } from "../assessment/utils/utils"
import { FormattedMessage, useIntl } from "react-intl"
import ThumbsFeedback from "../assessment/thumbs-feedback/ThumbsFeedback.js"
import { demandTranslations } from "../../utils"
import { useCareers } from "../assessment/useCareers.js"

const CardCareers = props => {
  const {
    img,
    title,
    slug,
    salaryEntry,
    salaryExperienced,
    correlation,
    demand,
    category,
    objectID,
    contentType,
    hideBookmarks,
    thumbsFeedback,
    setDislikedCareerSlugs,
  } = props
  const { locale } = useIntl()
  const allCosmicjsCareers = useCareers(locale)
  const matchedCareer = allCosmicjsCareers.find(career => career.slug === slug)

  return (
    <div
      className="component-card component-card-careers"
      style={{ alignItems: "flex-start" }}
      data-object={objectID}
    >
      <div
        className="component-card-image component-card-image-careers"
        style={{ position: "relative" }}
      >
        <Link to={`/career/${slug}`} state={{backURL: ''}}>
          {correlation && (
            <span className={`match-label-${calcMatchStrength(correlation)}`}>
              <label>
                {locale === "es-ES" ?
                  calcMatchStrength(correlation) === "Excellent" ? "Excelente" :
                    calcMatchStrength(correlation) === "Good" ? "Bueno" :
                      calcMatchStrength(correlation) === "Fair" ? "Regular" :
                        calcMatchStrength(correlation) :
                  calcMatchStrength(correlation)}
              </label>
            </span>
          )}
          <img
            src={`${img}?q=&auto=compress,&w=712&h=446&fit=crop&crop=faces`}
            alt=""
          />
        </Link>
      </div>
      <div className="component-card-meta component-card-meta-careers">
        <div className="component-card-meta-header component-card-meta-header-careers">
          {!hideBookmarks && (
            <>
              <Bookmark
                contentTitle={title}
                classContext={"careers"}
                contentId={objectID}
                contentSlug={slug}
                contentType={contentType}
                jobCategories={matchedCareer?.career_job_categories}
                careerPathwaysFilter={matchedCareer?.career_pathways_filter}
              />
            </>
          )}
          {thumbsFeedback && (
            <ThumbsFeedback
              contentId={objectID}
              contentSlug={slug}
              setDislikedCareerSlugs={setDislikedCareerSlugs}
            />
          )}

          <Link
            to={`/career/${slug}`}
            className="component-card-title component-card-title-careers"
          >
            {title}
          </Link>
        </div>
        {salaryEntry && salaryExperienced && (
          <div className="component-card-salary component-card-salary-careers">
            <strong><FormattedMessage id="cards-careers-salary-range" defaultMessage="Salary Range" /> : </strong>${salaryEntry}
            <span> - </span>${salaryExperienced}
          </div>
        )}
        {demand && (
          <div className="component-card-demand component-card-demand-careers">
            <strong><FormattedMessage id="cards-careers-demand" defaultMessage="Demand" /> : </strong>
            {demandTranslations[demand][locale] || demand}
          </div>
        )}
        {category && (
          <div className="component-card-category component-card-category-careers">
            <strong><FormattedMessage id="cards-careers-category" defaultMessage="Category" />: </strong>
            {category}
          </div>
        )}
        {/* <div className="component-card-meta-careers-toggles-container">
          {thumbsFeedback && <ThumbsFeedback contentId={objectID} setDislikedCareerSlugs={setDislikedCareerSlugs} />}

          {!hideBookmarks && (
            <>
              <Bookmark
                contentTitle={title}
                classContext={"careers"}
                contentId={objectID}
                contentSlug={slug}
                contentType={contentType}
              />
            </>
          )}
        </div> */}
      </div>
    </div>
  )
}

export default CardCareers
