import {
  createHeader,
  createAlignedHeader,
  createBulletPoint,
  createSpacingLine,
} from "./componentHelpers"

export const renderAwardsSection = awardExperiences => {
  return [
    createHeader("AWARDS"),
    ...awardExperiences
      .filter(experience => experience)
      .flatMap(experience => {
        const title = experience.title || "AWARD TITLE"
        const issuer = experience.issuer || "ISSUER"
        const bulletPoints = experience.bulletPoints || []
        const date = experience.endDate || "AWARD DATE"

        return [
          createAlignedHeader(title, issuer, "", date),
          ...bulletPoints.map(b => createBulletPoint(b)),
          createSpacingLine,
        ]
      }),
  ]
}
