export const notificationOptions = [
  {
    category: "motivational",
    message: "You can do it! Add a goal to keep you on track and earn 1pt.",
    isEmail: true,
    day: "Monday",
    point: "1",
    pointEventDescription: "",
    messageEs:
      "¡Tú puedes hacerlo! Añade un objetivo para mantenerte en el buen camino y gana 1 punto.",
    pointEventDescriptionEs: "",
  },
  {
    category: "initial",
    message:
      "Expand your horizons! Engage with a career story to learn about the mysterious world of work and earn 2 pts.",
    isEmail: false,
    day: "",
    point: "2",
    pointEventDescription: "2 points for engaging with a career story",
    messageEs:
      "¡Expande tus horizontes! Participa en una historia profesional para aprender sobre el misterioso mundo del trabajo y gana 2 puntos.",
    pointEventDescriptionEs:
      "2 puntos por participar en una historia profesional",
  },
  {
    category: "initial-hidden",
    message:
      "Excel in life and in the workplace! Read a post to get advice on how to have a rich and fulfilling career and earn 2 pts.",
    isEmail: false,
    day: "",
    point: "2",
    pointEventDescription: "",
    messageEs:
      "¡Sobresalir en la vida y en el lugar de trabajo! Lee un post para obtener consejos sobre cómo tener una carrera rica y satisfactoria y ganar 2 pts.",
    pointEventDescriptionEs: "",
  },
  {
    category: "initial",
    message:
      "Create a career plan to get started on your path and earn 10 pts.",
    isEmail: false,
    day: "",
    point: "10",
    pointEventDescription: "10 points for creating your first career plan",
    messageEs:
      "Crea un plan de carrera para comenzar tu camino y ganar 10 puntos.",
    pointEventDescriptionEs: "10 puntos por crear tu primer plan de carrera",
  },
  {
    category: "initial-hidden",
    message: "Identify your strengths in the portfolio and earn 1 pt!",
    isEmail: false,
    day: "",
    point: "1",
    pointEventDescription: "1 point earned for identifying strengths",
    messageEs: "¡Identifica tus fortalezas en el portafolio y gana 1 pt!",
    pointEventDescriptionEs: "1 punto ganado por identificar fortalezas",
  },
  {
    category: "initial-hidden",
    message: "Identify your interests in the portfolio and earn 1 pt!",
    isEmail: false,
    day: "",
    point: "1",
    pointEventDescription: "1 point earned for identifying interests",
    messageEs: "¡Identifique sus intereses en la cartera y gane 1 pt!",
    pointEventDescriptionEs: "1 punto ganado por identificar intereses",
  },
  {
    category: "initial-hidden",
    message: "Add something you're looking forward to and earn 1 pt!",
    isEmail: false,
    day: "Monday",
    point: "1",
    pointEventDescription: "1 point earned for updating goals",
    messageEs: "¡Añade algo que te apetezca y gana 1 punto!",
    pointEventDescriptionEs: "1 punto ganado por actualizar objetivos",
  },
  {
    category: "initial-hidden",
    message: "Bookmark your first story and earn double the pts.",
    isEmail: false,
    day: "",
    point: "2",
    pointEventDescription: "",
    messageEs: "Marque su primera historia y gane el doble de puntos.",
    pointEventDescriptionEs: "",
  },
  {
    category: "initial-hidden",
    message: "Bookmark a career and earn 1 pt.",
    isEmail: false,
    day: "",
    point: "1",
    pointEventDescription: "1 point for bookmarking a career",
    messageEs: "Marque una carrera y gane 1 punto.",
    pointEventDescriptionEs: "1 punto por marcar una carrera",
  },
  {
    category: "initial",
    message:
      "Complete the personal information in your portfolio and earn 5 pts.",
    isEmail: false,
    day: "",
    point: "5",
    pointEventDescription: "5 points for completing your personal profile",
    messageEs:
      "Completa la información personal de tu portafolio y gana 5 pts.",
    pointEventDescriptionEs: "5 puntos por completar tu perfil personal",
  },
  {
    category: "initial-hidden",
    message: "Spend time getting to know a school and earn 2 pts.",
    isEmail: false,
    day: "",
    point: "",
    pointEventDescription: "2 points for exploring a school",
    messageEs: "Dedica tiempo a conocer una escuela y gana 2 puntos.",
    pointEventDescriptionEs: "2 puntos por explorar una escuela",
  },
  {
    category: "motivational",
    message:
      "What does success mean to you? Keep this in mind as you go through the site.",
    isEmail: false,
    day: "",
    point: "",
    pointEventDescription: "",
    messageEs:
      "¿Qué significa el éxito para ti? Tenga esto en cuenta a medida que avanza por el sitio.",
    pointEventDescriptionEs: "",
  },
  {
    category: "motivational",
    message:
      "Journaling is a great way to take inventory on what makes you, you. Grab a pen and a notebook and keep track of your activities and emotions for at-least two weeks.",
    isEmail: false,
    day: "",
    point: "",
    pointEventDescription: "",
    messageEs:
      "Escribir un diario es una excelente manera de hacer un inventario de lo que te hace ser tú. Tome un bolígrafo y un cuaderno y lleve un registro de sus actividades y emociones durante al menos dos semanas.",
    pointEventDescriptionEs: "",
  },
  {
    category: "motivational",
    message:
      "What makes you happy? Keep this in mind as you go through the site.",
    isEmail: false,
    day: "",
    point: "",
    pointEventDescription: "",
    messageEs:
      "¿Qué te hace feliz? Tenga esto en cuenta a medida que avanza por el sitio.",
    pointEventDescriptionEs: "",
  },
  {
    category: "motivational",
    message:
      "What activities do you do well? Keep this in mind while going through the site.",
    isEmail: false,
    day: "",
    point: "",
    pointEventDescription: "",
    messageEs:
      "¿Qué actividades realizas bien? Tenga esto en cuenta mientras navega por el sitio.",
    pointEventDescriptionEs: "",
  },
  {
    category: "accomplishment",
    message:
      "Great job! You engaged with the story featuring ___ and earned 2 pts.",
    isEmail: true,
    day: "",
    point: "2",
    pointEventDescription: "",
    messageEs:
      "¡Buen trabajo! Te comprometiste con la historia con ___ y ganaste 2 puntos.",
    pointEventDescriptionEs: "",
  },
  {
    category: "accomplishment",
    message: "Nice work! You read __ posts this week and earned ___ pts.",
    isEmail: true,
    day: "",
    point: "2",
    pointEventDescription: "",
    messageEs:
      "¡Buen trabajo! Leíste __ publicaciones esta semana y ganaste ___ pts.",
    pointEventDescriptionEs: "",
  },
  {
    category: "accomplishment",
    message: "Yes! You set a goal and earned 1pt.",
    isEmail: false,
    day: "",
    point: "1",
    pointEventDescription: "1 point for setting your goal for the week",
    messageEs: "¡Sí! Estableciste una meta y ganaste 1 punto.",
    pointEventDescriptionEs:
      "1 punto por establecer tu objetivo para la semana",
  },
  {
    category: "accomplishment",
    message: "Nice! You identified your strengths and earned ___pts. ",
    isEmail: false,
    day: "",
    point: "",
    pointEventDescription: "",
    messageEs: "¡Muy bien! Identificaste tus fortalezas y te ganaste ___pts. ",
    pointEventDescriptionEs: "",
  },
  {
    category: "accomplishment",
    message: "Nice! You identified your interests and earned ___pts.",
    isEmail: false,
    day: "",
    point: "",
    pointEventDescription: "",
    messageEs: "¡Muy bien! Identificaste tus intereses y te ganaste ___pts.",
    pointEventDescriptionEs: "",
  },
  {
    category: "accomplishment",
    message:
      "Way to go! You completed the personal information in your portfolio and earned 5 pts.",
    isEmail: false,
    day: "",
    point: "5",
    pointEventDescription: "5 points for completing your personal profile",
    messageEs:
      "¡Bien hecho! Completaste la información personal en tu portafolio y ganaste 5 pts.",
    pointEventDescriptionEs: "5 puntos por completar tu perfil personal",
  },
  {
    category: "accomplishment",
    message:
      "Go get em! You created a career plan and earned 10 pts. Start preparing for this career path today!",
    isEmail: false,
    day: "",
    point: "10",
    pointEventDescription: "10 points for creating your first career plan",
    messageEs:
      "¡Ve a por ellos! Creaste un plan de carrera y ganaste 10 puntos. ¡Empieza a prepararte para esta carrera hoy mismo!",
    pointEventDescriptionEs: "10 puntos por crear tu primer plan de carrera",
  },
  {
    category: "accomplishment",
    message: "Great job! You engaged with ___ career and earned 1 pt.",
    isEmail: false,
    day: "",
    point: "1",
    pointEventDescription: "1 point for bookmarking a career",
    messageEs:
      "¡Buen trabajo! Te comprometiste con ___ carrera y ganaste 1 pt.",
    pointEventDescriptionEs: "1 punto por marcar una carrera",
  },
  {
    category: "newContent",
    message:
      "New story alert! Check out \"First Name Last Name\", a \"Job Title\".",
    isEmail: false,
    day: "",
    point: "",
    pointEventDescription: "",
    messageEs:
      '¡Alerta de nueva historia! Echa un vistazo a "Nombre Apellido", un "Título de trabajo".',
    pointEventDescriptionEs: "",
  },
  {
    category: "newContent",
    message: "New post alert! Check out \"Post Title\".",
    isEmail: false,
    day: "",
    point: "",
    pointEventDescription: "",
    messageEs:
      '¡Nueva alerta de publicación! Echa un vistazo a "Título de la publicación".',
    pointEventDescriptionEs: "",
  },
  {
    category: "motivational",
    message: "You haven't read a story in ____ days. Don't give up now!",
    isEmail: false,
    day: "",
    point: "",
    pointEventDescription: "",
    messageEs: "No has leído un cuento en ____ días. ¡No te rindas ahora!",
    pointEventDescriptionEs: "",
  },
  {
    category: "motivational",
    message:
      "What is one thing you can do today to prepare for the career of your dreams?",
    isEmail: false,
    day: "",
    point: "",
    pointEventDescription: "",
    messageEs:
      "¿Qué puedes hacer hoy para prepararte para la carrera de tus sueños?",
    pointEventDescriptionEs: "",
  },
  {
    category: "motivational",
    message:
      "Reach out to someone in your field of interest and ask them for an informational interview!",
    isEmail: false,
    day: "",
    point: "",
    pointEventDescription: "",
    messageEs:
      "¡Comunícate con alguien en tu campo de interés y pídele una entrevista informativa!",
    pointEventDescriptionEs: "",
  },

  {
    category: "motivational",
    message: "Welcome back! Let's continue exploring the world of work.",
    isEmail: false,
    day: "",
    point: "",
    pointEventDescription: "",
    messageEs: "¡Bienvenido! Sigamos explorando el mundo laboral.",
    pointEventDescriptionEs: "",
  },
  {
    category: "motivational",
    message: "You are __ points away from ___ badge. Keep going!",
    isEmail: false,
    day: "",
    point: "",
    pointEventDescription: "",
    messageEs: "Estás a __ puntos de la insignia ___. ¡Sigue!",
    pointEventDescriptionEs: "",
  },
]
