import React from "react"

const Subscription = () => {
  return (
    <>
      <h2>Manage subscription</h2>
    </>
  )
}

export default Subscription
