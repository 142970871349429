import React from "react"
import Bookmark from "../bookmark/bookmark"
import ReactPlayer from "react-player"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons"
import Link from "../../link"
import { FormattedMessage } from "react-intl"

const CardVideos = props => {
  const {
    img,
    title,
    slug,
    story_job_title,
    story_company,
    story_quote,
    story_video_url,
    objectID,
    idx,
    contentType,
  } = props

  return (
    <div
      className="component-card component-card-stories"
      key={idx}
      data-object={objectID}
    >
      <div className="component-card-image component-card-image-video component-card-image-video-stories">
        <div className="video-wrapper">
          <ReactPlayer
            url={story_video_url}
            light={img}
            controls={true}
            playing={true}
            width="100%"
            height="100%"
            playIcon={
              <>
                <button className="button component-button component-button-video component-button-video-large">
                  <FontAwesomeIcon className="play-video" icon={faPlayCircle} />
                  <FormattedMessage id="card-stories-watch-video" defaultMessage="Watch Video" />
                </button>
                <button className="button component-button component-button-video component-button-video-small">
                  <FontAwesomeIcon className="play-video" icon={faPlayCircle} />
                  <span className="sr-only">
                    <FormattedMessage id="card-stories-watch-video" defaultMessage="Watch Video" />
                  </span>
                </button>
              </>
            }
          />
        </div>
      </div>
      <div className="component-card-meta component-card-meta-stories">
        <div className="component-card-meta-header component-card-meta-header-stories">
          <Bookmark
            classContext={"videos"}
            contentTitle={title}
            contentId={objectID}
            contentSlug={slug}
            contentType={contentType}
          />

          <Link
            to={`/story/${slug}`}
            className="component-card-title component-card-title-stories"
          >
            {story_job_title}
          </Link>
        </div>
        <div className="component-card-company component-card-company-stories">
          {story_company}
        </div>
        <blockquote className="component-blockquote component-blockquote-stories">
          <p>{story_quote}</p>
          <cite>&mdash; {title}</cite>
        </blockquote>
      </div>
    </div>
  )
}

export default CardVideos
