import React, { useEffect, useContext, useState } from "react"
import { FirebaseContext } from "../../Firebase"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCompass as farCompass } from "@fortawesome/free-regular-svg-icons"
import { faMapMarkerAlt as farMapMarkerAlt } from "@fortawesome/pro-regular-svg-icons"
import { faLightbulbOn as farLightbulbOn } from "@fortawesome/pro-regular-svg-icons"
import { faReadme as fabReadme } from "@fortawesome/free-brands-svg-icons"
import { faChevronRight, faEllipsisH, faTimes } from "@fortawesome/free-solid-svg-icons"
import { useIntl } from "react-intl"
import { navigate } from "gatsby"
import { FormattedMessage } from "react-intl"
import DOMPurify from "dompurify"

import TimeAgo from "javascript-time-ago"
import en from "javascript-time-ago/locale/en"
import es from "javascript-time-ago/locale/es"
TimeAgo.addLocale(es)
TimeAgo.addDefaultLocale(en)

const Notifications = ({ setShowDropdown }) => {
  const { locale } = useIntl()

  const {
    localFirestore: { notificationsRecord },
  } = useContext(FirebaseContext)

  useEffect(() => {
    function clearSelectedText() {
      // bug fix to prevent text from being highlighted in the notifications list
      if (typeof window !== "undefined" && window.getSelection) {
        // Get the selection object
        var selection = window.getSelection()
        // Remove all ranges from the selection
        selection.removeAllRanges()
      } else if (document.selection) {
        // For older versions of Internet Explorer
        document.selection.empty()
      }
    }
    clearSelectedText()
  }, [])

  return (
    <>
      {
        <div className="notifications-list">
          {notificationsRecord.filter((notification) => !notification.isRead).length > 0 && (
            <>
              <div className="notifications-list-header">
                {" "}
                <div className="eyebrow component-eyebrow">
                  <FormattedMessage id="notifications-new" defaultMessage="What's new" />
                </div>{" "}
                {/* <FontAwesomeIcon icon={faEllipsisH} size="lg" color="#40777c" />{" "} */}
              </div>

              {notificationsRecord
                .filter((notification) => !notification.isRead)
                .map((notification, index) => (
                  <React.Fragment key={`notification-${index}`}>
                    <ListNotification notification={notification} index={index} locale={locale} setShowDropdown={setShowDropdown} />
                  </React.Fragment>
                ))}
            </>
          )}

          {notificationsRecord.filter((notification) => notification.isRead).length > 0 && (
            <>
              <div className="notifications-list-header">
                {" "}
                <div className="eyebrow component-eyebrow">
                  <span><FormattedMessage id="notifications-past" defaultMessage="Past Notifications" /></span>
                </div>{" "}
                {/* <FontAwesomeIcon icon={faEllipsisH} size="lg" color="#40777c" />{" "} */}
              </div>

              {notificationsRecord
                .filter((notification) => notification.isRead)
                .map((notification, index) => (
                  <React.Fragment key={`notification-${index}`}>
                    <ListNotification notification={notification} index={index} locale={locale} setShowDropdown={setShowDropdown} />
                  </React.Fragment>
                ))}
            </>
          )}

          {notificationsRecord.length === 0 && (
            <div className={`notifications-list-item empty`} style={{ textAlign: "center" }}>
              <label>No notifications yet</label>
            </div>
          )}
        </div>
      }
    </>
  )
}

const ListNotification = ({ notification = "", index, locale = "en-US", setShowDropdown }) => {
  const [isHovered, setIsHovered] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const {
    localFirestore: { deleteUserNotification },
  } = useContext(FirebaseContext)

  // destriucture notification object found in firebase userNotifications collection
  const { message, messageEs, type, publicationDate, notificationId, isRead, pageLink, heading, headingEs } = notification
  const isNavigational = !!pageLink

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  const handleDelete = () => {
    deleteUserNotification(notificationId)
    setIsMenuOpen(false)
  }

  // Create formatter (English).
  const timeAgo = locale === "es-ES" ? new TimeAgo("es-ES") : new TimeAgo("en-US")

  const displayTime = timeAgo.format(publicationDate.toDate()) || "long ago"

  // map icons to notification types
  const icons = {
    newContent: <FontAwesomeIcon icon={fabReadme} color="#37c742" size="2x" />,
    motivational: <FontAwesomeIcon icon={farLightbulbOn} color="#e07306" size="2x" />,
    accomplishment: <FontAwesomeIcon icon={farCompass} color="#c737d3" size="2x" />,
    initial: <FontAwesomeIcon icon={farMapMarkerAlt} color="#00b8ef" size="2x" />,
  }

  function CleanHTML({ dirtyHTML }) {
    if (!dirtyHTML) return ""

    // Sanitize the text
    const sanitizedText = DOMPurify.sanitize(dirtyHTML)

    // OLD CODE for bolding numbers, keeping in case we need to revert

    // // Define a regular expression to match numbers
    // const regex = /(\d+)/g

    // // Split the text into parts based on the regex
    // const parts = sanitizedText.split(regex)

    // // Map through the parts, wrapping numbers in <strong> tags
    // const formattedText = parts.map((part, index) => {
    //   if (part.match(regex)) {
    //     return <span key={index}>{part}</span>
    //   } else {
    //     return part
    //   }
    // })

    return <div dangerouslySetInnerHTML={{ __html: sanitizedText }} />
  }

  const handleNavigation = (e) => {
    e.preventDefault()
    if (isNavigational) {
      const localizedLink = locale === "es-ES" ? `/es-ES${notification.pageLink}` : notification.pageLink
      navigate(localizedLink)
      setShowDropdown(false)
    }
  }

  const getBackgroundStyle = () => {
    if (isHovered && isNavigational) {
      return { background: "#F9F9F9", cursor: "pointer" }
    } else {
      return { background: "white" }
    }
  }

  return (
    <>
      <div
        className={`notifications-list-item ${notification.type}`}
        key={index}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={getBackgroundStyle()}
        role={`${isNavigational ? "link" : ""}`}
        onClick={handleNavigation}
      >
        {isHovered && (
          <div
            className="notifications-list-item-menu-section"
            style={{
              right: isNavigational ? "35px" : "10px",
            }}
          >
            <button className="notifications-list-item-menu-section-button" onClick={handleDelete}>
              <FontAwesomeIcon icon={faTimes} color="#40777c" size="xs" />
            </button>
          </div>
        )}

        <div className={`notifications-list-item-circle ${type}`}>{icons[type]}</div>
        <div className="notifications-list-item-message">
          <label>{locale === "es-ES" && headingEs ? headingEs : heading}</label>
          <CleanHTML dirtyHTML={locale === "es-ES" && messageEs ? messageEs : message} />

          <span style={isRead ? { fontStyle: "italic", color: "#959595" } : { fontWeight: "bold", color: "#565656" }}>{displayTime}</span>
        </div>

        <div className="notifications-list-item-action">{isNavigational && <FontAwesomeIcon icon={faChevronRight} color="#40777c" />}</div>
      </div>
    </>
  )
}

export default Notifications
