import React from "react"
import "./spinner.css"
import Logo from "../../images/logo2.png"

const LoadingSpinner = (props) => (
  <div className="loader-container">
    <section className="fade-in">
      <img src={Logo} className="loading-logo" />
      <div className="lds-roller">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
      {props.children}
    </section>
  </div>
)

export default LoadingSpinner
