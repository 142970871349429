import { v4 } from 'uuid';

/**
 * Base milestone class, can be used for experiences!
 */
export default class Milestone {

  /**
   * Constructor.
   *
   * @param {null|Object} milestoneData
   *   The milestone data.
   */
  constructor(milestoneData = null) {
    this._type = milestoneData?.type;
    this._uuid = v4();
  }

  /**
   * Sets the milestone type.
   *
   * @param {string} type
   *   The `type` of milestone.
   */
  set type(type) {
    this._type = type;
  }

  /**
   * Gets the milestone type.
   *
   * @return {null|string}
   *   The `type` set in Cosmic.
   */
  get type() {
    return this._type;
  }

  /**
   * Get the uuid of the milestone
   *
   * @return {*|string}
   */
  get uuid() {
    return this._uuid;
  }

  /**
   * Gets the data object used to save in firestore.
   *
   * @return {{type: string}}
   *   Only `type` is saved for all milestone types.
   */
  getData() {
    return {
      type: this.type
    };
  }
}
