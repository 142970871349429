import React, { useState, useContext, useEffect } from "react"
import { FirebaseContext } from '../Firebase'
import { organizeByType } from "../bookmark/utils/utils";
import AlgoliaStoriesSaved from "./algolia-stories-saved"
import AlgoliaCareersSaved from "./algolia-careers-saved"
import AlgoliaSchoolsSaved from "./algolia-schools-saved"
import AlgoliaMajorsSaved from "./algolia-majors-saved"
import AlgoliaCompaniesSaved from "./algolia-companies-saved"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBookmark } from "@fortawesome/free-solid-svg-icons"
import PortfolioPublicToggle from "../../components/dashboard/portfolio-public-toggle"

import { Dropdown } from "semantic-ui-react";
import 'semantic-ui-css/components/dropdown.min.css'
import 'semantic-ui-css/components/search.min.css'
import 'semantic-ui-css/components/transition.min.css'
import 'semantic-ui-css/components/label.min.css'
import 'semantic-ui-css/components/icon.min.css'
import { FormattedMessage, useIntl } from "react-intl";

const AlgoliaToggleSaved = props => {
  const { heading } = props;
  const { profile } = useContext(FirebaseContext)
  const [selectState, setSelectState] = useState('People')
  const [saved, setSaved] = useState(null)
  const { locale } = useIntl();
  useEffect(() => {
    if (profile && profile.bookmarks) {
      // sort bookmarks by type
      const bookmarks = organizeByType(locale === 'es-ES' ? profile.bookmarksEs : profile.bookmarks)
      setSaved(bookmarks)
    }
  }, [profile])

  const options = [
    {
      key: 'People', text: <FormattedMessage
        id="algolia-toggle-saved-dropdown-people"
        defaultMessage="People"
      />, value: 'People'
    },
    {
      key: 'Careers', text: <FormattedMessage
        id="algolia-toggle-saved-dropdown-careers"
        defaultMessage="Careers"
      />, value: 'Careers'
    },
    {
      key: 'Schools', text: <FormattedMessage
        id="algolia-toggle-saved-dropdown-schools"
        defaultMessage="Schools"
      />, value: 'Schools'
    },
    {
      key: 'Majors', text: <FormattedMessage
        id="algolia-toggle-saved-dropdown-majors"
        defaultMessage="Majors"
      />, value: 'Majors'
    },
    {
      key: 'Companies', text: <FormattedMessage
        id="algolia-toggle-saved-dropdown-companies"
        defaultMessage="Companies"
      />, value: 'Companies'
    },
  ]

  /**
   * Generate valid Algolia filter list from saved items array.
   *
   * @param {string} type
   * @returns {null|string}
   */
  const getFilters = (type) => {
    if (saved && saved.hasOwnProperty(type)) {
      return saved[type].join(' OR ')
    } else {
      return null
    }
  }

  const content = {
    People: <AlgoliaStoriesSaved classContext={'stories'} filterList={getFilters('stories')} />,
    Careers: <AlgoliaCareersSaved classContext={'careers'} filterList={getFilters('careers')} />,
    Schools: <AlgoliaSchoolsSaved classContext={'schools'} filterList={getFilters('schools')} />,
    Majors: <AlgoliaMajorsSaved classContext={'majors'} filterList={getFilters('majors')} />,
    Companies: <AlgoliaCompaniesSaved classContext={'companies'} filterList={getFilters('companies')} />,
  }

  function changeHandler(e, data) {
    e.preventDefault()
    setSelectState(data.value)
  }

  return (
    <>
      <section className="portfolio-saved">
        <div className="inner inner-saved">
          <FontAwesomeIcon className="bookmark bookmark-saved" icon={faBookmark} />
          <h2>{heading}</h2>
          <div className="saved-sortby">
            <Dropdown
              fluid
              required
              selection
              icon='chevron down'
              options={options}
              onChange={changeHandler}
              className="ais-MenuSelect"
              defaultValue="People"
              upward={false}
            />
          </div>
          {/* <PortfolioPublicToggle section="bookmarks"/> */}

          {content[selectState]}
        </div>

      </section>
    </>
  )
}

export default AlgoliaToggleSaved
