import React, { useState, useEffect, useRef } from "react"
import orderBy from "lodash.orderby"
import _ from "lodash"
import CardVideos from "../cards/cards-videos"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronDown,
  faChevronUp,
  faChevronRight,
  faChevronLeft,
  faTimes,
} from "@fortawesome/free-solid-svg-icons"
import { faSearch } from "@fortawesome/pro-regular-svg-icons"
import EyeBrow from "../eyebrow/eyebrow"
import OutsideClickHandler from "react-outside-click-handler"
import AlgoliaClientKeys from "./AlgoliaClientKeys"
import algoliasearch from "algoliasearch"
import {
  InstantSearch,
  SearchBox,
  Configure,
  Pagination,
  Hits,
  RefinementList,
  ClearRefinements,
  ScrollTo,
} from "react-instantsearch-dom"
import "./algolia.css"
import "./algolia-explore.css"
import { useQueryParam, JsonParam, BooleanParam } from "use-query-params"
import { globalHistory } from "@reach/router"
import { FormattedMessage, useIntl } from "react-intl"
import { localizeEducationCheckboxTranslations, localizeIdentityCheckboxTranslation,
  localizeInterestCheckboxTranslation, localizePersonalityCheckboxTranslation } from "./localized-algolia-checkboxes"

// @TODO Style tweaking and cleanup
// @TODO make the component NOT display if there are no results
// @TODO update filter states

const searchClient = algoliasearch(
  AlgoliaClientKeys.appId,
  AlgoliaClientKeys.apiKey
)

const videoResult = ({ hit }) => {
  const {
    objectID,
    title,
    story_personal_photo,
    story_video_url,
    story_job_title,
    story_company,
    story_quote,
    slug,
    type,
  } = hit

  return (
    <CardVideos
      objectID={objectID}
      img={story_personal_photo}
      title={title}
      slug={slug}
      story_video_url={story_video_url}
      story_job_title={story_job_title}
      story_company={story_company}
      story_quote={story_quote}
      contentType={type}
    />
  )
}

/*
 * title = The title of the page. ie - Financial Manager, Architect, etc.
 * filterReference = The Algolia filter reference. ie - story_school_reference
 * classContext = A class to override default styles. ie - career, company, major, etc.
 */

const AlgoliaVideosExplore = props => {
  const { classContext, filterList } = props

  const [loadedFromHistory, setLoadedFromHistory] = useState(false)

  // ref to handle URL update timeout
  // ref to handle reseting scroll behavior to auto on timeout
  // ref to handle toggling off loaded from history
  const debouncedSetState = useRef(null)
  const resetScrollRef = useRef(null)
  const loadingRef = useRef(null)
  const { locale, formatMessage } = useIntl()
  const localeFilter = `locale:${locale}`

  const setHTMLScrollStyle = behavior => {
    document.querySelector("html").style.scrollBehavior = behavior
  }

  //  component mount / unmount hook to handle:
  // 1. clearing timeouts for
  // 2. setting loaded from history to prevent from triggering re-route, starts timeout to set back for normal behavior
  // 3. removing smooth scrolling
  // 4. unmount, clear timeouts and set scroll style to auto
  useEffect(() => {
    const timeoutLoadedFromHistory = () => {
      loadingRef.current = setTimeout(() => {
        setLoadedFromHistory(false)
      }, 100)
    }
    globalHistory.listen(({ action }) => {
      if (action === "POP") {
        setHTMLScrollStyle("auto")
        setLoadedFromHistory(true)
        timeoutLoadedFromHistory()
        clearTimeout(debouncedSetState.current)
      } else if (action === "PUSH") {
        setLoadedFromHistory(false)
        clearTimeout(debouncedSetState.current)
      }
    })
    timeoutLoadedFromHistory()

    return () => {
      setHTMLScrollStyle("auto")
      clearTimeout(debouncedSetState.current)
      clearTimeout(resetScrollRef.current)
      clearTimeout(loadingRef.current)
    }
  }, [])

  // URL search parameters
  // applied filters object, page, filters, and search input data of stories
  // boolean for if saved is showing
  const [queryFilters, setQueryFilters] = useQueryParam(
    "queryFilters",
    JsonParam
  )
  const [showSavedQuery, setShowSavedQuery] = useQueryParam(
    "showSaved",
    BooleanParam
  )

  // component state for showing saved vs newest results. Initial value set by query if exists
  // component state for toggling filter visibility
  // component state for instantly updating search results, can inherit value from URL
  const [showSaved, setShowSaved] = useState(showSavedQuery || false)
  const [showFilters, setShowFilters] = useState(false)
  const [searchState, setSearchState] = useState(queryFilters || {})

  const filters = () => {
    if (!showFilters) {
      setShowFilters(true)
    } else {
      handleOutsideFilterClick()
      setShowFilters(false)
    }
  }

  /**
   * Handles toggling of the newest | saved feature. Updates URL
   *
   * @param {SyntheticEvent} e
   */
  const handleShowSavedClick = e => {
    e.preventDefault()
    if (e.target.attributes.class.value === "active") {
      return
    }
    setShowSaved(!showSaved)
    setQueryFilters(undefined)
    setShowSavedQuery(!showSaved)
  }

  // hook for updating component state when query filters change
  useEffect(() => {
    setShowSaved(showSavedQuery)
  }, [showSavedQuery])

  // hook for updating component state when query filters change
  useEffect(() => {
    setSearchState(queryFilters)
  }, [queryFilters])

  // Handles updating the component search state and URL after any change to algolias search filters
  const onSearchStateChange = searchInput => {
    // set scroll to smooth for algolia components
    setHTMLScrollStyle("smooth")

    // reset scroll behavior on timeout, unmount reset is inconsistent
    resetScrollRef.current = setTimeout(() => {
      setHTMLScrollStyle("auto")
    }, 1)

    // update component state after any change. Allows for instant filtering
    setSearchState(searchInput)

    // conditional variables, may be absent from query or component search state
    const queryRefinementList = queryFilters ? queryFilters.refinementList : {}
    const searchInputRefinementList = searchInput.refinementList
      ? searchInput.refinementList
      : {}

    // booleans to determine the type of query change. Determined by comparing URL with current search
    const isTypedQuery =
      queryFilters?.query !== searchInput?.query &&
      searchInput?.query.length > 0
    const isFilterSelectionQuery =
      !_.isEqual(queryRefinementList, searchInputRefinementList) &&
      !_.isEmpty(searchInputRefinementList)
    const isSortByQuery = showSaved !== showSavedQuery
    const isEmptyURL = queryFilters || showSavedQuery ? false : true
    const isEmptySearch = _.isEqual(searchInput, {
      configure: { hitsPerPage: 9, distinct: true, filters: "" },
    })

    // clear timeout on URL updates for typed query
    clearTimeout(debouncedSetState.current)

    if (isTypedQuery) {
      // updates URL after timeout
      debouncedSetState.current = setTimeout(() => {
        setQueryFilters(searchInput)
      }, 1000)
    } else if (isFilterSelectionQuery) {
      // do nothing if filter change, URL is only updated after filter dropdown is exited
    } else if (isSortByQuery) {
      // do nothing if sort by query change, URL is updated on click
    } else if (isEmptySearch && isEmptyURL) {
      // if attempting to re route with no search info. Needed because of sort by toggling triggering this function on category change
    } else {
      // immediately update URL by default, runs when new page selected
      if (!loadedFromHistory) {
        setQueryFilters(searchInput)
      }
    }
    setLoadedFromHistory(false)
  }

  // handles toggle off of showFilters and updates URL
  const handleOutsideFilterClick = e => {
    // prevents click from firing when clicking scroll bar
    if (e?.target?.tagName === "HTML") return

    // check to see if filters changed, prevents URL setting when they are the same as current params
    const queryRefinementList = queryFilters ? queryFilters.refinementList : {}
    const searchStateRefinementList = searchState?.refinementList
      ? searchState.refinementList
      : {}
    const filtersHaveChanged =
      !_.isEqual(queryRefinementList, searchStateRefinementList) &&
      !_.isEmpty(searchStateRefinementList)

    showFilters && filtersHaveChanged && setQueryFilters(searchState)

    setShowFilters(false)
  }

  const transformVideoEducationFilterItems = (items) => {
    return items.map((item) => {
      const locItem = {
        ...item
      };
      if (localizeEducationCheckboxTranslations[item.label] && localizeEducationCheckboxTranslations[item.label][locale])
        locItem.label = localizeEducationCheckboxTranslations[item.label][locale] || item;
      return locItem;
    });
  };

  const transformVideoIdentityFilterItems = (items) => {
    const localizedItems = items.map((item) => {
      const localizeIdentityItem = { ...item };
      if (
        localizeIdentityCheckboxTranslation[item.label] &&
        localizeIdentityCheckboxTranslation[item.label][locale]
      ) {
        localizeIdentityItem.label =
          localizeIdentityCheckboxTranslation[item.label][locale];
      }
      return localizeIdentityItem;
    });
    // Sort the items alphabetically by their localized labels
    const sortedItems = orderBy(localizedItems,["label", "count"], ["asc", "desc"]);
    return sortedItems;
  };
  
  const transformVideoInterestFilterItems = (items) => {
    const localizedItems = items.map((item) => {
      const localizeInterestItem = { ...item };
      if (
        localizeInterestCheckboxTranslation[item.label] &&
        localizeInterestCheckboxTranslation[item.label][locale]
      ) {
        localizeInterestItem.label =
          localizeInterestCheckboxTranslation[item.label][locale];
      }
      return localizeInterestItem;
    });
    const sortedItems = orderBy(localizedItems, ["label", "count"], ["asc", "desc"]);
    return sortedItems;
  };

  const transformVideoPersonalityFilterItems = (items) => {
    const localizedItems = items.map((item) => {
      const localizePersonalityItem = { ...item };
      if (
        localizePersonalityCheckboxTranslation[item.label] &&
        localizePersonalityCheckboxTranslation[item.label][locale]
      ) {
        localizePersonalityItem.label =
          localizePersonalityCheckboxTranslation[item.label][locale];
      }
      return localizePersonalityItem;
    });
    const sortedItems = orderBy(localizedItems, ["label", "count"], ["asc", "desc"]);
    return sortedItems;
  };

  return (
    <InstantSearch
      indexName="stories_people_view_videos"
      searchState={searchState || {}}
      onSearchStateChange={onSearchStateChange}
      searchClient={searchClient}
    >
      <SearchBox
        searchAsYouType={true}
        translations={{
          placeholder: formatMessage({ id: "algolia-stories-view-search-placeholder", defaultMessage: "Search by name, job title, location, company" }),
        }}
        submit={<FontAwesomeIcon icon={faSearch} />}
        reset={<FontAwesomeIcon icon={faTimes} />}
      />

      <ScrollTo>
        <div
          className={`stream stream-${classContext} stream-${classContext}-explore stream-${classContext}-videos-explore`}
        >
          <OutsideClickHandler onOutsideClick={handleOutsideFilterClick}>
            <div className="stream-explore-filters">
              <button
                className="stream-explore-filters-button"
                onClick={filters}
              >
                <FormattedMessage id="more-filters" defaultMessage="More Filters" />
                <FontAwesomeIcon
                  className={`${!showFilters ? "show" : "hide"}`}
                  icon={faChevronDown}
                  style={{ color: "#363636", marginLeft: ".5rem" }}
                />
                <FontAwesomeIcon
                  className={`${showFilters ? "show" : "hide"}`}
                  icon={faChevronUp}
                  style={{ color: "#363636", marginLeft: ".5rem" }}
                />
              </button>

              <div
                className={`explore-context-filter ${
                  showFilters ? "show" : "hide"
                }`}
              >
                <h3><FormattedMessage id="filter-videos-by" defaultMessage="Filter Videos by" />:</h3>
                <div className="filter filter-personality filter-four">
                  <EyeBrow
                    text={formatMessage({ id: "algolia-stories-explore-personality", defaultMessage: "Personality" })}
                    style={{ marginBottom: "1.5rem" }}
                  />
                  <RefinementList
                    limit={50}
                    attribute="story_personality_filter"
                    transformItems={transformVideoPersonalityFilterItems}
                  />
                </div>
                <div className="filter filter-four">
                  <EyeBrow
                    text={formatMessage({ id: "algolia-stories-explore-interests", defaultMessage: "Interests" })}
                    style={{ marginBottom: "1.5rem" }}
                  />
                  <RefinementList
                    limit={30}
                    attribute="story_interests_filter"
                    transformItems={transformVideoInterestFilterItems}
                  />
                </div>
                <div className="filter filter-four">
                  <EyeBrow
                    text={formatMessage({ id: "algolia-stories-explore-identity", defaultMessage: "Identity" })}
                    style={{ marginBottom: "1.5rem" }}
                  />
                  <RefinementList
                    attribute="story_identity_filter"
                    transformItems={transformVideoIdentityFilterItems}
                    operator="and"
                  />
                </div>
                <div className="filter filter-four filter-last">
                  <EyeBrow
                    text={formatMessage({ id: "algolia-stories-explore-education", defaultMessage: "Education" })}
                    style={{ marginBottom: "1.5rem" }}
                  />
                  <RefinementList
                    attribute="story_required_education_filter"
                    transformItems={transformVideoEducationFilterItems}
                  />
                </div>
                <ClearRefinements
                  translations={{
                    reset: formatMessage({ id: "careers-explore-clear-filters", defaultMessage: "Clear Filters" }),
                  }}
                />
              </div>

              <ul className="explore-filters-list">
                <li className="explore-filters-list-item explore-filters-list-item-sort">
                  <FormattedMessage id="algolia-stories-view-sort-by" defaultMessage="Sort by:" />
                </li>
                <li className="explore-filters-list-item explore-filters-list-item-newest">
                  <a
                    href="/"
                    className={showSaved ? "" : "active"}
                    onClick={handleShowSavedClick}
                  >
                    <FormattedMessage id="algolia-stories-view-newest" defaultMessage="Newest" />
                  </a>
                </li>
                <li className="explore-filters-list-item explore-filters-list-item-saved">
                  <a
                    href="/"
                    className={showSaved ? "active" : ""}
                    onClick={handleShowSavedClick}
                  >
                    <FormattedMessage id="algolia-stories-view-saved" defaultMessage="Saved" />
                  </a>
                </li>
              </ul>
            </div>
          </OutsideClickHandler>

          <Configure
            hitsPerPage={9}
            distinct
            filters={
              showSaved ? `${filterList} AND has_video:true AND ${localeFilter}` : `has_video:true AND ${localeFilter}`
            }
          />

          <Hits hitComponent={videoResult} />

          <Pagination
            showFirst={false}
            showLast={false}
            translations={{
              previous: <FontAwesomeIcon icon={faChevronLeft} size="sm" />,
              next: <FontAwesomeIcon icon={faChevronRight} size="sm" />,
            }}
          />
        </div>
      </ScrollTo>
    </InstantSearch>
  )
}

export default AlgoliaVideosExplore
