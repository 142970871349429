import React, { useState, useEffect, useRef } from "react"
import orderBy from "lodash.orderby"
import _ from "lodash"
import CardStories from "../cards/cards-stories"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronDown,
  faChevronUp,
  faChevronRight,
  faChevronLeft,
  faTimes,
} from "@fortawesome/free-solid-svg-icons"
import { faSearch } from "@fortawesome/pro-regular-svg-icons"
import EyeBrow from "../eyebrow/eyebrow"
import OutsideClickHandler from "react-outside-click-handler"
import AlgoliaClientKeys from "./AlgoliaClientKeys"
import algoliasearch from "algoliasearch"
import {
  InstantSearch,
  SearchBox,
  Configure,
  Pagination,
  Hits,
  RefinementList,
  ClearRefinements,
  ScrollTo,
} from "react-instantsearch-dom"
import "./algolia.css"
import "./algolia-explore.css"
import { useQueryParam, JsonParam, StringParam } from "use-query-params"
import { globalHistory } from "@reach/router"
import { FormattedMessage, useIntl } from "react-intl"
import { localizeEducationCheckboxTranslations, localizeIdentityCheckboxTranslation,
  localizeInterestCheckboxTranslation, localizePersonalityCheckboxTranslation } from "./localized-algolia-checkboxes"

const searchClient = algoliasearch(
  AlgoliaClientKeys.appId,
  AlgoliaClientKeys.apiKey
)

const storyResult = ({ hit }) => {
  const {
    objectID,
    title,
    story_personal_photo,
    story_video_url,
    story_job_title,
    story_company,
    story_quote,
    rating_average,
    slug,
    type,
  } = hit

  return (
    <CardStories
      objectID={objectID}
      img={story_personal_photo}
      title={title}
      slug={slug}
      story_video_url={story_video_url}
      story_job_title={story_job_title}
      story_company={story_company}
      story_quote={story_quote}
      rating_average={rating_average}
      contentType={type}
    />
  )
}

/*
 * title = The title of the page. ie - Financial Manager, Architect, etc.
 * filterReference = The Algolia filter reference. ie - story_school_reference
 * classContext = A class to override default styles. ie - career, company, major, etc.
 */

const AlgoliaStoriesExplore = props => {
  const { classContext, filterList } = props

  const [loadedFromHistory, setLoadedFromHistory] = useState(false)

  const [index, setIndex] = useState("stories_explore")

  // ref to handle URL update timeout
  // ref to handle reseting scroll behavior to auto on timeout
  // ref to handle toggling off loaded from history
  const debouncedSetState = useRef(null)
  const resetScrollRef = useRef(null)
  const loadingRef = useRef(null)
  const mountedRef = useRef(true)

  const { locale, formatMessage } = useIntl()


  const localeFilter = `locale:${locale}`
  const filterListWithLocale = filterList
    ? `(${filterList}) AND ${localeFilter}`
    : localeFilter

  const setHTMLScrollStyle = behavior => {
    document.querySelector("html").style.scrollBehavior = behavior
  }

  //  component mount / unmount hook to handle:
  // 1. clearing timeouts for
  // 2. setting loaded from history to prevent from triggering re-route, starts timeout to set back for normal behavior
  // 3. removing smooth scrolling
  // 4. unmount, clear timeouts and set scroll style to auto
  useEffect(() => {
    const timeoutLoadedFromHistory = () => {
      if (!mountedRef.current) return null

      loadingRef.current = setTimeout(() => {
        setLoadedFromHistory(false)
      }, 100)
    }
    globalHistory.listen(({ action }) => {
      if (action === "POP") {
        setHTMLScrollStyle("auto")
        setLoadedFromHistory(true)
        timeoutLoadedFromHistory()
        clearTimeout(debouncedSetState.current)
      } else if (action === "PUSH") {
        setLoadedFromHistory(false)
        clearTimeout(debouncedSetState.current)
      }
    })
    timeoutLoadedFromHistory()

    return () => {
      setHTMLScrollStyle("auto")
      clearTimeout(debouncedSetState.current)
      clearTimeout(resetScrollRef.current)
      clearTimeout(loadingRef.current)
      mountedRef.current = false
    }
  }, [])

  // URL search parameters
  // applied filters object, page, filters, and search input data of stories
  // boolean for if saved is showing
  const [queryFilters, setQueryFilters] = useQueryParam(
    "queryFilters",
    JsonParam
  )
  const [sortByPageQuery, setSortByPageQuery] = useQueryParam(
    "sortByPageQuery",
    StringParam
  )

  // component state for showing saved vs newest results. Initial value set by query if exists
  // component state for toggling filter visibility
  // component state for instantly updating search results, can inherit value from URL
  const [sortByPage, setSortByPage] = useState("NEWEST")

  const [showFilters, setShowFilters] = useState(false)
  const [searchState, setSearchState] = useState(queryFilters || {})

  const filters = () => {
    if (!showFilters) {
      setShowFilters(true)
    } else {
      handleOutsideFilterClick()
      setShowFilters(false)
    }
  }

  const handleSortByClick = (e, page) => {
    e.preventDefault()
    if (page === sortByPage) return

    setSortByPage(page)
    setSortByPageQuery(page)

    switch (page) {
      case "NEWEST":
        setIndex("stories_explore")
        setQueryFilters(undefined)
        break
      case "SAVED":
        setIndex("stories_explore")
        setQueryFilters(undefined)
        break
      case "HIGHEST_RATED":
        setIndex("stories_rating_desc")
        setQueryFilters(undefined)
        break
      default:
        setIndex("stories_explore")
        break
    }
  }

  // hook for updating component state when query filters change
  useEffect(() => {
    setSortByPage(sortByPageQuery)
    if (sortByPageQuery === "SAVED") {
      setIndex("stories_explore")
    } else if (sortByPageQuery === "NEWEST") {
      setIndex("stories_explore")
    } else if (sortByPageQuery === "HIGHEST_RATED") {
      setIndex("stories_rating_desc")
    } else {
      setIndex("stories_explore")
    }
  }, [sortByPageQuery])

  // hook for updating component state when query filters change
  useEffect(() => {
    setSearchState(queryFilters)
  }, [queryFilters])

  // Handles updating the component search state and URL after any change to algolias search filters
  const onSearchStateChange = searchInput => {
    // set scroll to smooth for algolia components
    setHTMLScrollStyle("smooth")

    // reset scroll behavior on timeout, unmount reset is inconsistent
    resetScrollRef.current = setTimeout(() => {
      setHTMLScrollStyle("auto")
    }, 1)

    // update component state after any change. Allows for instant filtering
    setSearchState(searchInput)

    // conditional variables, may be absent from query or component search state
    const queryRefinementList = queryFilters ? queryFilters.refinementList : {}
    const searchInputRefinementList = searchInput.refinementList
      ? searchInput.refinementList
      : {}

    // booleans to determine the type of query change. Determined by comparing URL with current search
    const isTypedQuery =
      queryFilters?.query !== searchInput?.query &&
      searchInput?.query.length > 0
    const isFilterSelectionQuery =
      !_.isEqual(queryRefinementList, searchInputRefinementList) &&
      !_.isEmpty(searchInputRefinementList)
    const isSortByQuery = sortByPage !== sortByPageQuery
    const isEmptyURL =
      queryFilters ||
        sortByPageQuery === "NEWEST" ||
        sortByPageQuery === "HIGHEST_RATED"
        ? false
        : true
    const isEmptySearch = _.isEqual(searchInput, {
      configure: { hitsPerPage: 9, distinct: true, filters: "" },
    })

    // clear timeout on URL updates for typed query
    clearTimeout(debouncedSetState.current)

    if (isTypedQuery) {
      // updates URL after timeout
      debouncedSetState.current = setTimeout(() => {
        setQueryFilters(searchInput)
      }, 1000)
    } else if (isFilterSelectionQuery) {
      // do nothing if filter change, URL is only updated after filter dropdown is exited
    } else if (isSortByQuery) {
      // do nothing if sort by query change, URL is updated on click
    } else if (isEmptySearch && isEmptyURL) {
      // if attempting to re route with no search info. Needed because of sort by toggling triggering this function on category change
    } else {
      // immediately update URL by default, runs when new page selected
      if (!loadedFromHistory) {
        setQueryFilters(searchInput)
      }
    }
    setLoadedFromHistory(false)
  }

  // handles toggle off of showFilters and updates URL
  const handleOutsideFilterClick = (e) => {
    // prevents click from firing when clicking scroll bar
    if (e?.target?.tagName === "HTML") return

    // check to see if filters changed, prevents URL setting when they are the same as current params
    const queryRefinementList = queryFilters ? queryFilters.refinementList : {}
    const searchStateRefinementList = searchState?.refinementList
      ? searchState.refinementList
      : {}
    const filtersHaveChanged =
      !_.isEqual(queryRefinementList, searchStateRefinementList) &&
      !_.isEmpty(searchStateRefinementList)

    showFilters && filtersHaveChanged && setQueryFilters(searchState)

    setShowFilters(false)
  }

  // refresh state
  const [refresh, setRefresh] = useState(false)

  // set refresh on mount, remove on unmount
  useEffect(() => {
    searchClient.clearCache()
    return () => {
      searchClient.clearCache()
    }
  }, [])

  const transformStoriesEducationFilterItems = (items) => {
    return items.map((item) => {
      const locItem = {
        ...item
      };
      if (localizeEducationCheckboxTranslations[item.label] && localizeEducationCheckboxTranslations[item.label][locale])
        locItem.label = localizeEducationCheckboxTranslations[item.label][locale] || item;
      return locItem;
    });
  };

  const transformStoriesIdentityFilterItems = (items) => {
    const localizedItems = items.map((item) => {
      const localizeIdentityItem = { ...item };
      if (
        localizeIdentityCheckboxTranslation[item.label] &&
        localizeIdentityCheckboxTranslation[item.label][locale]
      ) {
        localizeIdentityItem.label =
          localizeIdentityCheckboxTranslation[item.label][locale];
      }
      return localizeIdentityItem;
    });
    // Sort the items alphabetically by their localized labels
    const sortedItems = orderBy(localizedItems,["label", "count"], ["asc", "desc"]);
    return sortedItems;
  };
  
  const transformStoriesInterestFilterItems = (items) => {
    const localizedItems = items.map((item) => {
      const localizeInterestItem = { ...item };
      if (
        localizeInterestCheckboxTranslation[item.label] &&
        localizeInterestCheckboxTranslation[item.label][locale]
      ) {
        localizeInterestItem.label =
          localizeInterestCheckboxTranslation[item.label][locale];
      }
      return localizeInterestItem;
    });
    const sortedItems = orderBy(localizedItems, ["label", "count"], ["asc", "desc"]);
    return sortedItems;
  };

  const transformStoriesPersonalityFilterItems = (items) => {
    const localizedItems = items.map((item) => {
      const localizePersonalityItem = { ...item };
      if (
        localizePersonalityCheckboxTranslation[item.label] &&
        localizePersonalityCheckboxTranslation[item.label][locale]
      ) {
        localizePersonalityItem.label =
          localizePersonalityCheckboxTranslation[item.label][locale];
      }
      return localizePersonalityItem;
    });
    const sortedItems = orderBy(localizedItems, ["label", "count"], ["asc", "desc"]);
    return sortedItems;
  };

  return (
    <InstantSearch
      indexName={index || "stories_explore"}
      searchState={searchState || {}}
      onSearchStateChange={onSearchStateChange}
      searchClient={searchClient}
      refresh={refresh}
    >
      <SearchBox
        searchAsYouType={true}
        translations={{
          placeholder: formatMessage({ id: "algolia-stories-view-search-placeholder", defaultMessage: "Search by name, job title, location, company" }),
        }}
        submit={<FontAwesomeIcon icon={faSearch} />}
        reset={<FontAwesomeIcon icon={faTimes} />}
      />

      <ScrollTo>
        <div
          className={`stream stream-${classContext} stream-${classContext}-explore`}
        >
          <OutsideClickHandler onOutsideClick={handleOutsideFilterClick}>
            <div className="stream-explore-filters">
              <button
                className="stream-explore-filters-button"
                onClick={filters}
              >
                <FormattedMessage id="more-filters" defaultMessage="More Filters" />
                <FontAwesomeIcon
                  className={`${!showFilters ? "show" : "hide"}`}
                  icon={faChevronDown}
                  style={{ color: "#363636", marginLeft: ".5rem" }}
                />
                <FontAwesomeIcon
                  className={`${showFilters ? "show" : "hide"}`}
                  icon={faChevronUp}
                  style={{ color: "#363636", marginLeft: ".5rem" }}
                />
              </button>

              <div
                className={`explore-context-filter ${showFilters ? "show" : "hide"
                  }`}
              >
                <h3><FormattedMessage id="filter-people-by" defaultMessage="Filter People by" />:</h3>
                <div className="filter filter-personality filter-four">
                  <EyeBrow
                    text={formatMessage({ id: "algolia-stories-explore-personality", defaultMessage: "Personality" })}
                    style={{ marginBottom: "1.5rem" }}
                  />
                  <RefinementList
                    limit={50}
                    attribute="story_personality_filter"
                    transformItems={transformStoriesPersonalityFilterItems}
                  />
                </div>
                <div className="filter filter-interests filter-four">
                  <EyeBrow
                    text={formatMessage({ id: "algolia-stories-explore-interests", defaultMessage: "Interests" })}
                    style={{ marginBottom: "1.5rem" }}
                  />
                  <RefinementList
                    limit={30}
                    attribute="story_interests_filter"
                    transformItems={transformStoriesInterestFilterItems}
                  />
                </div>
                <div className="filter filter-four">
                  <EyeBrow
                    text={formatMessage({ id: "algolia-stories-explore-identity", defaultMessage: "Identity" })}
                    style={{ marginBottom: "1.5rem" }}
                  />
                  <RefinementList
                    limit={30}
                    attribute="story_identity_filter"
                    transformItems={transformStoriesIdentityFilterItems}
                    operator="and"
                  />
                </div>
                <div className="filter filter-four filter-last">
                  <EyeBrow
                    text={formatMessage({ id: "algolia-stories-explore-education", defaultMessage: "Education" })}
                    style={{ marginBottom: "1.5rem" }}
                  />
                  <RefinementList
                    limit={30}
                    attribute="story_required_education_filter"
                    transformItems={transformStoriesEducationFilterItems}
                  />
                </div>
                <ClearRefinements
                  translations={{
                    reset: formatMessage({ id: "careers-explore-clear-filters", defaultMessage: "Clear Filters" }),
                  }}
                />
              </div>

              <ul className="explore-filters-list">
                <li className="explore-filters-list-item explore-filters-list-item-sort">
                  <FormattedMessage id="algolia-stories-view-sort-by" defaultMessage="Sort by:" />
                </li>

                <li className="explore-filters-list-item explore-filters-list-item-newest">
                  <a
                    href="/"
                    className={(sortByPage !== "HIGHEST_RATED" && sortByPage !== "SAVED") ? "active" : ""}
                    onClick={e => handleSortByClick(e, "NEWEST")}
                    value="newest"
                  >
                    <FormattedMessage id="algolia-stories-view-newest" defaultMessage="Newest" />
                  </a>
                </li>
                <li className="explore-filters-list-item explore-filters-list-item-newest">
                  <a
                    href="/"
                    className={sortByPage === "HIGHEST_RATED" ? "active" : ""}
                    onClick={e => handleSortByClick(e, "HIGHEST_RATED")}
                    value="highest_rated"
                  >
                    <FormattedMessage id="algolia-stories-view-highest-rated" defaultMessage="Highest Rated" />
                  </a>
                </li>
                <li className="explore-filters-list-item explore-filters-list-item-saved">
                  <a
                    href="/"
                    className={sortByPage === "SAVED" ? "active" : ""}
                    onClick={e => handleSortByClick(e, "SAVED")}
                    value="saved"
                  >
                    <FormattedMessage id="algolia-stories-view-saved" defaultMessage="Saved" />
                  </a>
                </li>
              </ul>
            </div>
          </OutsideClickHandler>

          <Configure
            hitsPerPage={9}
            distinct
            filters={sortByPage === "SAVED" ? filterListWithLocale : localeFilter}
          />

          <Hits hitComponent={storyResult} />

          <Pagination
            showFirst={false}
            showLast={false}
            translations={{
              previous: <FontAwesomeIcon icon={faChevronLeft} size="sm" />,
              next: <FontAwesomeIcon icon={faChevronRight} size="sm" />,
            }}
          />
        </div>
      </ScrollTo>
    </InstantSearch>
  )
}

export default AlgoliaStoriesExplore
