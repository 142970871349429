import { Paragraph, TextRun } from "docx"

export const renderWorkSection = workExperiences => {
  if (workExperiences.length === 0) {
    return []
  }
  
  return [
    new Paragraph({
      children: [
        new TextRun({
          text: "WORK EXPERIENCE",
          bold: true,
          size: 21,
        }),
      ],
      spacing: {
        before: 200,
      },
      border: {
        bottom: {
          color: "#d3d3d3",
          space: 1,
          style: "single",
          size: 6,
        },
      },
    }),
    ...workExperiences
      .map(experience => {
        const {
          title = "JOB TITLE",
          employer = "EMPLOYER NAME",
          location = "LOCATION",
          bulletPoints = [],
          startDate = "",
          endDate = "current",
        } = experience

        return [
          new Paragraph({
            children: [
              new TextRun({
                text: `${title}`,
                bold: true,
                size: 21,
              }),
            ],
            spacing: {
              before: 50,
            },
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: `${employer} | ${location} | ${startDate} - ${endDate}`,
                size: 20,
              }),
            ],
          }),
          ...bulletPoints.map(
            bulletPoint =>
              new Paragraph({
                bullet: {
                  level: 0,
                },
                children: [
                  new TextRun({
                    text: bulletPoint,
                    font: "cambria",
                    size: 20,
                  }),
                ],
              })
          )
        ]
      })
      .flat(),
  ]
}
