/**
 * Allowed user csv import types.
 *
 * @type {{SCHOOL: string, ORGANIZATION: string, INDIVIDUAL: string}}
 */
export const csvImportType = {
  SCHOOL: 'school',
  INDIVIDUAL: 'individual',
  ORGANIZATION: 'organization'
}

/**
 * Array of the csv user import types.
 *
 * @type {string[]}
 */
export const csvImportTypes = Object.values(csvImportType)

/**
 * Allowed csv export types.
 *
 * @type {{SCHOOL: string, ORGANIZATION: string, INDIVIDUAL: string}}
 */
export const csvExportType = {
  SCHOOL: 'school',
  ORGANIZATION: 'organization',
  INDIVIDUAL: 'individual'
}

/**
 * Array of the csv user export types.
 *
 * @type {string[]}
 */
export const csvExportTypes  = Object.values(csvExportType)

/**
 * Allowed user entity types.
 *
 * @type {{SCHOOL: string, ORGANIZATION: string}}
 */
export const userEntityType = {
  SCHOOL: 'school',
  ORGANIZATION: 'organization'
}

/**
 * Array of user entity types.
 *
 * @type {string[]}
 */
export const userEntityTypes = Object.values(userEntityType)

/**
 * User dashboard types.
 *
 * @type {{SCHOOL: string, ORGANIZATION: string, ALL: string, INDIVIDUAL: string}}
 */
export const userDashboardType = {
  SCHOOL: 'school',
  ORGANIZATION: 'organization',
  INDIVIDUAL: 'individual',
  ALL: 'all'
}

/**
 * The possible search selections in the user dashboard.
 */
export const userDashboardFilter = {
  NAME: 'name',
  SCHCODE: 'schcode',
  DEFAULT: 'default',
  ORGCODE: 'orgcode',
  EMAIL: 'email',
  ORGNAME: 'orgname',
  SCHOOLNAME: 'schoolname',
  EXPIRE: 'expire',
  USERTYPE: 'usertype',
  CREATED: 'created',
}

/**
 * The search selection values as an array.
 *
 * @type {(string)[]}
 */
export const userDashboardFilterIds = Object.values(userDashboardFilter)

export const userDashboardSort = {
  DEFAULT: 'default',
  EXPIRE_DESC: 'expire_desc',
  EXPIRE_ASC: 'expire_asc',
  CREATED_DESC: 'created_desc',
  CREATED_ASC: 'created_asc'
}

/**
 * Provides a class to manage the user dashboard search selections.
 */
export class UserDashBoardFilterSelection {

  /**
   * Getter for the id of the search selection.
   *
   * @return {string}
   */
  get id() {
    return this._id
  }

  /**
   * Setter for the id of the search selection.
   *
   * @param {string} value
   */
  set id(value) {
    this._id = value
  }

  /**
   * Getter for the search selection label.
   *
   * @return {string}
   */
  get label() {
    return this._label
  }

  /**
   * Setter for the search selection label.
   *
   * @param {string} value
   */
  set label(value) {
    this._label = value
  }

  /**
   * Getter for the description of the search selection.
   *
   * @return {string}
   */
  get description() {
    return this._description
  }

  /**
   * Setter for the description of the search selection.
   *
   * @param {string} value
   */
  set description(value) {
    this._description = value
  }

  /**
   * @constructor
   *
   * @param {string} id
   *   The search type ID.
   * @param {string} label
   *   The label of the search type.
   * @param {string} description
   *   The description of the search type.
   */
  constructor(id, label, description) {
    this._id = id
    this._label = label
    this._description = description
  }
}

/**
 * Provides a class to manage result sorts.
 */
export class UserDashBoardSortSelection extends UserDashBoardFilterSelection {

  /**
   * Getter for display list.
   *
   * @return {string[]}
   */
  get displayList() {
    return this._displayList
  }

  /**
   * Setter for display list.
   *
   * This is the list of search filters/types that the sort should display for.
   * @see userDashboardFilter
   *
   * @param {string[]} value
   */
  set displayList(value) {
    this._displayList = value
  }

  /**
   * Determines if the sort should display for this search type.
   *
   * @param {string} currentSearchFilter
   *   The current search type.
   *
   * @return {boolean}
   */
  isSortEnabled(currentSearchFilter) {
    return this.displayList.includes(currentSearchFilter)
  }

  /**
   * Gets the sort value that is passed to firebase `orderBy`
   *
   * @return {string[]|null}
   */
  get sortValue() {
    return this._sortValue
  }

  /**
   * Sets the sort value that is passed to firebase.
   *
   * @param {string[]|null} value
   */
  set sortValue(value) {
    this._sortValue = value
  }

  /**
   * @inheritDoc
   *
   * @param {string[]} displayList
   *   The list of filters this sort should display for.
   * @param {string[]} sortValue
   *   The sort value arguments that are passed to firebase `orderBy`.
   *
   */
  constructor(id, label, description, displayList, sortValue) {
    super(id, label, description);
    this._displayList = displayList
    this._sortValue = sortValue
  }
}
