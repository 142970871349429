import React, { useContext, useEffect, useState } from "react"
import { faReply } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Link from "../../link"
import { Role } from "./utils"
import { FirebaseContext } from "../Firebase"
import { useLocation } from "@reach/router"
import { navigate } from "gatsby"

const BackToEducatorDashboardButton = ({ role }) => {
  const { profile } = useContext(FirebaseContext)
  const location = useLocation()

  const [isVisible, setIsVisible] = useState(true)

  useEffect(() => {
    const hiddenPagePaths = ["/educator/", "/educator"]
    const currentPath = location?.pathname
    setIsVisible(!hiddenPagePaths.some((path) => currentPath?.startsWith(path)))
  }, [location])

  const shouldDisplayButton =
    isVisible &&
    (role === Role.SUPERADMIN ||
      role === Role.ADMIN ||
      role === Role.FACULTY ||
      role === Role.EDUCATOR ||
      role === Role.SUPERADMINFIREBASECONST ||
      profile?.membershipType === Role.EDUCATOR)

  if (!shouldDisplayButton) {
    return null
  }

  return (
    <div onClick={() => navigate("/educator", { replace: true })} className="back-to-admin" >
      <FontAwesomeIcon color="#00B8EF" icon={faReply} />
      <p className="back-to-admin-text" >View Admin Dashboard</p>
    </div>
  )
}

export default BackToEducatorDashboardButton
