import modalButterfly from "../../images/levels/butterfly.png"
import profileButterfly from "../../images/levels/butterfly.svg"
import modalBee from "../../images/levels/bee.png"
import profileBee from "../../images/levels/bee.svg"
import modalHummingbird from "../../images/levels/hummingbird.png"
import profileHummingbird from "../../images/levels/hummingbird.svg"
import modalBluejay from "../../images/levels/bluejay.png"
import profileBluejay from "../../images/levels/bluejay.svg"
import modalHeron from "../../images/levels/heron.png"
import profileHeron from "../../images/levels/heron.svg"
import modalEagle from "../../images/levels/eagle.png"
import profileEagle from "../../images/levels/eagle.svg"
import { faFire } from "@fortawesome/pro-solid-svg-icons"

export default [
    {
        points: [0, 10],
        name: "Fuego",
        message: "Lista para crecer!",
        profileColor: "orange",
        modalColor: "orange",
        image: faFire,
    },
    {
        points: [10, 50],
        name: "Mariposa",
        message: "Simbolizando que un pequeño cambio puede dar lugar a un gran maremoto.",
        profileColor: "#D1BCFC",
        modalColor: "#4B489D",
        image: modalButterfly,
    },
    {
        points: [50, 100],
        name: "Abeja",
        message: "Simboliza la concentración y el trabajo duro.",
        profileColor: "#F99F00",
        modalColor: "C86C20",
        image: modalBee,
    },
    {
        points: [100, 250],
        name: "colibrí",
        message: "Simboliza el trabajo atemporal y el néctar de la vida.",
        profileColor: "#C7EB29",
        modalColor: "#35A994",
        image: modalHummingbird,
    },
    {
        points: [250, 500],
        name: "Arrendajo azul",
        message: "SSimboliza comunicación, curiosidad y confianza. Un avistamiento puede ser un presagio que le inste a hablar, ser audaz y perseguir sus objetivos.",
        profileColor: "#5C4DF2",
        modalColor: "#20419A",
        image: modalBluejay,
    },
    {
        points: [500, 1000],
        name: "Garza",
        message: "Simboliza elegancia, paciencia, sabiduría, mensajes, dominio de uno mismo, transformación y buena fortuna.",
        profileColor: "#D1DCFF",
        modalColor: "#4B489D",
        image: modalHeron,
    },
    {
        points: [1000, Infinity],
        name: "Águila",
        message: "Simboliza la iluminación.",
        profileColor: "#FFD00C",
        modalColor: "#2b1c1c",
        image: modalEagle,
    },
]
