import React from "react"
import algoliasearch from 'algoliasearch';
import { InstantSearch, Configure, Index, RefinementList } from 'react-instantsearch-dom';
import AutoComplete from './AutoComplete';
import AlgoliaClientKeys from "./AlgoliaClientKeys"
import "./algolia.css"
import "./algolia-explore.css"
import { useIntl } from "react-intl";

const searchClient = algoliasearch(AlgoliaClientKeys.appId, AlgoliaClientKeys.apiKey);

const AlgoliaGlobalSearch = () => {

  const { locale } = useIntl()
  const localeFilter = `locale:${locale}`
  
  return (
    <InstantSearch indexName="stories" searchClient={searchClient}>
      <Configure hitsPerPage={10} filters={localeFilter} />
      <AutoComplete/>
      <Index indexName="stories"></Index>
      <Index indexName="careers"></Index>
      <Index indexName="schools"></Index>
      <Index indexName="majors"></Index>
      <Index indexName="companies"></Index>
      <Index indexName="blogs">
        <RefinementList
          attribute="blog_categories"
          defaultRefinement={["None"],["Blog"],["Advice"]}
        />
      </Index>
      <Index indexName="basic-pages"></Index>
    </InstantSearch>
  )
}

export default AlgoliaGlobalSearch
