import { createHeader, createSkillsInterestsBullet, createSpacingLine } from './componentHelpers'

export const renderSkillsInterestsSection = (skills, interests) => {
  const skillsString = skills.join(", ")
  const interestsString = interests.join(", ")

  return [
    ...createHeader("SKILLS & INTERESTS"),
    ...(skillsString ? [createSkillsInterestsBullet("Skills: ", skillsString)] : []),
    ...((skillsString && interestsString) ? [createSpacingLine] : []),
    ...(interestsString ? [createSkillsInterestsBullet("Interests: ", interestsString)] : []),
  ]
}
