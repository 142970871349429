import React, { useState } from "react"
import { organizeByType } from "../../bookmark/utils/utils"
import AlgoliaPublicStoriesSaved from "./algolia-public-stories-saved"
import AlgoliaPublicCareersSaved from "./algolia-public-careers-saved"
import AlgoliaPublicSchoolsSaved from "./algolia-public-schools-saved"
import AlgoliaPublicMajorsSaved from "./algolia-public-majors-saved"
import AlgoliaPublicCompaniesSaved from "./algolia-public-companies-saved"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBookmark } from "@fortawesome/free-solid-svg-icons"

import { Dropdown } from "semantic-ui-react"
import "semantic-ui-css/components/dropdown.min.css"
import "semantic-ui-css/components/search.min.css"
import "semantic-ui-css/components/transition.min.css"
import "semantic-ui-css/components/label.min.css"
import "semantic-ui-css/components/icon.min.css"
import { FormattedMessage } from "react-intl"

const AlgoliaPublicSavedSelect = props => {
  const { heading, profile } = props;
  const [selectState, setSelectState] = useState('People')

  let saved = null

  if (profile && profile.bookmarks) {
    saved = organizeByType(profile.bookmarks)
  }

  const options = [
    {
      key: 'People', text: <FormattedMessage
        id="algolia-toggle-saved-dropdown-people"
        defaultMessage="People"
      />, value: 'People'
    },
    {
      key: 'Careers', text: <FormattedMessage
        id="algolia-toggle-saved-dropdown-careers"
        defaultMessage="Careers"
      />, value: 'Careers'
    },
    {
      key: 'Schools', text: <FormattedMessage
        id="algolia-toggle-saved-dropdown-schools"
        defaultMessage="Schools"
      />, value: 'Schools'
    },
    {
      key: 'Majors', text: <FormattedMessage
        id="algolia-toggle-saved-dropdown-majors"
        defaultMessage="Majors"
      />, value: 'Majors'
    },
    {
      key: 'Companies', text: <FormattedMessage
        id="algolia-toggle-saved-dropdown-companies"
        defaultMessage="Companies"
      />, value: 'Companies'
    },
  ]

  /**
   * Generate valid Algolia filter list from saved items array.
   *
   * @param {string} type
   * @returns {null|string}
   */
  const getFilters = (type) => {
    if (saved && saved.hasOwnProperty(type)) {
      return saved[type].join(' OR ')
    } else {
      return null
    }
  }

  // dropdown content, needs to match spanish names too
  const content = {
    People: <AlgoliaPublicStoriesSaved classContext={'stories'} filterList={getFilters('stories')} />,
    Careers: <AlgoliaPublicCareersSaved classContext={'careers'} filterList={getFilters('careers')} />,
    Schools: <AlgoliaPublicSchoolsSaved classContext={'schools'} filterList={getFilters('schools')} />,
    Majors: <AlgoliaPublicMajorsSaved classContext={'majors'} filterList={getFilters('majors')} />,
    Companies: <AlgoliaPublicCompaniesSaved classContext={'companies'} filterList={getFilters('companies')} />,
    Gente: <AlgoliaPublicStoriesSaved classContext={'stories'} filterList={getFilters('stories')} />,
    Carreras: <AlgoliaPublicCareersSaved classContext={'careers'} filterList={getFilters('careers')} />,
    Escuelas: <AlgoliaPublicSchoolsSaved classContext={'schools'} filterList={getFilters('schools')} />,   
    Mayores: <AlgoliaPublicMajorsSaved classContext={'majors'} filterList={getFilters('majors')} />,
    Empresas: <AlgoliaPublicCompaniesSaved classContext={'companies'} filterList={getFilters('companies')} />,
  }

  function changeHandler(e) {
    e.preventDefault()
    setSelectState(e.currentTarget.textContent)
  }

  return (
    <>
      <section className="portfolio-saved">
        <div className="inner inner-saved">
          <FontAwesomeIcon className="bookmark bookmark-saved" icon={faBookmark} />
          <h2>{heading}</h2>
          <div className="saved-sortby">
            <Dropdown
              fluid
              required
              selection
              icon='chevron down'
              options={options}
              onChange={changeHandler}
              className="ais-MenuSelect"
              defaultValue="People"
              upward={false}
            />
          </div>
          {content[selectState]}
        </div>
      </section>
    </>
  )
}

export default AlgoliaPublicSavedSelect
