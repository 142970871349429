import React, { useState, useContext, useEffect } from "react"
import Modal from "react-bootstrap/Modal"
import LEVELS from "./Levels"
import LevelsEs from "./LevelsEs"
import { FirebaseContext } from "../Firebase"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import "./LevelsModal.css"
import { FormattedMessage, useIntl } from "react-intl"

const LevelsModal = ({ userOpened = false, handleShowLevelModalHide }) => {
  const [show, setShow] = useState(false)
  const { locale } = useIntl();
  const {
    pointTotals,
    localFirestore:{
      level
    },
    setLevel,
  } = useContext(FirebaseContext)

  const onModalHide = () => {
    setShow(false)
    if (handleShowLevelModalHide) {
      handleShowLevelModalHide()
    }
  }

  useEffect(() => {
    if (level !== null && LEVELS[level] && pointTotals >= LEVELS[level].points[1]) {
      setLevel(level + 1)
      setShow(true)
    }
  }, [pointTotals])

  useEffect(() => {
    setShow(userOpened)
  }, [userOpened])

  return (
    <>
      {level !== null && LEVELS[level]?.image && (
        <Modal
          className="levels-modal"
          show={show}
          onHide={onModalHide}
          centered
        >
          <Modal.Header closeButton />

          <Modal.Body>
            <h6>
              <b><FormattedMessage id="levels-modal-congratulations" defaultMessage="Congratulations!" /></b>
            </h6>

            <div className="level-info">
              {level === 0 ? (
                <div style={{ color: "#FAAD3D" }}>
                  <FontAwesomeIcon
                    icon={LEVELS[level].image}
                    style={{ marginRight: "0.5rem" }}
                    className="profile-point-image"
                    size="4x"
                  />
                </div>
              ) : (
                <img
                  src={LEVELS[level].image}
                  alt={LEVELS[level].name}
                  className="profile-point-image"
                />
              )}
              <div className="level-text">
                <p><FormattedMessage id="levels-modal-you-are-now-at-level" defaultMessage="You are now at level" /></p>
                <h4 style={{ color: LEVELS[level].modalColor }}>
                  {locale === 'en-US' ? LEVELS[level].name : LevelsEs[level].name}
                </h4>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer className="text-center">
            <p>{locale === 'en-US' ? LEVELS[level].message : LevelsEs[level].message}</p>
          </Modal.Footer>
        </Modal>
      )}
    </>
  )
}

export default LevelsModal
