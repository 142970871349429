import React from "react"
import ExperienceDatesInput from "./experience-dates-input"
import ExperienceTextInput from "./experience-text-input"
import { FormattedMessage, useIntl } from "react-intl"

const ExperienceInputAward = ({ type }) => {
  const { formatMessage } = useIntl()

  return (
    <>
      <ExperienceTextInput
        placeholder={formatMessage({ id: 'resume-experience-awards-form-title-placeholder', defaultMessage: "Ex. NSF GK-12 Fellowship" })}
        label={<FormattedMessage
          id="resume-experience-form-title"
          defaultMessage="Title"
        />}
        dataKey="title"
        required={true}
      />
      <ExperienceTextInput
        placeholder={formatMessage({ id: 'resume-experience-awards-form-associatedOrg-placeholder', defaultMessage: "Ex. Brown University" })}
        label={<FormattedMessage
          id="resume-experience-form-associated-with"
          defaultMessage="Associated With"
        />}
        dataKey="associatedOrg"
      />
      <ExperienceTextInput
        placeholder={formatMessage({ id: 'resume-experience-awards-form-issuer-placeholder', defaultMessage: "Ex. National Science Foundation" })}
        label={<FormattedMessage
          id="resume-experience-form-Issuer"
          defaultMessage="Issuer"
        />}
        dataKey="issuer"
        required={true}
      />
      <ExperienceDatesInput
        label={<FormattedMessage
          id="resume-experience-form-time-period"
          defaultMessage="Time Period"
        />}
        dataKey="timePeriod"
        required={true}
        isSingle={true}
      />
      <ExperienceTextInput
        placeholder={formatMessage({ id: 'resume-experience-awards-form-description-placeholder', defaultMessage: "Ex. This program provided full graduate student funding for two years. In addition to research, I taught 7th-grade science at Nathan Bishop Middle School in Providence, RI." })}
        label={<FormattedMessage
          id="resume-experience-form-description"
          defaultMessage="Description"
        />}
        dataKey="description"
        inputType="textarea"
      />
    </>
  )
}

export default ExperienceInputAward
