import { defaultLanguage, languages, language } from "./i18n"

const getTranslatedPath = (pathname, to) => {
  const currentPageLanguage = getCurrentPageLanguage(pathname)

  let languagePath = ""
  const isDefaultLanguage = defaultLanguage === currentPageLanguage
  if (!isDefaultLanguage) {
    languagePath = "/" + currentPageLanguage
  }

  let outputPath
  if (to === 'https://www.wingspans.com' && currentPageLanguage === language.spanish) {
    outputPath = `${to}${languagePath}`
  } else {
    outputPath = `${languagePath}${to}`
  }

  const hasTrailingSlash = outputPath.endsWith("/")
  if (!hasTrailingSlash) {
    outputPath += "/"
  }

  return outputPath
}

const getCurrentPageLanguage = pathname => {
  const pathElements = pathname.split("/")
  for (let element of pathElements) {
    for (let language of languages) {
      if (element === language) {
        return language
      }
    }
  }
  return defaultLanguage
}

export { getTranslatedPath, getCurrentPageLanguage }
