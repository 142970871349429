import { language } from "../../i18n"

export const localizeEducationCheckboxTranslations = {
    'High School': {
      [language.english]: "High School",
      [language.spanish]: "Escuela secundaria"
    },
    'License': {
      [language.english]: 'License',
      [language.spanish]: "Licencia"
    },
    'Certificate': {
      [language.english]: 'Certificate',
      [language.spanish]: "Certificado"
    },
    'Apprenticeship': {
      [language.english]: 'Apprenticeship',
      [language.spanish]: "Aprendizaje"
    },
    '2-year College': {
      [language.english]: '2-year College',
      [language.spanish]: "Universidad de 2 años"
    },
    '4-year College': {
      [language.english]: '4-year College',
      [language.spanish]: "Universidad de 4 años"
    },
    'Graduate School': {
      [language.english]: 'Graduate School',
      [language.spanish]: "Escuela de Postgrado"
    },
  };

export const localizeIdentityCheckboxTranslation = {
    'White': {
      [language.english]: "White",
      [language.spanish]: "Blanco"
    },
    'Female': {
      [language.english]: "Female",
      [language.spanish]: "Femenino"
    },
    'Male': {
      [language.english]: "Male",
      [language.spanish]: "Masculino"
    },
    'Black or African American': {
      [language.english]: "Black or African American",
      [language.spanish]: "Negro o afroamericano"
    },
    'First-generation': {
      [language.english]: "First-generation",
      [language.spanish]: "Primera generación"
    },
    'Hispanic or Latino': {
      [language.english]: "Hispanic or Latino",
      [language.spanish]: "Hispano o latino"
    },
    'Asian': {
      [language.english]: "Asian",
      [language.spanish]: "Asiático"
    },
    'LGBTQ': {
      [language.english]: "LGBTQ",
      [language.spanish]: "LGTBQ"
    },
    'Multiracial': {
      [language.english]: "Multiracial",
      [language.spanish]: "Multirracial"
    },
    'Native Hawaiian or Other Pacific Islander': {
      [language.english]: "Native Hawaiian or Other Pacific Islander",
      [language.spanish]: "Nativo hawaiano u otro isleño del Pacífico"
    },
    'Disabled': {
      [language.english]: "Disabled",
      [language.spanish]: "Desactivado"
    },
    'American Indian or Alaska Native': {
      [language.english]: "American Indian or Alaska Native",
      [language.spanish]: "Indio americano o nativo de Alaska"
    },
  }

export const localizeInterestCheckboxTranslation = {
    'Architecture': {
      [language.english]: "Architecture",
      [language.spanish]: "Arquitectura"
    },
    'Athletics': {
      [language.english]: "Athletics",
      [language.spanish]: "Atletismo"
    },
    'Business': {
      [language.english]: "Business",
      [language.spanish]: "Negocio"
    },
    'Computers': {
      [language.english]: "Computers",
      [language.spanish]: "Ordenadores"
    },
    'Cooking': {
      [language.english]: "Cooking",
      [language.spanish]: "Cocinando"
    },
    'Engineering': {
      [language.english]: "Engineering",
      [language.spanish]: "Ingeniería"
    },
    'Entrepreneurship': {
      [language.english]: "Entrepreneurship",
      [language.spanish]: "Emprendimiento"
    },
    'Environmental Science': {
      [language.english]: "Environmental Science",
      [language.spanish]: "Ciencia medioambiental"
    },
    'History': {
      [language.english]: "History",
      [language.spanish]: "Historia"
    },
    'Humanities': {
      [language.english]: "Humanities",
      [language.spanish]: "Humanidades"
    },
    'Language': {
      [language.english]: "Language",
      [language.spanish]: "Idioma"
    },
    'Law': {
      [language.english]: "Law",
      [language.spanish]: "Ley"
    },
    'Math': {
      [language.english]: "Math",
      [language.spanish]: "Matemáticas"
    },
    'Medicine': {
      [language.english]: "Medicine",
      [language.spanish]: "Medicamento"
    },
    'Music': {
      [language.english]: "Music",
      [language.spanish]: "Música"
    },
    'Politics': {
      [language.english]: "Politics",
      [language.spanish]: "Política"
    },
    'Psychology': {
      [language.english]: "Psychology",
      [language.spanish]: "Psicología"
    },
    'Science': {
      [language.english]: "Science",
      [language.spanish]: "Ciencia"
    },
    'Skilled Trades': {
      [language.english]: "Skilled Trades",
      [language.spanish]: "Comercios expertos"
    },
    'Social Justice': {
      [language.english]: "Social Justice",
      [language.spanish]: "Justicia social"
    },
    'Teaching': {
      [language.english]: "Teaching",
      [language.spanish]: "Enseñando"
    },
    'Theater': {
      [language.english]: "Theater",
      [language.spanish]: "Teatro"
    },
    'Visual Art': {
      [language.english]: "Visual Art",
      [language.spanish]: "Arte visual"
    },
    'Writing': {
      [language.english]: "Writing",
      [language.spanish]: "Escribiendo"
    },
  }

export const localizePersonalityCheckboxTranslation = {
    'Adventurous': {
      [language.english]: "Adventurous",
      [language.spanish]: "Aventurero"
    },
    'Analytical': {
      [language.english]: "Analytical",
      [language.spanish]: "Analítico"
    },
    'Assertive': {
      [language.english]: "Assertive",
      [language.spanish]: "Asertivo"
    },
    'Athletic': {
      [language.english]: "Athletic",
      [language.spanish]: "Atlético"
    },
    'Bold': {
      [language.english]: "Bold",
      [language.spanish]: "Negrito"
    },
    'Caring': {
      [language.english]: "Caring",
      [language.spanish]: "Cariñoso"
    },
    'Conventional': {
      [language.english]: "Conventional",
      [language.spanish]: "Convencional"
    },
    'Creative': {
      [language.english]: "Creative",
      [language.spanish]: "Creativo"
    },
    'Entrepreneurial': {
      [language.english]: "Entrepreneurial",
      [language.spanish]: "Emprendedor"
    },
    'Extroverted': {
      [language.english]: "Extroverted",
      [language.spanish]: "Extrovertido"
    },
    'Friendly': {
      [language.english]: "Friendly",
      [language.spanish]: "Amistoso"
    },
    'Idealistic': {
      [language.english]: "Idealistic",
      [language.spanish]: "Idealista"
    },
    'Innovative': {
      [language.english]: "Innovative",
      [language.spanish]: "Innovador"
    },
    'Intellectual': {
      [language.english]: "Intellectual",
      [language.spanish]: "Intelectual"
    },
    'Introverted': {
      [language.english]: "Introverted",
      [language.spanish]: "Introvertido"
    },
    'Intuitive': {
      [language.english]: "Intuitive",
      [language.spanish]: "Intuitivo"
    },
    'Leader': {
      [language.english]: "Leader",
      [language.spanish]: "Líder"
    },
    'Likes to build': {
      [language.english]: "Likes to build",
      [language.spanish]: "le gusta construir"
    },
    'Likes to debate': {
      [language.english]: "Likes to debate",
      [language.spanish]: "le gusta debatir"
    },
    'Likes to help people': {
      [language.english]: "Likes to help people",
      [language.spanish]: "Le gusta ayudar a la gente"
    },
    'Likes to perform': {
      [language.english]: "Likes to perform",
      [language.spanish]: "le gusta actuar"
    },
    'Likes to teach': {
      [language.english]: "Likes to teach",
      [language.spanish]: "le gusta enseñar"
    },
    'Likes to work alone': {
      [language.english]: "Likes to work alone",
      [language.spanish]: "Le gusta trabajar solo"
    },
    'Likes to work with hands': {
      [language.english]: "Likes to work with hands",
      [language.spanish]: "Le gusta trabajar con las manos."
    },
    'Likes to work with numbers': {
      [language.english]: "Likes to work with numbers",
      [language.spanish]: "Le gusta trabajar con números."
    },
    'Likes to work with people': {
      [language.english]: "Likes to work with people",
      [language.spanish]: "Le gusta trabajar con la gente."
    },
    'Methodical': {
      [language.english]: "Methodical",
      [language.spanish]: "Metódico"
    },
    'Observant': {
      [language.english]: "Observant",
      [language.spanish]: "Observante"
    },
    'Outdoorsy': {
      [language.english]: "Outdoorsy",
      [language.spanish]: "al aire libre"
    },
    'Persuasive': {
      [language.english]: "Persuasive",
      [language.spanish]: "Persuasivo"
    },
    'Practical': {
      [language.english]: "Practical",
      [language.spanish]: "Práctico"
    },
    'Precise': {
      [language.english]: "Precise",
      [language.spanish]: "Preciso"
    },
    'Problem solver': {
      [language.english]: "Problem solver",
      [language.spanish]: "Solucionador de problemas"
    },
    'Realistic': {
      [language.english]: "Realistic",
      [language.spanish]: "Realista"
    },
    'Responsible': {
      [language.english]: "Responsible",
      [language.spanish]: "Responsable"
    },
    'Scientific': {
      [language.english]: "Scientific",
      [language.spanish]: "Científico"
    },
    'Self-confident': {
      [language.english]: "Self-confident",
      [language.spanish]: "Seguro de si mismo"
    },
    'Sensitive': {
      [language.english]: "Sensitive",
      [language.spanish]: "Sensible"
    },
    'Social': {
      [language.english]: "Social",
      [language.spanish]: "Social"
    },
    'Staightforward': {
      [language.english]: "Staightforward",
      [language.spanish]: "Directo"
    },
    'Strategic': {
      [language.english]: "Strategic",
      [language.spanish]: "Estratégico"
    },
    'Understanding': {
      [language.english]: "Understanding",
      [language.spanish]: "Comprensión"
    },
  }

export const localizePathwayCheckboxTranslation = {
    'Agriculture, Food & Natural Resources': {
      [language.english]: "Agriculture, Food & Natural Resources",
      [language.spanish]: "Agricultura, alimentación y recursos naturales"
    },
    'Architecture & Construction': {
      [language.english]: "Architecture & Construction",
      [language.spanish]: "Arquitectura y Construcción"
    },
    'Arts, A/V Technology & Communications': {
      [language.english]: "Arts, A/V Technology & Communications",
      [language.spanish]: "Artes, tecnología audiovisual y comunicaciones"
    },
    'Business Management & Administration': {
      [language.english]: "Business Management & Administration",
      [language.spanish]: "Gestión y Administración de Empresas"
    },
    'Education & Training': {
      [language.english]: "Education & Training",
      [language.spanish]: "Educación y formación"
    },
    'Finance': {
      [language.english]: "Finance",
      [language.spanish]: "Finanzas"
    },
    'Government & Public Administration': {
      [language.english]: "Government & Public Administration",
      [language.spanish]: "Gobierno y administración pública"
    },
    'Health Science': {
      [language.english]: "Health Science",
      [language.spanish]: "Ciencia de la salud"
    },
    'Hospitality & Tourism': {
      [language.english]: "Hospitality & Tourism",
      [language.spanish]: "Hospitalidad y turismo"
    },
    'Human Services': {
      [language.english]: "Human Services",
      [language.spanish]: "Servicios Humanos"
    },
    'Information Technology': {
      [language.english]: "Information Technology",
      [language.spanish]: "Tecnologías de la información"
    },
    'Law, Public Safety, Corrections & Security': {
      [language.english]: "Law, Public Safety, Corrections & Security",
      [language.spanish]: "Ley, Seguridad Pública, Correccionales y Seguridad"
    },
    'Manufacturing': {
      [language.english]: "Manufacturing",
      [language.spanish]: "Fabricación"
    },
    'Marketing': {
      [language.english]: "Marketing",
      [language.spanish]: "Marketing"
    },
    'Science, Technology, Engineering & Mathematics': {
      [language.english]: "Science, Technology, Engineering & Mathematics",
      [language.spanish]: "Ciencia, Tecnología, Ingeniería y Matemáticas"
    },
    'Transportation, Distribution & Logistics': {
      [language.english]: "Transportation, Distribution & Logistics",
      [language.spanish]: "Transporte, Distribución y Logística"
    },
  }

export const localizeSizeCheckboxTranslations = {
    'Small': {
      [language.english]: "Small",
      [language.spanish]: "Pequeño"
    },
    'Medium': {
      [language.english]: 'Medium',
      [language.spanish]: "Medio"
    },
    'Large': {
      [language.english]: 'Large',
      [language.spanish]: "Grande"
    },
  };

export const localizeSettingCheckboxTranslations = {
    'Urban': {
      [language.english]: "Urban",
      [language.spanish]: "Urbano"
    },
    'Small town': {
      [language.english]: 'Small town',
      [language.spanish]: "Pequeño pueblo"
    },
    'Rural': {
      [language.english]: 'Rural',
      [language.spanish]: "Rural"
    },
    'Suburban': {
      [language.english]: 'Suburban',
      [language.spanish]: "Suburbano"
    },
  };

export const localizeTypeCheckboxTranslations = {
    'Public': {
      [language.english]: "Public",
      [language.spanish]: "Público"
    },
    'Private': {
      [language.english]: 'Private',
      [language.spanish]: "Privado"
    },
  };

export const localizeCostCheckboxTranslations = {
    'Less Expensive': {
      [language.english]: "Less Expensive",
      [language.spanish]: "Menos costoso"
    },
    'Somewhat Expensive': {
      [language.english]: 'Somewhat Expensive',
      [language.spanish]: "Algo caro"
    },
    'Very Expensive': {
      [language.english]: 'Very Expensive',
      [language.spanish]: "Muy caro"
    },
  };

export const localizeIndustryCheckboxTranslations = {
    'Advertising and Agencies': {
      [language.english]: "Advertising and Agencies",
      [language.spanish]: "Publicidad y Agencias"
    },
    'Architecture': {
      [language.english]: 'Architecture',
      [language.spanish]: "Arquitectura"
    },
    'Arts and Music': {
      [language.english]: 'Arts and Music',
      [language.spanish]: "Artes y Música"
    },
    'Biotechnology': {
      [language.english]: 'Biotechnology',
      [language.spanish]: "Biotecnología"
    },
    'Client Services': {
      [language.english]: 'Client Services',
      [language.spanish]: "Servicios al cliente"
    },
    'Consulting': {
      [language.english]: 'Consulting',
      [language.spanish]: "Consultante"
    },
    'Consumer': {
      [language.english]: 'Consumer',
      [language.spanish]: "Consumidor"
    },
    'Education': {
      [language.english]: 'Education',
      [language.spanish]: "Educación"
    },
    'Engineering': {
      [language.english]: 'Engineering',
      [language.spanish]: "Ingeniería"
    },
    'Entertainment & Gaming': {
      [language.english]: 'Entertainment & Gaming',
      [language.spanish]: "Entretenimiento y juegos"
    },
    'Fashion and Beauty': {
      [language.english]: 'Fashion and Beauty',
      [language.spanish]: "Moda y belleza"
    },
    'Finance': {
      [language.english]: 'Finance',
      [language.spanish]: "Finanzas"
    },
    'Food & Beverage': {
      [language.english]: 'Food & Beverage',
      [language.spanish]: "Bebida alimenticia"
    },
    'Government': {
      [language.english]: 'Government',
      [language.spanish]: "Gobierno"
    },
    'Healthcare': {
      [language.english]: 'Healthcare',
      [language.spanish]: "Cuidado de la salud"
    },
    'Insurance': {
      [language.english]: 'Insurance',
      [language.spanish]: "Seguro"
    },
    'Law': {
      [language.english]: 'Law',
      [language.spanish]: "Ley"
    },
    'Manufacturing': {
      [language.english]: 'Manufacturing',
      [language.spanish]: "Fabricación"
    },
    'Media': {
      [language.english]: 'Media',
      [language.spanish]: "Medios de comunicación"
    },
    'Real Estate & Construction': {
      [language.english]: 'Real Estate & Construction',
      [language.spanish]: "Bienes Raíces y Construcción"
    },
    'Social Good': {
      [language.english]: 'Social Good',
      [language.spanish]: "Bueno social"
    },
    'Social Media': {
      [language.english]: 'Social Media',
      [language.spanish]: "Medios de comunicación social"
    },
    'Tech': {
      [language.english]: 'Tech',
      [language.spanish]: "Tecnología"
    },
    'Telecom': {
      [language.english]: 'Telecom',
      [language.spanish]: "Telecomunicaciones"
    },
  };