import React, { useContext, useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { FirebaseContext } from "../Firebase"
import CareerPlanView from "./career-plan-view"
import allowedTypes from "./AllowedTypes"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPenSquare } from "@fortawesome/free-solid-svg-icons"

import "semantic-ui-css/components/modal.min.css"
import "semantic-ui-css/components/dimmer.min.css"
import "./career-plans.css"
import { FormattedMessage, useIntl } from "react-intl"

/**
 * Component to load all career plans for a user.
 */
const CareerPlansPublic = ({ userId, loadingPlans, setLoadingPlans }) => {
  /**
   * Milestone labeling data.
   *
   * @type {{}}
   */
  const milestoneLabels = {}
  const { locale } = useIntl()
  const dataCosmicMilestones = useStaticQuery(graphql`
    {
      allCosmicjsMilestones {
        edges {
          node {
            metadata {
              description
              milestone_id
              goal_label
              goal_detail_label
              goal_detail_label_plural
            }
            title
            locale
          }
        }
      }
    }
  `)
  dataCosmicMilestones.allCosmicjsMilestones.edges.filter((data) => data.node.locale === locale).forEach(({ node }) => {
    const milestoneId = node.metadata.milestone_id
    if (
      !node.title ||
      !allowedTypes.includes(milestoneId) ||
      !node.metadata.goal_label
    ) {
      return null
    }
    milestoneLabels[node.metadata.milestone_id] = {
      title: node.title,
      goalLabel: node.metadata.goal_label,
      goalDetailLabel: node.metadata.goal_detail_label,
      goalDetailLabelPlural: node.metadata.goal_detail_label_plural,
    }
  })

  const { firebase, loading } = useContext(FirebaseContext)
  // const [loadingPlans, setLoadingPlans] = useState(true)
  const [plans, setPlans] = useState([])
  const [planLoadError, setPlanLoadError] = useState(false)

  /**
   * Load's a users plans.
   */
  useEffect(() => {
    let isCancelled = false

    if (loading) {
      return
    }

    if (firebase) {
      try {
        const getPlans = async () => {
          const userPlans = await firebase.loadPublicCareerPlans({ userId })
          if (!isCancelled) {
            setPlans(userPlans)
            setLoadingPlans(false)
            setPlanLoadError(false)
          }
        }
        getPlans()
      } catch (e) {
        console.error("💣", e)
        if (!isCancelled) {
          setLoadingPlans(false)
          setPlanLoadError(true)
        }
      }
    }

    return () => {
      isCancelled = true
    }
  }, [firebase, loading])

  // If plans are loading do not finish rendering.
  if (loadingPlans) {
    return <></>
  }

  /**
   * Renders an error message or empty plan message.
   *
   * @return {JSX.Element}
   */
  const emptyPlans = () => {
    if (planLoadError) {
      return (
        <div className="message message-error">
          There was an error loading career plans, try refreshing the page.
        </div>
      )
    }
    return <></>
  }

  /**
   * Gets the plans render if there are any.
   *
   * @return {JSX.Element|[]}
   */
  const getPlanRender = () => {
    const careersPlanRender = []
    plans.forEach((careerPlan, index) => {
      careersPlanRender.push(
        <div className="career-plan-planned" key={index}>
          <div className="career-plan-planned-item-header">
            <h3>{careerPlan.label}</h3>
          </div>
          <div className="career-plan-planned-item">
            <CareerPlanView
              milestoneLabels={milestoneLabels}
              plan={careerPlan}
            />
          </div>
        </div>
      )
    })

    return careersPlanRender
  }

  if (!Array.isArray(plans) || plans.length < 1) {
    return emptyPlans()
  }

  return (
    <section className="career-plan-tab">
      <div
        className="career-plan-tab-header"
        style={{ display: "flex", alignItems: "flex-start" }}
      >
        <h2><FormattedMessage
          id="career-plan-career-plan"
          defaultMessage="Career Plans"
        /></h2>
      </div>
      <div className="career-plan-tab-header career-plan-tab-header-planned paragraph">
        {getPlanRender()}
      </div>
    </section>
  )
}

export default CareerPlansPublic
