import firebaseConfig from "./config"
import CareerPlan from "../careers/CareerPlan"
import School from "../schools/School"
import Major from "../majors/Major"
import { v4 } from 'uuid';
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/functions'
import 'firebase/compat/storage'
import { ImportResponseFailure, ImportResponseSuccess } from "../admin/AdminFirebaseFunctions";

/**
 * Add constant for the time window of general notifications (7 days).
 *
 * @type {number}
 */
const newContentAlertTimeFrame = 604800000

/**
 * Set default expiration time of 6 hours.
 *
 * @type {number}
 */
const sessionExpirationTime = 21600000

class Firebase {
  constructor() {
    if (!firebaseInstance) {
      firebase.initializeApp(firebaseConfig)
      this.app = firebase
      this.auth = this.app.auth()
      this.db = this.app.firestore()
      this.functions = this.app.functions()
      this.storage = this.app.storage()
    }
  }

  /**
   * Subscribe to global config data
   * @param onSnapshot
   * @returns {Promise<null|*>}
   */
  async getGlobalSiteConfig({ onSnapshot }) {
    const user = this.auth.currentUser
    if (user) {
      return this.db.collection("globalSiteConfig").doc("CONFIG").onSnapshot(onSnapshot)
    } else {
      return null
    }
  }

  /**
   * Subscribe to userActivity data
   * @param onSnapshot
   * @returns {Promise<null|*>}
   */
  async subscribeToUserActivities({ onSnapshot }) {
    const user = this.auth.currentUser
    if (user) {
      return this.db.collection("userActivities").doc(user.uid).onSnapshot(onSnapshot)
    } else {
      return null
    }
  }

  /** Get User Activity
   */
  async getUserActivities(userId) {
    try {
      const resp = await this.db.collection("userActivities").doc(userId).get()
      if (resp.exists) {
        return resp.data()
      } else {
        return null
      }
    } catch (error) {
      return error
    }
  }

  /**
   * Subscribe to userNotifications data
   * @param onSnapshot
   * @returns {Promise<null|*>}
   */
  async subscribeToUserNotifications({ onSnapshot }) {
    const user = this.auth.currentUser
    if (user) {
      return this.db.collection("userNotifications").doc(user.uid).onSnapshot(onSnapshot)
    } else {
      return null
    }
  }

  /**
   * Subscribe to userNotifications data
   * @param onSnapshot
   * @returns {Promise<null|*>}
   */
  async subscribeToUserPointTotal({ onSnapshot }) {
    const user = this.auth.currentUser
    if (user) {
      return this.db.collection("pointTotals").doc(user.uid).onSnapshot(onSnapshot)
    } else {
      return null
    }
  }

  /**
   * Retrieve a user's membership information
   *
   * @returns {Promise<null|*>}
   */
  async getUserMembership() {
    const user = this.auth.currentUser
    if (!user) {
      return null
    }

    const doc = await this.db.collection("memberships").doc(user.uid).get()
    if (doc.exists) {
      return doc.data()
    } else {
      return null
    }
  }

  /**
   * Retrieve a user's visit information
   *
   * @returns {Promise<null|*>}
   */
  async getVisitCount() {
    const user = this.auth.currentUser
    if (!user) {
      return null
    }

    const doc = await this.db.collection("userMetrics").doc(user.uid).get()
    if (doc.exists) {
      const data = doc.data()
      return data.siteVisitCount
    } else {
      return null
    }
  }

  /**
   * Retrieve a user's assessment information
   *
   * @returns {Promise<null|*>}
   */
  async getAssessmentRecord() {
    const user = this.auth.currentUser
    if (!user) {
      return null
    }

    const doc = await this.db.collection("assessments").doc(user.uid).get()
    if (doc.exists) {
      return doc.data()
    } else {
      return null
    }
  }

  /**
   * Retrieve a user's activity information
   *
   * @returns {Promise<null|*>}
   */
  async getActivityRecord() {
    const user = this.auth.currentUser
    if (!user) {
      return null
    }
    const doc = await this.db.collection("userActivities").doc(user.uid).get()
    if (doc.exists) {
      return doc.data()
    } else {
      return []
    }
  }

  /**
   * Retrieve a user's response record for a specific quest
   *
   * @returns {Promise<null|*>}
   */
  async getDailyQuestRecord(questId) {
    const user = this.auth.currentUser
    if (!user) {
      return null
    }
    const doc = await this.db.collection("userQuests").doc(`${questId}-${user.uid}`).get()
    if (doc.exists) {
      return doc.data()
    } else {
      return null
    }
  }

  /**
   *
   */
  async getPast5DaysQuestCompletionCount() {
    const user = this.auth.currentUser
    if (!user) {
      return null
    }
    const currentTime = new Date()
    const fiveDaysAgo = new Date()
    fiveDaysAgo.setDate(currentTime.getDate() - 5)

    const docs = await this.db.collection("userQuests").where("createdDate", ">=", fiveDaysAgo).where("userId", "==", user.uid).get()

    const questRecords = []

    docs.forEach((doc) => {
      questRecords.push({ id: doc.id, ...doc.data() })
    })

    return questRecords.length
  }

  /**
   * remove a user's membership information
   */
  async removeUserMembership({ userId, onSnapshot }) {
    const deleteSubscriptionCallable = this.functions.httpsCallable("deleteSubscription")
    const callableResult = await deleteSubscriptionCallable()
    return callableResult.data
  }

  /**
   * Subscribe to user profile data for current user
   * @param onSnapshot
   * @returns {() => void}
   */
  getUserProfile({ userId, onSnapshot }) {
    const user = this.auth.currentUser
    if (user) {
      return this.db.collection("users").doc(user.uid).onSnapshot(onSnapshot)
    } else {
      return null
    }
  }

  /**
   * Retrieve the current user's profile as a static object, rather
   * than subscribing to the data.
   *
   * @return {Promise<null|*>}
   */
  async getProfile() {
    const user = this.auth.currentUser
    try {
      const snapshot = await this.db.collection("users").doc(user.uid).get()

      if (!snapshot.exists) {
        return null
      } else {
        return snapshot.data()
      }
    } catch (e) {
      return null
    }
  }

  async getCareerData(title, locale) {
    try {
      const snapshot = await this.db
        .collection(locale === "en-US" ? "wages" : "wagesEs")
        .where("docId", "==", title)
        .get()
      if (snapshot.empty) {
        return null
      } else {
        const career = []
        snapshot.forEach((doc) => {
          career.push({
            ...doc.data(),
          })
        })
        return career[0]
      }
    } catch (e) {
      return null
    }
  }

  async createNewCareer(careerTitle, locale) {
    try {
      const data = {
        hardSkills: [],
        salaryData: [],
        softSkills: [],
        title: careerTitle,
        docId: careerTitle.toLowerCase(),
      }
      const response = {
        success: true,
        message: "saved",
      }
      const checkDuplicate = await this.getCareerData(careerTitle, locale)
      if (checkDuplicate && checkDuplicate.title === careerTitle) {
        response.message = `Career with name ${careerTitle} already exist`
        response.success = false
        return response
      }
      await this.db.collection("wages").doc(careerTitle).set(data)
      return response
    } catch (e) {
      return e
    }
  }

  async getSchoolData(studentId) {
    try {
      const snapshot = await this.db.collection("schoolData").doc(studentId).get()

      if (!snapshot.exists) {
        return null
      } else {
        return snapshot.data()
      }
    } catch (e) {
      return null
    }
  }

  async getOtherUserPointTotal(studentId) {
    const getOtherUserPointTotal = this.functions.httpsCallable("getOtherUserPointTotal")

    try {
      const res = await getOtherUserPointTotal({ userId: studentId })
      return res?.data
    } catch (e) {
      return 0
    }
  }

  async getCampusList(schCode) {
    const fetchCampus = this.functions.httpsCallable("onGetCampusBySchoolCode")
    try {
      const resp = await fetchCampus({ sch_code: schCode })
      return resp?.data
    } catch (error) {
      return error
    }
  }

  async getDepartmentList(schCode) {
    const fetchDepartment = this.functions.httpsCallable("onGetDepartmentBySchoolCode")
    try {
      const resp = await fetchDepartment({ sch_code: schCode })
      return resp?.data
    } catch (error) {
      return error
    }
  }

  async groupsListCreatedByUser(type) {
    const grouplist = this.functions.httpsCallable("getGroupsByUserId")
    try {
      const resp = await grouplist({ type })
      return resp
    } catch (error) {
      return error
    }
  }

  async addNewGroup(data) {
    const groupAdd = this.functions.httpsCallable("createGroup")
    try {
      const resp = await groupAdd(data)
      return resp
    } catch (error) {
      return error
    }
  }

  async associateStudentToGroup(payload) {
    const groupsResponse = this.functions.httpsCallable("onAddUsersToGroup")
    try {
      const resp = await groupsResponse(payload)
      return resp
    } catch (error) {
      return error
    }
  }

  async getAllUserListing(data) {
    const getAllUsersList = this.functions.httpsCallable("onGetAllUsers")
    try {
      const resp = await getAllUsersList(data)
      return resp
    } catch (error) {
      return error
    }
  }

  async getAllUserFromGroup(data) {
    const getAllUsersListFromGroup = this.functions.httpsCallable("onGetUsersFromGroup")
    try {
      const resp = await getAllUsersListFromGroup(data)
      return resp
    } catch (error) {
      return error
    }
  }

  async getActiveGroupMembersById(type) {
    const getActiveGroupMembersById = this.functions.httpsCallable("onGetActiveGroupMembersById")
    try {
      const resp = await getActiveGroupMembersById({ type })
      return resp
    } catch (error) {
      return error
    }
  }

  async editGroupName(data) {
    const groupEdit = this.functions.httpsCallable("onUpdateGroupByGroupId")
    try {
      const resp = await groupEdit(data)
      return resp
    } catch (error) {
      return error
    }
  }

  async deleteGroup(data) {
    const groupDelete = this.functions.httpsCallable("onDeleteGroups")
    try {
      const resp = await groupDelete(data)
      return resp
    } catch (error) {
      return error
    }
  }

  async getAllRaisecListing() {
    const getRiasecList = this.functions.httpsCallable("onGetRIASEC")
    try {
      const resp = await getRiasecList()
      return resp
    } catch (error) {
      return error
    }
  }

  async updateCareerSkillsAndStateData(title, data) {
    const careerRef = this.db.collection("wages").doc(title)
    const updateWagesEs = this.functions.httpsCallable("setCareerDataInEs")
    try {
      await careerRef.update(data)
      await updateWagesEs(data)
      return true
    } catch (error) {
      return error
    }
  }

  async getAllCareerSkillsAndStateData() {
    try {
      const snapshot = await this.db.collection("wages").get()
      const d = []
      snapshot.forEach((doc) => {
        d.push(doc.data())
      })
      return d
    } catch (e) {
      return null
    }
  }

  async getDailyQuestListPerUser(userId) {
    const doc = await this.db.collection("userQuests").where("userId", "==", userId).get()
    try {
      if (doc.empty) {
        return []
      } else {
        const quest = []
        doc.forEach((doc) => {
          quest.push({
            ...doc.data(),
          })
        })
        return quest
      }
    } catch (error) {
      return error
    }
  }
  async getOtherUserCareerPlans(userId) {

    const getOtherUserCareerPlans = this.functions.httpsCallable("getOtherUserCareerPlans")

    try {
      const res = await getOtherUserCareerPlans({ userId: userId })
      return res?.data
    } catch (error) {
      return error
    }
  }

  async getFacultyListing(payload) {
    const facultyListing = this.functions.httpsCallable("facultyListings")
    try {
      const resp = await facultyListing(payload)
      return resp
    } catch (error) {
      return error
    }
  }

  async getStudentListing(payload) {
    const studentListing = this.functions.httpsCallable("studentListings")
    try {
      const resp = await studentListing(payload)
      return resp
    } catch (error) {
      return error
    }
  }

  async getProfileDetails(userId) {
    const profileDetails = this.functions.httpsCallable("getProfileDetails")
    try {
      return await profileDetails({ userId: userId })
    } catch (error) {
      return error
    }
  }

  async addUser(payload) {
    const addUser = this.functions.httpsCallable("onAddUser")
    try {
      const resp = await addUser(payload)
      return resp
    } catch (error) {
      return error
    }
  }
  async editUser(payload) {
    const editUser = this.functions.httpsCallable("onEditUser")
    try {
      const resp = await editUser(payload)
      return resp
    } catch (error) {
      return error
    }
  }
  async OnDeleteUser(payload) {
    const OnDeleteUser = this.functions.httpsCallable("onDeleteUser")
    try {
      const resp = await OnDeleteUser(payload)
      return resp
    } catch (error) {
      return error
    }
  }

  async studentActivitySnapshot(payload) {
    const getStudentActivity = this.functions.httpsCallable("onUserActivity")
    try {
      const resp = await getStudentActivity(payload)
      return resp
    } catch (error) {
      return error
    }
  }

  async getFacultyQueryResponse(payload) {
    const facultyQuery = this.functions.httpsCallable("onGetFacultyReport")
    try {
      const resp = await facultyQuery(payload)
      return resp
    } catch (error) {
      return error
    }
  }

  async getStudentQueryResponse(payload) {
    const studentQuery = this.functions.httpsCallable("onGetStudentReport")
    try {
      const resp = await studentQuery(payload)
      return resp
    } catch (error) {
      return error
    }
  }

  async getAllReports(payload) {
    const reports = this.functions.httpsCallable("onGetAllReports")
    try {
      const resp = await reports(payload)
      return resp
    } catch (error) {
      return error
    }
  }

  async saveReports(payload) {
    const saveReports = this.functions.httpsCallable("onSaveReport")
    try {
      const resp = await saveReports(payload)
      return resp
    } catch (error) {
      return error
    }
  }

  async saveSchedule(payload) {
    const saveSchedule = this.functions.httpsCallable("onSaveScheduler")
    try {
      const resp = await saveSchedule(payload)
      return resp
    } catch (error) {
      return error
    }
  }

  async editSchedule(payload) {
    const editSchedule = this.functions.httpsCallable("onEditScheduler")
    try {
      const resp = await editSchedule(payload)
      return resp
    } catch (error) {
      return error
    }
  }

  async getSchedularInfo(payload) {
    const getSchedular = this.functions.httpsCallable("onGetSchedulerById")
    try {
      const resp = await getSchedular(payload)
      return resp
    } catch (error) {
      return error
    }
  }

  async getReports(payload) {
    const exportReport = this.functions.httpsCallable("onGetReportById")
    try {
      const resp = await exportReport(payload)
      return resp
    } catch (error) {
      return error
    }
  }

  async deleteReport(payload) {
    const deleteReport = this.functions.httpsCallable("onDeleteReportById")
    try {
      const resp = await deleteReport(payload)
      return resp
    } catch (error) {
      return error
    }
  }

  async deleteSchedule(payload) {
    const deleteSchedule = this.functions.httpsCallable("onDeleteSchedulerById")
    try {
      const resp = await deleteSchedule(payload)
      return resp
    } catch (error) {
      return error
    }
  }

  async getSchedulers(payload) {
    const schedulers = this.functions.httpsCallable("onGetSchedulers")
    try {
      const resp = await schedulers(payload)
      return resp
    } catch (error) {
      return error
    }
  }

  async importUsers(fileData, fileName, type, defaultPassword) {
    const importer = this.functions.httpsCallable("onImportUser")
    try {
      const response = await importer({ fileData, fileName, type, defaultPassword })
      return new ImportResponseSuccess(response.data)
    } catch (e) {
      if (e.code === "invalid-argument") {
        return new ImportResponseFailure(e.message)
      }
      return e
    }
  }

  /**
   * Copy user profile to the public record, making it available to all.
   *
   * @return {Promise<boolean>}
   */
  async makeProfilePublic() {
    try {
      await this.updateProfile({ data: { profileIsPublic: true } })
      return true
    } catch (e) {
      return false
    }
  }

  /**
   * Copy section of user profile to the public record, making it available to all.
   *
   * @return {Promise<boolean>}
   */
  async makeProfileSectionPublic(section) {
    try {
      await this.updateProfile({
        data: { [`profilePublicSections.${section}`]: true },
      })
      return true
    } catch (e) {
      return false
    }
  }

  /**
   * Delete public profile data and mark main profile flag as a private profile.
   *
   * @return {Promise<boolean>}
   */
  async makeProfilePrivate() {
    try {
      await this.updateProfile({ data: { profileIsPublic: false } })
      return true
    } catch (e) {
      return false
    }
  }

  /**
   * Delete public profile section data.
   *
   * @return {Promise<boolean>}
   */
  async makeProfileSectionPrivate(section) {
    try {
      await this.updateProfile({
        data: { [`profilePublicSections.${section}`]: false },
      })
      return true
    } catch (e) {
      return false
    }
  }

  /**
   * Load a public user profile if the user has set it to public.
   *
   * @param userId
   * @return {Promise<*>}
   */
  async loadPublicProfile(userId) {
    const loadPublicProfileCallable = this.functions.httpsCallable("loadPublicProfile")
    const callableResult = await loadPublicProfileCallable({ userId: userId })
    return callableResult.data
  }

  async deleteUser(uid) {
    const deleteUser = this.functions.httpsCallable("deleteUser")
    return await deleteUser({ userId: uid })
  }

  async initializeUser(user) {
    // if (!user.uid) {
    //   return false
    // }

    this.createFirestoreUser(user)
  }

  /**
   * Gets resume data from firestore based on id.
   *
   * @param {string} planId
   *   The resume document ID.
   *
   * @return {Promise<null|T>}
   */
  async getResumeData() {
    const user = this.auth.currentUser
    if (!user) {
      return null
    }

    try {
      const query = await this.db.collection("userResumes").doc(user.uid).get()
      if (query.exists) {
        return query.data()
      } else {
        return null
      }
    } catch {
      return null
    }
  }

  async getResumeDataForStudents(userId) {
    if (!userId) {
      return null
    }

    try {
      const query = await this.db.collection("userResumes").doc(userId).get()
      if (query.exists) {
        return query.data()
      } else {
        return null
      }
    } catch {
      return null
    }
  }

  /**
   * Saves a resume.
   *
   * @param {CareerPlan} resume
   *
   * @return {Promise<void>}
   */
  async editResumeExperience(experience) {
    const editResumeExperienceCallable = this.functions.httpsCallable("editResumeExperience")
    const callableResult = await editResumeExperienceCallable({
      experience: experience,
    })
    const result = callableResult.data

    // resume.id = result.id
    // resume.updated = result.updated
    // if (result.created) {
    //   // resume.created = result.created
    // }
  }

  /**
   * Saves a resume.
   *
   * @param {CareerPlan} resume
   *
   * @return {Promise<void>}
   */
  async createResumeExperience(experience) {
    const uuid = v4()
    const resumeCreateExperienceCallable = this.functions.httpsCallable("createResumeExperience")
    const callableResult = await resumeCreateExperienceCallable({
      experience: { id: uuid, ...experience },
    })
    const result = callableResult.data
    // resume.id = result.id
    // resume.updated = result.updated
    // if (result.created) {
    //   // resume.created = result.created
    // }
  }

  /**
   * Saves a resume.
   *
   * @param {CareerPlan} resume
   *
   * @return {Promise<void>}
   */
  async deleteResumeExperience(experienceId) {
    const deleteResumeExperienceCallable = this.functions.httpsCallable("deleteResumeExperience")
    const callableResult = await deleteResumeExperienceCallable({
      id: experienceId,
    })
    const result = callableResult.data

    // resume.id = result.id
    // resume.updated = result.updated
    // if (result.created) {
    //   // resume.created = result.created
    // }
  }
  
  /**
   * Given a resume bullet, returns 3 optimized versions
   *
   * @param {ResumeBullet} bullet
   *
   * @return {Promise<Array>}
   */
  async optimizeText(text, type) {
    const optimizeBulletCallable = this.functions.httpsCallable("optimizeText")
    const callableResult = await optimizeBulletCallable({
      text: text,
      type: type,
    })

    const result = callableResult.data
    return result
  }

  /**
   * Gets career plan data from firestore based on id.
   *
   * @param {string} planId
   *   The career plan document ID.
   *
   * @return {Promise<null|T>}
   */
  async getCareerPlansData(planId) {
    try {
      const query = await this.db.collection("userCareerPlans").doc(planId).get()
      if (query.exists) {
        return query.data()
      } else {
        return null
      }
    } catch {
      return null
    }
  }

  /**
   * Loads all careers for a user.
   *
   * @param userId
   *  The uid of the firebase user.
   *
   * @return {Promise<null|[CareerPlan]>}
   */
  async loadCareerPlans(userId) {
    const careers = []
    try {
      const allCareersSnapshot = await this.db.collection("userCareerPlans").where("userId", "==", userId).get()
      if (allCareersSnapshot.empty) {
        return null
      }
      allCareersSnapshot.forEach((doc) => {
        careers.push(new CareerPlan(doc.id, doc.data()))
      })
      return careers
    } catch {
      return null
    }
  }

  /**
   * Retrieve a user's career plans for use on public profiles.
   *
   * @param userId
   * @return {Promise<null|[]>}
   */
  async loadPublicCareerPlans(userId) {
    const careerPlansCallable = this.functions.httpsCallable("loadPublicCareerPlans")
    const plans = await careerPlansCallable(userId)
    if (Array.isArray(plans.data) && plans.data.length > 0) {
      let careerPlans = []
      plans.data.forEach((plan) => {
        // Don't include plans with no milestones
        if (plan.milestones && plan.milestones.length > 0) {
          careerPlans.push(new CareerPlan(null, plan))
        }
      })
      return careerPlans
    }
    return null
  }

  /**
   * Deletes a career plan.
   *
   * @param planId
   *   The document ID of the plan to delete.
   *
   * @return {Promise<boolean>}
   */
  async deleteCareerPlan(planId) {
    const careerDeleteCallable = this.functions.httpsCallable("deleteCareerPlan")
    await careerDeleteCallable(planId)
    return true
  }

  /**
   * Saves a career plan.
   *
   * @param {CareerPlan} careerPlan
   *
   * @return {Promise<void>}
   */
  async saveCareerPlan(careerPlan) {
    const careerSaveCallable = this.functions.httpsCallable("saveCareerPlan")
    const callableResult = await careerSaveCallable(careerPlan.getData())
    const result = callableResult.data
    careerPlan.id = result.id
    careerPlan.updated = result.updated
    if (result.created) {
      careerPlan.created = result.created
    }
  }

  /**
   * Loads school data from firestore.
   *
   * @param id
   *   The school ID.
   *
   * @return {Promise<null|School>}
   *   The school data or null.
   */
  async loadSchool(id, locale) {
    try {
      const localeId = locale === "en-US" ? `${id}` : `${id}-es`
      const query = await this.db.collection("schools").doc(localeId).get()
      if (query.exists) {
        return new School(query.data())
      } else {
        return null
      }
    } catch (e) {
      return null
    }
  }

  /**
   * Loads major data from firestore.
   *
   * @param cip
   *   The major CIP.
   *
   * @return {Promise<null|Major>}
   *   The major data or null.
   */
  async loadMajor(cip) {
    try {
      const query = await this.db.collection("majors").doc(`${cip}`).get()
      if (query.exists) {
        return new Major(query.data())
      } else {
        return null
      }
    } catch (e) {
      return null
    }
  }

  updateUserEmail(email) {
    const user = this.auth.currentUser
    return user.updateEmail(email)
  }

  updateUserPassword(password) {
    const user = this.auth.currentUser
    return user.updatePassword(password)
  }

  updateProfile(record) {
    const user = this.auth.currentUser
    return this.db.collection("users").doc(user.uid).update(record.data)
  }

  /**
   * Dismisses the weekly Monday notification.
   *
   * @return {Promise}
   */
  dismissMondayNotification() {
    const user = this.auth.currentUser
    return this.db.collection("users").doc(user.uid).update({
      "dismissedNotifications.monday": this.app.firestore.FieldValue.serverTimestamp(),
    })
  }

  /**
   * Dismisses the 10 day story notification.
   *
   * @return {Promise}
   */
  dismissStoryTenDayNotification() {
    const user = this.auth.currentUser
    return this.db.collection("users").doc(user.uid).update({
      "dismissedNotifications.storyTenDay": this.app.firestore.FieldValue.serverTimestamp(),
    })
  }

  /**
   * Dismisses the 10 day login notification.
   *
   * @return {Promise}
   */
  dismissLoginTenDayNotification() {
    const user = this.auth.currentUser
    return this.db.collection("users").doc(user.uid).update({
      "dismissedNotifications.loginTenDay": this.app.firestore.FieldValue.serverTimestamp(),
    })
  }

  dismissNewContentNotification(userProfile, notification) {
    const user = this.auth.currentUser
    if (!userProfile?.dismissedNotifications?.newContent || userProfile?.dismissedNotifications?.newContent.length === 0) {
      return this.db
        .collection("users")
        .doc(user.uid)
        .update({
          "dismissedNotifications.newContent": [notification],
        })
    }
    let dismissals = userProfile.dismissedNotifications.newContent
    // Filter out any old dismissals that are out of timeframe
    dismissals = dismissals.filter((dismissedNotification) => Date.now() - dismissedNotification.createdDate.toMillis() <= newContentAlertTimeFrame)
    dismissals.push(notification)
    return this.db.collection("users").doc(user.uid).update({
      "dismissedNotifications.newContent": dismissals,
    })
  }

  /**
   * Store a user's profile photo to Firebase Storage.
   *
   * @param {File} file
   * @return {Promise<null|string>} Firebase Download URL or null
   */
  async storeProfileImage(file) {
    // Properly configured Storage rules will validate file type and file size
    const user = this.auth.currentUser
    const extension = file.type.split("/")[1]

    if (!extension) {
      return null
    }

    const storageRef = this.storage.ref()
    const profileImageRef = storageRef.child(`profile-images/${user.uid}/profile.${extension}`)

    await profileImageRef.put(file).catch(() => {
      return null
    })

    const fileUrl = await profileImageRef.getDownloadURL()
    if (fileUrl) {
      return fileUrl
    } else {
      return null
    }
  }

  /**
   * Store a user's wallpaper photo to Firebase Storage.
   *
   * @param {File} file
   * @return {Promise<null|string>} Firebase Download URL or null
   */
  async storeWallpaperImage(file) {
    // Properly configured Storage rules will validate file type and file size
    const user = this.auth.currentUser
    const extension = file.type.split("/")[1]

    if (!extension) {
      return null
    }

    const storageRef = this.storage.ref()
    const wallpaperImageRef = storageRef.child(`profile-images/${user.uid}/wallpaper.${extension}`)

    await wallpaperImageRef.put(file).catch(() => {
      return null
    })

    const fileUrl = await wallpaperImageRef.getDownloadURL()
    if (fileUrl) {
      return fileUrl
    } else {
      return null
    }
  }

  // async register({email, password, username}) {
  //   await this.auth.createUserWithEmailAndPassword(email, password);
  //   const createProfileCallable = this.functions.httpsCallable('createPublicProfile');
  //   return createProfileCallable({
  //     username
  //   })
  // }
  async register(user) {
    await this.auth.createUserWithEmailAndPassword(user.email, user.password)

    await this.initializeUser(user)

    // const createProfileCallable = this.functions.httpsCallable('createPublicProfile');
    // return createProfileCallable({
    //   username
    // })
  }


  /**
   * Creates a school based user
   *
   */
  async createSchoolUser(schoolId, locale, authResultDisplayName = '', isNameReversed = false) {
    if (typeof window !== "undefined") {
      const hostname = window.location.hostname
      const createSchoolUserCallable = this.functions.httpsCallable(
        "createSchoolUser"
      )
      const callableResult = await createSchoolUserCallable({ schoolId, locale, authResultDisplayName, isNameReversed, hostname })
      return callableResult.data
    }
  }

  // async postComment({text, bookId}){
  //   const postCommentCallable = this.functions.httpsCallable('postComment');
  //   return postCommentCallable({
  //     text,
  //     bookId
  //   });
  // }

  async logout() {
    if (this.auth.currentUser?.uid) {
      localStorage.removeItem(`wings-session:${this.auth.currentUser.uid}`)
    }
    await this.auth.signOut()
  }

  /**
   * Adds login count to user profile.
   *
   * This will only increase login count if the user does not have a session
   * in local storage. Otherwise at least 24 hours needs to pass for a new
   * login to register. This is due to firebase and google auth automatically
   * logging users in and generating new tokens on hard refresh which would
   * result in a large number of login events.
   *
   * @param {string} uid
   *   The user id.
   *
   * @return {Promise<void>}
   */
  async loginCount(uid) {
    const wingsSessionKey = `wings-session:${this.auth.currentUser.uid}`
    const sessionExpiration = localStorage.getItem(wingsSessionKey)
    if (sessionExpiration && Date.now() < sessionExpiration) {
      return
    }
    localStorage.setItem(wingsSessionKey, Date.now() + sessionExpirationTime)

    try {
      // must occur on a delay in order for firebase user to exist upon account creation first login
      setTimeout(async () => {
       await this.db
        .collection("users")
        .doc(uid)
        .update({
          loginCount: this.app.firestore.FieldValue.increment(1),
          lastLoginDate: this.app.firestore.FieldValue.serverTimestamp(),
        })
      }, 20000)
    
    } catch (e) {
      console.error(e)
    }
  }

  /**
   * Handles storing contact form submissions to Firestore.
   *
   * @param formValues
   * @returns {Promise<HttpsCallableResult>}
   */
  async storeContactFormSubmission(formValues) {
    const storeSubmission = this.functions.httpsCallable("storeContactFormSubmission")
    return storeSubmission(formValues)
  }

  /**
   * Handles storing form submission from /signup/employers to Firestore.
   *
   * @param formValues
   * @returns {Promise<HttpsCallableResult>}
   */
  async storeCompanySignUpFormSubmission(formValues) {
    const storeSubmission = this.functions.httpsCallable("storeCompanySignUpFormSubmission")
    return storeSubmission(formValues)
  }

  /**
   * Handles storing form submission from /signup/educators to Firestore.
   *
   * @param formValues
   * @returns {Promise<HttpsCallableResult>}
   */
  async storeEducatorSignUpFormSubmission(formValues) {
    const storeSubmission = this.functions.httpsCallable("storeEducatorSignUpFormSubmission")
    return storeSubmission(formValues)
  }

  /**
   * send email
   *
   * @param formValues
   * @returns {Promise<HttpsCallableResult>}
   */
  async sendEmail(formValues) {
    const sendEmail = this.functions.httpsCallable("sendEmail")
    return sendEmail(formValues)
  }

  async loadDashbardNotifications() {
    const user = this.auth.currentUser
    if (!user) {
      return null
    }
    const notifications = []

    try {
      const snapshot = await this.db.collection("notifications").where("userId", "==", user.uid).get()

      if (!snapshot.empty) {
        snapshot.forEach((doc) => {
          notifications.push({
            ...doc.data(),
            docId: doc.id,
          })
        })
      }

      // Only add general notifications for time frame.
      const timeFrame = new Date(Date.now() - newContentAlertTimeFrame)

      // 1 hour delay for new content notifications
      const hourDelay = new Date(Date.now() - 3600000)

      const snapshotGeneral = await this.db.collection("generalNotifications").where("createdDate", ">", timeFrame).get()

      if (!snapshotGeneral.empty) {
        snapshotGeneral.forEach((doc) => {
          if (doc.data().createdDate.toDate() < hourDelay || doc.data().type !== "newContent") {
            notifications.push({
              ...doc.data(),
              docId: doc.id,
            })
          }
        })
      }

      return notifications.length > 0 ? notifications : null
    } catch (e) {
      console.error("☠️", e)
      return null
    }
  }

  async removeDashboardNotifications(data) {
    // A specific document is required.
    if (!data.docId) {
      return
    }

    const user = this.auth.currentUser
    if (!user) {
      return false
    }

    try {
      await this.db.collection("notifications").doc(data.docId).delete()
      return true
    } catch (e) {
      console.error("☠️ removeDashboardNotifications ☠️", e)
      return false
    }
  }

  async createDashboardNotification(data) {
    const createDashboardNotification = this.functions.httpsCallable("createDashboardNotification")
    return createDashboardNotification(data)
  }

  addAssessmentCareersFeedback(data) {
    const addAssessmentCareersFeedback = this.functions.httpsCallable("handleAssessmentCareersFeedback")
    return addAssessmentCareersFeedback({ ...data, isRemoval: false })
  }

  async removeAssessmentCareersFeedback(data) {
    const removeAssessmentCareersFeedback = this.functions.httpsCallable("handleAssessmentCareersFeedback")
    return removeAssessmentCareersFeedback({ ...data, isRemoval: true })
  }

  async saveAssessment(data) {
    const saveAssessment = this.functions.httpsCallable("saveAssessment")
    return saveAssessment(data)
  }

  async saveAssessmentResponses(data) {
    const saveAssessmentResponses = this.functions.httpsCallable("saveAssessmentResponses")
    return saveAssessmentResponses(data)
  }

  async savePointEvents(data) {
    const savePointEvents = this.functions.httpsCallable("savePointEvents")
    return savePointEvents(data)
  }

  async saveVisitedStory(data) {
    const saveVisitedStory = this.functions.httpsCallable("saveVisitedStory")
    return saveVisitedStory(data)
  }

  async loadVisitedStories() {
    const user = this.auth.currentUser
    if (!user) {
      return null
    }

    try {
      const snapshot = await this.db.collection("visitedStories").where("userId", "==", user.uid).get()

      if (snapshot.empty) {
        return null
      } else {
        const stories = []
        snapshot.forEach((doc) => {
          stories.push({
            ...doc.data(),
            docId: doc.id,
          })
        })

        return stories
      }
    } catch (e) {
      console.error("☠️", e)
      return null
    }
  }

  async saveVisitedBlog(data) {
    const saveVisitedBlog = this.functions.httpsCallable("saveVisitedBlog")
    return saveVisitedBlog(data)
  }

  async loadVisitedBlogs() {
    const user = this.auth.currentUser
    if (!user) {
      return null
    }

    try {
      const snapshot = await this.db.collection("visitedBlogs").where("userId", "==", user.uid).get()

      if (snapshot.empty) {
        return null
      } else {
        const blogs = []
        snapshot.forEach((doc) => {
          blogs.push({
            ...doc.data(),
            docId: doc.id,
          })
        })

        return blogs
      }
    } catch (e) {
      console.error("☠️", e)
      return null
    }
  }

  async saveVisitedSchool(data) {
    const saveVisitedSchool = this.functions.httpsCallable("saveVisitedSchool")
    return saveVisitedSchool(data)
  }

  async loadVisitedSchools() {
    const user = this.auth.currentUser
    if (!user) {
      return null
    }

    try {
      const snapshot = await this.db.collection("visitedSchools").where("userId", "==", user.uid).get()

      if (snapshot.empty) {
        return null
      } else {
        const schools = []
        snapshot.forEach((doc) => {
          schools.push({
            ...doc.data(),
            docId: doc.id,
          })
        })
        return schools
      }
    } catch (e) {
      console.error("☠️", e)
      return null
    }
  }

  /**
   * Retrieve the current user's pointTotal as a static object, rather
   * than subscribing to the data.
   *
   * @return {Promise<null|*>}
   */
  async getPointTotal() {
    const user = this.auth.currentUser
    try {
      const snapshot = await this.db.collection("pointTotals").doc(user.uid).get()

      if (!snapshot.exists) {
        return null
      } else {
        return snapshot.data()
      }
    } catch (e) {
      return null
    }
  }

  async loadPointEvents() {
    const user = this.auth.currentUser
    if (!user) {
      return null
    }

    try {
      const snapshot = await this.db.collection("pointEvents").where("userId", "==", user.uid).get()

      if (snapshot.empty) {
        return null
      } else {
        const pointEvents = []
        snapshot.forEach((doc) => {
          pointEvents.push({
            ...doc.data(),
            docId: doc.id,
          })
        })

        return pointEvents
      }
    } catch (e) {
      console.error("☠️", e)
      return null
    }
  }

  /**
   * Saves walkthrough steps.
   *
   * @param {stepKey, stepValue}
   *
   * @return {Promise<void>}
   */
  async saveWalkthroughStep(stepKey, stepValue) {
    const saveWalkthroughStepCallable = this.functions.httpsCallable("saveWalkthroughStep")
    const callableResult = await saveWalkthroughStepCallable({
      stepKey,
      stepValue,
    })
    const result = callableResult.data
    return result
  }

  async getWalkthroughSteps() {
    const user = this.auth.currentUser
    if (!user) {
      return null
    }

    const doc = await this.db.collection("userWalkthroughs").doc(user.uid).get()
    if (doc.exists) {
      return doc.data()
    } else {
      return null
    }
  }

  // saves user story rating to firestore, cosmic, and algolia
  async saveUserStoryRating(data) {
    const saveUserStoryRating = this.functions.httpsCallable("saveUserStoryRating")
    return saveUserStoryRating(data)
  }

  // gets users history of ratings across all stories
  async getUserStoryRatingsRecord(data) {
    const getUserStoryRatingsRecord = this.functions.httpsCallable("getUserStoryRatingsRecord")
    return getUserStoryRatingsRecord(data)
  }

  // gets record of all story ratings
  async getAllStoryMetrics() {
    const getAllStoryMetrics = this.functions.httpsCallable("getAllStoryMetrics")
    return getAllStoryMetrics()
  }

  // saves user activity completion
  async setActivityCompletion(data) {
    const setActivityCompletion = this.functions.httpsCallable("setActivityCompletion")
    return setActivityCompletion(data)
  }

  // removes user activity completion
  async unsetActivityCompletion(data) {
    const unsetActivityCompletion = this.functions.httpsCallable("unsetActivityCompletion")
    return unsetActivityCompletion(data)
  }

  // saves user activity completion
  async setDailyQuest(data) {
    const setDailyQuest = this.functions.httpsCallable("setDailyQuest")
    return setDailyQuest(data)
  }

  /**
   * Creates a new user via the magic link.
   */
  async createMagicLinkUser(data) {
    const createMagicLinkUserCallable = this.functions.httpsCallable("createMagicLinkUser")
    const callableResult = await createMagicLinkUserCallable(data)
    return callableResult.data
  }

  /**
   * Generate and send sign in link.
   */
  async generateAndSendSignInLink(data) {
    const generateAndSendSignInLinkCallable = this.functions.httpsCallable("generateAndSendSignInLink")
    await generateAndSendSignInLinkCallable(data)
  }

  /**
   * Send client side event details to GCP logs.
   * @param {string} message
   * @param {string} severity - info, warning, error
   * @returns {Promise<HttpsCallableResult>}
   */
  async logClientSideEvent(message, severity = "info") {
    const data = {
      message,
      severity,
    }
    const logClientSideEvent = this.functions.httpsCallable("logClientSideEvent")
    return logClientSideEvent(data)
  }

  /**
   * creates user notification, can be used for to trigger notification based on template ID or custom notification composed in client
   * @param {object} data.notificationData - object custom notification data
   * @param {string} data.notificationData - string template ID for notification
   */
  async createUserNotification(data) {
    const createUserNotification = this.functions.httpsCallable("createUserNotification")
    return createUserNotification({ notificationData: data })
  }

  /**
   * creates user notification, can be used for to trigger notification based on template ID or custom notification composed in client
   * @param {object} data.notificationData - object custom notification data
   * @param {string} data.notificationData - string template ID for notification
   */
  async createEducatorNotification(data) {
    const createEducatorNotification = this.functions.httpsCallable("createEducatorNotification")
    return createEducatorNotification({ notificationData: data })
  }

  /**
   * admin function for creating global notification
   */
  async createGlobalNotification(data) {
    const createGlobalNotification = this.functions.httpsCallable("createGlobalNotification")
    return createGlobalNotification(data)
  }

  /**
   * admin function for deleting notification globally by template ID
   */
  async deleteGlobalNotification(data) {
    const deleteGlobalNotification = this.functions.httpsCallable("deleteGlobalNotification2")
    return deleteGlobalNotification(data)
  }

  /**
   * Marks all notifications as read. Happens when notification drawer is opened.
   */
  async markUserNotificationsAsRead() {
    const markUserNotificationsAsRead = this.functions.httpsCallable("markUserNotificationsAsRead")
    return markUserNotificationsAsRead()
  }

  /**
   * Deletes a user notification, hides permanent notifications.
   *
   * @param {string} data.notificationId - the notification ID
   */
  async deleteUserNotification(data) {
    const deleteUserNotification = this.functions.httpsCallable("deleteUserNotification")
    return deleteUserNotification(data)
  }

  /**
   * Publishes a user notification
   *
   * @param {string} data.notificationId - the notification ID
   */
  async publishScheduledUserNotification(data) {
    const publishScheduledUserNotification = this.functions.httpsCallable("publishScheduledUserNotification")
    return publishScheduledUserNotification(data)
  }

  async logUserSiteVisit(data) {
    const logUserSiteVisit = this.functions.httpsCallable("logUserSiteVisit")
    return logUserSiteVisit({ siteVisitData: data })
  }
  
  async getEducatorAccountInfo() {
    const getEducatorAccountInfo = this.functions.httpsCallable("getEducatorAccountInfo")
    return getEducatorAccountInfo()
  }
}

let firebaseInstance

function getFirebaseInstance() {
  if (!firebaseInstance) {
    firebaseInstance = new Firebase()
    return firebaseInstance
  } else if (firebaseInstance) {
    return firebaseInstance
  } else {
    return null
  }
}

export default getFirebaseInstance
