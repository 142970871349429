import { getValidMonthDay, allowedMonthsNames } from '../careers/AllowedTypes';

/**
 * Provides a class to handle deadline dates.
 */
export default class MonthDayDeadline {

  /**
   * Constructor.
   *
   * @param {string} dateData
   *   The date in format `M-D`.
   */
  constructor(dateData) {
    const date = getValidMonthDay(dateData);
    if (!date) {
      this._isValid = false;
      this._monthInt = null;
      this._dayInt = null;
    } else {
      this._isValid = true;
      this._monthInt = date.month;
      this._dayInt = date.day;
    }
  }

  /**
   * If the date created is valid or not.
   *
   * @return {boolean}
   */
  get isValid() {
    return this._isValid
  }

  /**
   * The integer value of the month.
   *
   * @return {number|null}
   */
  get monthInt() {
    return this._monthInt
  }

  /**
   * The integer value of the day.
   *
   * @return {number|null}
   */
  get dayInt() {
    return this._dayInt
  }

  /**
   * Gets the month name.
   *
   * @return {string|null}
   */
  get monthName() {
    if (this.monthInt) {
      return allowedMonthsNames[this.monthInt - 1];
    } else {
      return null;
    }
  }

}
