import React from "react"
import MyAccount from "../../components/account/Account"

const MyAccountPage = () => {
  return (
    <MyAccount />
  )
}

export default MyAccountPage
