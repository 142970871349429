import { userEntityFactoryFromDoc } from "./UserEntity"
import { userEntityType } from "./adminOptions"
import { User } from "./UserModel"
import { expirationType } from "../user/userType"
import {schoolOrganizationOptions} from "../organizationOptions"

/**
 * Provides a base class for function responses.
 */
class FunctionResponse {

  /**
   * Function response constructor.
   *
   * @param {string|Object} data
   *   The data from the functon response.
   */
  constructor(data) {
    this._data = data
  }

  /**
   * If the import response type is a successful import.
   *
   * @return {boolean}
   */
  get isSuccessful() {
    return false;
  }

  /**
   * Gets the data related to the import response.
   *
   * @return {*}
   */
  get data() {
    return this._data
  }
}

/**
 * Provides a class for imports that fail, the data will be the error message.
 */
export class ImportResponseFailure extends FunctionResponse {

  /**
   * @inheritDoc
   */
  get isSuccessful() {
    return false;
  }
}

/**
 * Provides a class for a successful import.
 */
export class ImportResponseSuccess extends FunctionResponse {

  /**
   * If the import had any record errors.
   *
   * @return {boolean}
   */
  isError() {
    return this.data.errors.length > 0
  }

  /**
   * Get the errors from the response.
   *
   * @return {string[]}
   */
  getErrors() {
    return this.data.errors;
  }

  /**
   * Gets the total records imported.
   *
   * @return {number}
   */
  getTotal() {
    return this.data.totalRecords;
  }

  /**
   * Gets the total records successfully imported.
   *
   * @return {number}
   */
  getProcessedTotal() {
    return this.data.recordsProcessed;
  }

  /**
   * Gets the new users created count.
   *
   * @return {number}
   */
  getNewUserCount() {
    return this.data.newUserCount;
  }

  /**
   * Gets the users updated count.
   *
   * @return {number}
   */
  getUpdateUserCount() {
    return this.data.updateUserCount;
  }

  /**
   * @inheritDoc
   */
  get isSuccessful() {
    return true;
  }
}

/**
 * Provides a successful export response.
 */
export class ExportResponseSuccess extends FunctionResponse {

  /**
   * @inheritDoc
   */
  get isSuccessful() {
    return true;
  }

  /**
   * Gets the CSV data in the response.
   *
   * @return {string}
   */
  getCsvData() {
    return this.data.csvData
  }

  /**
   * Gets the total records in the data.
   *
   * @return {number}
   */
  getTotalRecords() {
    return this.data.totalRecords
  }
}

/**
 * Provides an export failure response.
 */
export class ExportResponseFailure extends FunctionResponse {

  /**
   * @inheritDoc
   */
  get isSuccessful() {
    return false;
  }
}

/**
 * Creates a class for handling admin firebase functions.
 */
export default class AdminFirebaseFunctions {

  /**
   * @constructor
   *
   * @param firebase
   *   The firebase instance.
   */
  constructor(firebase) {
    this._firebase = firebase
  }

  /**
   * Gets the firebase instance.
   *
   * @return {*}
   */
  get firebase() {
    return this._firebase
  }

  /**
   * Calls the user import function.
   *
   * @param {string} fileData
   *   Base 64 encoded csv data.
   * @param fileName
   *   The file name of the csv.
   * @param type
   *   The import type.
   *
   * @return {Promise<FunctionResponse>}
   */
  async importCsv(fileData, fileName, type, defaultPassword) {
    const importer = this.firebase.functions.httpsCallable(
      "importUser"
    )
    try {
      const response = await importer({fileData, type, fileName, defaultPassword})
      return new ImportResponseSuccess(response.data)
    } catch(e) {
      if (e.code === 'invalid-argument') {
        return new ImportResponseFailure(e.message)
      }
      return new ImportResponseFailure(
        "An internal error has occurred processing the data"
      )
    }
  }

  /**
   * Provides a caller for the export csv firebase function.
   *
   * @param {Object} options
   *   The filter options, and type of the export.
   *
   * @return {Promise<FunctionResponse>}
   */
  async exportCsv(options) {
    const exporter = this.firebase.functions.httpsCallable(
      "exportUser"
    )
    try {
      const response = await exporter({options})
      return new ExportResponseSuccess(response.data)
    } catch (e) {
      if (e.code === 'invalid-argument') {
        return new ExportResponseFailure(e.message)
      }
      if (e.code === 'not-found') {
        return new ExportResponseFailure(e.message)
      }
      return new ExportResponseFailure("An internal error has occurred exporting the data")
    }
  }

  /**
   * Gets all user entities.
   *
   * @return {Promise<*[]>}
   */
  async getUserEntities() {
    const entities = []
      const snapshot = await this.firebase.db
        .collection("userEntities")
        .get()
      if (snapshot.empty) {
        return entities
      }
      snapshot.forEach((document) => {
        entities.push(userEntityFactoryFromDoc(document))
      })
    return entities
  }

  /**
   * Gets a user entity by document id.
   *
   * @param {string} id
   *   The document ID.
   *
   * @return {Promise<UserEntity>}
   */
  async getUserEntity(id) {
    const document = await this.firebase.db
      .collection("userEntities")
      .doc(id)
      .get()
    if (!document.exists) {
      throw new Error("No entity exists with the ID provided")
    }
    return userEntityFactoryFromDoc(document)
  }

  async getUserEntityByName(name, type) {
    const snapshot = await this.firebase.db
      .collection("userEntities")
      .where('label', '==', name)
      .where('type', '==', type)
      .get()
    if (snapshot.empty) {
      return null
    }
    return userEntityFactoryFromDoc(snapshot.docs[0])
  }

  async getUserEntityByCode(code, type) {
    const snapShot = await this.firebase.db
      .collection("userEntities")
      .where('id', '==', code)
      .get()
    if (snapShot.empty) {
      return null
    }
    let entity = null
    snapShot.forEach(doc => {
      if (doc.data().type === type) {
        entity = userEntityFactoryFromDoc(doc)
      }
    })
    return entity
  }

  async getUserEntityByCodeWithHash(code, type, hash) {
    if (Object.prototype.hasOwnProperty.call(hash, `${type}:${code}`)) {
      return hash[`${type}:${code}`]
    }
    const entity = await this.getUserEntityByCode(code, type)
    hash[`${type}:${code}`] = entity
    return entity
  }

  /**
   * Saves a user entity.
   *
   * @param {UserEntity} entity
   *   The user entity to save.
   *
   * @return {Promise<UserEntity>}
   */
  async saveUserEntity(entity) {
    if (!entity.docId) {
      const result = await this.firebase.db
        .collection("userEntities")
        .add(entity.getData())
      entity.docId = result.id
    } else {
      await this.firebase.db
        .collection("userEntities")
        .doc(entity.docId)
        .update(entity.getData())
    }
    return entity
  }

  /**
   * Deletes a user entity.
   *
   * @param {UserEntity} entity
   *   The user entity to delete.
   *
   * @return {Promise<void>}
   */
  async deleteUserEntity(entity) {
    if (!entity.docId) {
      return
    }
    await this.firebase.db
      .collection("userEntities")
      .doc(entity.docId)
      .delete()
  }

  /**
   * The default search gets all the users from `users` collection.
   *
   * @param {number} limit
   *   The limit of results.
   * @param {{}|null} cursor
   *   The current cursor to get results after.
   * @param {[]|null} sort
   *   The orderBy sort arguments.
   *
   * @return {Promise<[[], null]|[*[], null]>}
   */
  async getAllUsers(limit, cursor, sort) {
    if (!sort) {
      sort = [this.firebase.app.firestore.FieldPath.documentId()]
    }
    const usersSnapshotBase = this.firebase.db
      .collection('memberships')
      .orderBy(...sort)
    let usersSnapshotQuery
    if (cursor) {
      usersSnapshotQuery = usersSnapshotBase
        .startAfter(cursor)
        .limit(limit + 1)
    }
    else {
      usersSnapshotQuery = usersSnapshotBase
        .limit(limit + 1)
    }
    const usersSnapshot = await usersSnapshotQuery.get()
    return await this.buildUserDataFromSnapshot(usersSnapshot, limit)
  }

  /**
   * Gets users from `users` collection with the given email.
   *
   * @param {string} email
   *   User email to search.
   * @param {number} limit
   *   The limit of results.
   * @param {{}|null} cursor
   *   The current cursor to get results after.
   *
   * @return {Promise<[[], null]|[*[], null]>}
   */
  async getUsersByEmail(email, limit, cursor = null) {
    const usersSnapshot = await this.firebase.db
      .collection('users')
      .where('email', '==', email)
      .limit(limit + 1)
      .get()
    return await this.buildUserDataFromSnapshot(usersSnapshot, limit)
  }

  /**
   * Gets users from `users` collection with names sent, empty names will be skipped.
   *
   * @param {string|null} firstName
   *   The user's first name.
   * @param {string|null} lastName
   *   The user's last name.
   * @param {number} limit
   *   The limit of results.
   * @param {{}|null} cursor
   *   The current cursor to get results after.
   *
   * @return {Promise<*[][]|[[], null]|[*[], null]>}
   */
  async getUsersByName(firstName, lastName, limit, cursor = null) {
    if ((firstName === null || firstName.length === 0) && (lastName === null || lastName.length === 0)) {
      return [[], null]
    }
    const usersSnapshotBase = this.firebase.db
      .collection('users')
    let usersSnapshotQuery
    if (firstName) {
      usersSnapshotQuery = usersSnapshotBase.where('firstName', '==', firstName)
    }
    if (lastName) {
      if (usersSnapshotQuery) {
        usersSnapshotQuery = usersSnapshotQuery.where('lastName', '==', lastName)
      } else {
        usersSnapshotQuery = usersSnapshotBase.where('lastName', '==', lastName)
      }
    }
    usersSnapshotQuery = usersSnapshotQuery.orderBy(this.firebase.app.firestore.FieldPath.documentId())
    if (cursor) {
      usersSnapshotQuery = usersSnapshotQuery
        .startAfter(cursor)
        .limit(limit + 1)
    } else {
      usersSnapshotQuery = usersSnapshotQuery
        .limit(limit + 1)
    }
    const usersSnapshot = await usersSnapshotQuery.get()
    return await this.buildUserDataFromSnapshot(usersSnapshot, limit)
  }

  /**
   * Gets users from the `schoolData` collection based on field sent.
   *
   * @param {string} fieldName
   *   The name of the field.
   * @param {string} fieldValue
   *   The value to search for.
   * @param {number} limit
   *   The limit of results.
   * @param {{}|null} cursor
   *   The current cursor to get results after.
   *
   * @return {Promise<[[], null]|[*[], null]>}
   */
  async getUsersBySchoolDataField(fieldName, fieldValue, limit, cursor = null) {
    const usersSnapshotBase = this.firebase.db
      .collection('schoolData')
      .where(fieldName, '==', fieldValue)
      .orderBy(this.firebase.app.firestore.FieldPath.documentId())
    let usersSnapshotQuery
    if (cursor) {
      usersSnapshotQuery = usersSnapshotBase
        .startAfter(cursor)
        .limit(limit + 1)
    }
    else {
      usersSnapshotQuery = usersSnapshotBase
        .limit(limit + 1)
    }
    const usersSnapshot = await usersSnapshotQuery.get()
    return await this.buildUserDataFromSnapshot(usersSnapshot, limit)
  }

  /**
   * Gets users from the `memberships` collection based on field sent.
   *
   * @param {string} fieldName
   *   The name of the field.
   * @param {string} fieldValue
   *   The value to search for.
   * @param {number} limit
   *   The limit of results.
   * @param {[]|null} sort
   *   The `orderBy` sort options.
   * @param {{}|null} cursor
   *   The current cursor to get results after.
   *
   * @return {Promise<[[], null]|[*[], null]>}
   */
  async getUsersByMembershipDataFieldWithSort(fieldName, fieldValue, limit, sort, cursor = null) {
    if (!sort) {
      sort = [this.firebase.app.firestore.FieldPath.documentId()]
    }
    const usersSnapshotBase = this.firebase.db
      .collection('memberships')
      .where(fieldName, '==', fieldValue)
      .orderBy(...sort)
    let usersSnapshotQuery
    if (cursor) {
      usersSnapshotQuery = usersSnapshotBase
        .startAfter(cursor)
        .limit(limit + 1)
    }
    else {
      usersSnapshotQuery = usersSnapshotBase
        .limit(limit + 1)
    }
    const usersSnapshot = await usersSnapshotQuery.get()
    return await this.buildUserDataFromSnapshot(usersSnapshot, limit)
  }

  /**
   * Gets the user by type in `memberships` collection.
   *
   * @param {string} type
   *   The user type.
   * @param {number} limit
   *   The limit of results.
   * @param {[]|null} sort
   *   The orderBy sort arguments.
   * @param {{}|null} cursor
   *   The current cursor to get results after.
   *
   * @return {Promise<[[], null]|[*[], null]>}
   */
  async getUsersByType(type, limit, sort, cursor = null) {
    if (!sort) {
      sort = [this.firebase.app.firestore.FieldPath.documentId()]
    }
    const usersSnapshotBase = this.firebase.db
      .collection('memberships')
      .where('membershipType', '==', type)
      .orderBy(...sort)
    let usersSnapshotQuery
    if (cursor) {
      usersSnapshotQuery = usersSnapshotBase
        .startAfter(cursor)
        .limit(limit + 1)
    }
    else {
      usersSnapshotQuery = usersSnapshotBase
        .limit(limit + 1)
    }
    const usersSnapshot = await usersSnapshotQuery.get()
    return await this.buildUserDataFromSnapshot(usersSnapshot, limit)
  }

  /**
   * Gets the users created since the date sent.
   *
   * @param {Date} date
   *   The date since to search for.
   * @param {number} limit
   *   The limit of results.
   * @param {'asc'|'desc'} sort
   *   The sort applied to the created date.
   * @param {{}|null} cursor
   *   The current cursor to get results after.
   *
   * @return {Promise<[[], null]|[*[], null]>}
   */
  async getUsersBySinceCreatedDate(date, limit, sort, cursor = null) {
    const usersSnapshotBase = this.firebase.db
      .collection('memberships')
      .where('createdDate', '>=', date)
      .orderBy('createdDate', sort)
    let usersSnapshotQuery
    if (cursor) {
      usersSnapshotQuery = usersSnapshotBase
        .startAfter(cursor)
        .limit(limit + 1)
    }
    else {
      usersSnapshotQuery = usersSnapshotBase
        .limit(limit + 1)
    }
    const usersSnapshot = await usersSnapshotQuery.get()
    return await this.buildUserDataFromSnapshot(usersSnapshot, limit)
  }

  /**
   * Gets users by expiration status.
   *
   * @param {string} status
   *   The status of the user.
   * @param {number} limit
   *   The limit of results.
   * @param {string|null} sort
   *   The date sort, for users who never expire this is ignored.
   * @param {{}|null} cursor
   *   The current cursor to get results after.
   *
   * @return {Promise<[[], null]|[*[], null]>}
   */
  async getUsersByExpiration(status, limit, sort, cursor = null) {
    let operation
    let value
    let sortArgs
    const currentTime = new Date()
    if (expirationType.ACTIVE === status) {
      operation = '>'
      value = currentTime
      sortArgs = ['expiresDate', sort]
    }
    if (expirationType.INACTIVE === status) {
      operation = '<='
      value = currentTime
      sortArgs = ['expiresDate', sort]
    }
    if (expirationType.NEVER === status) {
      operation = '=='
      value = ''
      sortArgs = [this.firebase.app.firestore.FieldPath.documentId()]
    }
    const usersSnapshotBase = this.firebase.db
      .collection('memberships')
      .where('expiresDate', operation, value)
      .orderBy(...sortArgs)
    let usersSnapshotQuery
    if (cursor) {
      usersSnapshotQuery = usersSnapshotBase
        .startAfter(cursor)
        .limit(limit + 1)
    }
    else {
      usersSnapshotQuery = usersSnapshotBase
        .limit(limit + 1)
    }
    const usersSnapshot = await usersSnapshotQuery.get()
    return await this.buildUserDataFromSnapshot(usersSnapshot, limit)
  }

  /**
   * Gets users linked to the school/org name.
   *
   * @param {string} name
   *   The entity name.
   * @param {string} type
   *   The entity type.
   * @param {number} limit
   *   The limit of results.
   * @param {string[]|null} sort
   *   The current sort value.
   * @param {{}|null} cursor
   *   The current cursor to get results after.
   *
   * @return {Promise<*[][]|(*[]|null)[]|(*[]|null)[]>}
   */
  async getUsersByEntityName(name, type, limit, sort, cursor = null) {
    const entity = await this.getUserEntityByName(name, type)
    if (!entity) {
      return [[], null]
    }
    if (type === userEntityType.SCHOOL) {
      return await this.getUsersByMembershipDataFieldWithSort('schcode', entity.id, limit, sort, cursor)
    }
    if (type === userEntityType.ORGANIZATION) {
      return await this.getUsersByMembershipDataFieldWithSort('organization', entity.id, limit, sort, cursor)
    }
    return [[], null]
  }

  /**
   * Builds user data from the query snapshot.
   *
   * @param usersSnapshot
   *   The query snapshot.
   * @param {number} limit
   *   The limit of results.
   *
   * @return {Promise<*[][]>}
   */
  async buildUserDataFromSnapshot(usersSnapshot, limit) {
    if (usersSnapshot.empty) {
      return [[], null]
    }
    const promises = []
    const users = []
    const queryCount = usersSnapshot.size
    let doNextRecordExists = false
    if (queryCount === limit + 1) {
      doNextRecordExists = true
    }
    let userSnapshot = null
    let iteratorCount = 1
    const entityHash = {}
    usersSnapshot.forEach((doc) => {
      if (iteratorCount === limit + 1) {
        return
      }
      const uid = doc.id
      const user = new User(uid)
      users.push(user)
      promises.push(this.buildUser(uid, user, entityHash))
      if (doNextRecordExists && iteratorCount === limit) {
        userSnapshot = doc
      }
      iteratorCount++
    })
    await Promise.all(promises)
    return [users, userSnapshot]
  }

  async  getUserEntitiesBySchoolCodes(schoolCodes) {
    const entities = [];
    for (const code of schoolCodes) {
        const school = schoolOrganizationOptions.find(option => option.value === code);
        if (school) {
            entities.push(school.text);
        }
    }
    return entities;
}

  /**
   * Builds user data from uid sent.
   *
   * @param {string} uid
   *   The uid of the user.
   * @param {User} user
   *   The user model.
   * @param {{}} hash
   *   The entity hash.
   *
   * @return {Promise<void>}
   */
  async buildUser(uid, user, hash) {
    await this.getProfileData(uid, user)
    await this.getSchoolData(uid, user)
    if (user.schcode) {
      const entity = await this.getUserEntityByCodeWithHash(user.schcode, 'school', hash)
      if (entity) {
        user.schoolName = entity.label
      }
    }
    if (user.organizationCode) {
      const entity = await this.getUserEntityByCodeWithHash(user.organizationCode, 'organization', hash)
      if (entity) {
        user.organizationName = entity.label
      }
    }
    if (user.schCodes) {
      user.schCodesName = await this.getUserEntitiesBySchoolCodes(user.schCodes)
    }
    await this.getCareersData(uid, user)
    await this.getUserPoints(uid, user)
    await this.getUserMembership(uid, user)
    await this.getAssessmentData(uid, user)
  }

  /**
   * Gets data for user form the `schoolData` collection.
   *
   * @param {string} uid
   *   The uid of the user.
   * @param {User} user
   *   The user model.
   *
   * @return {Promise<void>}
   */
  async getSchoolData(uid, user) {
    const schoolDataSnapshot = await this.firebase.db
      .collection('schoolData')
      .doc(uid)
      .get()
    if (!schoolDataSnapshot.exists) {
      return
    }
    const data = schoolDataSnapshot.data()
    user.schcode = data.schcode
    user.organizationCode = data.organization
    user.distcode = data.distcode
    user.grade = data.grade
    user.lasid = data.lasid
    user.sasid = data.sasid
  }

  /**
   * Gets user data from the `memberships` collection.
   *
   * @param {string} uid
   *   The user uid.
   * @param {User} user
   *   The user model.
   *
   * @return {Promise<void>}
   */
  async getUserMembership(uid, user) {
    const membershipSnapshot = await this.firebase.db
      .collection('memberships')
      .doc(uid)
      .get()
    if (!membershipSnapshot.exists) {
      return
    }
    const data = membershipSnapshot.data()
    if (data.expiresDate) {
      user.expirationDate = data.expiresDate.toDate()
    }
    if (data.promoCode) {
      user.promoCode = data.promoCode
    }
    if (data.createdDate && data.createdDate.toDate) {
      user.createdDate = data.createdDate.toDate()
    }
    user.userType = data.membershipType
  }

  /**
   * Gets user's point data.
   *
   * @param {string} uid
   *   The uid of the user.
   * @param {User} user
   *   The user model.
   *
   * @return {Promise<void>}
   */
  async getUserPoints(uid, user) {
    const pointSnapshot = await this.firebase.db
      .collection("pointTotals")
      .doc(uid)
      .get()
    if (!pointSnapshot.exists) {
      return
    }
    const data = pointSnapshot.data()
    user.pointsCount = parseInt(data.pointTotal)
  }

  /**
   * Gets the data from `users` collection.
   *
   * @param {string} uid
   *   The uid of the user.
   * @param {User} user
   *   The user model.
   *
   * @return {Promise<void>}
   */
  async getProfileData(uid, user) {
    const profileSnapshot = await this.firebase.db
      .collection("users")
      .doc(uid)
      .get()
    if (!profileSnapshot.exists) {
      return
    }
    const profile = profileSnapshot.data()
    if (profile.firstName) {
      user.firstName = profile.firstName
    }
    if (profile.lastName) {
      user.lastName = profile.lastName
    }
    if (!user.email) {
      user.email = profile.email
    }
    if (profile.createdDate && profile.createdDate.toDate) {
      user.createdDate = profile.createdDate.toDate()
    }
    if (profile.lastLoginDate && profile.lastLoginDate.toDate) {
      user.lastLoginDate = profile.lastLoginDate.toDate()
    }
    user.doPortfolio = Boolean(profile.myStrengths || profile.myWeaknesses || profile.aboutMe || profile.profileQuote)
    if (profile.skills && Array.isArray(profile.skills)) {
      user.skillCount = profile.skills.length
      user.skills = profile.skills
    }
    if (profile.interests && Array.isArray(profile.interests)) {
      user.interests = profile.interests
      user.interestCount = profile.interests.length
    }
    user.doMyGoal = Boolean(profile.goals)
    user.doLookingForward = Boolean(profile.lookingForwardTo)
    const setBookmarkData = (bookmarks, type, titlesMethod) => {
      if (!bookmarks) {
        return 0
      }
      const bookmarkValues = Object.values(bookmarks)
      if (!Array.isArray(bookmarkValues)) {
        return 0
      }
      return bookmarkValues.filter(
        (bookmark) => {
          if (bookmark.contentType === type) {
            if (bookmark.contentTitle) {
              user[titlesMethod] = bookmark.contentTitle
            }
            return true
          } else {
            return false
          }
        }
      ).length
    }
    user.bookmarkStoryCount = setBookmarkData(profile.bookmarks, "stories", "bookmarkStoryTitles")
    user.bookmarkBlogCount = setBookmarkData(profile.bookmarks, "blogs", "bookmarkBlogTitles")
    user.bookmarkMajorCount = setBookmarkData(profile.bookmarks, "majors", "bookmarkMajorTitles")
    user.bookmarkSchoolCount = setBookmarkData(profile.bookmarks, "schools", "bookmarkSchoolTitles")
    user.bookmarkCareerCount = setBookmarkData(profile.bookmarks, "careers", "bookmarkCareerTitles")
    if (profile.city) {
      user.city = profile.city
    }
    if (profile.state) {
      user.state = profile.state
    }
    if (profile.loginCount) {
      user.loginCount = profile.loginCount
    }
    if (profile.role) {
      user.role = profile.role
    }
    if (profile.schCodes) {
      user.schCodes = profile.schCodes
    }
  }

  /**
   * Gets data from `userCareerPlans` collection.
   *
   * @param {string} uid
   *   The user uid.
   * @param {User} user
   *   The user model.
   *
   * @return {Promise<void>}
   */
  async getCareersData(uid, user) {
    const userCareerPlanSnapshot = await this.firebase.db
      .collection('userCareerPlans')
      .where("userId", "==", uid)
      .get()
    if (!userCareerPlanSnapshot.empty) {
      user.careerPlanCount = userCareerPlanSnapshot.size
    }
  }

    /**
   * Gets data from `assessments` collection.
   *
   * @param {string} uid
   *   The user uid.
   * @param {User} user
   *   The user model.
   *
   * @return {Promise<void>}
   */
  async getAssessmentData(uid, user) {
    const userAssessmentSnapshot = await this.firebase.db
      .collection('assessments')
      .doc(uid)
      .get()

    // default
    user.assessmentTaken = false;

    if (userAssessmentSnapshot.exists) {
      const assessmentData = userAssessmentSnapshot.data()
      // It is possible that the user has started the assessment without yet finishing,
      // so check for the presence of a result profile to indicate assessment is complete.
      if (assessmentData.resultProfile) {
        user.assessmentTaken = true
        // This is the object with all score data in case we want to output
        // it to a single column.
        user.assessmentResultProfile = assessmentData.resultProfile

        // Separates each of the RIASEC values into user properties so each
        // can have a separate column if exported.
        const setAssessmentResults = (data) => {
          for (const personalityFacet in data) {
            user[personalityFacet] = data[personalityFacet]
          }
        }

        setAssessmentResults(assessmentData.resultProfile)
      }
    }
  }

  /**
   * Deletes the user using the deletion function.
   *
   * This deletes the user from all collections.
   *
   * @param {string} uid
   *
   * @return {Promise<*>}
   */
  async deleteUser(uid) {
    const deleteUser = this.firebase.functions.httpsCallable(
      'deleteUser'
    )
    return await deleteUser({ userId: uid })
  }

  /**
   * Gets the total membership docs from the counts collection.
   *
   * @return {Promise<number>}
   */
  async getTotalUsers() {
    const countSnapshot = await this.firebase.db
      .collection('counts')
      .doc('memberships')
      .get()
    if (countSnapshot.exists) {
      return countSnapshot.data().count
    }
    return 0
  }

  async modifyOnUser (data) {
    try {
      const onUpdateUserCallable = this.firebase.functions.httpsCallable("onboardingSuperAdmin")
      const onUpdateUserResult = await onUpdateUserCallable(data)
    return onUpdateUserResult
    } catch (error) {
      return error
    }
  }

}
