import React, { useContext, useEffect, useState } from "react"
import {
  Accordion,
  AccordionContext,
  Button,
  Card,
  Modal,
  useAccordionToggle,
} from "react-bootstrap"
import { useLocation } from "@reach/router"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ReactPlayer from "react-player"
import { StaticImage } from "gatsby-plugin-image"
import ProfileImage from "../profile-image/profile-image"
import Flyout from "../flyout/flyout"
import "./get-started.css"
import getStartedData from "./get-started-data"
import getStartedDataEs from "./get-started-data.es"
import { Link } from "gatsby"
import ProgressCircle from "../progress-circle/progress-circle"
import { calculateCompletionPercent } from "./utils/utils"
import { faFile, faPlayCircle } from "@fortawesome/free-solid-svg-icons"
import ExpandIcon from "../../../static/assets/images/expand-icon.svg"
import MinimizeIcon from "../../../static/assets/images/minimize-icon.svg"
import ArrowUpIcon from "../../../static/assets/images/arrow-up.svg"
import ArrowDownIcon from "../../../static/assets/images/arrow-down.svg"
import CloseIcon from "../../../static/assets/images/closeIcon.svg"
import { FormattedMessage, useIntl } from "react-intl"

function VideoModal(props) {
  const { videoUrl, title, ...restProps } = props
  return (
    <Modal
      className="get-started-video-modal"
      backdropClassName="get-started-video-backdrop"
      {...restProps}
      centered
      size="xl"
    >
      <Modal.Header closeButton />
      <div className="video-wrapper">
        <ReactPlayer
          url={videoUrl}
          controls={true}
          playing
          width="100%"
          height="100%"
          light
          playIcon={
            <>
              <button className="button component-button component-button-video component-button-video-large">
                <FontAwesomeIcon className="play-video" icon={faPlayCircle} />
                <FormattedMessage
                  id="get-started-watch-video"
                  defaultMessage="Watch Video"
                />
              </button>
            </>
          }
        />
      </div>
    </Modal>
  )
}

function Toggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext)

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  )

  const isCurrentEventKey = currentEventKey === eventKey

  return (
    <Button variant="link" onClick={decoratedOnClick}>
      {children}
      <img src={isCurrentEventKey ? ArrowUpIcon : ArrowDownIcon} />
    </Button>
  )
}

function GetStartedFlyout({ profile, show, setShow, stepsData, onStepChange }) {
  const location = useLocation()
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [showVideoModal, setShowVideoModal] = useState(false)
  const [videoForStep, setVideoForStep] = useState(null)
  const profileProgress = calculateCompletionPercent(stepsData)
  const { locale } = useIntl()

  useEffect(() => {
    setShow(false)
  }, [location])

  function handleFullscreenToggle() {
    setIsFullScreen(!isFullScreen)
  }

  function handleStepChanged(stepKey) {
    onStepChange(stepKey)
  }

  function handleOnClose() {
    setShow(false)
  }

  function handleVideoTutorialClick(step) {
    setVideoForStep(step)
    setShowVideoModal(true)
  }

  function handleVideoModalClose() {
    setVideoForStep(null)
    setShowVideoModal(false)
  }

  return (
    <>
      <Flyout show={show} setShow={setShow} isFullScreen={isFullScreen}>
        <div className="get-started-flyout">
          <div className="fly-out-header">
            <div className="header-content-container">
              <div className="full-screen-toggle">
                <button
                  variant="link"
                  className="full-screen-button"
                  onClick={handleFullscreenToggle}
                >
                  <img src={isFullScreen ? MinimizeIcon : ExpandIcon} />
                </button>
              </div>
              <div className="profile-image-wrapper">
                <ProfileImage profile={profile} />
              </div>
              <div className="profile-name-container">
                {profile && profile.firstName && (
                  <div className="profile-name">
                    {profile.firstName} {profile.lastName ?? ""}
                  </div>
                )}
                <div className="get-started"><FormattedMessage
                  id="get-started-get-started"
                  defaultMessage="Get Started"
                /></div>
              </div>
            </div>
            <div className="close-container">
              <button onClick={handleOnClose} className="close-button">
                <img src={CloseIcon} />
              </button>
            </div>
          </div>
          <div className="flyout-scroll-area">
            <div className="fly-out-banner">
              <div className="percent-complete-section">
                <div>
                  <ProgressCircle progressPercentage={profileProgress} />
                </div>
                <div className="progress-text">{profileProgress}% <FormattedMessage
                  id="get-started-complete"
                  defaultMessage="Complete"
                /></div>
              </div>
              <div className="banner-header"><FormattedMessage
                id="get-started-wingspans-checklist"
                defaultMessage="Wingspans Checklist"
              /></div>
            </div>

            <div className="flyout-content">
              <div className="content-header-text"><FormattedMessage
                id="get-started-welcome-start-here"
                defaultMessage="Welcome! Start here"
              /></div>
              <Accordion>
                {stepsData && (locale !== 'es-ES' && getStartedDataEs ? getStartedData : getStartedDataEs).map((row, index) => {
                  let hasVideoOrPdf =
                    row.videoTutorialLink || row.pdfTutorialLink
                  return (
                    <Card key={index}>
                      <Card.Header>
                        <div>
                          <div className="checkbox-container">
                            <input
                              value={row.stepKey}
                              type="checkbox"
                              checked={!!stepsData[row.stepKey]}
                              className="custom-checkbox"
                              onChange={() => handleStepChanged(row.stepKey)}
                            />
                            <Toggle eventKey={index.toString()}>
                              <div className="title">{row.title}</div>
                            </Toggle>
                          </div>
                        </div>
                      </Card.Header>
                      <Accordion.Collapse eventKey={index.toString()}>
                        <Card.Body>
                          <div className="card-body-container">
                            <div className="row">
                              <div
                                className={`content-container ${hasVideoOrPdf ? "col-sm-7" : "col-sm"
                                  }`}
                              >
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: row.content,
                                  }}
                                ></div>
                                {row.buttonLink && (
                                  <Link to={row.buttonLink}>
                                    <button
                                      type="button"
                                      className="action-button button component-button component-button-default comopnent-button-ghost"
                                    >
                                      {row.buttonText}
                                    </button>
                                  </Link>
                                )}
                              </div>
                              {hasVideoOrPdf && (
                                <>
                                  <div className="col-"></div>
                                  <div className="col-sm p-0">
                                    <div className="video-doc-container container-fluid v-divider">
                                      {row.videoTutorialLink && (
                                        <div className="row align-items-center">
                                          <div className="video col-">
                                            <button
                                              className="button component-button-video-large"
                                              onClick={() =>
                                                handleVideoTutorialClick(row)
                                              }
                                              style={{
                                                background: "transparent",
                                              }}
                                            >
                                              <FontAwesomeIcon
                                                className="play-video"
                                                icon={faPlayCircle}
                                              />
                                            </button>
                                          </div>
                                          {row.videoTutorialText && (
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html: row.videoTutorialText,
                                              }}
                                              className="video-text col"
                                            ></div>
                                          )}
                                        </div>
                                      )}
                                      {row.pdfTutorialLink && (
                                        <div
                                          className={`row align-items-center ${row.videoTutorialLink
                                            ? "pt-3"
                                            : ""
                                            }`}
                                        >
                                          <div className="pdf col-">
                                            <a
                                              href={row.pdfTutorialLink}
                                              target="_blank"
                                            >
                                              <FontAwesomeIcon
                                                className="view-doc"
                                                icon={faFile}
                                              />
                                            </a>
                                          </div>
                                          {row.pdfTutorialText && (
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html: row.pdfTutorialText,
                                              }}
                                              className="pdf-text col"
                                            ></div>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  )
                })}
              </Accordion>
            </div>
          </div>
        </div>
      </Flyout>
      {videoForStep && (
        <VideoModal
          videoUrl={videoForStep.videoTutorialLink}
          title={videoForStep.title}
          show={showVideoModal}
          onHide={handleVideoModalClose}
        />
      )}
    </>
  )
}

export default GetStartedFlyout
