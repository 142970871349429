import React from "react"

function ProgressCircle({
  progressPercentage = 0,
  size = "46px",
  circleColor = "var(--color-ccff00)",
  backgroundColor = "var(--color-00b8ef)",
}) {
  return (
    <svg
      className="progress-circle"
      height={size}
      width={size}
      viewBox="-1 -1 20 23"
    >
      <circle
        r="10"
        cx="10"
        cy="10"
        fill={backgroundColor}
        stroke={circleColor}
      />

      <circle
        r="5"
        cx="10"
        cy="10"
        fill="transparent"
        stroke={circleColor}
        strokeWidth="10"
        strokeDasharray={`calc(${progressPercentage} * 31.4px / 100) 31.4`}
        transform="rotate(-90) translate(-20)"
      />
    </svg>
  )
}

export default ProgressCircle
