import { useState } from 'react'

const useModal = () => {
    const [isShowing, setIsShowing] = useState(false);

    function toggle(state) {
        // explicitly set state if needed, else toggle normally
        if (typeof state === "boolean") {
            setIsShowing(state)
        } else {
            setIsShowing(!isShowing);
        }
    }

    return [
        isShowing,
        toggle
    ];
}

export default useModal;