import SchoolList from "./SchoolList";

/**
 * Provides a base class for major data from firestore.
 */
export default class Major {

  /**
   * If the major is offered as a doctorate degree.
   *
   * @return {boolean}
   */
  get doctorate() {
    return this._doctorate;
  }

  /**
   * If the major is offered as a master's degree.
   *
   * @return {boolean}
   */
  get master() {
    return this._master;
  }

  /**
   * The name of the CIP group of the major.
   *
   * @return {string|null}
   */
  get cipGroupName() {
    return this._cipGroupName;
  }

  /**
   * Gets the CIP group two digit code.
   *
   * @return {string|null}
   */
  get cipGroup() {
    return this._cipGroup;
  }

  /**
   * Gets the CIP description provided by school standards.
   *
   * @return {string|null}
   */
  get cipDescription() {
    return this._cipDescription;
  }

  /**
   * If the major is offered by a technical school.
   *
   * @return {boolean}
   */
  get technical() {
    return this._technical;
  }

  /**
   * If the major is offered by a graduate school.
   *
   * @return {boolean}
   */
  get graduate() {
    return this._graduate;
  }

  /**
   * If the major is offered as a bachelor's degree.
   *
   * @return {boolean}
   */
  get bachelor() {
    return this._bachelor;
  }

  /**
   * If the major is offered as an associate's degree.
   *
   * @return {boolean}
   */
  get associate() {
    return this._associate;
  }

  /**
   * The major's CIP code (these are currently 2020 standard).
   *
   * @return {string}
   */
  get id() {
    return this._id;
  }

  /**
   * Gets the major's name.
   *
   * @return {string}
   */
  get name() {
    return this._name;
  }

  /**
   * Gets the list of schools offering the major.
   *
   * @see SchoolList
   * *This may change and be removed if large numbers of schools causes an issue.*
   *
   * @return {SchoolList|null}
   */
  get schools() {
    return this._schools;
  }

  /**
   * Major constructor.
   *
   * @param firestoreData
   *  The firestore data.
   */
  constructor(firestoreData) {
    this._name = firestoreData?.name;
    this._bachelor = firestoreData?.bachelor;
    this._associate = firestoreData?.associate;
    this._id = firestoreData?.id;
    this._graduate = firestoreData?.graduate;
    this._master = firestoreData?.master;
    this._doctorate = firestoreData?.doctorate;
    this._technical = firestoreData?.technical;
    this._cipDescription = firestoreData?.cipDescription;
    this._cipGroup = firestoreData?.cipGroup;
    this._cipGroupName = firestoreData?.cipGroupName;
    this._schools = firestoreData?.schools ? new SchoolList(firestoreData?.schools) : null;
  }
}
