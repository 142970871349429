const questionData = {
  version: 1.4,
  questions: [
    {
      question: "Like Julia Xu, I enjoy leading product development.",
      personality_type: "personality_type_enterprising",
      story:
        'As the founder of Multitasky, Julia Xu leads product development.\n\nShe says, "Multitasky is a trendy lifestyle brand that makes functional, aesthetic, and affordable workspace products to support modern working women and students. Our products are more than regular stationary like notebooks or pens; we use a lot of technology, so our products consist of a lot of phone and laptop accessories, as well as gadgets for kitchens and bathrooms."',
      name: "Julia Xu",
      career: "Founder of Multitasky",
      image:
        "https://imgix.cosmicjs.com/e3fcf210-f349-11ec-a2eb-c1653f3f9199-Julia-59-2-sm.jpeg?w=294&h=294&fit=crop&crop=faces",
    },
    {
      question:
        "Like Eli Bronstein, I enjoy mathematical or scientific activities. ",
      personality_type: "personality_type_investigative",
      story:
        'As a software engineer for Waymo, the Google self driving car company, Eli Bronstein engages in mathematical activities.\n\nHe says, "Once you understand the rules of how to write code, you can really do anything with it. For example, one of the years that I was doing the computer science elective, I was interested in game development with Python. So I followed this book that had various tutorials for how to make different games, and it was really cool to see your code come to life."',
      name: "Eli Bronstein",
      career: "Research Software Engineer for Waymo",
      image:
        "https://imgix.cosmicjs.com/6fd11060-0578-11ed-b7be-d956591ad437-waymosecuritypicwhitebackground.png?w=294&h=294&fit=crop&crop=faces",
    },
    {
      question: "Like Jamal Davis, I enjoy operating machines.",
      personality_type: "personality_type_realistic",
      story:
        'Jamal Davis is a Certified Machine Operator who runs a cutter machine.\n\nHe says, "For people who are interested in being a machine operator, my advice would be, don’t just be a button pusher, someone who just knows this button does this, and this button does that. Take the time to learn how to operate a machine, to understand what the machine is actually producing. Know its true purpose."',
      name: "Jamal Davis",
      career: "Machine Operator",
      image:
        "https://imgix.cosmicjs.com/c7a7de60-f523-11eb-bf0b-e5e9ea5f18c4-image2-1.jpeg?w=294&h=294&fit=crop&crop=faces",
    },
    {
      question: "Like Grace Kim, I enjoy responding in the the moment.",
      personality_type: "personality_type_artistic",
      story:
        "As an event florist, focusing on weddings, Grace Kim needs to respond in the moment.\n\nShe says, \"I also like that because I am working with nature, there are things outside of my control. Sometimes I'll have a specific type of flower I'd like to use and find out it didn't come in nicely or it isn't available at all, but then I'll go to the market and find something I hadn't thought of that looks amazing and ends up working out perfectly! I like that I have to work with the season and use what nature offers you.\"",
      name: "Grace Kim",
      career: "Wedding and Event Florist",
      image:
        "https://imgix.cosmicjs.com/41da7e30-453a-11eb-ae0c-07d0b084405e-Grace-Kim-2.png?w=294&h=294&fit=crop&crop=faces",
    },
    {
      question: "Like Erica Stanzione, I like teaching people new skills.",
      personality_type: "personality_type_social",
      story:
        'As a yoga teacher and leader of a yoga school teacher training program, Erica Stanzione teaches people new skills.\n\nShe says, "My favorite part is when you finish a class, and the students have the biggest smiles. You can sense their energy, the aura, and how light they feel. You can see the confidence in their postures. It\'s incredibly rewarding to witness their journeys – their healing, wisdom, and confidence. They leave class exuding real joy."',
      name: "Erica Stanzione",
      career: "Yoga Teacher",
      image:
        "https://imgix.cosmicjs.com/32135e30-cb13-11ec-aaa1-f1e7c930cb45-Erica-photo-1.jpg?w=294&h=294&fit=crop&crop=faces",
    },
    {
      question: "Like Erminio Pinque, I thrive in the unknown.",
      personality_type: "personality_type_artistic",
      story:
        'As the Artistic Director of the Big Nazo Lab, a world-reknowned performance group, Erminio Pinque thrives in the unknown.\n\nHe says, "Puppetry, modelmaking, storytelling, performance--these are all creative fields that make up their own rules.  We wrap all of these professions into one. The result is unpredictable and entertaining."',
      name: "Erminio Pinque",
      career: "Artistic Director",
      image:
        "https://imgix.cosmicjs.com/bde6d980-4538-11eb-ae0c-07d0b084405e-1f83ef3daf2adf55d5ff04e327e32622_Erminio-copy.png?w=294&h=294&fit=crop&crop=faces",
    },
    {
      question: "Like Irena Asmundson, I like being in a position of authority.",
      personality_type: "personality_type_enterprising",
      story:
        'As Chief Economist of Califormia, Irena Asmundson was in a position of authority, responsible for the California economy. \n\nShe says, "As Chief Economist of California, I spent a lot of time with my team talking about the latest economic data. There was always some sort of research project going on about where we predicted the economy would move towards in the future." ',
      name: "Irena Asmundson",
      career: "Former Chief Economist for the State of California",
      image:
        "https://imgix.cosmicjs.com/b0f9c8d0-05db-11ed-b7be-d956591ad437-Irena-Asmundson.jpeg?w=294&h=294&fit=crop&crop=faces",
    },
    {
      question:
        "Like Sherry Harmon, I like work that requires me to be detail-oriented.",
      personality_type: "personality_type_conventional",
      story:
        'As a runner in the main pharmacy at the Robert Wood Johnson Hospital, Sherry Harmon is required to be detail-oriented.\n\nShe says, "The runner takes the medication up to the floors and inputs the medication into the medication cabinets. You make sure the medication goes in the correct pocket and in the correct machine. If it\'s not in the correct machine, or if somebody pulls it out of the wrong pocket and gives it to the patient, that could be trouble. It’s important to pay attention to what you’re doing."',
      name: "Sherry Harmon",
      career: "Certified Pharmacy Technician",
      image:
        "https://imgix.cosmicjs.com/9be03630-34ff-11ec-9419-c99768f4956b-20210928212950.jpg?w=294&h=294&fit=crop&crop=faces",
    },
    {
      question:
        "Like Kimberly Domenick, I like carrying out tasks for my clients.",
      personality_type: "personality_type_conventional",
      story:
        'As a UX Engineer, which stands for User Experience Engineer, Kimberly Domenick carries out tasks for her clients.\n\nShe says, "My typical day is often working with client requests to either add or update some sort of functionality related to the front end of their site...We might have a client tell us,"When I mouse over this button, it doesn\'t look right. Make it look better." Or sometimes they\'ll say, "I like the way this looks but I want to change it. I want to redesign that and that." So I help them do that on a daily basis."',
      name: "Kimberly Domenick",
      career: "UX Engineer",
      image:
        "https://imgix.cosmicjs.com/6bfb2180-cbed-11eb-9a55-ed85c0b4dfd8-Kim-Domenick-5G4A8058.jpg?w=294&h=294&fit=crop&crop=faces",
    },
    {
      question: "Like Carmen Vazquez, I like coaching students.",
      personality_type: "personality_type_social",
      story:
        "As a high school advisor, Carmen Vazquez coaches her students by asking them to tell their story. \n\nShe says, “When I work with my students, I tend to ask them, “Before we even write anything down, what's your story?” I help them realize the potential they have and help them understand that where they come from is, in fact, beautiful. There’s beauty in the struggle. It doesn't define you—it's what you do now that defines you.”",
      name: "Carmen Vazquez",
      career: "High School Advisor",
      image:
        "https://imgix.cosmicjs.com/210bfb90-3808-11ec-8fb9-8fa9f96066ad-pic2.jpg?w=294&h=294&fit=crop&crop=faces",
    },
    {
      question:
        "Like James Kingon, I like helping people with their emotional problems.",
      personality_type: "personality_type_social",
      story:
        'As a psychiatric nurse, James Kingon helps people with their emotional problems, often seeing them improve in five to seven days.\n\nHe says, "A lot of the people we see are very acutely ill. In our hospital, we do short-term stabilization. Most people stay five to seven days. You wouldn’t believe how much someone can change in five to seven days."',
      name: "James Kingon",
      career: "Psychiatric Nurse",
      image:
        "https://imgix.cosmicjs.com/d85614d0-4537-11eb-ae0c-07d0b084405e-alex.jpg?w=294&h=294&fit=crop&crop=faces",
    },
    {
      question: "Like Maezza Romero, I enjoy writing interesting stories.",
      personality_type: "personality_type_artistic",
      story:
        'As a video game designer, Maezza Romero writes stories for the characters in the video game Empire of Sin.\n\nShe says, "An example of a mission is when you say, “Hey, what’s up?” to a character. And the character says to you, “I need you to get me 10 rocks.” And you say, “Okay, cool. I can do that.” And that’s your mission." ',
      name: "Maezza Romero",
      career: "Video Game Designer",
      image:
        "https://imgix.cosmicjs.com/a65ea810-ab99-11ec-bd4d-d755cb3d1844-Maezza-headshot-two.jpg?w=294&h=294&fit=crop&crop=faces",
    },
    {
      question: "Like Lauren Quattrochi, I like performing science experiments.",
      personality_type: "personality_type_investigative",
      story:
        'As a neuroscientist who studies the eye, Lauren Quattrochi uses a microscope to perform science experiments.\n\nShe says, "I study the way your eye connects to your brain. Part of your eye called the retina is an extension out from your brain and is composed of 1-1.5 million retinal ganglion cells...I use an electrophysiology setup to look at the input and output signals that the cell is giving and receiving." ',
      name: "Lauren Quattrochi",
      career: "Neuroscientist",
      image:
        "https://imgix.cosmicjs.com/def77b40-453b-11eb-ae0c-07d0b084405e-a8f2bc3aa98d9ca794fd62df3183fc01_LaurenQuattrochi_MPP_12-20101.jpg?w=294&h=294&fit=crop&crop=faces",
    },
    {
      question: "Like Steve Gacin, I like building things.",
      personality_type: "personality_type_realistic",
      story:
        'As the engineering director for the company SharkNinja, Steve Gacin builds vacuums and kitchen products.\n\nHe says, "Ever since I was a little kid, I was always fascinated with working with my hands, building things, designing things, creating something – I was all part of that. When when I was younger, I was overexcited about certain things. I’d walk around the house, pick something up, think, ‘How does that work?’ I would end up breaking it obviously, because I tore things apart with random screwdrivers, as a means to keep my idle hands busy."',
      name: "Steve Gacin",
      career: "Director of Engineering ",
      image:
        "https://imgix.cosmicjs.com/afff4f10-b11f-11ec-97bc-19d12908cbbe-IMG5396.jpg?w=294&h=294&fit=crop&crop=faces",
    },
    {
      question: "Like Sophie Worthington, I would like to work outdoors.",
      personality_type: "personality_type_realistic",
      story:
        'As a goat herder, Sophie Worthington works outdoors.\n\nShe says, "It’s really getting to spend time with these animals in this beautiful setting. This breed, La Mancha, is super affectionate. Working with them from birth and getting to know all of their personalities. Getting to hang out with them every day. One of my favorite things to do is to take the whole herd on a walk through the hills. They all stay together and browse all the good stuff they find along the way. It’s pretty special."',
      name: "Sophie Worthington",
      career: "Goat Herd Manager",
      image:
        "https://imgix.cosmicjs.com/3bc38fe0-227a-11ed-8337-95a76fda76ff-StepladderCreamery-98746eec3f54-181e-43da-ae89-7cd0b7b644a6600x900cropcenter.progressive-1.jpg?w=294&h=294&fit=crop&crop=faces",
    },
    {
      question: "Like Liza Donnelly, I often rely on my gut feelings.",
      personality_type: "personality_type_artistic",
      story:
        "As a cartoonist, Liza Donnelly trusts her gut when coming up with an idea for a cartoon.\n\nShe says, \"The first cartoon I sold was a twist on the artist Paul Cezanne's three elements in nature - a cone, a sphere, and a cylinder. I drew those three things and then added a television set. So that's a classic twist on a given. That's what a cartoon usually is -- you take a known thing and make it different, or weird, or odd.\" ",
      name: "Liza Donnelly",
      career: "Cartoonist",
      image:
        "https://imgix.cosmicjs.com/2b3d4a50-d837-11eb-a1a6-c3f080da6c53-Screen-Shot-2021-06-28-at-1.34.43-PM.png?w=294&h=294&fit=crop&crop=faces",
    },
    {
      question: "Like Lorena Abreu, I enjoy activities that are hands-on.",
      personality_type: "personality_type_realistic",
      story:
        'As a barista at a coffeehouse, Lorena Abreu\'s job is hands-on.\n\nShe says, "When it’s busy, you’re running back and forth – Register! Coffee! Register! Sometimes, there are two people working at the bar, which can be a little overwhelming. One person is preparing the cup, putting the syrups in it, while someone else is steaming the milk and pulling the shots. Shots after shots."\n',
      name: "Lorena Abreu",
      career: "Barista",
      image:
        "https://imgix.cosmicjs.com/218c4fd0-453c-11eb-ae0c-07d0b084405e-40d7fcbe5e365545d85e02568801a2bf_Lorena-Abrew-2-of-2.jpg?w=294&h=294&fit=crop&crop=faces",
    },
    {
      question:
        "Like Derek Lietz, I like evaluating data to understand complicated issues.",
      personality_type: "personality_type_investigative",
      story:
        'As a data scientist, Derek Lietz evaluates data to help lawyers make sense of complicated issues.\n\nHe says, "The company that I started at, right out of college, was Elysium Digital. At Elysium, our slogan was “You know the law, we know technology.” What that meant was, we would work with lawyers who were working on technology cases and we would essentially be the backroom tech folks that are helping the lawyers understand the technology issue. Digital forensics is another name for this."',
      name: "Derek Lietz",
      career: "Data Scientist",
      image:
        "https://imgix.cosmicjs.com/a381b260-88bb-11eb-a1e5-63d55f58b597-UNADJUSTEDNONRAWthumb239c-1.jpg?w=294&h=294&fit=crop&crop=faces",
    },
    {
      question: "Like Richard Sherin, I like teaching young people.",
      personality_type: "personality_type_social",
      story:
        'As a Principal at a high school, Richard Sherin teaches young people to speak up but be respectful at the same time.\n\nHe says, "My favorite part of my job has always been the children. I also like letting my children know that it is okay to disagree with other adults, but it must be done in a respectful manner."',
      name: "Richard Sherin",
      career: "High School Principal",
      image:
        "https://imgix.cosmicjs.com/59f608b0-453d-11eb-ae0c-07d0b084405e-Screen-Shot-2018-07-01-at-5.12.52-PM.png?w=294&h=294&fit=crop&crop=faces",
    },
    {
      question: "Like Jane Bird, I enjoy seeing all kinds of performances.",
      personality_type: "personality_type_artistic",
      story:
        'As an actress, Jane Bird enjoys seeing all kinds of performances.\n\nShe says, "Explore all that is out there. Go see all performances: charming orators, interesting debates, book readings, inventive music and dance, films, and stand up. It all informs your work. See regional theater and small-scale performances, not just the most competitive shows."',
      name: "Jane Bird",
      career: "Actress",
      image:
        "https://imgix.cosmicjs.com/ab9dd740-453a-11eb-ae0c-07d0b084405e-Jane-Bird-2.jpg?w=294&h=294&fit=crop&crop=faces",
    },
    {
      question: "Like Wanda Wang, I like work that involves reviewing documents.",
      personality_type: "personality_type_conventional",
      story:
        'As a loan officer, Wanda Wang Both spends a lot of time going through documents for her clients.\n\n"The loan officer\'s job is to help a borrower, or someone who needs money, look for an ideal loan program that fits their scenario. There are many different types of loan programs that are out there, so the loan officer is the expert that helps guide a borrower to go and shop for the loan that will help them purchase the house."',
      name: "Wanda Wang",
      career: "Loan Officer",
      image:
        "https://imgix.cosmicjs.com/52b69b70-89bf-11eb-9573-45be27b43642-WandaWang4.jpg?w=294&h=294&fit=crop&crop=faces",
    },
    {
      question:
        "Like Mark Levinsky, I like work that is structured and systematic.",
      personality_type: "personality_type_conventional",
      story:
        'As a landscaper in Vermont, Mark Levinsky\'s work is structured and changes with the season. \n\nHe says, "You do snow removal in the winter, and landscaping and property maintenance in the summer."',
      name: "Mark Levinsky",
      career: "Landscaper and Sole Proprietor",
      image:
        "https://imgix.cosmicjs.com/764691c0-ba02-11ec-a7cc-25c8f0ace24d-IMG950727001.jpg?w=294&h=294&fit=crop&crop=faces",
    },
    {
      question:
        "Like Salvador Avila Bretts, I don't mind working long hours for higher salaries.",
      personality_type: "personality_type_enterprising",
      story:
        'As a management trainee at JP Morgan, Salvador Avila Bretts was well compensated but often worked 19 hour days. \n\nHe says, "In 2008 I joined the analyst class for JP Morgan, Asset Management. They recruited us with all the Wall Street perks—fantastic compensation, great bonuses, plus the understanding that it would be really hard work...The workload was ridiculous. We started work at 7:00 am and got out at 2:00 am the next morning." ',
      name: "Salvador Avila Bretts",
      career: "Managing Director of Business Development",
      image:
        "https://imgix.cosmicjs.com/a6b176a0-3432-11ec-ae1a-fd00181e938d-Image-1.jpeg?w=294&h=294&fit=crop&crop=faces",
    },
    {
      question:
        "Like Gina Rupard, I like teaching my patients how to be healthier.",
      personality_type: "personality_type_social",
      story:
        'As a dental hygienist, Gina Rupard teaches her patients how to take better care of their teeth and gums. \n\nShe says, "When a patient sits in your chair beaming because they have been flossing every day since their last visit with you and their gums don’t bleed anymore and they’re so proud of themselves, you can’t help but feel like a proud parent." ',
      name: "Gina Rupard",
      career: "Dental Hygienist",
      image:
        "https://imgix.cosmicjs.com/c066f4b0-4538-11eb-ae0c-07d0b084405e-ff765ddf00e76654789a52906dc96359_photo-3.jpg?w=294&h=294&fit=crop&crop=faces",
    },
    {
      question:
        "Like Kristan Robinson, I enjoy performing rehabiltation therapy.",
      personality_type: "personality_type_social",
      story:
        'As a licensed physical therapist assistant, Kristan Robinson peforms rehabilitation therapy, which includes occupational, physical and speech therapy.\n\nShe says, "The best parts are anytime a patient does something they didn’t think they could do. Anytime I can do something that will improve a patient’s life, whether it’s in a minor or a major way."',
      name: "Kristan Robinson",
      career: "Licensed Physical Therapist Assistant",
      image:
        "https://imgix.cosmicjs.com/979d24d0-453a-11eb-ae0c-07d0b084405e-Kristan-Profile-Photo-e1505438464380.jpg?w=294&h=294&fit=crop&crop=faces",
    },
    {
      question:
        "Like Aneela Rajaram, I like having rules and regulations to follow.",
      personality_type: "personality_type_conventional",
      story:
        'As a Senior Controls Manger, Aneela Rajaram makes sure her company follows rules and regulations.\n\nShe says, "My job is to monitor our activities and make sure our operations comply with regulatory bodies...That’s what internal control is: reviewing the steps in a transaction to ensure they were properly followed. This is very important because it allows us to do our financial reporting in a standard way, which in turn allows investors to make proper decisions for public reporting." ',
      name: "Aneela Rajaram",
      career: "Senior Internal Control Manager",
      image:
        "https://imgix.cosmicjs.com/ea120a40-be3f-11eb-9839-1fdec066e864-Aneela-Rajaram-2.jpg?w=294&h=294&fit=crop&crop=faces",
    },
    {
      question:
        "Like Jenna Perlstein, I like learning about a business through interactions with customers.",
      personality_type: "personality_type_enterprising",
      story:
        'Jenna Perlstein, Senior Customer Associate, learns about the business by talking to customers.\n\nShe says, "Customer experience is the best way to learn a business because you learn it from the consumer up. You learn it from the inside out. I am absolutely an expert on all things LOLA in a way that not everyone on the team is just based on my job description."',
      name: "Jenna Perlstein",
      career: "Senior Customer Associate",
      image:
        "https://imgix.cosmicjs.com/e1625630-453a-11eb-ae0c-07d0b084405e-IMG_1783.jpg?w=294&h=294&fit=crop&crop=faces",
    },
    {
      question: "Like Marcy Guttman, I enjoy raising money for a good cause.",
      personality_type: "personality_type_enterprising",
      story:
        'As a fundraiser for the Madison Boys and Girls Club, Marcy Guttman reaches out to potential donors to raise money.\n\nShe says, "I’m currently working on a Capital/Endowment Campaign, which entails raising substantial funds to build a facility for underserved youth in one of New York City’s most impoverished neighborhoods. For the potential donors, I schedule tours of our other facilities with our Executive Director so that they have a better understanding of what their donations can achieve. I work with our marketing director to craft communication pieces to send to potential donors to inspire interest. The list goes on but for the most part my job involves writing-emails, letters, proposals and phone calls of all sorts."',
      name: "Marcy Guttman",
      career: "Capital Campaign Manager",
      image:
        "https://imgix.cosmicjs.com/19ae26e0-a408-11ec-a536-8726e3bb3867-3K1A7307.JPG?w=294&h=294&fit=crop&crop=faces",
    },
    {
      question: "Like Denny Moe, I am energized by creativity.",
      personality_type: "personality_type_artistic",
      story:
        'For barber Denny Moe, cutting hair is a purely creative process.\n\n"Cutting hair for me is art. It’s creation. That’s what gets me up in the morning...There’s nothing better than seeing someone look in the mirror and say, “Wow, that’s exactly what I wanted, thank you!” I live for that. That’s the gas that drives this old machine.”',
      name: "Denny Moe",
      career: "Barber and Owner of Denny Moe's Superstar Barber Shop",
      image:
        "https://imgix.cosmicjs.com/32bb8bb0-26e9-11ec-8357-6f5124205417-Best-shot-Denny-doing-a-design-cut.jpeg?w=294&h=294&fit=crop&crop=faces",
    },
    {
      question:
        "Like Betsy Wells, I like collecting information to make sense of a situation.",
      personality_type: "personality_type_investigative",
      story:
        'As a sonographer, Betsy Wells collects images to help doctors understand the health of a pregnancy. \n\n"When we’re doing an X-ray, we ask ourselves, Do we have everything the doctor needs to see in the image, as far as the correct anatomy? Then the doctor analyzes everything that\'s in that image and they can determine, Is that normal? Is there anything abnormal?"',
      name: "Betsy Wells",
      career: "Sonographer",
      image:
        "https://imgix.cosmicjs.com/f599ec50-8cfe-11ec-ae88-8982447d5ddf-Screenshot20220125-123210.png?w=294&h=294&fit=crop&crop=faces",
    },
    {
      question: "Like Angelica Wooden, I enjoy serving others.",
      personality_type: "personality_type_social",
      story:
        'As a flight attendant for Republic Airline, Angelica Wooden serves her passengers throughout the flight.\n\nShe says, "A flight attendant\'s main purpose is to protect the integrity of the flight deck at all costs. The flight deck is where the pilot sits and operates the plane. On a day-to-day basis, the purpose of a flight attendant is to serve the passengers through the flight to make them comfortable and get them safely to their destination."',
      name: "Angelica Wooden",
      career: "Flight Attendant",
      image:
        "https://imgix.cosmicjs.com/5bd0fc80-4538-11eb-ae0c-07d0b084405e-1c36b1e63a1913235b5a359eef64d405_angelica_profile-1.jpg?w=294&h=294&fit=crop&crop=faces",
    },
    {
      question:
        "Like Shalija Patel, I enjoy using mathematics to develop a plan.",
      personality_type: "personality_type_investigative",
      story:
        'As a medical dosimetrist, Shalija Patel uses mathematics to design cancer patients’ radiation treatment plans.\n\nShe says, "I design the radiation beams and shape the beams and angles so that I can deliver high doses of radiation to the tumor. I see patients only when they come for that initial CT scan, and I try to give them the best possible treatment."',
      name: "Shalija Patel",
      career: "Medical Dosimetrist",
      image:
        "https://imgix.cosmicjs.com/53ba2a70-756c-11ec-bfef-718573fe8f91-Shailja.-Patel.jpeg?w=294&h=294&fit=crop&crop=faces",
    },
    {
      question: "Like Adi Serbaroli, I enjoy work that is physically active.",
      personality_type: "personality_type_realistic",
      story:
        'As a United States Marine Corps Officer, Adi Serbaroli participates in training that is physically active.\n\nShe says, "During training stages, we do long marches where we\'re carrying about 80lbs of equipment for up to 15 miles at a time. We have to use a basic compass and map and pencil (no GPS allowed) in order to navigate through 24 km of nothing but woods, looking for red mail box stations for 8 hours at a time."',
      name: "Adi Serbaroli",
      career: "United States Marine Corps Officer",
      image:
        "https://imgix.cosmicjs.com/e9aede10-487f-11eb-96cc-03fa52082d80-1930133291416017878798n.jpg?w=294&h=294&fit=crop&crop=faces",
    },
    {
      question:
        "Like James Wood, I enjoy simplifiying complex problems through analysis.",
      personality_type: "personality_type_investigative",
      story:
        'As an actuary, James Wood simplifies complex problems through analysis. \n\nHe says, "An actuary is someone that puts a number on risk. There\'s a couple of different ways that they can do that. One is through pricing. If you think about your auto insurance, your premium is going to vary by the type of car that your drive, where you live, your gender, your marital status, things like that. All those different characteristics are what we call your risk characteristics and that is how we determine how likely someone is to submit a claim and how much that claim might cost."',
      name: "James Wood",
      career: "Actuary ",
      image:
        "https://imgix.cosmicjs.com/21d79080-927f-11ec-9a4e-05fc85d83574-James-Woods.jpeg?w=294&h=294&fit=crop&crop=faces",
    },
    {
      question: "Like Tyrone Bell, I enjoy using tools to build things.",
      personality_type: "personality_type_realistic",
      story:
        'As an electrician\'s apprentice, Tyrone Bell uses tools to run and bend pipe. \n\nHe says, "It’s actually pretty amazing when you see—when I was here last year at this time there was no building at all—there were just dirt and an empty hall. And seeing how much progress we had from last year to this year—it’s nice to see. Then you can look back at it for years to come and say hey, I was a part of building that."',
      name: "Tyrone Bell",
      career: "Electrician's Apprentice",
      image:
        "https://imgix.cosmicjs.com/2abc6250-453e-11eb-ae0c-07d0b084405e-8a35e9ac67997014140c088f4b6b439b_Screen-Shot-2017-12-22-at-12.48.20-PM.png?w=294&h=294&fit=crop&crop=faces",
    },
    {
      question:
        "Like Kit Yan, I enjoy complex situations that require sensitivity. ",
      personality_type: "personality_type_artistic",
      story:
        'As an award-winning playwright and poet, Kit Yan writes about situations that require sensitivity.\n\nThey say, "When I zoom out, I see the greater landscape of what I’m doing. I’m influencing American theatre and shifting the narrative to include queer and trans folks of color. I’m creating jobs and opportunities and projects and the opportunity to dream about a world that I want to live in."',
      name: "Kit Yan",
      career: "Playwright and Poet",
      image:
        "https://imgix.cosmicjs.com/a829c9b0-453b-11eb-ae0c-07d0b084405e-5f9e00dec6a6498833e7df690e8ee819_Kit-Yan.jpg?w=294&h=294&fit=crop&crop=faces",
    },
    {
      question: "Like Alfredo Garma, I enjoy turning my ideas into drawings.",
      personality_type: "personality_type_artistic",
      story:
        'As a graphic artist, Alfredo Garma sketches his ideas with pencil and paper.\n\nHe says, "Every one of the logos I’ve done I can show you the origins of how it developed in my sketchbook. Over the years I’ve learned how to edit in my head – I can think about it, visualize, and see if it’s going to work and then put it on paper."',
      name: "Alfredo Garma",
      career: "Graphic Designer",
      image:
        "https://imgix.cosmicjs.com/16714820-4538-11eb-ae0c-07d0b084405e-24131572_493937647656174_5997800359132330081_n.jpg?w=294&h=294&fit=crop&crop=faces",
    },
    {
      question: "Like Megan Barko, I like putting out fires.",
      personality_type: "personality_type_realistic",
      story:
        "Megan Barko previously worked at U.S. Steel and was the commander for one of the largest fires ever.\n\nShe says,\"The guys in the plant used a torch to cut steel and a piece fell in between two plastic tanks. It smoldered for a while, and then caught fire. I had a team of five guys who responded with one fire truck, which eventually grew to five trucks on site. We made sure the electricity and gas were off and hit the flames with foam and water. It was a huge fire. Smoke poured out of the roof. The flames were 40 to 50 feet high. You're in there and you're feeling the heat on your protective clothing. It took 10-hours to put that fire out, and it was a $90-million loss to U.S. Steel. The workers all got out. I couldn't ask for more than that. Buildings can be replaced but people cannot.\"",
      name: "Megan Barko",
      career: "Deputy Fire Marshal & Tank Inspector",
      image:
        "https://imgix.cosmicjs.com/2917ce70-7739-11ec-bfef-718573fe8f91-Megan-on-ropes.jpg?w=294&h=294&fit=crop&crop=faces",
    },
    {
      question:
        "Like Courtney McDonald, I like using principles in medicine to help people feel better.",
      personality_type: "personality_type_social",
      story:
        "As a licensed massage and medical therapist, Courtney McDonald uses principles in medicine to help people.\n\n\"I have a 25-year-old fitness instructor who had a life-threatening brain tumor when she was about 17 years old. She's got a pretty significant scar going all the way across her head. As a result of that, she’s got nerve pain all over the place. That has been a really difficult case. We've been working together for about two and a half years now. She comes once a week, and she always says that I changed her life.\"",
      name: "Courtney McDonald",
      career: "Licensed Massage and Medical Therapist",
      image:
        "https://imgix.cosmicjs.com/174d5a60-7bc2-11ec-8c2f-a300cf8a78dc-IMG20220105154908352.jpg?w=294&h=294&fit=crop&crop=faces",
    },
    {
      question: "Like Alec Beckett, I like managing a team.",
      personality_type: "personality_type_enterprising",
      story:
        "As creative director of an advertising firm, Alec Beckett manages a team.\n\nHe says, \"My responsibilities as creative director are the output and the quality of the work that our agency does. Whether it's a Superbowl TV spot, or a Facebook post that we're doing for a client, ultimately its quality comes down to me and my partner. I've got nine people who report to me: designers, copywriters, art directors, social media people, and it's ultimately my job to get great work out of our people.",
      name: "Alec Beckett",
      career: "Creative Director ",
      image:
        "https://imgix.cosmicjs.com/d0704ab0-4537-11eb-ae0c-07d0b084405e-a7137a34439b75543597db0c88bbed3a_alec1.jpg?w=294&h=294&fit=crop&crop=faces",
    },
    {
      question:
        "Like Stefanie Goldberg, I like interacting with powerful people.",
      personality_type: "personality_type_enterprising",
      story:
        'As Senior Talent Development, Stefanie Goldberg interacts with powerful people in senior leadership.\n\nShe says, "I get to interact with so many people--we have roughly about 40,000 associates, and I don’t interact with all of them, but it’s really cool to think that the work that I do and that my team does help to influence and impact those 40,000 associates. And then, you know, I get to interact with very senior leadership, which is really cool as well."',
      name: "Stefanie Goldberg",
      career: "Senior Director of Talent Management",
      image:
        "https://imgix.cosmicjs.com/6c2f4aa0-8dad-11ec-ae88-8982447d5ddf-STefanie.jpeg?w=294&h=294&fit=crop&crop=faces",
    },
    {
      question:
        "Like Archie Roberts, I like that my work requires me to talk to people about what they care about.",
      personality_type: "personality_type_social",
      story:
        "As a psychotherapist, Archie Roberts meets with patients one-on-one and talks to them about what they care about..\n\nHe says, “All-day long I get to sit down and talk with people about the most important things in their lives. I mean, what's better than that?”",
      name: "Archie Roberts",
      career: "Psychotherapist",
      image:
        "https://imgix.cosmicjs.com/7ec580d0-4538-11eb-ae0c-07d0b084405e-f8691c325e52bda7d2a8465b842a378e_OLG_367_2_2-e1403358061734-copy.jpg?w=294&h=294&fit=crop&crop=faces",
    },
    {
      question: "Like Lacurshia Harris, I like work that is straightforward.",
      personality_type: "personality_type_conventional",
      story:
        'As a phlebotomist, Lacurshia Harris\' work is straightforward.\n\nShe says, "Drawing blood is pretty straightforward, but when patients get nervous, my job calls for me to be a listening ear, a shared family member, or a prayer warrior. "',
      name: "Lacurshia Harris",
      career: "Phlebotomist and Phlebotomy Instructor",
      image:
        "https://imgix.cosmicjs.com/b70bd610-0567-11ed-b7be-d956591ad437-Lacurshia-at-work.jpg?w=294&h=294&fit=crop&crop=faces",
    },
    {
      question: "Like Ben Wright, I enjoy meeting dozens of people a day.",
      personality_type: "personality_type_enterprising",
      story:
        'As the campaign manager for someone running for governor, Ben Wright meets dozens of people a day.\n\nHe says, "Candidates running for statewide office meet dozens or more people a day. Many of them want to connect with the candidate about something local and so the person who is with the candidate is able to take down their information to find out how to engage with them to get them involved in the campaign. For example, I was at a meeting in Jamaica Plain on Monday. We met two people who seemed really great so they’re coming to join our policy team. It’s my job to follow up and make sure that happens."',
      name: "Ben Wright",
      career: "Campaign Manager ",
      image:
        "https://imgix.cosmicjs.com/5fd9f2e0-453e-11eb-ae0c-07d0b084405e-ben1-e1493644638239.jpg?w=294&h=294&fit=crop&crop=faces",
    },
    {
      question:
        "Like Anna Low, I enjoy figuring out a solution with all of the information available to me.",
      personality_type: "personality_type_investigative",
      story:
        'As a family physician, Anna Low uses all of the information available to her to help her patients.\n\nShe says, "We take what we’ve learned in textbooks and apply it to the real world. Take high blood pressure, for instance. We might learn about the science of why it happens, but you have to see the patient as a person as well with their own unique ways of dealing with that illness to know how to help them. For example, a homeless person might not be able to control their blood pressure because they don’t necessarily have access to regular food sources. ',
      name: "Anna Low",
      career: "Family Physician",
      image:
        "https://imgix.cosmicjs.com/085abdd0-8529-11eb-a95f-87eaa19894a9-Screen-Shot-2021-03-14-at-8.54.39-PM.png?w=294&h=294&fit=crop&crop=faces",
    },
    {
      question: "Like Amorie Robinson, I like helping people heal. ",
      personality_type: "personality_type_social",
      story:
        'As a clinical psychologist, Amorie Robinson helps people heal from trauma.\n\nShe says, "Clinical psychology is about figuring out the “why.” Why, for example, is someone having panic attacks, mood swings, insomnia, or problems in a relationship? There are a lot of people walking around with trauma and if that trauma doesn’t get addressed, even though it may have happened many years ago, it can affect their overall functioning. We get to the root of the problem, so they can untangle these things and heal."',
      name: "Amorie Robinson",
      career: "Clinical Psychologist",
      image:
        "https://imgix.cosmicjs.com/e38b73d0-0a63-11ec-916a-1b70c6cc245e-20200113162813.jpg?w=294&h=294&fit=crop&crop=faces",
    },
    {
      question:
        "Like Amanda Markley, I like taking care of the needs of animals.",
      personality_type: "personality_type_realistic",
      story:
        "As a zookeeper, Amanda Markley takes care of the needs of animals, including aoudads, red river hogs, the West African crowned crane, and African spur side tortoises.\n\n\"Zookeepers take care of animals but don't just feed and clean up after them. Zookeepers also train them and monitor the animals’ psychological well-being and develop a relationship with their animals of respect and trust so that if we have to do a presentation for the public or a medical procedure with them, they'll cooperate.\"",
      name: "Amanda Markley",
      career: "Zookeeper",
      image:
        "https://imgix.cosmicjs.com/40cf3aa0-4538-11eb-ae0c-07d0b084405e-9dbeb67a926a44e6fece515a8cffc87f_Amanda-copy.png?w=294&h=294&fit=crop&crop=faces",
    },
    {
      question: "Like Tara Plochocki, I enjoy researching evidence for a case.",
      personality_type: "personality_type_investigative",
      story:
        "As a lawyer, Tara Plochocki's work requires a lot of research to prepare for court.\n\nShe says, \"It is rewarding when we're able to get courts to do the right thing. For example, I sued on behalf of a journalist, who was actually a stand-up comedian from Brooklyn who converted to Islam and moved to Syria some years ago, because he felt like he was not getting the real news about what was happening in the Syrian conflict. He started a news network, and because he proved himself to be trustworthy, he was able to interview all these different rebel groups. Well, the way the CIA works, if I'm a rebel in Syria, and you're a journalist, and we're hanging out, and the CIA can tell our phones are together, guess what the government thinks you are? So he was almost killed by drones five times before he made his way to the organization we work with and then to me. We sued the government for a violation of his constitutional rights.\" ",
      name: "Tara Plochocki",
      career: "International Lawyer and Partner at a Law Firm",
      image:
        "https://imgix.cosmicjs.com/f44f93c0-974b-11ec-8bb7-91577e4f4933-IMG3660.jpg?w=294&h=294&fit=crop&crop=faces",
    },
    {
      question:
        "Like Jim Frohna, I believe my strongest asset is my artistic talent.",
      personality_type: "personality_type_artistic",
      story:
        'As a Cinematographer who has worked on Big Little Lies on HBO, among other television shows, Jim Frohna\'s strongest asset is his artistic talent.  \n\nHe says, "My job is to collaborate with the director to bring the visual look of the project to life, work really closely with the production designer who has a huge impact on the visual storytelling as well, run the camera department, and oversee the lighting and grip department." ',
      name: "Jim Frohna",
      career: "Cinematographer",
      image:
        "https://imgix.cosmicjs.com/62d712f0-8a6e-11eb-bb26-d9125127b7e0-1-Headshot.jpeg?w=294&h=294&fit=crop&crop=faces",
    },
    {
      question:
        "Like Dan Bjorkegren, I enjoy exploring new ways to use technology to solve problems.",
      personality_type: "personality_type_investigative",
      story:
        'As an economist and Professor at Brown Univerisity, Dan Bjorkegren explores new ways to use technology to solve problems.\n\nHe says, "If you went back in time, say 20 years ago, many of the poorest countries had basically no phones and no internet. If you were a typical Rwandan, there was just no way to communicate remotely—you had to visit someone in person. There was no way to use software or anything digital. But over the past 20 years, cell phones have become available to even the very poor, which has started to allow people to use digital services, apps and the internet (though those have still not reached everyone). In my work, I ask, “Now that people have this new technology, what opportunities for them can we create with that?”"',
      name: "Dan Bjorkegren",
      career: "Assistant Professor of Economics",
      image:
        "https://imgix.cosmicjs.com/81466c20-7a3a-11eb-ab8e-bd77c5b212a3-DSC0615square.jpg?w=294&h=294&fit=crop&crop=faces",
    },
    {
      question: "Similar Dr. Andrew Lam, I like to manually fix things.",
      personality_type: "personality_type_realistic",
      story:
        "As a retina surgeon, Dr. Andrew Lam manually fixes people with sight-threatening conditions.\n\nHe says, \"I really wanted to choose a surgical specialty because I love to fix things, and I like taking action. There are doctors who are more cerebral, problem solvers who like to solve mysteries. They often will go into internal medicine where there are many diagnostic challenges, where you are trying hard to figure out what a patient's disease state is and how to best treat that person medically. I'm a little too impatient for that. I like to do treatments and see immediate results, so I was definitely looking into the different surgical subspecialties.\" ",
      name: "Dr. Andrew Lam",
      career: "Retina Surgeon",
      image:
        "https://imgix.cosmicjs.com/589ef670-4538-11eb-ae0c-07d0b084405e-0-1.jpeg?w=294&h=294&fit=crop&crop=faces",
    },
    {
      question:
        "Like Lorena Abreu, I enjoy influencing the decisions of others. ",
      personality_type: "personality_type_enterprising",
      story:
        'As a barista, Lorena Abreu tries to steer her customers in a certain direction.\n\nShe says, "You can chat people up, say, “I had this drink this morning, do you want to try it?” I love our chai – we make the tea separately, leave it overnight, and then add our own spice blend. It’s so rich and creamy."\n',
      name: "Lorena Abreu",
      career: "Barista",
      image:
        "https://imgix.cosmicjs.com/218c4fd0-453c-11eb-ae0c-07d0b084405e-40d7fcbe5e365545d85e02568801a2bf_Lorena-Abrew-2-of-2.jpg?w=294&h=294&fit=crop&crop=faces",
    },
    {
      question:
        "Like Aaron Symonds, I like work that requires me to be in touch with the emotions of others. ",
      personality_type: "personality_type_artistic",
      story:
        'As a composer, Aaron Symonds uses music to connect to the emotions of people.\n\nHe says, "I love connecting to people and music is the most direct way you can connect with people, bypassing language and just going directly to their emotions."',
      name: "Aaron Symonds",
      career: "Film and TV Composer",
      image:
        "https://imgix.cosmicjs.com/c75ed1d0-4537-11eb-ae0c-07d0b084405e-fb4e03fa9e300116b20d9031cbc0b811_AaronHeadshot1.jpg?w=294&h=294&fit=crop&crop=faces",
    },
    {
      question:
        "Like Leticia Romney, I like finding and removing grammatical errors from reports.",
      personality_type: "personality_type_conventional",
      story:
        'As a copy editor, Leticia Romney finds and removes grammatical errors from reports.\n\nShe says, "Copy editing is like proofreading. For example, copy editing projects might be where I receive a report from a medical facility, or a law office, and they\'re looking for me to comb through the report for any grammatical errors, or errors with spelling, capitalization, punctuation, conciseness, and looking at the organization of the piece." ',
      name: "Leticia Romney",
      career: "Copyeditor, Copywriter, and Business Owner",
      image:
        "https://imgix.cosmicjs.com/2e534bd0-2dc9-11ec-b5e8-73b095e03049-1275110139418138630256703858600978175338521n-1.jpg?w=294&h=294&fit=crop&crop=faces",
    },
    {
      question:
        "Like Hout Seng, I like receiving clear instructions from my boss.",
      personality_type: "personality_type_conventional",
      story:
        'As a cook, Hout Seng receives clear instructions from his boss on what to cook for the day.\n\nHe says, "On most days I check with the lead to see what they need me to do. Sometimes the lead says, "make a soup, a starch, and an entree for lunch." Today, I came in and they gave me a turkey, mashed potatoes, and gravy."',
      name: "Hout Seng",
      career: "Cook",
      image:
        "https://imgix.cosmicjs.com/76910f90-453a-11eb-ae0c-07d0b084405e-Houtprof.jpg?w=294&h=294&fit=crop&crop=faces",
    },
    {
      question:
        "Like Jason Feifer, I like having control over a company's mission.",
      personality_type: "personality_type_enterprising",
      story:
        'As editor-in-chief of Entrepreneur Magazine, Jason Feifer has control over the company\'s mission. \n\nHe says, "As editor-in-chief, it is my responsibility to define the mission and to ensure that everything that we do--every decision we make on the editorial side, anything part of the editorial product--anything you read on the magazine, read online, anything on the podcast, anything on stage--serves that mission."',
      name: "Jason Feifer",
      career: "Editor-in-Chief",
      image:
        "https://imgix.cosmicjs.com/0c5e1f40-89ab-11eb-9573-45be27b43642-JC69509.jpg?w=294&h=294&fit=crop&crop=faces",
    },
    {
      question:
        "Like David Henriquez, I enjoy understanding how the universe works.",
      personality_type: "personality_type_investigative",
      story:
        'As an aerospace engineer working at NASA, David Henriquez studies how the universe works.\n\nHe says, "I am currently working on the Psyche mission, which is a NASA mission that will explore an asteroid called 16 Psyche. It is a unique and intriguing body in the asteroid belt that, unlike most other asteroids, but similar to Earth’s core, is mostly made of metal. There are a lot of questions about the origin of Psyche. Some theories posit that it is a leftover fragment from the birth of the solar system. Regardless, Psyche presents what may be our only opportunity to study part of a planetary core that’s frozen in time and space, and understand how magnetic fields are generated by metallic cores. This will help us understand our own planet since we don’t have the technology to drill into Earth’s core—no matter what the movies suggest!"',
      name: "David Henriquez",
      career: "Aerospace Engineer",
      image:
        "https://imgix.cosmicjs.com/b06aa6d0-d1fe-11eb-99f3-f5daa6ac37d2-D20160816T028.jpg?w=294&h=294&fit=crop&crop=faces",
    },
    {
      question:
        "Like Gennady Gelman, I like looking for answers by reviewing large amounts of data.",
      personality_type: "personality_type_conventional",
      story:
        'As the Director of Infomatics, Gennady Gelman looks for answers in large amounts of data.\n\nHe says, "In the case of medical informatics, we do data analysis within health systems. This year, for example we calculated the colonscopy rate, which is the number of people getting colonoscopies versus the number of people who should be getting colonoscopies, to see how we compared to the state average. Then we came up with ideas to bring about change." ',
      name: "Gennady Gelman",
      career: "Chief Medical Informatics Officer",
      image:
        "https://imgix.cosmicjs.com/99a35f00-6817-11ec-9a64-73e60d601d04-Gennady.jpeg?w=294&h=294&fit=crop&crop=faces",
    },
    {
      question: "Like Anthony Randall, I have athletic abilities.",
      personality_type: "personality_type_realistic",
      story:
        'As the Associate Head Swim Coach at the University of Rhode Island, Anthony Randall has athletic abilties.\n\nHe says, "I started swimming at the age of five. Ultimately my goal was to make it to the Olympics. I wanted to win and that drove me to train."',
      name: "Anthony Randall",
      career: "Swim Coach",
      image:
        "https://imgix.cosmicjs.com/789ad430-4538-11eb-ae0c-07d0b084405e-ad72ede80e92c8c9fd6a43d91b0abbd8_Anthony-copy.png?w=294&h=294&fit=crop&crop=faces",
    },
    {
      question: "Like Lizzy Wolozin, I am motivated by making my boss happy.",
      personality_type: "personality_type_conventional",
      story:
        'As a paralegal for the The Prisoners’ Rights Project, Lizzy Wolozin helps her boss with whatever is needed.\n\nShe says, "A paralegal is whatever the lawyer you’re working for wants it to be. You help the lawyers do whatever they want to do. We do document preparation, case support, clerical work, and whatever is needed to support legal offices and their functioning...You push paper around and answer the phones."',
      name: "Lizzy Wolozin",
      career: "Paralegal",
      image:
        "https://imgix.cosmicjs.com/117dbf20-453c-11eb-ae0c-07d0b084405e-af809e222da4b3e572e620f9f7e95465_Screen-Shot-2019-06-23-at-3.57.01-PM.png?w=294&h=294&fit=crop&crop=faces",
    },
  ]
}
export default questionData
