import React from "react";
import './profile-image.css';


import PortfolioProfile from "../../../static/assets/images/portfolio-profile-default.jpg"

const ProfileImage = (profile) => {
  profile = profile?.profile ? profile.profile : profile;
  return <div className="profile-image-container">
    {!!profile && !!profile.profileImageUrl ? (
      <img
        className="profile-image"
        style={{
          maxWidth: "100%",
          objectFit: "cover",
          objectPosition: "center top",
        }}
        src={profile.profileImageUrl}
        alt="user profile"
      />
    ) : (
        <img
          className="profile-image"
          alt="user profile"
          src={PortfolioProfile}
        />
      )}
  </div>
}

export default ProfileImage;
