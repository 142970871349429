/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
const React = require("react")
const Layout = require("./src/components/layout").default

exports.wrapPageElement = ({ element, props }) => {
  const { pageContext } = props
  if (typeof window !== "undefined") {
    window.___gatsbyIntl = pageContext
  }
  return <Layout {...props}>{element}</Layout>
}

const { anchorate } = require("anchorate")

exports.onRouteUpdate = () => {
  anchorate()
}

exports.shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const currentPosition = getSavedScrollPosition(location)

  const defaultTopPaths = [
    "story",
    "career",
    "school",
    "major",
    "company",
    "assessment",
    "signup",
    "login",
  ]

  if (location.search) {
    // disables any scrolling upon algolia search URL updates
    return false
  } else if (defaultTopPaths.includes(location.pathname.split("/")[1])) {
    // these routes default to the top of window
    window.scrollTo(0, 0)
  } else {
    // default behavior, routes return to last position upon back button navigation
    window.scrollTo(...(currentPosition || [0, 0]))
  }
  return false
}
