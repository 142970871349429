import React, { useState, useEffect, useContext, useRef } from "react"
import { FirebaseContext } from "../Firebase"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit } from "@fortawesome/free-solid-svg-icons"
import { Alert } from "react-bootstrap"
import EyeBrow from "../eyebrow/eyebrow"
import { Checkbox } from "semantic-ui-react"
import Form from "react-bootstrap/Form"

import { notificationOptions } from "../dashboard/notifications/notificationOptions"
import PropTypes from "prop-types"

const PortfolioPublicToggle = props => {
  const { section, isBlank } = props

  const { profile, firebase } = useContext(FirebaseContext)

  const [isPublic, setIsPublic] = useState(true)
  const [profileIsPublic, setProfileIsPublic] = useState(false)

  useEffect(() => {
    // set the preview image to current image, once available
    if (profile) {
        if (typeof profile.profileIsPublic === 'boolean'){
            setProfileIsPublic(profile.profileIsPublic)
        } else {
            setProfileIsPublic(true)
        }
    }
  }, [profile])

  const handleChange = e => {
    setIsPublic(!isPublic)
    uploadSelection(e.target.checked)
  }

  const uploadSelection = async selection => {
    if (!section || typeof selection != "boolean") return

    const success = selection
      ? await firebase.makeProfileSectionPublic(section)
      : await firebase.makeProfileSectionPrivate(section)

    if (!success) {
      console.error(
        "failed to upload public / private selection for " + section
      )
      // revert toggle if fails
      setIsPublic(!isPublic)
    }
  }

  const renderLabel = () => {
    if (isBlank){
      return ''
    } else {
      return isPublic ? "Public" : "Private"
    }
  }

  useEffect(() => {
    // on mount check for public setting
    if (!profile) return

    const sectionStatus = profile.profilePublicSections?.[section]

    if (typeof sectionStatus == "boolean") {
      setIsPublic(sectionStatus)
    }
  }, [profile])

  return (
    <>
      {profileIsPublic &&
        <Form.Check
          id={`portfolio-public-switch-${section}`}
          className="portfolio-toggle printer-none"
          type="switch"
          label={renderLabel()}
          checked={isPublic}
          onChange={handleChange}
        />
      }
    </>
  )
}

PortfolioPublicToggle.propTypes = {
  section: PropTypes.string.isRequired,
}

export default PortfolioPublicToggle
