import React from "react"
import MilestoneView from "./career-plan-milestone-view"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit } from "@fortawesome/free-solid-svg-icons"

/**
 * Component that renders the milestones of a plan.
 *
 * @param {CareerPlan} plan
 *   The user's plan.
 * @param milestoneLabels
 *   The milestone labeling.
 *
 * @return {JSX.Element|[]}
 */
const CareerPlanView = ({ plan, milestoneLabels }) => {

  /**
   * The milestones.
   *
   * @type {Milestone[]}
   */
  const milestones = plan.milestones;

  /**
   * The milestone render array.
   *
   * @type {*[]}
   */
  const milestoneRender = [];

  if (!milestones || milestones.length < 1) {
    return (
      <div>This plan has no milestones set, click <FontAwesomeIcon aria-hidden="true" icon={faEdit} size="lg" /><span className="sr-only">Edit</span> it to add some!</div>
    );
  }

  milestones
    .sort(
      (a, b) =>
        new Date(a.period?._startDate ?? null) -
        new Date(b.period?._startDate ?? null)
    )
    .forEach((milestone, index) => {
      milestoneRender.push(
        <MilestoneView
          milestoneLabel={milestoneLabels[milestone.type]}
          key={index}
          milestone={milestone}
        />
      )
    })

  return milestoneRender;
}

export default CareerPlanView;
