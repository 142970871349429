import React, {useContext} from "react"
import PropTypes from "prop-types"
import { navigate } from "@reach/router"
import { FirebaseContext } from "../../components/Firebase"

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const {user, loading} = useContext(FirebaseContext)

  if (!user && !loading || user?.membership === null && !loading) {
    navigate('/403')
    return null
  }

  return <Component {...rest} />
}

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
}

export default PrivateRoute
