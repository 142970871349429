import React, { useContext, useEffect, useState, useRef } from "react"
import { Dropdown } from "semantic-ui-react"
import { FirebaseContext } from "../Firebase"
import "semantic-ui-css/components/dropdown.min.css"
import "semantic-ui-css/components/search.min.css"
import "semantic-ui-css/components/transition.min.css"
import "semantic-ui-css/components/label.min.css"
import "semantic-ui-css/components/icon.min.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHeartCircle } from "@fortawesome/pro-solid-svg-icons"

import { notificationOptions } from "../dashboard/notifications/notificationOptions"
import { FormattedMessage, useIntl } from "react-intl"


const defaultOptions = [
  {
    header: "true",
    key: "header-arts-and-creativity",
    text: "Arts and Creativity",
    disabled: true,
  },
  { key: "painting", value: "Painting", text: "Painting" },
  { key: "drawing", value: "Drawing", text: "Drawing" },
  { key: "sculpting", value: "Sculpting", text: "Sculpting" },
  { key: "photography", value: "Photography", text: "Photography" },
  { key: "pottery", value: "Pottery", text: "Pottery" },
  {
    key: "writing_(fiction,_poetry,_etc.)",
    value: "Writing (fiction, poetry, etc.)",
    text: "Writing (fiction, poetry, etc.)",
  },
  { key: "calligraphy", value: "Calligraphy", text: "Calligraphy" },
  { key: "graphic_design", value: "Graphic design", text: "Graphic design" },
  { key: "fashion_design", value: "Fashion design", text: "Fashion design" },
  { key: "acting", value: "Acting", text: "Acting" },
  { key: "dance", value: "Dance", text: "Dance" },
  {
    header: "true",
    key: "header-entertainment",
    text: "Entertainment",
    disabled: true,
  },
  {
    key: "watching_movies_and_tv_shows",
    value: "Watching movies and TV shows",
    text: "Watching movies and TV shows",
  },
  {
    key: "gaming_(video_games,_board_games,_etc.)",
    value: "Gaming (video games, board games, etc.)",
    text: "Gaming (video games, board games, etc.)",
  },
  {
    key: "reading_books_and_literature",
    value: "Reading books and literature",
    text: "Reading books and literature",
  },
  {
    key: "listening_to_music",
    value: "Listening to music",
    text: "Listening to music",
  },
  {
    key: "attending_live_performances_(concerts,_theater,_etc.)",
    value: "Attending live performances (concerts, theater, etc.)",
    text: "Attending live performances (concerts, theater, etc.)",
  },
  { key: "stand-up_comedy", value: "Stand-up comedy", text: "Stand-up comedy" },
  { key: "magic_shows", value: "Magic shows", text: "Magic shows" },
  {
    key: "improv_workshops",
    value: "Improv workshops",
    text: "Improv workshops",
  },
  { key: "karaoke", value: "Karaoke", text: "Karaoke" },
  { key: "escape_rooms", value: "Escape rooms", text: "Escape rooms" },
  {
    header: "true",
    key: "header-outdoor-and-nature",
    text: "Outdoor and Nature",
    disabled: true,
  },
  {
    key: "hiking_and_trekking",
    value: "Hiking and trekking",
    text: "Hiking and trekking",
  },
  { key: "camping", value: "Camping", text: "Camping" },
  { key: "birdwatching", value: "Birdwatching", text: "Birdwatching" },
  { key: "gardening", value: "Gardening", text: "Gardening" },
  {
    key: "nature_photography",
    value: "Nature photography",
    text: "Nature photography",
  },
  {
    key: "wildlife_conservation",
    value: "Wildlife conservation",
    text: "Wildlife conservation",
  },
  {
    key: "kayaking_or_canoeing",
    value: "Kayaking or canoeing",
    text: "Kayaking or canoeing",
  },
  { key: "geocaching", value: "Geocaching", text: "Geocaching" },
  { key: "surfing", value: "Surfing", text: "Surfing" },
  {
    key: "rockhounding_(collecting_rocks_and_minerals)",
    value: "Rockhounding (collecting rocks and minerals)",
    text: "Rockhounding (collecting rocks and minerals)",
  },
  {
    header: "true",
    key: "header-sports-and-physical-activities",
    text: "Sports and Physical Activities",
    disabled: true,
  },
  {
    key: "playing_sports_(soccer,_basketball,_tennis,_etc.)",
    value: "Playing sports (soccer, basketball, tennis, etc.)",
    text: "Playing sports (soccer, basketball, tennis, etc.)",
  },
  {
    key: "yoga_and_meditation",
    value: "Yoga and meditation",
    text: "Yoga and meditation",
  },
  {
    key: "running_and_jogging",
    value: "Running and jogging",
    text: "Running and jogging",
  },
  { key: "swimming", value: "Swimming", text: "Swimming" },
  { key: "cycling", value: "Cycling", text: "Cycling" },
  { key: "rock_climbing", value: "Rock climbing", text: "Rock climbing" },
  { key: "martial_arts", value: "Martial arts", text: "Martial arts" },
  { key: "parkour", value: "Parkour", text: "Parkour" },
  {
    key: "dance_fitness_(e.g.,_zumba)",
    value: "Dance fitness (e.g., Zumba)",
    text: "Dance fitness (e.g., Zumba)",
  },
  { key: "skilling", value: "Skilling", text: "Skilling" },
  {
    header: "true",
    key: "header-travel-and-exploration",
    text: "Travel and Exploration",
    disabled: true,
  },
  {
    key: "traveling_to_new_places",
    value: "Traveling to new places",
    text: "Traveling to new places",
  },
  {
    key: "exploring_different_cultures",
    value: "Exploring different cultures",
    text: "Exploring different cultures",
  },
  {
    key: "trying_local_cuisines",
    value: "Trying local cuisines",
    text: "Trying local cuisines",
  },
  { key: "road_trips", value: "Road trips", text: "Road trips" },
  { key: "backpacking", value: "Backpacking", text: "Backpacking" },
  {
    key: "urban_exploration_(exploring_abandoned_buildings_and_sites)",
    value: "Urban exploration (exploring abandoned buildings and sites)",
    text: "Urban exploration (exploring abandoned buildings and sites)",
  },
  { key: "cruises", value: "Cruises", text: "Cruises" },
  {
    key: "adventure_travel_(e.g.,_safaris,_trekking_expeditions)",
    value: "Adventure travel (e.g., safaris, trekking expeditions)",
    text: "Adventure travel (e.g., safaris, trekking expeditions)",
  },
  { key: "ecotourism", value: "Ecotourism", text: "Ecotourism" },
  { key: "train_journeys", value: "Train journeys", text: "Train journeys" },
  {
    header: "true",
    key: "header-food-and-cooking",
    text: "Food and Cooking",
    disabled: true,
  },
  {
    key: "cooking_and_trying_new_recipes",
    value: "Cooking and trying new recipes",
    text: "Cooking and trying new recipes",
  },
  { key: "baking", value: "Baking", text: "Baking" },
  { key: "gourmet_dining", value: "Gourmet dining", text: "Gourmet dining" },
  { key: "food_blogging", value: "Food blogging", text: "Food blogging" },
  {
    key: "wine_tasting_and_appreciation",
    value: "Wine tasting and appreciation",
    text: "Wine tasting and appreciation",
  },
  {
    key: "food_festivals_and_events",
    value: "Food festivals and events",
    text: "Food festivals and events",
  },
  {
    key: "fermentation_(making_sauerkraut,_kimchi,_etc.)",
    value: "Fermentation (making sauerkraut, kimchi, etc.)",
    text: "Fermentation (making sauerkraut, kimchi, etc.)",
  },
  {
    key: "vegan_or_plant-based_cooking",
    value: "Vegan or plant-based cooking",
    text: "Vegan or plant-based cooking",
  },
  {
    key: "food_photography_and_styling",
    value: "Food photography and styling",
    text: "Food photography and styling",
  },
  {
    key: "molecular_gastronomy",
    value: "Molecular gastronomy",
    text: "Molecular gastronomy",
  },
  {
    header: "true",
    key: "header-technology",
    text: "Technology",
    disabled: true,
  },
  {
    key: "exploring_new_gadgets_and_tech_innovations",
    value: "Exploring new gadgets and tech innovations",
    text: "Exploring new gadgets and tech innovations",
  },
  {
    key: "coding_and_programming",
    value: "Coding and programming",
    text: "Coding and programming",
  },
  { key: "robotics", value: "Robotics", text: "Robotics" },
  {
    key: "virtual_reality_(vr)_experiences",
    value: "Virtual reality (VR) experiences",
    text: "Virtual reality (VR) experiences",
  },
  {
    key: "electronics_tinkering_and_diy_projects",
    value: "Electronics tinkering and DIY projects",
    text: "Electronics tinkering and DIY projects",
  },
  {
    key: "drones_and_aerial_photography",
    value: "Drones and aerial photography",
    text: "Drones and aerial photography",
  },
  {
    key: "smart_home_automation",
    value: "Smart home automation",
    text: "Smart home automation",
  },
  { key: "3d_printing", value: "3D printing", text: "3D printing" },
  {
    key: "internet_of_things_(iot)_projects",
    value: "Internet of Things (IoT) projects",
    text: "Internet of Things (IoT) projects",
  },
  {
    key: "cryptocurrency_and_blockchain_technology",
    value: "Cryptocurrency and blockchain technology",
    text: "Cryptocurrency and blockchain technology",
  },
  {
    header: "true",
    key: "header-science-and-knowledge",
    text: "Science and Knowledge",
    disabled: true,
  },
  {
    key: "astronomy_and_stargazing",
    value: "Astronomy and stargazing",
    text: "Astronomy and stargazing",
  },
  {
    key: "learning_about_scientific_discoveries",
    value: "Learning about scientific discoveries",
    text: "Learning about scientific discoveries",
  },
  {
    key: "philosophy_and_deep_discussions",
    value: "Philosophy and deep discussions",
    text: "Philosophy and deep discussions",
  },
  {
    key: "history_and_historical_research",
    value: "History and historical research",
    text: "History and historical research",
  },
  {
    key: "learning_new_languages",
    value: "Learning new languages",
    text: "Learning new languages",
  },
  { key: "archaeology", value: "Archaeology", text: "Archaeology" },
  {
    key: "particle_physics_and_cosmology",
    value: "Particle physics and cosmology",
    text: "Particle physics and cosmology",
  },
  {
    key: "psychology_and_human_behavior",
    value: "Psychology and human behavior",
    text: "Psychology and human behavior",
  },
  {
    key: "marine_biology_and_oceanography",
    value: "Marine biology and oceanography",
    text: "Marine biology and oceanography",
  },
  {
    key: "meteorology_and_weather_tracking",
    value: "Meteorology and weather tracking",
    text: "Meteorology and weather tracking",
  },
  {
    header: "true",
    key: "header-community",
    text: "Community",
    disabled: true,
  },
  {
    key: "volunteering_and_community_service",
    value: "Volunteering and community service",
    text: "Volunteering and community service",
  },
  {
    key: "social_and_environmental_activism",
    value: "Social and environmental activism",
    text: "Social and environmental activism",
  },
  {
    key: "joining_clubs_or_organizations",
    value: "Joining clubs or organizations",
    text: "Joining clubs or organizations",
  },
  {
    key: "networking_and_social_events",
    value: "Networking and social events",
    text: "Networking and social events",
  },
  {
    key: "mentoring_or_tutoring",
    value: "Mentoring or tutoring",
    text: "Mentoring or tutoring",
  },
  {
    key: "public_speaking_and_toastmasters",
    value: "Public speaking and Toastmasters",
    text: "Public speaking and Toastmasters",
  },
  { key: "debating_clubs", value: "Debating clubs", text: "Debating clubs" },
  {
    key: "support_groups_(e.g.,_mental_health,_recovery)",
    value: "Support groups (e.g., mental health, recovery)",
    text: "Support groups (e.g., mental health, recovery)",
  },
  {
    key: "community_gardening",
    value: "Community gardening",
    text: "Community gardening",
  },
  {
    key: "fundraising_for_charitable_causes",
    value: "Fundraising for charitable causes",
    text: "Fundraising for charitable causes",
  },

  {
    header: "true",
    key: "header-health-and-wellness",
    text: "Health and Wellness",
    disabled: true,
  },
  {
    key: "fitness_and_exercise_",
    value: "Fitness and exercise ",
    text: "Fitness and exercise ",
  },
  {
    key: "healthy_cooking_and_nutrition",
    value: "Healthy cooking and nutrition",
    text: "Healthy cooking and nutrition",
  },
  {
    key: "meditation_and_mindfulness",
    value: "Meditation and mindfulness",
    text: "Meditation and mindfulness",
  },
  { key: "acupuncture", value: "Acupuncture", text: "Acupuncture" },
  { key: "pilates", value: "Pilates", text: "Pilates" },
  { key: "aromatherapy", value: "Aromatherapy", text: "Aromatherapy" },
  { key: "ayurveda", value: "Ayurveda", text: "Ayurveda" },
  { key: "tai_chi", value: "Tai Chi", text: "Tai Chi" },
]

const defaultOptionsEs = [
  {
    header: "true",
    key: "header-arts-and-creativity",
    text: "Arte y Creatividad",
    disabled: true,
  },
  { key: "painting", value: "Painting", text: "Pintura" },
  { key: "drawing", value: "Drawing", text: "Dibujo" },
  { key: "sculpting", value: "Sculpting", text: "Escultura" },
  { key: "photography", value: "Photography", text: "Fotografía" },
  { key: "pottery", value: "Pottery", text: "Cerámica" },
  {
    key: "writing_(fiction,_poetry,_etc.)",
    value: "Writing (fiction, poetry, etc.)",
    text: "Escritura (ficción, poesía, etc.)",
  },
  { key: "calligraphy", value: "Calligraphy", text: "Caligrafía" },
  { key: "graphic_design", value: "Graphic design", text: "Diseño gráfico" },
  { key: "fashion_design", value: "Fashion design", text: "Diseño de moda" },
  { key: "acting", value: "Acting", text: "Interino" },
  { key: "dance", value: "Dance", text: "Bailar" },
  {
    header: "true",
    key: "header-entertainment",
    text: "Diversión",
    disabled: true,
  },
  {
    key: "watching_movies_and_tv_shows",
    value: "Watching movies and TV shows",
    text: "Ver películas y programas de televisión",
  },
  {
    key: "gaming_(video_games,_board_games,_etc.)",
    value: "Gaming (video games, board games, etc.)",
    text: "Juegos (videojuegos, juegos de mesa, etc.)",
  },
  {
    key: "reading_books_and_literature",
    value: "Reading books and literature",
    text: "Lectura de libros y literatura",
  },
  {
    key: "listening_to_music",
    value: "Listening to music",
    text: "Escuchar música",
  },
  {
    key: "attending_live_performances_(concerts,_theater,_etc.)",
    value: "Attending live performances (concerts, theater, etc.)",
    text: "Asistir a actuaciones en vivo (conciertos, teatro, etc.)",
  },
  {
    key: "stand-up_comedy",
    value: "Stand-up comedy",
    text: "Comedia stand-up",
  },
  { key: "magic_shows", value: "Magic shows", text: "Espectáculos de magia" },
  {
    key: "improv_workshops",
    value: "Improv workshops",
    text: "Talleres de improvisación",
  },
  { key: "karaoke", value: "Karaoke", text: "Karaoke" },
  { key: "escape_rooms", value: "Escape rooms", text: "Salas de escape" },
  {
    header: "true",
    key: "header-outdoor-and-nature",
    text: "Exterior y naturaleza",
    disabled: true,
  },
  {
    key: "hiking_and_trekking",
    value: "Hiking and trekking",
    text: "Senderismo y trekking",
  },
  { key: "camping", value: "Camping", text: "Acampada" },
  { key: "birdwatching", value: "Birdwatching", text: "Observación de aves" },
  { key: "gardening", value: "Gardening", text: "Jardinería" },
  {
    key: "nature_photography",
    value: "Nature photography",
    text: "Fotografía de naturaleza",
  },
  {
    key: "wildlife_conservation",
    value: "Wildlife conservation",
    text: "Conservación de la vida silvestre",
  },
  {
    key: "kayaking_or_canoeing",
    value: "Kayaking or canoeing",
    text: "Kayak o piragüismo",
  },
  { key: "geocaching", value: "Geocaching", text: "Geocaching" },
  { key: "surfing", value: "Surfing", text: "Surf" },
  {
    key: "rockhounding_(collecting_rocks_and_minerals)",
    value: "Rockhounding (collecting rocks and minerals)",
    text: "Rockhounding (recolección de rocas y minerales)",
  },
  {
    header: "true",
    key: "header-sports-and-physical-activities",
    text: "Deportes y actividades físicas",
    disabled: true,
  },
  {
    key: "playing_sports_(soccer,_basketball,_tennis,_etc.)",
    value: "Playing sports (soccer, basketball, tennis, etc.)",
    text: "Practicar deportes (fútbol, baloncesto, tenis, etc.)",
  },
  {
    key: "yoga_and_meditation",
    value: "Yoga and meditation",
    text: "Yoga y meditación",
  },
  {
    key: "running_and_jogging",
    value: "Running and jogging",
    text: "Correr y trotar",
  },
  { key: "swimming", value: "Swimming", text: "Natación" },
  { key: "cycling", value: "Cycling", text: "Ciclismo" },
  { key: "rock_climbing", value: "Rock climbing", text: "Escalada" },
  { key: "martial_arts", value: "Martial arts", text: "Artes marciales" },
  { key: "parkour", value: "Parkour", text: "Parkour" },
  {
    key: "dance_fitness_(e.g.,_zumba)",
    value: "Dance fitness (e.g., Zumba)",
    text: "Fitness de baile (por ejemplo, Zumba)",
  },
  { key: "skilling", value: "Skilling", text: "Skilling" },
  {
    header: "true",
    key: "header-travel-and-exploration",
    text: "Viajes y Exploración",
    disabled: true,
  },
  {
    key: "traveling_to_new_places",
    value: "Traveling to new places",
    text: "Viajar a nuevos lugares",
  },
  {
    key: "exploring_different_cultures",
    value: "Exploring different cultures",
    text: "Explorando diferentes culturas",
  },
  {
    key: "trying_local_cuisines",
    value: "Trying local cuisines",
    text: "Probando cocinas locales",
  },
  { key: "road_trips", value: "Road trips", text: "Viajes por carretera" },
  { key: "backpacking", value: "Backpacking", text: "Mochilero" },
  {
    key: "urban_exploration_(exploring_abandoned_buildings_and_sites)",
    value: "Urban exploration (exploring abandoned buildings and sites)",
    text: "Exploración urbana (exploración de edificios y sitios abandonados)",
  },
  { key: "cruises", value: "Cruises", text: "Cruceros" },
  {
    key: "adventure_travel_(e.g.,_safaris,_trekking_expeditions)",
    value: "Adventure travel (e.g., safaris, trekking expeditions)",
    text: "Viajes de aventura (por ejemplo, safaris, expediciones de trekking)",
  },
  { key: "ecotourism", value: "Ecotourism", text: "Ecoturismo" },
  { key: "train_journeys", value: "Train journeys", text: "Viajes en tren" },
  {
    header: "true",
    key: "header-food-and-cooking",
    text: "Comida y cocina",
    disabled: true,
  },
  {
    key: "cooking_and_trying_new_recipes",
    value: "Cooking and trying new recipes",
    text: "Cocinar y probar nuevas recetas",
  },
  { key: "baking", value: "Baking", text: "Hornada" },
  {
    key: "gourmet_dining",
    value: "Gourmet dining",
    text: "Gastronomía gourmet",
  },
  { key: "food_blogging", value: "Food blogging", text: "Blogs de alimentos" },
  {
    key: "wine_tasting_and_appreciation",
    value: "Wine tasting and appreciation",
    text: "Degustación y apreciación de vinos",
  },
  {
    key: "food_festivals_and_events",
    value: "Food festivals and events",
    text: "Festivales y eventos gastronómicos",
  },
  {
    key: "fermentation_(making_sauerkraut,_kimchi,_etc.)",
    value: "Fermentation (making sauerkraut, kimchi, etc.)",
    text: "Fermentación (hacer chucrut, kimchi, etc.)",
  },
  {
    key: "vegan_or_plant-based_cooking",
    value: "Vegan or plant-based cooking",
    text: "Cocina vegana o basada en plantas",
  },
  {
    key: "food_photography_and_styling",
    value: "Food photography and styling",
    text: "Fotografía y estilismo de alimentos",
  },
  {
    key: "molecular_gastronomy",
    value: "Molecular gastronomy",
    text: "Gastronomía molecular",
  },
  {
    header: "true",
    key: "header-technology",
    text: "Tecnología",
    disabled: true,
  },
  {
    key: "exploring_new_gadgets_and_tech_innovations",
    value: "Exploring new gadgets and tech innovations",
    text: "Explorando nuevos gadgets e innovaciones tecnológicas",
  },
  {
    key: "coding_and_programming",
    value: "Coding and programming",
    text: "Codificación y programación",
  },
  { key: "robotics", value: "Robotics", text: "Robótica" },
  {
    key: "virtual_reality_(vr)_experiences",
    value: "Virtual reality (VR) experiences",
    text: "Experiencias de realidad virtual (VR)",
  },
  {
    key: "electronics_tinkering_and_diy_projects",
    value: "Electronics tinkering and DIY projects",
    text: "Retoques electrónicos y proyectos de bricolaje",
  },
  {
    key: "drones_and_aerial_photography",
    value: "Drones and aerial photography",
    text: "Drones y fotografía aérea",
  },
  {
    key: "smart_home_automation",
    value: "Smart home automation",
    text: "Domótica inteligente",
  },
  { key: "3d_printing", value: "3D printing", text: "Impresión 3D" },
  {
    key: "internet_of_things_(iot)_projects",
    value: "Internet of Things (IoT) projects",
    text: "Proyectos de Internet de las cosas (IoT)",
  },
  {
    key: "cryptocurrency_and_blockchain_technology",
    value: "Cryptocurrency and blockchain technology",
    text: "Criptomoneda y tecnología blockchain",
  },
  {
    header: "true",
    key: "header-science-and-knowledge",
    text: "Ciencia y Conocimiento",
    disabled: true,
  },
  {
    key: "astronomy_and_stargazing",
    value: "Astronomy and stargazing",
    text: "Astronomía y observación de estrellas",
  },
  {
    key: "learning_about_scientific_discoveries",
    value: "Learning about scientific discoveries",
    text: "Aprender sobre los descubrimientos científicos",
  },
  {
    key: "philosophy_and_deep_discussions",
    value: "Philosophy and deep discussions",
    text: "Filosofía y discusiones profundas",
  },
  {
    key: "history_and_historical_research",
    value: "History and historical research",
    text: "Historia e investigación histórica",
  },
  {
    key: "learning_new_languages",
    value: "Learning new languages",
    text: "Aprender nuevos idiomas",
  },
  { key: "archaeology", value: "Archaeology", text: "Arqueología" },
  {
    key: "particle_physics_and_cosmology",
    value: "Particle physics and cosmology",
    text: "Física de partículas y cosmología",
  },
  {
    key: "psychology_and_human_behavior",
    value: "Psychology and human behavior",
    text: "Psicología y comportamiento humano",
  },
  {
    key: "marine_biology_and_oceanography",
    value: "Marine biology and oceanography",
    text: "Biología marina y oceanografía",
  },
  {
    key: "meteorology_and_weather_tracking",
    value: "Meteorology and weather tracking",
    text: "Meteorología y seguimiento meteorológico",
  },
  {
    header: "true",
    key: "header-community",
    text: "Comunidad",
    disabled: true,
  },
  {
    key: "volunteering_and_community_service",
    value: "Volunteering and community service",
    text: "Voluntariado y servicio comunitario",
  },
  {
    key: "social_and_environmental_activism",
    value: "Social and environmental activism",
    text: "Activismo social y ambiental",
  },
  {
    key: "joining_clubs_or_organizations",
    value: "Joining clubs or organizations",
    text: "Unirse a clubes u organizaciones",
  },
  {
    key: "networking_and_social_events",
    value: "Networking and social events",
    text: "Networking y eventos sociales",
  },
  {
    key: "mentoring_or_tutoring",
    value: "Mentoring or tutoring",
    text: "Tutoría o tutoría",
  },
  {
    key: "public_speaking_and_toastmasters",
    value: "Public speaking and Toastmasters",
    text: "Hablar en público y Toastmasters",
  },
  { key: "debating_clubs", value: "Debating clubs", text: "Clubes de debate" },
  {
    key: "support_groups_(e.g.,_mental_health,_recovery)",
    value: "Support groups (e.g., mental health, recovery)",
    text: "Grupos de apoyo (p. ej., salud mental, recuperación)",
  },
  {
    key: "community_gardening",
    value: "Community gardening",
    text: "Jardinería comunitaria",
  },
  {
    key: "fundraising_for_charitable_causes",
    value: "Fundraising for charitable causes",
    text: "Recaudación de fondos para causas benéficas",
  },
  {
    header: "true",
    key: "header-health-and-wellness",
    text: "Salud y Bienestar",
    disabled: true,
  },
  {
    key: "fitness_and_exercise_",
    value: "Fitness and exercise ",
    text: "Fitness y ejercicio ",
  },
  {
    key: "healthy_cooking_and_nutrition",
    value: "Healthy cooking and nutrition",
    text: "Cocina y nutrición saludables",
  },
  {
    key: "meditation_and_mindfulness",
    value: "Meditation and mindfulness",
    text: "Meditación y mindfulness",
  },
  { key: "acupuncture", value: "Acupuncture", text: "Acupuntura" },
  { key: "pilates", value: "Pilates", text: "Pilates" },
  { key: "aromatherapy", value: "Aromatherapy", text: "Aromaterapia" },
  { key: "ayurveda", value: "Ayurveda", text: "Ayurveda" },
  { key: "tai_chi", value: "Tai Chi", text: "Tai Chi" },
];


const PortfolioInterests = ({ isModalOpen = false, isPublicFacing }) => {
  const maxCharacterCount = 40
  const { locale } = useIntl()
  const { firebase, profile } = useContext(FirebaseContext)
  const [options, setOptions] = useState(locale === 'en-US' ? defaultOptions : defaultOptionsEs)
  const [buttonText, setButtonText] = useState(locale === 'en-US' ? "+ Add" : "+ Agregar")
  const [buttonActive, setButtonActive] = useState("inactive")
  const [currentValues, setCurrentValues] = useState([])
  // track if we've merged user's saved custom values with default values
  const [mergedValues, setMergedValues] = useState(false)

  const myRef = useRef(null)

  useEffect(() => {
    if (profile && profile.interests) {
      // The following should only be run one time.
      if (!mergedValues) {
        // set dropdown selections to existing firestore values
        setCurrentValues(profile.interests)
        mergeUserOptionsWithDefaults()
      }
    }
  }, [profile, mergedValues])

  useEffect(() => {
    if (profile && profile.interests) {
      setOptions(locale === 'en-US' ? defaultOptions : defaultOptionsEs)
    }
  }, [locale])

  // gets updated interests if changed in modal
  useEffect(() => {
    if (profile && profile.interests) {
      setCurrentValues(profile.interests)
      setOptions(options => options.filter(option => !option.key.includes("_custom")))
      mergeUserOptionsWithDefaults()
    }
  }, [isModalOpen])

  /**
   * Take existing firestore profile fields and merge those with
   * the default options. Failure to do this will prevent the
   * dropdown component from setting the user's saved fields as
   * initial values.
   */
  const mergeUserOptionsWithDefaults = () => {
    setMergedValues(true)
    const optionsToMerge = []
    const defaultOptionsArray = []
    // Extract value only from defaultOptions objects
    defaultOptions.forEach(option => {
      if (option.value) {
        defaultOptionsArray.push(option.value)
      }
    })

    // Check if each stored item is a default or custom the user created. If it is
    // custom, it must be added to the merge list so it becomes a selectable option.
    profile.interests.forEach(interest => {
      if (defaultOptionsArray.includes(interest) === false) {
        const key = interest.toLowerCase().replace(/ /g, "_")
        optionsToMerge.push({ key: key + "_custom", value: interest, text: interest })
      }
    })

    // combine default options with user's custom options.
    if (optionsToMerge.length > 0) {
      setOptions(options => [
        ...options,
        ...optionsToMerge,
      ])
    }
  }

  const handleAddition = (e, { value }) => {
    const key = value.toLowerCase().replace(/ /g, "_")
    // item needs to be added to the default options for it to stick
    setOptions(options => [...options, { key: key + "_custom", value: value, text: value }])
  }

  const handleChange = (e, { value }) => {
    setCurrentValues(value)

    firebase.updateProfile({
      data: { interests: value },
    })
  }

  const setFocus = () => {
    if (myRef.current)
      myRef.current.ref.current.querySelector("input:first-of-type").focus()
  }

  /**
   * Handle keydown events in the drop down and prevent
   * user from exceeding a max character count.
   * @param e {SyntheticKeyboardEvent}
   */
  const handleKeyDown = e => {
    const regex = new RegExp("^[\\w\\[\\] `~!@#$%^?.,&*()={}:;<>+'\"-]{1}$")
    if (e.target.value.length >= maxCharacterCount && regex.test(e.key)) {
      e.preventDefault()
    }
  }

  const handleButtonClick = () => {
    if (buttonText === "+ Add" || buttonText === "+ Agregar") {
      setButtonText(locale === 'en-US' ? "Done" : "Hecho")
      setFocus()
      setButtonActive("active")
    } else if (buttonText === "Done" || buttonText === "Hecho") {
      setButtonText(locale === 'en-US' ? "+ Add" : "+ Agregar")
      setButtonActive("inactive")
    }
  }

  const handleBlur = e => {
    if (e?.relatedTarget?.innerText === "Done" || e?.relatedTarget?.innerText === "Hecho") {
      return
    }
    setButtonText(locale === 'en-US' ? "+ Add" : "+ Agregar")
    setButtonActive("inactive")
  }

  const handleFocus = e => {
    if (e?.relatedTarget?.innerText === "+ Add" || e?.relatedTarget?.innerText === "+ Agregar") {
      return
    }
    setButtonText(locale === 'en-US' ? "Done" : "Hecho")
    setButtonActive("active")
  }

  return (
    <div className="portfolio-attr-interests">
      <div className="portfolio-attr-interests-header">
        <h3>
          <span style={{ marginRight: "0.5rem" }}>
            <FontAwesomeIcon icon={faHeartCircle} color={"#5E4CF2"} size={"sm"}/>
          </span>
          <FormattedMessage id="portfolio-interests-interests" defaultMessage="Interests" />
        </h3>
        { !isPublicFacing &&
          <button
            className={`button component-button component-button-ghost component-button-ghost-${buttonActive} component-button-small`}
            onClick={handleButtonClick}
          >
            {buttonText}
          </button>
        }
      </div>
      <blockquote className="component-blockquote component-blockquote-stories printer-none">
        <p>
          <FormattedMessage
            id="portfolio-interests-intrest-description"
            defaultMessage="Ask yourself, what do I like? Listen to your inner voice and honor it."
          />
        </p>
      </blockquote>
      <div className="portfolio-attr-add paragraph">
        {profile && (
          <Dropdown
            className="printer-no-border"
            ref={myRef}
            options={options}
            value={currentValues}
            onAddItem={handleAddition}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            placeholder="Type here to add interests."
            fluid
            multiple
            search
            selection
            icon=""
            allowAdditions
            upward={false}
            onFocus={handleFocus}
            onBlur={handleBlur}
            renderLabel={(label) =>
            ({
              className: "custom-portfolio-skills-label",
              content: label.text,
            })}
          />
        )}
      </div>
    </div>
  )
}

export default PortfolioInterests
