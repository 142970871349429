import React, { useState, useRef, useEffect, useContext, useCallback } from "react"
import ReactDOM from 'react-dom';
import { FirebaseContext } from "../Firebase"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { faArrowToBottom, faStarShooting } from '@fortawesome/pro-solid-svg-icons';
import { notificationOptions } from "../dashboard/notifications/notificationOptions"
import { FormattedMessage, useIntl } from "react-intl"
import SimpleLocalize from "../SimpleLocalize"
import { v4 as uuidv4 } from "uuid"
import { Dropdown, ButtonGroup } from "react-bootstrap"
import * as XLSX from 'xlsx';

const DashboardThisWeek = ({ pageContext }) => {
  const [data, setData] = useState([])
  const [exportCsv, setExportCsv] = useState(false)

  const handleExportData = useCallback((itemType, itemsData) => {
    setData(prevData => ({
      ...prevData,
      [itemType]: itemsData,
    }));
  }, []);

  const flattenDataForExcel = (data) => {
    const wb = XLSX.utils.book_new();
    const sections = Object.keys(data);
  
    sections.forEach((section) => {
      let ws_data = [];
      const sectionName = section === "goals" ? "Goals" : "Looking Forward To";
      const headers = ['Date', 'Name', 'Status'];
      const headerKeys = ['date', 'name', 'completed'];
  
      ws_data.push([sectionName]);
  
      if (data[section].length > 0) {
        ws_data.push(headers);
  
        data[section].forEach(item => {
          const row = headerKeys.map(header =>
            header === "completed"
              ? item[header] === true
                ? "Done"
                : "Active"
              : item[header]
          )
          ws_data.push(row);
        });
      }
  
      const ws = XLSX.utils.aoa_to_sheet(ws_data);
      XLSX.utils.book_append_sheet(wb, ws, sectionName);
    });
  
    return wb;
  }

  const downloadExcel = (data) => {
    const wb = flattenDataForExcel(data);
    XLSX.writeFile(wb, 'this_week.xlsx');
  }

  useEffect(() => {
    if (exportCsv && data.length !== 0) {
      downloadExcel(data)
    }
    setExportCsv(false)
  }, [data, exportCsv])

  return (
    <SimpleLocalize {...{ pageContext }}>
      <section className="this-week">
        <div className="this-week-details">
          <div
           className="this-week-details-header"
          >
            <div
              className="eyebrow component-eyebrow this-week-details-header-title"
            >
              <FormattedMessage
                id="dashboard-this-week"
                defaultMessage="This Week"
              />
              <FontAwesomeIcon className="this-week-details-header-title-icon" icon={faStarShooting} color="#CAFE07" size="lg" style={{ marginLeft: "0.5rem" }}/>
            </div>
            <button
              className="this-week-details-header-export"
              onClick={() => setExportCsv(true)}
            >
              <FontAwesomeIcon
                icon={faArrowToBottom}
                size="lg"
                color="#54757A"
              />
            </button>
          </div>
          <ThisWeekItem type="goals" exportCsv={exportCsv} onExportData={handleExportData}/>
          <ThisWeekItem type="lookingForwardTo" exportCsv={exportCsv} onExportData={handleExportData}/>
        </div> 
      </section>
    </SimpleLocalize>
  )
}

const ThisWeekItem = ( {type, exportCsv, onExportData} ) => {
  const { setPointTotals, setNotification, firebase, profile, notifier} = useContext(
    FirebaseContext
  )

  const [newEntryVal, setNewEntryVal] = useState("")
  const [items, setItems] = useState([])
  const [editingItems, setEditingItems] = useState(
    items.reduce((prev, curr) => ({ ...prev, [curr.id]: false }), {})
  )
  const [pastEdit, setPastEdit] = useState("")
  const prevItemsLenRef = useRef(items.length)
  const [filter, setFilter] = useState("active")
  const [menuOpen, setMenuOpen] = useState(null)

  const inputRefs = useRef({})
  const itemsEndRef = useRef(null)

  const intl = useIntl();


  useEffect(() => {
    const currentLength = items.length
    if (prevItemsLenRef.current && currentLength > prevItemsLenRef.current) {
      if (itemsEndRef.current && itemsEndRef.current.parentElement) {
        const container = itemsEndRef.current.parentElement
        container.scrollTop = container.scrollHeight
      }
    }
    prevItemsLenRef.current = currentLength
  }, [items.length])

  useEffect(() => {
    items.forEach(item => {
      if (editingItems[item.id] && inputRefs.current[item.id]) {
        inputRefs.current[item.id].focus()
      }
    })
  }, [editingItems])

  useEffect(() => {
    onExportData(type, items);
  }, [items, type]);

  useEffect(() => {
    if (profile[type] && items.length === 0) {
      let items;
      if (!Array.isArray(profile[type])) {
        const currentDate = new Date().toLocaleDateString()
        const newItem = {
          id: uuidv4(),
          name: profile[type],
          completed: false,
          date: currentDate,
        }
  
        items = [newItem]
      } else {
        items = profile[type]
      }
      setItems(items)
    }
  }, [profile[type]])

  const handleAddBlur = async e => {
    if (e.target.value != "") {
      const currentDate = new Date().toLocaleDateString()
      const newItem = {
        id: uuidv4(),
        name: newEntryVal,
        completed: false,
        date: currentDate,
      }

      setItems(prevItems => {
        const updatedItems = [...prevItems, newItem]

        firebase.updateProfile({
          data: { [type]: updatedItems },
        })

        return updatedItems
      })

      setNewEntryVal("")

      try {
        await notifier.handleGoalSetting(profile)
      } catch (e) {
        console.error(e)
      }
    }
  }

  const handleCheckboxChange = (e, item) => {
    const completed = e.target.checked
    const newItem = { ...item, completed: completed }

    setItems(prevItems => {
      const updatedItems = prevItems.map(prevItem => (item.id === prevItem.id ? newItem : prevItem))

      firebase.updateProfile({
        data: { [type]: updatedItems },
      })

      return updatedItems
    })
  }

  const handleEditBlur = () => {

    let editedItems = items
    if (items.some(item => item.name == "")) {
      editedItems = items.map(item => item.name == "" ? pastEdit : item)
      setPastEdit("")
      setItems(editedItems)
    }

    const updatedEditingItems = items.reduce(
      (prev, curr) => ({ ...prev, [curr.id]: false }),
      {}
    )
    setEditingItems(updatedEditingItems)
    
    firebase.updateProfile({
      data: {[type] : editedItems}
    })
  }

  const handleEdit = (e, id) => {    
    e.stopPropagation()
    let updatedEditingItems = items.reduce(
      (prev, curr) => ({ ...prev, [curr.id]: false }),
      {}
    )

    setPastEdit(items.filter(item => item.id === id)[0])
    updatedEditingItems[id] = true
    setEditingItems(updatedEditingItems)
  }

  const handleEditingChange = (e, id) => {
    e.stopPropagation()           
    const newName = e.currentTarget.textContent

    setItems(prevItems => {
      const updatedItems = prevItems.map(prevItem =>
        id === prevItem.id ? { ...prevItem, name: newName } : prevItem
      )

      return updatedItems
    })
  }

  const handleKeyDown = (e, type) => {
    if (e.key == "Enter" && type == "add") {
      handleAddBlur(e)
    }

    if (e.key == "Enter" && type == "edit") {
      handleEditBlur()
    }
  }

  const handleChange = e => {
    setNewEntryVal(e.target.value)
  }
  
  return (
    <div className="this-week">
      <div className="this-week-details-title">
        <p className="this-week-details-title-item">
          {type === "goals" ? (
            <FormattedMessage
              id={"dashboard-my-goals"}
              defaultMessage={"My Goals"}
            />
          ) : (
            <FormattedMessage
              id={"dashboard-something-I'm-looking-forward-to"}
              defaultMessage={"Something I'm Looking Forward To"}
            />
          )}
        </p>
        {items.length !== 0 && (
          <div className="this-week-details-title-item-types">
            <button
              className={
                filter === "active"
                  ? "filter-button filter-button-bold"
                  : "filter-button"
              }
              onClick={() => {
                setFilter("active")
              }}
            >
              <FormattedMessage
                id="dashboard-filter-active"
                defaultMessage="Active"
              />
            </button>
            <button
              className={
                filter === "done"
                  ? "filter-button filter-button-bold"
                  : "filter-button"
              }
              onClick={() => {
                setFilter("done")
              }}
            >
              <FormattedMessage
                id="dashboard-filter-completed"
                defaultMessage="Completed"
              />
            </button>
            <button
              className={
                filter === "all"
                  ? "filter-button filter-button-last filter-button-bold"
                  : "filter-button filter-button-last"
              }
              onClick={() => {
                setFilter("all")
              }}
            >
              <FormattedMessage
                id="dashboard-filter-all"
                defaultMessage="All"
              />
            </button>
          </div>
        )}
      </div>
      {items.length != 0 ? (
        <div className="this-week-details-filled">
          <div className="this-week-details-items">
            {items
              .filter(item => {
                if (filter === "active") return !item.completed
                if (filter === "done") return item.completed
                return true
              })
              .map((item, index) => {
                return (
                  <div
                    className={
                      index === 0
                        ? "item-container item-container-first"
                        : "item-container"
                    }
                    key={item.id}
                  >
                    <div className="item-container-input">
                      <label className="custom-checkbox">
                        <input
                          type="checkbox"
                          checked={item.completed}
                          onChange={e => handleCheckboxChange(e, item)}
                          onKeyDown={e => handleKeyDown(e, "add")}
                        />
                        <span className="checkmark"></span>
                      </label>
                      {editingItems[item.id] ? (
                        <>
                          <div
                            className="editing-input"
                            contentEditable={true}
                            ref={el => {
                              if (el) {
                                inputRefs.current[item.id] = el
                                el.textContent = item.name
                              }
                            }}
                            style={
                              item.completed
                                ? {
                                    textDecoration: "line-through",
                                    opacity: 0.5,
                                  }
                                : {}
                            }
                            onBlur={e => handleEditBlur()}
                            onKeyDown={e => handleKeyDown(e, "edit", item)}
                            onInput={e => handleEditingChange(e, item.id)}
                          ></div>
                        </>
                      ) : (
                        <label
                          className="item-label"
                          onDoubleClick={e => handleEdit(e, item.id)}
                          style={
                            item.completed
                              ? { textDecoration: "line-through", opacity: 0.5 }
                              : {}
                          }
                        >
                          {item.name}
                        </label>
                      )}
                    </div>
                    <div className="item-container-menu">
                      <p className="item-container-menu-date">{item.date}</p>
                      <EditingMenu
                        itemId={item.id}
                        menuOpen={menuOpen}
                        setMenuOpen={setMenuOpen}
                        handleEdit={handleEdit}
                        firebase={firebase}
                        type={type}
                        setItems={setItems}
                        items={items}
                      />
                    </div>
                  </div>
                )
              })}
            <div ref={itemsEndRef} />
          </div>
        </div>
      ) : (
        <div className="this-week-details-empty">
          <blockquote
            className="component-blockquote component-blockquote-stories"
            style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}
          >
            <p>
              {type === "goals" ? (
                <FormattedMessage
                  id={"dashboard-para-1"}
                  defaultMessage={`What do you want to accomplish? Set SMART Specific, Measurable,
                      Attainable, Relevant, and Time-bound goals.`}
                />
              ) : (
                <FormattedMessage
                  id={"dashboard-para-2"}
                  defaultMessage={`Take the time to focus on the good things in your life.`}
                />
              )}
            </p>
          </blockquote>
        </div>
      )}
      <input
        className="editing-input editing-input-new"
        type="text"
        value={newEntryVal}
        onChange={handleChange}
        onBlur={e => handleAddBlur(e)}
        onKeyDown={e => handleKeyDown(e, "add")}
        placeholder={
          type === "goals"
            ? intl.formatMessage({
                id: "dashboard-new-goal",
                defaultMessage: "New Goal",
              })
            : intl.formatMessage({
                id: "dashboard-new-event",
                defaultMessage: "New Event",
              })
        }
      />
    </div>
  )
}



const EditingMenu = ({ itemId, menuOpen, setMenuOpen, handleEdit, firebase, type, setItems }) => {

  const toggleRef = useRef(null);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });

  const updateDropdownPosition = () => {
    if (toggleRef.current) {
      const rect = toggleRef.current.getBoundingClientRect();
      setDropdownPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX
      });
    }
  };

  const handleDropdownClick = () => {
    updateDropdownPosition()

    if (menuOpen !== itemId) {
      setMenuOpen(itemId)
    } else {
      setMenuOpen(null)
    }
  }

  const handleDropdownBlur = () => {
      if (document.activeElement === document.body) {
        setMenuOpen(null);
      }
  }

  const handleDropdownDelete = () => {

    setMenuOpen(null)

    setItems(prevItems => {
      const updatedItems = prevItems.filter(prevItem => itemId !== prevItem.id)
      
      firebase.updateProfile({
        data: {[type] : updatedItems}
      })
      return updatedItems
    })

  }

  return (
    <Dropdown
      as={ButtonGroup}
      className="ellipsis"
      onClick={e => handleDropdownClick()}
    >
      <Dropdown.Toggle
        id={`dropdown-basic-${itemId}`}
        className="item-dropdown"
        style={{
          background: "none",
          border: "none",
          opacity: menuOpen == itemId ? 1 : null,
        }}
        onBlur={handleDropdownBlur}
      >
        <FontAwesomeIcon icon={faEllipsisH} />
      </Dropdown.Toggle>

      {ReactDOM.createPortal(
        <div
          className="menu"
          style={{
            top: dropdownPosition.top,
            left: dropdownPosition.left,
            position: "absolute",
          }}
        >
          <Dropdown.Menu>
            <Dropdown.Item onClick={e => handleEdit(e, itemId)}>
              <FormattedMessage
                id="dashboard-item-menu-edit"
                defaultMessage="Edit"
              />
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleDropdownDelete()}>
              <FormattedMessage
                id="dashboard-item-menu-delete"
                defaultMessage="Delete"
              />
            </Dropdown.Item>
          </Dropdown.Menu>
        </div>,
        document.body
      )}
    </Dropdown>
  )
}

export default DashboardThisWeek
