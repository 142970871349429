import MilestoneTextType from "./MilestoneTextType"
import DateMonthYear from "./DateMonthYear"

/**
 * Provides a milestone with a free input field and a single date.
 */
export default class MilestoneTextDate extends MilestoneTextType {
  /**
   * Constructor.
   *
   * @param milestoneData
   *   Existing milestone data.
   */
  constructor(milestoneData = null) {
    super(milestoneData);
    this.date = this.date = new DateMonthYear(milestoneData?.date);
  }

  /**
   * @inheritDoc
   */
  getData() {
    const data = super.getData();
    data.date = this.date.date;
    return data;
  }
}
