import React from "react"
import "react-responsive-tabs/styles.css"
import AlgoliaToggle from "../algolia/algolia-toggle"
import Portfolio from "./portfolio"
import CareerPlans from "../careers/career-plans"
import Selects from "../career-resources/career-resources-content"
import AssessmentOverview from "../assessment/assessment-overview-content"
import { FormattedMessage } from "react-intl"
import WorkReadiness from "../work-readiness/work-readiness-tab"

const DashboardTabContent = [
  {
    name: <a href={void 0}><FormattedMessage
      id="dashboard-tab-explore"
      defaultMessage="Explore"
    /></a>,
    param: "explore",
    content: <AlgoliaToggle heading={<FormattedMessage id="dashboard-tab-explore" defaultMessage="Explore" />} />,
  },
  {
    name: <a href={void 0}><FormattedMessage
      id="dashboard-tab-assessment"
      defaultMessage="Assessment"
    /></a>,
    param: "assessment",
    content: <AssessmentOverview />,
  },
  {
    name: <a href={void 0}><FormattedMessage
      id="dashboard-tab-portfolio"
      defaultMessage="Portfolio"
    /></a>,
    param: "portfolio",
    content: <Portfolio />,
  },
  {
    name: <a href={void 0}><FormattedMessage
      id="dashboard-tab-career-plans"
      defaultMessage="Career Plans"
    /></a>,
    param: "career-plan",
    content: <CareerPlans hasPublicToggle={false} />,
  },
  {
    name: <a href={void 0}><FormattedMessage id="dashboard-tab-work-readiness" defaultMessage="Work Readiness" /></a>,
    param: "work-readiness",
    content: <WorkReadiness />,
  },
]

export default DashboardTabContent
