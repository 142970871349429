export const Role = {
  SUPERADMIN: "super-admin",
  ADMIN: "admin",
  FACULTY: "faculty",
  EDUCATOR: "educator",
  STUDENT: "student",
  SUPERADMINFIREBASECONST: "Super Admin",
  USER: "user"
}

export const RoleNames = {
  SUPERADMIN: "Super Admin",
  ADMIN: "Admin",
  FACULTY: "Faculty",
  EDUCATOR: "Educator",
  STUDENT: "Student",
  USER: "User"
}

export const Status = {
  ERROR: "error",
  SUCCESS: "success",
  INTERNAL: "internal"
}
