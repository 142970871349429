import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBriefcase,
  faMedal,
  faSchool,
  faHandsHelping,
  faUser,
  faUsers,
  faEdit,
} from "@fortawesome/free-solid-svg-icons"
import { faPen } from "@fortawesome/pro-light-svg-icons"
import ResumeExperienceEditorModal from "./ResumeExperienceEditorModal"
import { renderMonthName } from "./utils/utils.js"
import { FormattedMessage, useIntl } from "react-intl"

const ResumeTimelineExperience = ({
  experienceData,
  precedingExperience,
  isPublicFacing,
  color,
}) => {
  const {
    type,
    employer,
    title,
    milestone,
    location,
    description,
    highlights = [],
    associatedOrg,
    degree,
    issuer,
    activities,
    affiliation,
    school,
    field,
    startDateMonth,
    startDateYear,
    endDateMonth,
    endDateYear,
  } = experienceData

  const [isModalOpen, setIsModalOpen] = useState(false)
  const { locale } = useIntl()
  const isPrecededBySameCompany = precedingExperience => {
    if (
      !precedingExperience ||
      !type === "work" ||
      !precedingExperience ||
      !employer
    ) {
      return false
    }

    return (
      precedingExperience?.employer?.replace(/\s/g, "").toLowerCase() ===
      employer?.replace(/\s/g, "").toLowerCase()
    )
  }

  const closeTimelineModal = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      {isModalOpen && (
        <ResumeExperienceEditorModal
          type={type}
          experienceData={experienceData}
          show={isModalOpen}
          closeTimelineModal={closeTimelineModal}
          isNewExperience={false}
        />
      )}

      <div className="step" style={{ borderLeftColor: color }}>
        <div className="heading-row">
          <div>
            {type === "work" &&
              !isPrecededBySameCompany(precedingExperience) && (
                <h5>{employer}</h5>
              )}

            {type === "education" && <h5>{school}</h5>}

            {type === "life" && <h5>{milestone}</h5>}

            {type === "volunteer" && (
              <h5 style={{ marginBottom: ".5rem" }}>{employer}</h5>
            )}

            {type === "award" && (
              <h5 style={{ marginBottom: ".5rem" }}>{issuer}</h5>
            )}

            {type === "leadership" && (
              <h5 style={{ marginBottom: ".5rem" }}>{affiliation}</h5>
            )}

            <div className="subheading-row">
              {type === "work" && (
                <h6>{title}</h6>
              )}

              {type === "education" && (
                <h6>
                  {degree && `${degree}, `} {field}
                </h6>
              )}

              {(type === "leadership" ||
                type === "award" ||
                type === "volunteer") && <h6>{title}</h6>}

              <span style={{ opacity: 0.8 }}>
                {renderMonthName(startDateMonth, locale)} {startDateYear}{" "}
                {startDateMonth && startDateYear && "-"}{" "}
                {endDateMonth === null && endDateYear === null
                  ? <FormattedMessage
                    id="resume-timeline-experience"
                    defaultMessage="Present"
                  >
                  </FormattedMessage>
                  : `${renderMonthName(endDateMonth, locale)} ${endDateYear}`}
                <br />
                {location}
                {type === "award" && (
                  <>{associatedOrg && `Associated with ${associatedOrg}`}</>
                )}
              </span>
            </div>
          </div>

          {!isPublicFacing && (
            <button onClick={() => setIsModalOpen(true)}>
              <FontAwesomeIcon
                icon={faPen}
                color="#40777c"
                className="printer-none"
              />
            </button>
          )}
        </div>

        {description && <p>{description}</p>}
        {highlights?.filter(highlight => highlight).length > 0 && (
          <div className="secondary-info">
            <ul>
              {highlights
                .filter(highlight => !!highlight)
                .map((highlight, index) => (
                  <li key={`highlight-${index}`}>
                    <div className="secondary-info-row">
                      <div className="secondary-info-row-paragraph">
                        {highlight}
                      </div>
                    </div>
                  </li>
                ))}

              {type === "education" && activities && (
                <li>
                  <div className="secondary-info-row">
                    <div className="secondary-info-row-paragraph">
                      {activities}
                    </div>
                  </div>
                </li>
              )}
            </ul>
          </div>
        )}
      </div>
    </>
  )
}

export default ResumeTimelineExperience
