import React, { useState, useEffect, useContext } from "react"
import ResumeTimelines from "./ResumeTimelines.js"
import ExperienceTypeSelector from "./experience-type-selector-modal"
import "./resume.css"

/**
 * Base component for resume, includes editor and public version
 */
const Resume = ({
  allResumeExperiences,
  isEditorModalOpen,
  isSelectorModalOpen,
  isPublicFacing,
  showSkillsAndCareer = true,
}) => {
  return (
    <>
      <section
        className={`resume ${!showSkillsAndCareer ? "mt-0 border-0 p-0" : '' }`}
        style={{
          padding: allResumeExperiences.length == 0 && "0",
          borderTop: allResumeExperiences.length == 0 && "none",
        }}
      >
        {!showSkillsAndCareer ? (
          ""
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "2rem",
            }}
          ></div>
        )}

        <ResumeTimelines
          isPublicFacing={isPublicFacing}
          showSkillsAndCareer={showSkillsAndCareer}
          experiences={allResumeExperiences.map((data, index) => ({
            ...data,
            index: index,
          }))}
        />

        {!isPublicFacing && (
          <ExperienceTypeSelector
            isEditorModalOpen={isEditorModalOpen}
            isSelectorModalOpen={isSelectorModalOpen}
          />
        )}
      </section>
    </>
  )
}

export default Resume
