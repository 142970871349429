/**
 * Provides a cost data model.
 */
export default class Cost {
  /**
   * Constructor.
   *
   * @param {Object} firebaseData
   */
  constructor(firebaseData) {
    this._value = firebaseData?.value;
    this._symbol = firebaseData?.symbol;
  }

  /**
   * Gets the cost value.
   *
   * @return {number}
   */
  get value() {
    return this._value;
  }

  /**
   * Gets the currency symbol, most cases it is dollars, but some schools are not.
   *
   * @return {string}
   */
  get symbol() {
    return this._symbol;
  }
}
