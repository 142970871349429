/**
 * Provides a base class for school lists found in majors.
 */
export default class SchoolList {

  /**
   * SchoolList constructor.
   *
   * @param firestoreData
   *   The firestore school data.
   */
  constructor(firestoreData) {
    this._schools = firestoreData?.schoolList ?? [];
    this._schoolIds = firestoreData?.schoolIds ?? [];
  }

  /**
   * Gets the list of schools offering the major.
   *
   * A school object follows this structure:
   * {
   *   associateSchooltype: {boolean} If the school offers an associate's degree for the major.
   *   bachSchooltype: {boolean} If the school offers a bachelor's dgree for the major.
   *   city: {string} City of the school.
   *   cosmicId: {string} Cosmic ID of the school.
   *   country: {string} Country code of the school.
   *   gradSchooltype: {boolean} If the school offers a graduate degree for the major.
   *   id: {number} The school Peterson ID.
   *   line1: {string} The school's address.
   *   name: {string} The name of the school.
   *   slug: {string} The slug for the school.
   *   state: {string} State code for the school, not always USA.
   *   techSchooltype: {boolean} If the school offers a technical degree for the major (is a tech school).
   *   zipCode: {string} The zip code of the school.
   * }
   *
   * @return {string[]}
   */
  get schools() {
    return this._schools;
  }

  /**
   * List of school IDs.
   *
   * @return {number[]}
   */
  get schoolIds() {
    return this._schoolIds;
  }

  /**
   * Get a school by ID.
   *
   * @param pid
   *  The school id.
   *
   * @return {object|null}
   *  A school object see `::schools`
   */
  getSchoolById(pid) {
    if (!this.schoolIds.includes(pid)) {
      return null;
    }
    const location = this.schoolIds.indexOf(pid);
    return this.schools[location];
  }
}
