/**
 * Provides major data list for schools.
 */
export default class MajorList {
  /**
   * Constructor.
   *
   * @param {Object} firestoreData
   */
  constructor(firestoreData) {
    this._majors = firestoreData?.majorList;
    this._majorGroupCips = firestoreData?.majorCipGroups;
    this._majorRender = firestoreData?.majorGroup;
  }

  /**
   * Provides a list of CIP group keys in alphabetical order of their group name.
   *
   * Use this array to walk through the default grouped majors listing, object
   * key names are in the `::majorRender` which will then provide the CIP group
   * names and organized majors.
   *
   * @return {string[]}
   */
  get majorGroupCips() {
    return this._majorGroupCips;
  }

  /**
   * Provides the default sorted major list @see `::majorGroupCips`.
   *
   * The major render follows this pattern:
   * {
   *   [`major group CIP code; XX`]: {
   *     groupName: {string} The name of the CIP group.
   *     majorList: {Object[]} List of majors sorted alphabetically for this CIP group, see `::majors` for detailed description of a major object.
   *   },
   *   ...[`major group CIP code; XX`]: {...}
   * }
   *
   * @return {Object}
   */
  get majorRender() {
    return this._majorRender;
  }

  /**
   * Gets all the individual majors for a school. Use this list for search.
   *
   * A major list object follows this pattern:
   * {
   *   associate: {boolean} If the major is offered for Associate degrees.
   *   bachelor: {boolean} If the major is offered for Bachelor degrees.
   *   graduate: {boolean} If the major is offered for Graduate degrees.
   *   technical: {boolean} If the major is offered for technical degrees.
   *   id: {string} CIP code for the major.
   *   cipGroup: {string} CIP group for the major.
   *   cipGroupName: {string} CIP group name.
   *   cosmicId: {string} Cosmic ID.
   *   cipDescription: {string} Description provided by CIP data.
   *   slug: {string} The slug to the major page
   *   name: {string} The name of the major.
   * }
   *
   * @return {Object[]}
   */
  get majors() {
    return this._majors;
  }
}
