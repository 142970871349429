import MilestoneTextType from "./MilestoneTextType"
import DatePeriod from "./DatePeriod"

/**
 * Provides a milestone with a free input field and a date period.
 */
export default class MilestoneTextDates extends MilestoneTextType {
  /**
   * Constructor.
   *
   * @param milestoneData
   *   Existing milestone data.
   */
  constructor(milestoneData = null) {
    super(milestoneData);
    this.period = new DatePeriod(milestoneData?.startDate, milestoneData?.endDate);
  }

  /**
   * @inheritDoc
   */
  getData() {
    const data = super.getData();
    data.endDate = this.period.endDate;
    data.startDate = this.period.startDate;
    return data;
  }
}
