import React, { useState, useEffect, useContext } from "react"
import Link from "../../link"
import "../career-resources/career-resources-tab.css"
import "./overview.css"
import { FirebaseContext } from "../Firebase"
import dayjs from "dayjs"
import questionData from "../../components/assessment/questions.js"
import { FormattedMessage } from "react-intl"

const AssessmentOverview = () => {
  const { profile } = useContext(FirebaseContext)

  const [isAssessmentTaken, setIsAssessmentTaken] = useState(false)
  const [assessmentDate, setAssessmentDate] = useState(null)

  const [canResume, setCanResume] = useState(false)

  useEffect(() => {
    // if profile exists and has assessment data, set avaliable data
    if (profile?.assessmentRecord) {
      const {
        createdDate,
        responsesVersion,
        responses,
        resultProfile,
      } = profile.assessmentRecord

      if (
        responsesVersion === questionData.version &&
        responses.length < questionData.questions.length
      ) {
        setCanResume(true)
      }

      if (resultProfile) {
        setIsAssessmentTaken(true)
      }

      if (createdDate) {
        let date = dayjs(new Date(createdDate.toDate())).format("MMMM DD, YYYY")
        setAssessmentDate(date)
      }
    }
  }, [profile])

  return (
    <div className="assessments-overview">

      <h2><FormattedMessage
        id="assessment-overview-content-assessment"
        defaultMessage="Assessment"
      /></h2>

      <section>
        <img
          src={
            "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/assessment-assets%2FIH9%20smaller.jpg?alt=media&token=e5b5f1c3-ad4d-4b63-ba81-a723cca9391d"
          }
        />
        <div className="assessment-info">
          <div>
            <Link
              className="component-card-title"
              to={"/assessment/personality/results"}
            >
              <FormattedMessage id="assessment-overview-personality-assessment" defaultMessage="Personality Assessment" />
            </Link>
            <p>
              <FormattedMessage
                id="assessment-overview-personality-assessment-description"
                defaultMessage="The world is big and full of opportunity! This assessment uses the
                Holland Code to determine your personality profile and match you
                to career possibilities that are right for you."
              />
            </p>
          </div>

          {isAssessmentTaken && !canResume && (
            <Link
              style={{ alignSelf: "flex-start" }}
              type="button"
              className="button component-button component-button-default"
              to={"/assessment/personality/results"}
            >
              {" "}
              <FormattedMessage
                id="assessment-overview-review-results"
                defaultMessage="Review Results"
              />
            </Link>
          )}

          {!isAssessmentTaken && (
            <Link
              style={{ alignSelf: "flex-start" }}
              type="button"
              className="button component-button component-button-default"
              to={"/assessment/personality"}
            >
              {" "}
              {canResume ? <FormattedMessage
                id="assessment-overview-resume-assessment"
                defaultMessage="Resume Assessment"
              /> : <FormattedMessage id="assessment-overview-take-personality-assessment" defaultMessage="Take Personality Assessment" />}
            </Link>
          )}
        </div>

        {isAssessmentTaken && (
          <div className="assessment-info end">
            <div>
              <p><FormattedMessage
                id="assessment-overview-completed"
                defaultMessage="Completed"
              />: {assessmentDate}</p>
            </div>
            <Link
              style={{ alignSelf: "flex-start", minWidth: "259px" }}
              type="button"
              className="button component-button component-button-ghost"
              to={"/assessment/personality"}
            >
              {" "}
              {canResume ? <FormattedMessage
                id="assessment-overview-resume-assessment"
                defaultMessage="Resume Assessment"
              /> : <FormattedMessage
                id="assessment-overview-retake-assessment"
                defaultMessage="Retake Assessment"
              />}
            </Link>
          </div>
        )}
      </section>
    </div>
  )
}

export default AssessmentOverview
