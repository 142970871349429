import React, { useContext, useState } from "react"
import { ExperienceEditorContext } from "./ExperienceEditorContext"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import OptimizerButton from "./optimizer/optimizer-button"

const ExperienceBulletInput = ({ index, placeholder }) => {
  const { experience = {}, dispatchHighlightAction } = useContext(ExperienceEditorContext)
  
  const updateData = (event) => {
    const value = event.target.value
    dispatchHighlightAction("UPDATE_INPUT_VALUE", value, index)
  }

  const removeInput = (event) => {
    event.preventDefault()
    dispatchHighlightAction("DELETE_INPUT_FIELD", null, index)
  }

  return (
    <>
      <div className="form-element" style={{ flexFlow: "unset", marginBottom: "1rem" }}>
        <textarea type="text" value={experience?.highlights[index] ?? ""} placeholder={placeholder ?? ""} onChange={updateData} rows={1} />
        <OptimizerButton type='bullet' text={experience?.highlights[index]} updateData={updateData}/>
        <button
          onClick={removeInput}
          style={{
            backgroundColor: "white",
            paddingRight: "0px",
            paddingLeft: "0px",
          }}
        >
          <FontAwesomeIcon icon={faTimes} size="md" color="#363636" />
        </button>
      </div>
    </>
  )
}

export default ExperienceBulletInput
