import React, { useEffect, useContext } from "react"
import ExperienceBulletInput from "./experience-bullet-input"
import { ExperienceEditorContext } from "./ExperienceEditorContext"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons"

const ExperienceBulletsInput = ({ label, placeholders }) => {
  const {
    experience = { highlights: [] },
    dispatchHighlightAction,
  } = useContext(ExperienceEditorContext)

  const addExtraField = e => {
    e.preventDefault()
    dispatchHighlightAction("CREATE_INPUT_FIELD")
  }

  useEffect(() => {
    dispatchHighlightAction("INITIALIZE_EMPTY_FIELDS")
  }, [])

  return (
    <>
      <label>{label}</label>
      {experience?.highlights?.map((highlight, index) => (
        <ExperienceBulletInput
          highlight={highlight}
          index={index}
          placeholder={placeholders[index]}
        />
      ))}
      <button onClick={e => addExtraField(e)} style={{backgroundColor: 'white', paddingLeft: '8px'}}>
        <FontAwesomeIcon icon={faPlusCircle} size="lg" color="#40777c" />
      </button>
    </>
  )
}

export default ExperienceBulletsInput
