import getStartedData from "../get-started-data"

export const walkThroughSteps = {
  createFuture: false,
  selfAwareness: false,
  explorePossibilities: false,
  buildPortfolio: false,
  engage: false,
  planPath: false,
}

export const VIEWED_STORIES_THRESHOLD = 10

export const calculateCompletionPercent = stepsData => {
  const totalSteps = getStartedData.length
  const stepKeys = getStartedData.map(row => row.stepKey)
  let completedSteps = 0,
    inCompleteSteps = 0

  stepKeys.forEach(stepKey => {
    !!stepsData[stepKey] ? completedSteps++ : inCompleteSteps++
  })
  return Math.ceil((completedSteps * 100) / totalSteps)
}
