import React from "react"
import CardSchools from "../../cards/cards-schools"
import EyeBrow from "../../eyebrow/eyebrow"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons"
import algoliasearch from "algoliasearch"
import {
  InstantSearch,
  Configure,
  Pagination,
  Hits,
} from "react-instantsearch-dom"
import AlgoliaClientKeys from "../AlgoliaClientKeys"
import "../algolia.css"
import "../algolia-explore.css"
import { FormattedMessage, useIntl } from "react-intl"

const searchClient = algoliasearch(
  AlgoliaClientKeys.appId,
  AlgoliaClientKeys.apiKey
)

const schoolResult = ({ hit }) => {
  const {
    objectID,
    title,
    school_banner_image,
    slug,
    type,
    school_city,
    school_state_or_region,
    school_acceptance_rate,
    school_enrolled_count,
  } = hit

  return (
    <CardSchools
      objectID={objectID}
      img={school_banner_image}
      title={title}
      slug={slug}
      contentType={type}
      hideBookmarks={true}
      city={school_city}
      state={school_state_or_region}
      acceptance={school_acceptance_rate}
      enrolled={school_enrolled_count}
    />
  )
}

const AlgoliaPublicSchoolsSaved = props => {
  const { classContext, filterList } = props

  const { locale } = useIntl()
  const localeFilter = `locale:${locale}`
  const filterListWithLocale = filterList
    ? `(${filterList}) AND ${localeFilter}`
    : localeFilter

  if (filterListWithLocale === null) {
    return (
      <div
        className={`stream stream-${classContext} stream-${classContext}-saved`}
      >
        <p><FormattedMessage id="no-content-saved-message" defaultMessage="No content has been saved in this category" /></p>
      </div>
    )
  } else {
    return (
      <InstantSearch indexName="schools_explore" searchClient={searchClient}>
        <div
          className={`stream stream-${classContext} stream-${classContext}-saved`}
        >
          <EyeBrow text="Schools" style={{ marginBottom: "2rem" }} />

          <Configure hitsPerPage={8} distinct filters={filterListWithLocale} />

          <Hits hitComponent={schoolResult} />
          <Pagination
            showFirst={false}
            showLast={false}
            translations={{
              previous: <FontAwesomeIcon icon={faChevronLeft} size="sm" />,
              next: <FontAwesomeIcon icon={faChevronRight} size="sm" />,
            }}
          />
        </div>
      </InstantSearch>
    )
  }
}

export default AlgoliaPublicSchoolsSaved
