import { allowedAlgoliaListTypes } from "../AllowedTypes"

/**
 * List of Algolia enabled milestones.
 *
 * List of Algolia enabled milestones. This is pulled from AllowedTypes
 * and may include other algolia milestone types that is why this pattern
 * is used.
 *
 * @type {string[]}
 */
const AlgoliaEnabledMilestones = [
  ...allowedAlgoliaListTypes,
];

/**
 * Provides a base class that manages Algolia index and field data for a milestone.
 */
class AlgoliaEnabledMilestone {

  /**
   * Gets the milestone type.
   *
   * @return {string}
   */
  get milestoneType() {
    return this._milestoneType
  }

  /**
   * Sets the milestone type.
   *
   * @param {string} value
   */
  set milestoneType(value) {
    this._milestoneType = value
  }

  /**
   * Gets the primary indexes searchable field.
   *
   * This is usually title as we rely on Cosmic's Algolia plugin to create this list.
   *
   * @return {string}
   */
  get primarySearchableFieldKey() {
    return this._primarySearchableFieldKey
  }

  /**
   * The name of the secondary indexes searchable field.
   *
   * The current pattern has been setting this key to `name`.
   *
   * @return {string}
   */
  get secondarySearchableFieldKey() {
    return this._secondarySearchableFieldKey
  }

  /**
   * The boolean field on the primary object in Cosmic.
   *
   * This field sorts the content type to the milestone type. In the case of
   * majors
   *
   * @return {string}
   */
  get indexPrimaryField() {
    return this._indexPrimaryField
  }

  get indexSecondary() {
    return this._indexSecondary
  }

  get indexSecondaryField() {
    return this._indexSecondaryField
  }

  get indexPrimary() {
    return this._indexPrimary
  }

  get indexRelationField() {
    return this._indexRelationField
  }

  constructor(indexSecondary, indexSecondaryField, indexPrimary, indexPrimaryField, indexRelationField, primarySearchableFieldKey, secondarySearchableFieldKey, milestoneType) {
    this._indexSecondary = indexSecondary
    this._indexSecondaryField = indexSecondaryField
    this._indexPrimary = indexPrimary
    this._indexRelationField = indexRelationField
    this._indexPrimaryField = indexPrimaryField
    this._primarySearchableFieldKey = primarySearchableFieldKey
    this._secondarySearchableFieldKey = secondarySearchableFieldKey
    this._milestoneType = milestoneType
  }
}

const milestoneList = [];

/**
 * The doctorate milestone Algolia data.
 */
const doctorateMilestone = new AlgoliaEnabledMilestone(
  'cb_milestones_schools_doctorate',
  'majors',
  'majors',
  'major_doctorate',
  'major_cip',
  'title',
  'name',
  'doctorate'
  )
milestoneList.push(doctorateMilestone)

/**
 * The bachelor milestone Algolia data.
 */
const bachelorMilestone = new AlgoliaEnabledMilestone(
  'cb_milestones_schools_bachelor',
  'majors',
  'majors',
  'major_bachelor',
  'major_cip',
  'title',
  'name',
  'bachelor_degree'
)
milestoneList.push(bachelorMilestone)

/**
 * The associate milestone Algolia data.
 */
const associateMilestone = new AlgoliaEnabledMilestone(
  'cb_milestones_schools_associate',
  'majors',
  'majors',
  'major_associate',
  'major_cip',
  'title',
  'name',
  'associate_degree'
)
milestoneList.push(associateMilestone)

/**
 * The masters milestone Algolia data.
 */
const mastersMilestone = new AlgoliaEnabledMilestone(
  'cb_milestones_schools_master',
  'majors',
  'majors',
  'major_master',
  'major_cip',
  'title',
  'name',
  'masters_deg'
)
milestoneList.push(mastersMilestone)

/**
 * Map of milestones to Algolia indexes.
 *
 * @type {{}}
 */
export const AlgoliaIndexMap = milestoneList.reduce(
  (data, milestone) => {
    data[milestone.milestoneType] = milestone
    return data
  }, {})

/**
 * If the milestone is Algolia enabled.
 *
 * @param {string} milestoneId
 *   The milestone ID.
 *
 * @return {boolean}
 */
export const isAlgoliaEnabled = (milestoneId) => AlgoliaEnabledMilestones.includes(milestoneId);

/**
 * Gets the milestone index data.
 *
 * Will return a data object like:
 * {
 *   indexSecondary: {string} The secondary index usually an index of schools data
 *   indexSecondaryField: {string} The field in the secondary index that relates primary goal to secondary
 *   indexPrimary: {string} The primary index to pull goal data
 *   indexPrimaryField: {string} The boolean filter for the primary field
 *   indexRelationField: {string} The field that links the primary index to the secondary index
 * }
 *
 * @param {string} milestoneId
 *   The milestone ID.
 *
 * @return {null|AlgoliaEnabledMilestone}
 */
const getAlgoliaIndexData = (milestoneId) => {
  if (!isAlgoliaEnabled(milestoneId)) {
    return null;
  }
  return AlgoliaIndexMap[milestoneId];
}

export default getAlgoliaIndexData;
