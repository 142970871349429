import modalButterfly from "../../images/levels/butterfly.png"
import modalBee from "../../images/levels/bee.png"
import modalHummingbird from "../../images/levels/hummingbird.png"
import modalBluejay from "../../images/levels/bluejay.png"
import modalHeron from "../../images/levels/heron.png"
import modalEagle from "../../images/levels/eagle.png"
import { faFire } from "@fortawesome/pro-solid-svg-icons"

export default [
    {
        points: [0, 10],
        name: "Fire",
        message: "Ready to grow!",
        profileColor: "orange",
        modalColor: "orange",
        image: faFire,
    },
    {
        points: [10, 50],
        name: "Butterfly",
        message: "Symbolizing that a small change can give rise to a big tidal wave.",
        profileColor: "#D1BCFC",
        modalColor: "#4B489D",
        image: modalButterfly,
    },
    {
        points: [50, 100],
        name: "Bee",
        message: "Symbolizing focus and hardwork.",
        profileColor: "#F99F00",
        modalColor: "C86C20",
        image: modalBee,
    },
    {
        points: [100, 250],
        name: "Hummingbird",
        message: "Symbolizing timeless job and nectar of life.",
        profileColor: "#C7EB29",
        modalColor: "#35A994",
        image: modalHummingbird,
    },
    {
        points: [250, 500],
        name: "Bluejay",
        message: "Symbolizing communication, curiosity, and confidence. A sighting may be an omen urging you to speak up, be bold, and chase your goals.",
        profileColor: "#bad8fc",
        modalColor: "#20419A",
        image: modalBluejay,
    },
    {
        points: [500, 1000],
        name: "Heron",
        message: "Symbolizing elegance, patience, wisdom, messages, self-possession, transformation, and good fortune.",
        profileColor: "#D1DCFF",
        modalColor: "#4B489D",
        image: modalHeron,
    },
    {
        points: [1000, Infinity],
        name: "Eagle",
        message: "Symbolizing enlightenment.",
        profileColor: "#FFD00C",
        modalColor: "#2b1c1c",
        image: modalEagle,
    },
]
