import React from "react"
import Bookmark from "../bookmark/bookmark"
import ReactPlayer from "react-player"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlayCircle, faStar } from "@fortawesome/free-solid-svg-icons"
import Link from "../../link"
import { useIntl } from "react-intl"

const CardStories = props => {
  const {
    img,
    title,
    slug,
    story_job_title,
    story_company,
    story_quote,
    story_video_url,
    rating_average,
    objectID,
    idx,
    contentType,
    hideBookmarks,
  } = props
  const { formatMessage } = useIntl()
  return (
    <div
      className="component-card component-card-stories"
      key={idx}
      data-object={objectID}
    >
      {story_video_url && img ? (
        <div className="component-card-image component-card-image-video component-card-image-video-stories">
          <div className="video-wrapper">
            <ReactPlayer
              url={story_video_url}
              light={img}
              controls={true}
              playing={true}
              width="100%"
              height="100%"
              playIcon={
                <>
                  <button className="button component-button component-button-video component-button-video-large">
                    <FontAwesomeIcon
                      className="play-video"
                      icon={faPlayCircle}
                    />
                    {formatMessage({ id: "card-stories-watch-video", defaultMessage: "Watch Video" })}
                  </button>
                  <button className="button component-button component-button-video component-button-video-small">
                    <FontAwesomeIcon
                      className="play-video"
                      icon={faPlayCircle}
                    />
                    <span className="sr-only">
                      {formatMessage({ id: "card-stories-watch-video", defaultMessage: "Watch Video" })}
                    </span>
                  </button>
                </>
              }
            />
          </div>
        </div>
      ) : (
        <div className="component-card-image component-card-image-stories">
          <Link to={`/story/${slug}`}>
            <img
              src={`${img}?q=&auto=compress,&w=712&h=446&fit=crop&crop=faces`}
              alt=""
            />
          </Link>
        </div>
      )}
      <div className="component-card-meta component-card-meta-stories">
        <div className="component-card-meta-header component-card-meta-header-stories">
          {!hideBookmarks && (
            <Bookmark
              contentTitle={title}
              classContext={"stories"}
              contentId={objectID}
              contentSlug={slug}
              contentType={contentType}
            />
          )}

          {typeof rating_average === "number" && (
            <>
              <FontAwesomeIcon
                icon={faStar}
                color="#E07306"
                style={{
                  fontSize: "22px",
                  marginRight: "7px",
                }}
              />
              <span
                style={{
                  fontSize: "18px",
                  color: "#565656",
                  marginLeft: "1rem",
                  marginRight: "5px",
                  position: "relative",
                  top: "1px",
                }}
              >
                {rating_average.toFixed(1)}
              </span>
            </>
          )}

          <Link
            to={`/story/${slug}`}
            className="component-card-title component-card-title-stories"
          >
            {story_job_title}
          </Link>
        </div>
        <div className="component-card-company component-card-company-stories">
          {story_company}
        </div>
        <blockquote className="component-blockquote component-blockquote-stories">
          <p>&ldquo;{story_quote}&rdquo;</p>
          <cite>&mdash; {title}</cite>
        </blockquote>
      </div>
    </div>
  )
}

export default CardStories
