import { allowedMonths, isValidDateString, isValidYear } from "./AllowedTypes"

/**
 * Provides a base class for milestones that require a date range.
 */
export default class DatePeriod {

  /**
   * Constructor.
   *
   * @param milestoneData
   *   Usually data from firestore, but can be from anywhere.
   */
  constructor(start, end) {
    this._endDate = end;
    this._startDate = start;
  }

  /**
   * Clears the end month data, but retains the year.
   */
  clearEndDateMonth() {
    this._endDate = null;
    this._endDateMonth = null;
  }

  /**
   * Clears the start month data, but retains the year.
   */
  clearStartDateMonth() {
    this._startDate = null;
    this._startDateMonth = null;
  }

  /**
   * Clears the end year data, but retains the month.
   */
  clearEndDateYear() {
    this._endDate = null;
    this._endDateYear = null;
  }

  /**
   * Clears the start date year, but retains the month.
   */
  clearStartDateYear() {
    this._startDate = null;
    this._startDateYear = null;
  }

  /**
   * Setter for start date.
   *
   * @param {string} date
   */
  set startDate(date) {
    if (!isValidDateString(date)) {
      throw new Error('Invalid date attempted to be set must have valid Year and Month');
    }
    const year = date.split("-")[0];
    const month = date.split("-")[1];
    if (!this.endDate) {
      this._startDate = date;
      this._startDateMonth = month;
      this._startDateYear = year;
      return;
    }
    if (parseInt(date.split("-")[0]) > this.endDateYear) {
      throw new Error('Invalid start date attempted to be set must come before the end date.');
    }
    if (parseInt(date.split("-")[0]) === this.endDateYear && parseInt(date.split("-")[1]) >= this.endDateMonthInt) {
      throw new Error('Invalid start date attempted to be set must come before the end date.');
    }
    this._startDateMonth = month;
    this._startDateYear = year;
    this._startDate = date;
  }

  /**
   * Setter for end date.
   *
   * @param {string} date
   */
  set endDate(date) {
    if (!isValidDateString(date)) {
      throw new Error('Invalid date attempted to be set must have valid Year and Month');
    }
    const year = date.split("-")[0];
    const month = date.split("-")[1];
    if (!this.startDate) {
      this._endDate = date;
      this._endDateYear = year;
      this._endDateMonth = month;
      return;
    }
    if (parseInt(date.split("-")[0]) < this.startDateYear) {
      throw new Error('Invalid end date attempted to be set must come after the start date.');
    }
    if (parseInt(date.split("-")[0]) === this.startDateYear && parseInt(date.split("-")[1]) <= this.startDateMonthInt) {
      throw new Error('Invalid end date attempted to be set must come after the start date.');
    }
    this._endDateYear = year;
    this._endDateMonth = month;
    this._endDate = date;
  }

  /**
   * Gets the end date.
   *
   * @return {string|null}
   */
  get endDate() {
    return this._endDate;
  }

  /**
   * Gets the start date.
   *
   * @return {string|null}
   */
  get startDate() {
    return this._startDate;
  }

  /**
   * Gets the end date year.
   *
   * @return {number|null}
   */
  get endDateYear() {
    if (!this._endDate) {
      return this._endDateYear ? parseInt(this._endDateYear) : null;
    }
    return parseInt(this._endDate.split("-")[0]);
  }

  /**
   * Setter for end date year.
   *
   * @param {string} year
   */
  set endDateYear(year) {
    if (!isValidYear(year)) {
      throw new Error("Invalid year being set.");
    }
    if (this.endDateMonth) {
      this.endDate = `${year}-${this.endDateMonth}`;
    }
    else {
      this._endDateYear = year;
    }
  }

  /**
   * Gets the end date month as an int.
   *
   * @return {number|null}
   */
  get endDateMonthInt() {
    return this.endDateMonth ? parseInt(this.endDateMonth) : null;
  }

  /**
   * Gets the end date month.
   *
   * @return {string|null}
   */
  get endDateMonth() {
    if(!this._endDate) {
      return this._endDateMonth ?? null;
    }
    return this._endDate.split("-")[1];
  }

  /**
   * Sets the end date month.
   *
   * @param {string} month
   */
  set endDateMonth(month) {
    if (!allowedMonths.includes(month)) {
      throw new Error("Invalid month being set");
    }
    if (this.endDateYear) {
      this.endDate = `${this.endDateYear}-${month}`;
    }
    else {
      this._endDateMonth = month;
    }
  }

  /**
   * Gets the start date year.
   *
   * @return {number|null}
   */
  get startDateYear() {
    if(!this._startDate) {
      return this._startDateYear ? parseInt(this._startDateYear) : null;
    }
    return parseInt(this._startDate.split("-")[0]);
  }

  /**
   * Sets the start date year.
   *
   * @param {string} year
   */
  set startDateYear(year) {
    if (!isValidYear(year)) {
      throw new Error("Invalid year being set.");
    }
    if (this.startDateMonth) {
      this.startDate = `${year}-${this.startDateMonth}`;
    }
    else {
      this._startDateYear = year;
    }
  }

  /**
   * Gets the strat date month as an int.
   *
   * @return {number|null}
   */
  get startDateMonthInt() {
    return this.startDateMonth ? parseInt(this.startDateMonth) : null;
  }

  /**
   * Gets the start date month.
   *
   * @return {string|null}
   */
  get startDateMonth() {
    if(!this._startDate) {
      return this._startDateMonth ?? null;
    }
    return this._startDate.split("-")[1];
  }

  /**
   * Sets the start date month.
   *
   * @param {string} month
   */
  set startDateMonth(month) {
    if (!allowedMonths.includes(month)) {
      throw new Error("Invalid month being set");
    }
    if (this.startDateYear) {
      this.startDate = `${this.startDateYear}-${month}`;
    }
    else {
      this._startDateMonth = month;
    }
  }
}
