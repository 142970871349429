import { createHeader, createAwardSubHeader, createBulletPoint, createSpacingLine } from "./componentHelpers"

export const renderAwardsSection = (awardExperiences) => {
  
  return [
    ...createHeader("AWARDS"),
    ...awardExperiences
      .filter((experience) => experience)
      .flatMap((experience) => {

        const title = experience.title || 'TITLE'
        const issuer = experience.issuer || 'ISSUER'
        const associatedOrg = experience.associatedOrg
        const endDate = experience.endDate || 'DATE AWARDED'
        const bulletPoints = experience.bulletPoints || []

        const headerText = `${title} | ${issuer}${associatedOrg && ", associated with " + associatedOrg}`

        return [
          createAwardSubHeader(headerText, endDate),
          bulletPoints.map(b => createBulletPoint(b)),
          createSpacingLine,
        ].flat()
      }),
  ]
}
