import React, { useContext, useEffect, useState } from "react"
import { useLocation } from "@reach/router"
import { FirebaseContext } from "../Firebase"
import { navigate } from "../../link"
import SEO from "../seo"
import DashboardThisWeek from "./dashboard-this-week"
import AlgoliaStoriesWeek from "../algolia/algolia-stories-week"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons"
import Modal from "react-bootstrap/Modal"
import KnowledgeBaseLinks from "./knowledge-base"
import LoadingSpinner from "../loading/LoadingSpinner.js"
import { CSSTransition } from "react-transition-group"
import "../loading/spinner.css"
import DailyQuest from "../daily-quest/dashboard-daily-quest"
import Link from "../../link"
import { FormattedMessage } from "react-intl"
import { PropsContext } from "../../components/dashboard/PropsContext"

const Dashboard = () => {
  const { pageContext } = useContext(PropsContext)
  const { firebase, user, loading, profile, localFirestore } = useContext(FirebaseContext)
  const [showModal, setShowModal] = useState(false)
  const [displayLoading, setDisplayLoading] = useState(true)
  const location = useLocation()

  useEffect(() => {
    loading === false && setDisplayLoading(false)
  }, [loading])

  const handleClose = () => setShowModal(false)
  const handleOpen = e => {
    e.preventDefault()
    setShowModal(true)
  }

  if (!loading && user && !profile) {
    return (
      <>
        <div
          style={{
            textAlign: "center",
            marginTop: "10em",
            marginBottom: "10em",
            fontSize: "20px",
          }}
        >
          <FormattedMessage
            id="dashboard-heading-error-loading-profile"
            defaultMessage="There was a problem loading your profile. Please try again later,"
          /><FormattedMessage
            id="login-or"
            defaultMessage="or"
          />
          {" "}
          <Link to="/contact"><FormattedMessage
            id="registration-form-contact-us"
            defaultMessage="contact us"
          /></Link> <FormattedMessage
            id="dashboard-heading-support-if-problem-continues"
            defaultMessage="for support if the problem continues."
          />
        </div>
      </>
    )
  }

  if (!loading && !user) {
    // done loading and user is not authenticated
    navigate("/")
    return null
  }

  function scrollToTop() {
    // If user is coming to dashboard and the anchorate is present
    // do not scroll to top, as we want scroll to stay at anchorate position.
    if (location.hash === "#start") {
      return
    }
    window.scrollTo(0, 0)
  }

  return (
    <>
      <CSSTransition
        in={displayLoading}
        timeout={500}
        classNames="loader"
        onEnter={() => {
          scrollToTop()
        }}
        onExiting={() => {
          scrollToTop()
        }}
        onExit={() => {
          scrollToTop()
        }}
        onExited={() => {
          scrollToTop()
        }}
        unmountOnExit
      >
        <LoadingSpinner />
      </CSSTransition>

      {!loading && !localFirestore.isQuestLoading && (
        <section>
          <SEO title="Dashboard" />
          <section
            className="dashboard-intro"
            style={{ backgroundColor: "white" }}
          >
            <div className="inner inner-dashboard-intro">
              <div className="dashboard-intro-header" style={{ width: "100%" }}>
                <h1>
                  <FormattedMessage id="dashboard-hi" defaultMessage="Hi" />
                  {profile && profile.firstName ? `, ${profile.firstName}` : ""}
                  !
                </h1>
                <button onClick={handleOpen}>
                  <FontAwesomeIcon
                    icon={faQuestionCircle}
                    size="2x"
                    color="#40777c"
                  />
                </button>
                <Modal show={showModal} onHide={handleClose} centered size="lg">
                  <Modal.Header closeButton>
                    <Modal.Title>
                      <FormattedMessage
                        id="dashboard-modal-title"
                        defaultMessage="Have a question? We are here to help!"
                      />
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>
                      <FormattedMessage
                        id="dashboard-modal-body-para-1"
                        defaultMessage="Here are links to frequently asked questions."
                      />
                    </p>
                    <KnowledgeBaseLinks />
                    <p>
                      <FormattedMessage
                        id="dashboard-modal-body-para-2"
                        defaultMessage="If you can't find what you're looking for, feel free to
                    reach out to our team"
                      />
                      &nbsp;
                      <Link to="/contact">
                        <FormattedMessage
                          id="dashboard-modal-body-here"
                          defaultMessage="here."
                        />
                      </Link>
                    </p>
                  </Modal.Body>
                </Modal>
              </div>
              <div className="dashboard-weekly">
                <DailyQuest />
                <DashboardThisWeek
                  firebase={firebase}
                  profile={profile}
                  pageContext={pageContext}
                />
              </div>
              <AlgoliaStoriesWeek />
            </div>
          </section>
        </section>
      )}
    </>
  )
}

export default Dashboard
