import { createHeader, createSubHeader, createBulletPoint, createSpacingLine } from "./componentHelpers"

export const renderVolunteerSection = (volunteerExperiences) => {
  return [
    ...createHeader("VOLUNTEER EXPERIENCE"),
    ...volunteerExperiences
      .filter((experience) => experience)
      .flatMap((experience) => {
        const title = experience.title || "TITLE"
        const organization = experience.employer || "ORGANIZATION"
        const startDate = experience.startDate || "STARTDATE"
        const endDate = experience.endDate || "Present"
        const location = experience.location || "LOCATION"
        const bulletPoints = experience.bulletPoints || []

        return [
          ...createSubHeader(title, organization, location, startDate, endDate),
          ...bulletPoints.map(b => createBulletPoint(b)),
          createSpacingLine,
        ]
      }),
  ]
}
