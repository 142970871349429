/**
 * The expiration statuses for a user.
 *
 * @type {{ACTIVE: string, INACTIVE: string, NEVER: string}}
 */
export const expirationType = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  NEVER: 'never'
}

/**
 * The expiration statuses array.
 *
 * @type {string[]}
 */
export const expirationTypes = Object.values(expirationType)

/**
 * The user types that belong to a school entity.
 *
 * @type {{EDUCATOR: string, STUDENT: string}}
 */
export const schoolUserType = {
  STUDENT: 'student',
  EDUCATOR: 'educator',
}

/**
 * The user types that belong to a school entity as list of values.
 *
 * @type {string[]}
 */
export const schoolUserTypes = Object.values(schoolUserType)

/**
 * The user types that belong to an organization entity.
 *
 * @type {{ORGANIZATION: string}}
 */
export const organizationUserType = {
  ORGANIZATION: 'organization',
}

/**
 * The user types that belong to an organization entity as a list of values.
 *
 * @type {string[]}
 */
export const organizationUserTypes = Object.values(organizationUserType)

/**
 * The user type for individuals.
 *
 * @type {{INDIVIDUAL: string}}
 */
export const individualUserType = {
  INDIVIDUAL: 'individual'
}

/**
 * The list of user types for individuals.
 *
 * @type {string[]}
 */
export const individualUserTypes = Object.values(individualUserType)

/**
 * The possible user types.
 *
 * @type {{EDUCATOR: string, ORGANIZATION: string, STUDENT: string, INDIVIDUAL: string}}
 */
const userType = {
  ...schoolUserType,
  ...organizationUserType,
  ...individualUserType
}

/**
 * List of all user type values.
 *
 * @type {string[]}
 */
export const userTypes = Object.values(userType)

export default userType;

