import React from "react"
import CardMajors from "../cards/cards-majors"
import EyeBrow from "../eyebrow/eyebrow"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight, faChevronLeft } from "@fortawesome/free-solid-svg-icons"
import algoliasearch from 'algoliasearch';
import { InstantSearch, Configure, Pagination, Hits, ScrollTo } from 'react-instantsearch-dom';
import AlgoliaClientKeys from "./AlgoliaClientKeys"
import "./algolia.css"
import "./algolia-explore.css"
import { FormattedMessage, useIntl } from "react-intl"

const searchClient = algoliasearch(AlgoliaClientKeys.appId, AlgoliaClientKeys.apiKey);

const majorResult = ({ hit }) => {
  const {
    objectID,
    title,
    major_banner_image,
    major_job_categories,
    slug,
    type,
    major_bachelor,
    major_associate,
    major_graduate,
    major_master,
    major_doctorate,
  } = hit

  return (
    <CardMajors
      objectID={objectID}
      img={major_banner_image}
      title={title}
      slug={slug}
      categories={major_job_categories}
      contentType={type}
      bachelor={major_bachelor}
      associate={major_associate}
      graduate={major_graduate}
      master={major_master}
      doctorate={major_doctorate}
    />
  )
}

const AlgoliaMajorSaved = props => {
  const { classContext, filterList } = props;

  const { locale, formatMessage } = useIntl()

  if (filterList === null) {
    return (
      <div className={`stream stream-${classContext} stream-${classContext}-saved`}>
        <p><FormattedMessage
          id="algolia-saved-js-message"
          defaultMessage="You have no saved items. Bookmark a page to save content that resonates with you."
        /></p>
      </div>
    )

  } else {

    return (

      <InstantSearch
        indexName="majors_explore"
        searchClient={searchClient}
      >
        <ScrollTo>

          <div className={`stream stream-${classContext} stream-${classContext}-saved`}>
            <EyeBrow text={formatMessage({ id: "algolia-toggle-saved-dropdown-majors", defaultMessage: "Majors" })} style={{ marginBottom: '2rem' }} />

            <Configure
              hitsPerPage={8}
              distinct
              filters={`${filterList} AND locale: ${locale}`}
            />

            <Hits hitComponent={majorResult} />
            <Pagination
              showFirst={false}
              showLast={false}
              translations={{
                previous: <FontAwesomeIcon icon={faChevronLeft} size="sm" />,
                next: <FontAwesomeIcon icon={faChevronRight} size="sm" />
              }}
            />
          </div>
        </ScrollTo>
      </InstantSearch>
    )
  }
}

export default AlgoliaMajorSaved
