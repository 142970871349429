import { createHeader, createAlignedHeader, createBulletPoint, createSpacingLine } from './componentHelpers'

export const renderVolunteerSection = volunteerExperiences => {

  return [
    createHeader("VOLUNTEER EXPERIENCE"),
    ...volunteerExperiences
      .filter(experience => experience)
      .flatMap(experience => {
        const title = experience.title || "TITLE"
        const organization = experience.employer || "ORGANIZATION"
        const bulletPoints = experience.bulletPoints || []
        const startDate= experience.startDate || "STARTDATE"
        const endDate = experience.endDate || "Present"

        return [
          createAlignedHeader(title, organization, startDate, endDate),
          ...bulletPoints.map(b => createBulletPoint(b)),
          createSpacingLine,
        ]
      }),
  ]
}
