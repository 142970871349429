import React, { useContext, useState } from "react"
import { FirebaseContext } from "../Firebase"
import { PortfolioContext } from "./PortfolioContext"
import { Dropdown, Button } from "react-bootstrap"
import ResumeTipsModal from "../resume/tips/tips-modal"
import { saveAs } from "file-saver"
import ExamplePDF from "../resume/example/sample-resume.pdf"
import Link from "../../link"
import useModal from "../hooks/useModal"
import PortfolioShareModal from "./portfolio-share-modal"
import { FormattedMessage } from "react-intl"
import ResumeDocModal from "../resume/docxExport/resume-doc-modal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faShare } from "@fortawesome/pro-solid-svg-icons"
import "./portfolio-heading.css"

const PortfolioHeading = () => {
  const { profile } = useContext(FirebaseContext)
  const { handleModalChange, handlePdfExport, handleResumeExport, isExportModalOpen, setIsExportModalOpen, selectedTemplate, setSelectedTemplate } = useContext(PortfolioContext)
  const [isShowingPortfolioShareModal, togglePortfolioShareModal] = useModal()

  const saveResumeExample = () => {
    saveAs(ExamplePDF, "wingspans-sample-resume")
  }

  return (
    <div>
      <PortfolioShareModal show={isShowingPortfolioShareModal} togglePortfolioShareModal={togglePortfolioShareModal} />
      {profile && profile.firstName && (
        <div className="portfolio-header-container">
          <div className="name-add-experience-container">
            <div className="name-container">
              <h2>
                {profile.firstName} {profile.lastName ?? ""}
              </h2>
            </div>

            <button
              onClick={(e) => {
                e.target.blur()
                handleModalChange("SELECTION")
              }}
              className="add-experience-button printer-none button component-button component-button-ghost component-button-ghost-inactive component-button-small"
            >
              + <FormattedMessage id="portfolio-heading-add-experience" defaultMessage="Add experience" />
            </button>
          </div>
          <div>
            <button className="share-button" onClick={() => togglePortfolioShareModal()} title="Share">
              <FormattedMessage id="portfolio-share" defaultMessage="Share" />
              {" "}
              <FontAwesomeIcon icon={faShare} size="lg" color="#54757A" />
            </button>

            <ResumeDocModal
              show={isExportModalOpen}
              setShow={setIsExportModalOpen}
              selectedTemplate={selectedTemplate}
              setSelectedTemplate={setSelectedTemplate}
              handleExportToWord={handleResumeExport}
              handleExportToPDF={handlePdfExport}
            />
          </div>
        </div>
      )}{" "}
    </div>
  )
}

export default PortfolioHeading
