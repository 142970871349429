import React from "react"
import Bookmark from "../bookmark/bookmark"
import Link from "../../link"

const CardCompanies = props => {
  const {
    img,
    title,
    slug,
    location,
    objectID,
    idx,
    industries,
    size,
    contentType,
    hideBookmarks,
  } = props

  return (
    <div
      className="component-card component-card-companies"
      key={idx}
      data-object={objectID}
    >
      {!hideBookmarks && (
        <Bookmark
          contentTitle={title}
          classContext={"companies"}
          contentId={objectID}
          contentSlug={slug}
          contentType={contentType}
        />
      )}
      <div className="component-card-image component-card-image-companies">
        <Link to={`/company/${slug}`}>
          <img
            src={`${img}?q=&auto=compress,&w=224&h=224&fit=crop&crop=faces`}
            alt=""
          />
        </Link>
      </div>
      <div className="component-card-meta component-card-meta-companies">
        <Link
          to={`/company/${slug}`}
          className="component-card-title component-card-title-companies"
        >
          {title}
        </Link>
        <div className="component-card-location component-card-location-companies">
          {location}
        </div>
        <span className="component-card-size component-card-size-companies">
          {size?.join(", ")}
        </span>
        {industries?.length > 0 && size?.length > 0 && (
          <span className="pipe">|</span>
        )}
        <span className="component-card-industry component-card-industry-companies">
          {industries?.join(", ")}
        </span>
      </div>
    </div>
  )
}

export default CardCompanies
