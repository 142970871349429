import React, { useState, useEffect } from "react"
import { useLocation } from "@reach/router"

const FinalistBanner = () => {
  const location = useLocation()

  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    if (typeof window !== "undefined") {
      const paths = ["/", "/signup/employers/", "/signup/employers", "/signup/educators/", "/signup/educators"]

      if (paths.includes(location.pathname)) {
        setIsVisible(true)
      } else {
        setIsVisible(false)
      }
      
    }
  }, [location])

  return (
    <div
      className="finalist-banner"
      style={{
        display: isVisible ? "flex" : "none",
        backgroundColor: "#dcf8fe",
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
        columnGap: "2rem",
        rowGap: "1rem",
        padding: "0.7rem 2rem",
        flexWrap: "wrap",
      }}
    >
      <div>
        <span>
        Wingspans is a <b>winner</b> of the <b>Engaging Adult Learners in Higher Education Track</b> Tools Competition
        </span>
      </div>
      <div
        style={{
          backgroundColor: "aliceblue",
          padding: "0.25rem 0.8rem",
          borderRadius: "5rem",
        }}
        >
        <a href="https://tools-competition.org/2023-24-winners-press-release/" style={{ fontWeight: "bold" }} target="_blank" >
          Learn More
        </a>
      </div>
    </div>
  )
}

export default FinalistBanner
