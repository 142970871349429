import React from "react"
import PropTypes from "prop-types"
import { Link as GatsbyLink, navigate as gatsbyNavigate } from "gatsby"
import { Location } from "@reach/router"
import { getCurrentPageLanguage, getTranslatedPath } from "./linkUtils"
import { defaultLanguage } from "./i18n"

const Link = React.forwardRef(
  ({ to, language, children, onClick, ...rest }, ref) => (
    <Location>
      {locationProps => {
        const { pathname = "" } = locationProps.location
        const languageLink = getCurrentPageLanguage(pathname)
        const link = getTranslatedPath(pathname, to)

        const handleClick = e => {
          if (languageLink) {
            localStorage.setItem("gatsby-intl-language", languageLink)
          }
          if (onClick) {
            onClick(e)
          }
        }

        return (
          <GatsbyLink {...rest} to={link} onClick={handleClick} ref={ref}>
            {children}
          </GatsbyLink>
        )
      }}
    </Location>
  )
)

Link.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string,
  language: PropTypes.string,
}

Link.defaultProps = {
  to: "",
}

export default Link

export const navigate = (to, options) => {
  if (typeof window === "undefined") {
    return
  }

  const { language, routed } = window.___gatsbyIntl
  const link = routed ? `/${language}${to}` : `${to}`
  gatsbyNavigate(link, options)
}

export const changeLocale = (language, to) => {
  if (typeof window === "undefined") {
    return
  }
  const removePrefix = pathname => {
    const base =
      typeof __BASE_PATH__ !== `undefined` ? __BASE_PATH__ : __PATH_PREFIX__
    if (base && pathname.indexOf(base) === 0) {
      pathname = pathname.slice(base.length)
    }
    return pathname
  }

  const removeLocalePart = pathname => {
    const routed = getCurrentPageLanguage(pathname) !== defaultLanguage
    if (!routed) {
      return pathname
    }
    const i = pathname.indexOf(`/`, 1)
    return pathname.substring(i)
  }

  const pathname =
    to || removeLocalePart(removePrefix(window.location.pathname))

  const link =
    language == defaultLanguage
      ? `${pathname}${window.location.search}`
      : `/${language}${pathname}${window.location.search}`
  localStorage.setItem("gatsby-intl-language", language)
  gatsbyNavigate(link)
}
