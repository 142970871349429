import React, { useState, useEffect, useContext } from "react"
import { FirebaseContext } from '../Firebase'
import { organizeByType } from "../bookmark/utils/utils"
import AlgoliaStoriesExplore from "./algolia-stories-explore"
import AlgoliaCareersExplore from "./algolia-careers-explore"
import AlgoliaCareersPersonalityExplore from "./algolia-careers-personality-explore"
import AlgoliaSchoolsExplore from "./algolia-schools-explore"
import AlgoliaMajorsExplore from "./algolia-majors-explore"
import AlgoliaCompaniesExplore from "./algolia-companies-explore"
import AlgoliaVideosExplore from "./algolia-videos-explore"
import { Dropdown } from "semantic-ui-react"

import { useQueryParam, StringParam, JsonParam, BooleanParam } from "use-query-params";

import "semantic-ui-css/components/dropdown.min.css"
import "semantic-ui-css/components/search.min.css"
import "semantic-ui-css/components/transition.min.css"
import "semantic-ui-css/components/label.min.css"
import "semantic-ui-css/components/icon.min.css"
import { FormattedMessage, useIntl } from "react-intl"

const AlgoliaToggle = props => {
  const { heading } = props
  const { locale } = useIntl()
  const { profile } = useContext(FirebaseContext)
  const [selectState, setSelectState] = useState('People')
  const [saved, setSaved] = useState(null)

  const [categoryQuery, setCategoryQuery] = useQueryParam("category", StringParam)
  const [queryFilters, setQueryFilters] = useQueryParam("queryFilters", JsonParam)
  const [showSavedQuery, setShowSavedQuery] = useQueryParam("showSaved", BooleanParam)
  const [personalityQuery, setPersonalityQuery] = useQueryParam("personality", BooleanParam)

  useEffect(() => {
    if (profile && profile.bookmarks) {
      const bookmarks = organizeByType(profile.bookmarks)
      setSaved(bookmarks)
    }
  }, [profile])

  const options = [
    {
      key: "Personality", text: <FormattedMessage
        id="algolia-toggle-dropdown-careers"
        defaultMessage="Careers"
      />, value: "PersonalityCareers"
    },
    {
      key: "People", text: <FormattedMessage
        id="algolia-toggle-dropdown-people"
        defaultMessage="People"
      />, value: "People"
    },
    {
      key: "Careers", text: <FormattedMessage
        id="algolia-toggle-dropdown-careers"
        defaultMessage="Careers"
      />, value: "Careers"
    },
    {
      key: "Schools", text: <FormattedMessage
        id="algolia-toggle-dropdown-schools"
        defaultMessage="Schools"
      />, value: "Schools"
    },
    {
      key: "Majors", text: <FormattedMessage
        id="algolia-toggle-dropdown-majors"
        defaultMessage="Majors"
      />, value: "Majors"
    },
    {
      key: "Companies", text: <FormattedMessage
        id="algolia-toggle-dropdown-companies"
        defaultMessage="Companies"
      />, value: "Companies"
    },
    {
      key: "Videos", text: <FormattedMessage
        id="algolia-toggle-dropdown-videos"
        defaultMessage="Videos"
      />, value: "Videos"
    },
  ]

  /**
   * Generate valid Algolia filter list from saved items array.
   *
   * @param {string} type
   * @returns {null|string}
   */
  const getFilters = type => {
    if (saved && saved.hasOwnProperty(type)) {
      return saved[type].join(" OR ")
    } else {
      return null
    }
  }

  // PersonalityCareers temporarily included in dropwdown but removed with CSS in aloglia css file, important to remove when refactoring
  // First item will be invisible from dropdown
  //
  //.explore-sortby.first-hidden{
  // div[role="option"]:first-of-type{
  //  display: none !important;
  //  }
  //}

  const content = {
    PersonalityCareers: <AlgoliaCareersPersonalityExplore classContext={'careers'} />,
    People: <AlgoliaStoriesExplore classContext={'stories'} filterList={getFilters('stories') || 'false_attribute:false'} />,
    Careers: <AlgoliaCareersExplore classContext={'careers'} filterList={getFilters('careers') || 'false_attribute:false'} />,
    Schools: <AlgoliaSchoolsExplore classContext={'schools'} filterList={getFilters('schools') || 'false_attribute:false'} />,
    Majors: <AlgoliaMajorsExplore classContext={'majors'} filterList={getFilters('majors') || 'false_attribute:false'} />,
    Companies: <AlgoliaCompaniesExplore classContext={'companies'} filterList={getFilters('companies') || 'false_attribute:false'} />,
    Videos: <AlgoliaVideosExplore classContext={'stories'} filterList={getFilters('stories') || 'false_attribute:false'} />
  }

  function changeHandler(e, data) {
    e.preventDefault()
    setSelectState(data.value);

    setCategoryQuery(data.value)
    setQueryFilters(undefined)
    setShowSavedQuery(undefined)
  }

  useEffect(() => {
    if (categoryQuery === "Careers" && personalityQuery === true) {
      setSelectState('PersonalityCareers')
    } else {
      setSelectState(categoryQuery || 'People')
    }
  }, [categoryQuery])

  return (
    <section className="explore">
      <div className="inner inner-explore">
        <h2>{heading}</h2>
        <div className="explore-sortby first-hidden">
          <span
            style={{
              fontWeight: "bold",
              marginBottom: ".5rem",
              display: "block",
              color: "#565656",
            }}
          >
            <FormattedMessage
              id="algolia-toggle-explore-categories"
              defaultMessage="Explore Categories"
            />
          </span>

          <Dropdown
            value={selectState}
            fluid
            required
            selection
            icon="chevron down"
            options={options}
            onChange={changeHandler}
            className="ais-MenuSelect"
            upward={false}
          />
        </div>
        {/* this ensures that the algolia components for each language are individually rendered and their internal states are reset during a language switch */}
        { locale === 'es-ES' ? content[selectState] : content[selectState]}
      </div>
    </section>
  )
}

export default AlgoliaToggle
